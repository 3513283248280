import React from 'react'

import Grid from '@mui/material/Grid'

import Paper from '@mui/material/Paper'

import Typography from '@mui/material/Typography'

import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import AvatarLetters from '../../components/AvatarLetters.tsx'

import { Suggestion, Signature } from '../../util/Api.tsx'

import { getTimeSince } from '../../util/Func.tsx'

interface SuggestionCardProps {
  suggestion: Suggestion
  signatures?: Signature[]
  selected?: boolean
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

const SuggestionCard = ({ suggestion, signatures = [], selected = false, onClick = () => {} }: SuggestionCardProps) => {
  return (
    <Paper elevation={selected ? 4 : 0} sx={{ cursor: 'pointer' }} onClick={onClick}>
      <Grid container padding={1} alignItems="center" flexWrap="nowrap">
        <Grid item>
          <AvatarLetters email={suggestion.email} />
        </Grid>
        <Grid item flexGrow={1} zeroMinWidth>
          <Typography noWrap>{suggestion.email}</Typography>
          <Typography variant="caption" color="text.disabled">
            Created {getTimeSince(suggestion.created)}
          </Typography>
          {suggestion.wontdo && (
            <Typography variant="caption" color="error">
              {' '}
              Wont-Do
            </Typography>
          )}
          {suggestion.done && (
            <Typography variant="caption" color="green">
              {' '}
              Done
            </Typography>
          )}
        </Grid>
        {suggestion.wontdo && (
          <Grid item>
            <CloseIcon color="error" />
          </Grid>
        )}
        {suggestion.done && (
          <Grid item>
            <CheckIcon color="success" />
          </Grid>
        )}
      </Grid>
      <Grid container padding={1} spacing={1} alignItems="center">
        <Grid item xs={12} zeroMinWidth>
          <Typography color="text.disabled" noWrap>
            {suggestion.type === 'create-signature' && `Create Signature ${suggestion.data.signature.name}`}
            {suggestion.type === 'edit-signature' &&
              `Modify Signature ${
                signatures.find((s) => s.id === suggestion.data.signature.id)?.name || suggestion.data.signature.name
              }`}
            {suggestion.type === 'recat-urls' &&
              `Add ${suggestion.data.urls.length} URL${suggestion.data.urls.length === 1 ? '' : 's'} into ${
                suggestion.data.signature.name
              }`}
            {suggestion.type === 'edit-appstores' &&
              `Edit app links for ${suggestion.data.signature?.name || suggestion.data.controlledApp?.name}`}
            {suggestion.type === 'new-appstore' && `Add new App ${suggestion.data?.controlId}`}
            {suggestion.type === 'add-keywords' && `Add keywords to ${suggestion.data.keywordId}`}
            {suggestion.type === 'other-request' && `Custom Request`}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SuggestionCard
