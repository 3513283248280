import React, { useCallback } from 'react'
import {
  Alert,
  Autocomplete,
  Box,
  Collapse,
  Dialog,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { KeyboardArrowDown, KeyboardArrowUp, SubdirectoryArrowRight } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import {
  obtainStatistics,
  obtainStatisticsRequests,
  Statistic,
  ReqStatistics,
  ReqStatistic,
  obtainProviderStatistics,
  providerStatistics,
} from '../util/Api.tsx'
import handleError from '../util/Error.tsx'
import CloseIcon from '@mui/icons-material/Close'

interface StatisticsDialogProps {
  open?: boolean
  onClose?: () => void
}

const StatisticsDialog = ({ open = false, onClose = () => {} }: StatisticsDialogProps) => {
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [selector, setSelector] = useState(0)
  const [appindexStatistics, setAppindexStatistics] = useState<Statistic[]>([])
  const [appindexStatisticsHeaders, setAppindexStatisticsHeaders] = useState<string[]>([])
  const [reqStatistics, setReqStatistics] = useState<ReqStatistics | null>(null)
  const [reqDates, setReqDates] = useState<string[]>([])
  const [selectedDateRange, setSelectedDateRange] = useState<string | undefined>('')
  const [shownStatistics, setShownStatistics] = useState<ReqStatistic[]>([])
  const [wontCount, setWontCount] = useState(0)
  const [doneCount, setDontCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [providerData, setProviderData] = useState<providerStatistics[]>([])
  const [providerHeaders, setProviderHeaders] = useState<string[]>([])
  const [providerDataSubCategories, setProviderDataSubCategories] = useState<providerStatistics[]>([])

  const handleClose = () => {
    onClose()
  }

  const populateSatistics = useCallback(async () => {
    try {
      if (!open) return
      setLoading(true)
      const data = await obtainStatistics()
      setAppindexStatistics(data.statistics)

      let providers: string[] = []
      data.statistics.forEach((stat) => {
        stat.entries.forEach((entry) => {
          if (providers.includes(entry.provider)) return
          if (entry.provider === '') return
          providers.push(entry.provider)
        })
      })
      setAppindexStatisticsHeaders(providers)
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      setLoading(false)
    }
  }, [open])

  const handleChangeSelected = (e: React.SyntheticEvent, newValue: string | undefined) => {
    setSelectedDateRange(newValue)
    let doneCountTemp: number = 0
    let wontdoCountTemp: number = 0
    let totalCountTemp: number = 0
    if (!reqStatistics) return
    if (!newValue) return

    const stats = reqStatistics[newValue]
    setShownStatistics(stats)
    stats.forEach((stat) => {
      Object.keys(stat).forEach((statName) => {
        const miniStat = stat[statName]
        doneCountTemp += miniStat.done
        wontdoCountTemp += miniStat.wontdo
        totalCountTemp += miniStat.total
      })
    })
    setDontCount(doneCountTemp)
    setWontCount(wontdoCountTemp)
    setTotalCount(totalCountTemp)
  }

  const populateStatisticsRequests = useCallback(async () => {
    try {
      setLoading(true)
      const data = await obtainStatisticsRequests()
      setReqStatistics(data.requests)
      let dates: string[] = []
      Object.keys(data.requests).forEach((key) => {
        dates.push(key)
      })

      setReqDates(dates || [])
      setSelectedDateRange(dates[0])
      setShownStatistics(Object.values(data.requests)[0] || [])

      let doneCountTemp: number = 0
      let wontdoCountTemp: number = 0
      let totalCountTemp: number = 0

      Object.values(data.requests)[0].forEach((stat: ReqStatistic) => {
        Object.keys(stat).forEach((statName) => {
          const miniStat = stat[statName]
          doneCountTemp += miniStat.done
          wontdoCountTemp += miniStat.wontdo
          totalCountTemp += miniStat.total
        })
      })

      setDontCount(doneCountTemp)
      setWontCount(wontdoCountTemp)
      setTotalCount(totalCountTemp)
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      setLoading(false)
    }
  }, [setLoading])

  const populateProviderData = useCallback(async () => {
    try {
      setLoading(true)
      const data = await obtainProviderStatistics()
      // save all unique data.provider values in an array
      let providers: string[] = []
      data.statistics.forEach((entry) => {
        // if the json key name is in providers array in data.statistics.providers then skip otherwise add to providers array
        entry.provider.forEach((provider, index) => {
          if (Object.keys(provider)[0] === '') return
          if (providers.includes(Object.keys(provider)[0])) return
          providers.push(Object.keys(provider)[0])
        })
      })

      setProviderHeaders(providers)

      // order all data.statistics by name alphabetically
      const ordered = data.statistics.sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
        return 0
      })

      setProviderData(ordered.filter((entry) => entry.theme === true))
      setProviderDataSubCategories(ordered.filter((entry) => entry.theme === false))
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      setLoading(false)
    }
  }, [setLoading])

  const handleSelector = (event: React.SyntheticEvent, newValue: number) => {
    setSelector(newValue)
  }

  useEffect(() => {
    if (selector === 0) {
      populateSatistics()
    } else if (selector === 1) {
      populateStatisticsRequests()
    } else if (selector === 2) {
      if (providerData.length === 0) {
        populateProviderData()
      }
    }
  }, [populateSatistics, populateStatisticsRequests, populateProviderData, providerData.length, selector])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={
        selector !== 1 ? (providerHeaders.length > 7 || appindexStatisticsHeaders.length > 7 ? 'xl' : 'lg') : 'sm'
      }
    >
      <div
        style={{
          height: '100%',
          backgroundColor: theme.palette.background.default,
          minHeight: 400,
        }}
      >
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                Monthly Appindex Statistics
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container padding={2} justifyContent="center">
            <Grid item xs={12}>
              <Collapse in={Boolean(error)}>
                <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
                  {error || ' '}
                </Alert>
              </Collapse>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Tabs value={selector} onChange={handleSelector}>
              <Tab label="Appindex" />
              <Tab label="Requests" />
              <Tab label="Providers" />
            </Tabs>
          </Grid>
          <Grid container justifyContent="center" padding={2} spacing={2}>
            {selector === 1 && (
              <Grid item>
                <Typography>Click the month for relevant statistics</Typography>
              </Grid>
            )}
            {selector === 0 && (
              <Grid item>
                <Paper>
                  <Table sx={{ minWidth: '500px' }}>
                    <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography variant="overline" fontWeight="bold">
                            Month - Year
                          </Typography>
                        </TableCell>
                        {appindexStatisticsHeaders.map((provider) => (
                          <TableCell width={100} align="right">
                            <Typography variant="overline" fontWeight="bold">
                              {provider}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {appindexStatistics.map((data) => (
                        <TableRow>
                          <TableCell>{data.name}</TableCell>
                          {appindexStatisticsHeaders.map((header) => {
                            const matchingEntry = data.entries.find((entry) => entry.provider === header)
                            return <TableCell align="right">{matchingEntry ? matchingEntry.count : 0}</TableCell>
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            )}
            {selector === 1 && (
              <Grid item>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Autocomplete
                      options={reqDates}
                      value={selectedDateRange}
                      onChange={handleChangeSelected}
                      sx={{ width: 250 }}
                      renderInput={(params) => <TextField {...params} label="Month" />}
                      disableClearable
                      disabled={loading}
                    />
                  </Grid>
                </Grid>

                <Grid item padding={1}>
                  <Paper>
                    <TableContainer>
                      <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
                      <Table sx={{ minWidth: 500 }} stickyHeader size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Request Type</TableCell>
                            <TableCell>Done</TableCell>
                            <TableCell>Wont-Do</TableCell>
                            <TableCell>Total</TableCell>
                          </TableRow>
                        </TableHead>
                        {Object.keys(shownStatistics).map((key, index) => {
                          const data = shownStatistics[index]
                          return Object.values(data).map((stats, ind) => (
                            <TableBody>
                              <TableRow hover key={`request-statistics-table-item-${index}`}>
                                <TableCell component="th" scope="row">
                                  <Typography variant="body2" noWrap sx={{ maxWidth: 200 }}>
                                    {Object.keys(data)[ind]}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                  }}
                                >
                                  <Typography variant="body2" sx={{ maxWidth: 200 }}>
                                    {stats.done}
                                  </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Typography variant="body2" noWrap sx={{ maxWidth: 200 }}>
                                    {stats.wontdo}
                                  </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Typography variant="body2" noWrap sx={{ maxWidth: 200 }}>
                                    {stats.total}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ))
                        })}
                        <TableBody>
                          <TableRow hover key={`total`}>
                            <TableCell component="th" scope="row">
                              Total
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Typography variant="body2" noWrap sx={{ maxWidth: 200 }}>
                                {doneCount}
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Typography variant="body2" noWrap sx={{ maxWidth: 200 }}>
                                {wontCount}
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Typography variant="body2" noWrap sx={{ maxWidth: 200 }}>
                                {totalCount}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              </Grid>
            )}
            {selector === 2 && (
              <Grid item>
                <>
                  <Box>
                    <Typography sx={{ mb: 2 }}>
                      Shows the percentage of websites that have been classified by each provider for the last 30 Days
                    </Typography>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table>
                      <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography variant="overline" fontWeight="bold">
                              Theme/Category
                            </Typography>
                          </TableCell>
                          {providerHeaders.map((provider) => (
                            <TableCell width={100} align="right">
                              <Typography variant="overline" fontWeight="bold">
                                {provider}
                              </Typography>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* for each entry in providerData if any providerDataSubCategory entries for signature_categoryy match the providerData.signature_id, then total up all seperate entries in each provider e.g. if two signatures contain provder categegory bc one with 120, the other with 160 then the provider bd will contain 280 */}
                        {providerData.map((data) => (
                          <RenderChildren
                            data={data}
                            headers={providerHeaders}
                            providerDataSubCategories={providerDataSubCategories}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}

interface RenderChildrenProps {
  data: providerStatistics
  headers: string[]
  providerDataSubCategories: providerStatistics[]
}

const RenderChildren = ({ data, headers, providerDataSubCategories }: RenderChildrenProps) => {
  const [open, setOpen] = useState(false)

  const totals: { [key: string]: number }[] = headers.map((header) => ({ [header]: 0 }))
  providerDataSubCategories.forEach((sub) => {
    if (sub.signature_category === data.signature_id) {
      sub.provider.forEach((provider) =>
        totals.find((total, index) =>
          Object.keys(total)[0] === Object.keys(provider)[0]
            ? (totals[index][Object.keys(provider)[0]] += Object.values(provider)[0])
            : ''
        )
      )
    }
  })

  return (
    <>
      <TableRow sx={open ? { backgroundColor: 'lightblue' } : { backgroundColor: '' }}>
        <TableCell>
          {providerDataSubCategories.filter((sub) => sub.signature_category === data.signature_id).length > 0 ? (
            <Grid container>
              <Grid item>
                {open ? (
                  <KeyboardArrowUp onClick={() => setOpen(false)} />
                ) : (
                  <KeyboardArrowDown onClick={() => setOpen(true)} />
                )}
              </Grid>
              <Grid item pl={1}>
                <Typography variant="overline">{data.name}</Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item pl={4}>
                <Typography variant="overline">{data.name}</Typography>
              </Grid>
            </Grid>
          )}
        </TableCell>
        {totals.map((total, index) => {
          let max: number = 0
          totals.forEach((pro) => {
            if (typeof Object.values(pro)[0] !== 'number') return
            max += Object.values(pro)[0]
          })
          const pro = `${Math.round((Object.values(total)[0] / max) * 100)}%`
          return (
            headers[index] === Object.keys(total)[0] &&
            (pro === 'NaN%' ? (
              <TableCell align="right">0%</TableCell>
            ) : (
              <Tooltip title={Object.values(total)[0]} placement="right">
                <TableCell align="right">{pro}</TableCell>
              </Tooltip>
            ))
          )
        })}
      </TableRow>
      {open &&
        providerDataSubCategories
          .filter((sub) => sub.signature_category === data.signature_id)
          .map((sub) => {
            return (
              <TableRow>
                <TableCell>
                  <Grid container>
                    <Grid item pl={3}>
                      <SubdirectoryArrowRight fontSize="small" />
                    </Grid>
                    <Grid item pl={1}>
                      <Typography variant="overline">{sub.name}</Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                {headers.map((header) => {
                  let max: number = 0
                  sub.provider.forEach((pro) => {
                    if (typeof Object.values(pro)[0] !== 'number') return
                    max += Object.values(pro)[0]
                  })

                  const result = sub.provider.map((provider, index) => {
                    const pro = `${Math.round((Object.values(provider)[0] / max) * 100)}%`
                    return (
                      header === Object.keys(provider)[0] && (
                        <Tooltip title={Object.values(provider)[0]} placement="right">
                          <TableCell align="right">{pro}</TableCell>
                        </Tooltip>
                      )
                    )
                  })
                  const filtered = result.filter((res) => res !== false)
                  if (filtered.length === 0) return <TableCell align="right">0%</TableCell>
                  return filtered[0]
                })}
              </TableRow>
            )
          })}
    </>
  )
}

export default StatisticsDialog
