import React, { useState } from 'react'
import Box from '@mui/material/Box'

import questionImage from '../assets/question.png'
import { SxProps } from '@mui/material'

interface LazyImageProps {
  src: string
  size?: number
  sx?: SxProps
  [key: string]: any
}

const LazyImage = ({ src, size = 24, sx = {}, ...props }: LazyImageProps) => {
  const [loading, setLoading] = useState(true)

  const handleOnLoad = () => {
    setLoading(false)
  }

  const handleOnError = () => {
    setLoading(true)
  }

  const imgStyle = {
    width: 'auto',
    height: '100%',
  }

  return (
    <Box
      sx={{
        width: size,
        height: size,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
      {...props}
    >
      {loading && <img src={questionImage} style={imgStyle} alt="" />}
      <img
        src={src}
        onLoad={handleOnLoad}
        onError={handleOnError}
        style={{ ...imgStyle, display: loading ? 'none' : 'inline-block' }}
        alt=""
      />
    </Box>
  )
}

export default LazyImage
