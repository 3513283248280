import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'

import { useTheme } from '@mui/material/styles'

interface AddEditGenericListEntryDialogProps {
  open?: boolean
  loading?: boolean
  error: string
  onClickAdd: (entry: string) => void
  onClose: () => void
}

const AddEditGenericListEntry = ({
  open = false,
  loading = false,
  error = '',
  onClickAdd = () => {},
  onClose = () => {},
}: AddEditGenericListEntryDialogProps) => {
  const theme = useTheme()
  const [entry, setEntry] = useState('')

  const handleChangeEntry = (e: any) => {
    setEntry(e.target.value)
  }

  const handleClickAdd = () => {
    if (!entry) return
    onClickAdd(entry)
  }

  const handleClose = () => {
    onClose()
  }

  useEffect(() => {
    setEntry('')
  }, [open])

  return (
    <Dialog open={open} onClose={handleClose}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                Add a Entry
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          {error && (
            <Grid container justifyContent="center" sx={{ mb: 3 }}>
              <Grid item>
                <Alert severity="error">{error}</Alert>
              </Grid>
            </Grid>
          )}
          <Grid container direction="column" alignItems="stretch" justifyContent="center" padding={2} spacing={3}>
            <Grid item>
              <TextField
                id="entry"
                variant="outlined"
                label="ENTRY"
                fullWidth
                value={entry}
                onChange={handleChangeEntry}
                helperText={'Enter the Entry wish to associate'}
              />
            </Grid>

            <Grid item>
              <Button variant="contained" fullWidth disabled={loading || !entry} onClick={handleClickAdd}>
                Add
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}

export default AddEditGenericListEntry
