import React, { useState, useEffect } from 'react'
import useIsMounted from 'react-is-mounted-hook'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Collapse from '@mui/material/Collapse'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'

import CloseIcon from '@mui/icons-material/Close'

import LoadingButton from '@mui/lab/LoadingButton'

import SignatureSelect from './SignatureSelect.tsx'

import { addSignatureURL, Signature } from '../util/Api.tsx'
import handleError from '../util/Error.tsx'

interface ClassifyDialogProps {
  open?: boolean
  url: string
  signatures: Signature[]
  onClose?: () => void
  onClassify?: (signatureId: string) => void
}

const ClassifyDialog = ({
  open = false,
  url,
  signatures = [],
  onClose = () => {},
  onClassify = () => {},
}: ClassifyDialogProps) => {
  const theme = useTheme()
  const isMounted = useIsMounted()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [signature, setSignature] = useState<Signature | null>(null)

  const handleClickClassify = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      e.preventDefault()
      if (!url) throw new Error('Url is invalid')
      if (!signature) throw new Error('Signature is invalid')

      setError('')
      setLoading(true)

      await addSignatureURL(signature.id, url)
      if (!isMounted()) return
      onClassify(signature.id)
      onClose()
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClose()
  }

  const handleEditId = (e: React.SyntheticEvent, value: Signature | null) => {
    setSignature(value)
  }

  useEffect(() => {
    if (!open) return
    setError('')
  }, [open])

  return (
    <Dialog open={open} onClose={handleClose}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Paper variant="outlined">
                <Grid container padding={2} spacing={2} justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Typography variant="h5" noWrap>
                      Classify {url}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                {error && (
                  <Grid container padding={2} justifyContent="center">
                    <Grid item xs={12}>
                      <Collapse in={Boolean(error)}>
                        <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
                          {error || 'Error'}
                        </Alert>
                      </Collapse>
                    </Grid>
                  </Grid>
                )}
                <Grid container padding={2} spacing={4} justifyContent="center" alignItems="stretch" direction="column">
                  <Grid item>
                    <SignatureSelect
                      id="signatureReclassifyUrl"
                      signatures={signatures}
                      onChange={handleEditId}
                      defaultSignature={null}
                    />
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      disableElevation
                      loading={loading}
                      onClick={handleClickClassify}
                      fullWidth
                    >
                      Reclassify
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}

export default ClassifyDialog
