import { Button, Dialog, Grid, IconButton, TextField, Typography, useTheme } from '@mui/material'
import { ImageClassifierEntries } from '../util/Api.tsx'
import CloseIcon from '@mui/icons-material/Close'
import Alert from '@mui/material/Alert'
import { useEffect, useState } from 'react'

interface AddClassiderTermProps {
  open: boolean
  editing: boolean
  error: string
  loading: boolean
  classifierEntry: ImageClassifierEntries | null
  onAdd: (term: string, notes: string) => void
  onEdit: (classifierEntry: ImageClassifierEntries, term: string, notes: string) => void
  onClose: () => void
}

const AddClassiderTerm = ({
  open = false,
  editing,
  classifierEntry,
  loading,
  error,
  onAdd = () => {},
  onEdit = () => {},
  onClose,
}: AddClassiderTermProps) => {
  const theme = useTheme()
  const [term, setTerm] = useState<string | null>(null)
  const [notes, setNotes] = useState<string>('')

  const handleChangeTerm = (e: any) => {
    setTerm(e?.target.value)
  }

  const handleChangeNotes = (e: any) => {
    setNotes(e?.target.value)
  }

  const handleClose = () => {
    onClose()
  }

  const handleClickAdd = () => {
    if (!term) return
    if (editing === true) {
      if (!classifierEntry) return
      onEdit(classifierEntry, term, notes)
    } else {
      onAdd(term, notes)
    }
    setTerm('')
    setNotes('')
  }

  useEffect(() => {
    setTerm(classifierEntry?.term || null)
    setNotes(classifierEntry?.notes || '')
  }, [classifierEntry])

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                {editing === true ? 'Edit' : 'Add'} Term
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          {error && (
            <Grid container justifyContent="center" sx={{ mb: 3 }}>
              <Grid item>
                <Alert severity="error">{error}</Alert>
              </Grid>
            </Grid>
          )}
          <Grid container direction="column" alignItems="stretch" justifyContent="center" padding={2} spacing={3}>
            <Grid item>
              <Typography variant="overline" noWrap gutterBottom>
                Term:
              </Typography>
              <TextField id="term" variant="outlined" fullWidth onChange={handleChangeTerm} value={term || ''} />
            </Grid>
            <Grid item>
              <Typography variant="overline" noWrap gutterBottom>
                Notes (Optional):
              </Typography>
              <TextField
                id="notes"
                variant="outlined"
                fullWidth
                multiline
                minRows={2}
                helperText={`${notes.length} / 500`}
                onChange={handleChangeNotes}
                value={notes}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                fullWidth
                disabled={(term ? term.length < 3 : true) || loading}
                onClick={handleClickAdd}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}

export default AddClassiderTerm
