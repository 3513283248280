import React, { useState, useCallback, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Alert from '@mui/material/Alert'
import CloseIcon from '@mui/icons-material/Close'
import ArrowLeftIcon from '@mui/icons-material/ArrowBack'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTheme } from '@mui/material/styles'
import useIsMounted from 'react-is-mounted-hook'
import {
  getDetailedSignatures,
  getURLInfoQuick,
  addCreateSignatureSuggestion,
  addRecatUrlsSuggestion,
  addEditSignatureSuggestion,
  addEditAppstoresSuggestion,
  Signature,
  Keyword,
  Suggestion,
  SignatureUrlInfo,
  getKeywords,
  addKeywordsSuggestion,
  addOtherSuggestion,
  AppListings,
  getAppListings,
  getApps,
  AppControls,
  bulkClassify,
} from '../util/Api.tsx'
import handleError from '../util/Error.tsx'
import { useHistory } from 'react-router-dom'

import UrlsEdit from './UrlsEdit.tsx'
import SignatureEdit from './SignatureEdit.tsx'
import SignatureSelect from './SignatureSelect.tsx'
import ApplicationEdit from './ApplicationEdit.tsx'
import KeywordSelect from './KeywordSelect.tsx'
import KeywordAdd from './KeywordAdd.tsx'
import KeywordTypeSelect from './KeywordTypeSelect.tsx'
import { Divider, Link, Tooltip } from '@mui/material'
import ControlledAppSelector from './ControlledAppSelector.tsx'
import AddControlDialog from './AddControlDialog.tsx'
import MassClassification from './MassClassification.tsx'

interface CreateSuggestionDialogProps {
  open: boolean
  defaultUrls?: string[]
  onClose?: () => void
  afterSubmit?: (suggestion: Suggestion) => void
}

interface ErroredField {
  field: keyof Signature
  msg: string
}

export interface ClassifyMessages {
  warning: number
  msg: string
}

const CreateSuggestionDialog = ({
  open = false,
  defaultUrls = [],
  onClose = () => {},
  afterSubmit = () => {},
}: CreateSuggestionDialogProps) => {
  const theme = useTheme()
  const isMounted = useIsMounted()
  const history = useHistory()
  const [error, setError] = useState('')
  const [type, setType] = useState<string | null>(null)
  const [signatures, setSignatures] = useState<Signature[]>([])
  const [loading, setLoading] = useState(false)
  const [erroredFields, setErroredFields] = useState<ErroredField[]>([])
  const [signature, setSignature] = useState<Signature | null>(null)
  const [oldSignature, setOldSignature] = useState<Signature | null>(null)
  const [urls, setUrls] = useState<SignatureUrlInfo[]>([])
  const [selectedSignature, setSelectedSignature] = useState<Signature | null>(null)
  const [showOtherConfirmation, setShowOtherConfirmation] = useState(false)

  const [stage, setStage] = useState(0)
  const [showAppEdit, setShowAppEdit] = useState(false)
  const [showSignatureCreate, setShowSignatureCreate] = useState(false)
  const [showUrls, setShowUrls] = useState(false)
  const [showSignatureSelect, setShowSignatureSelect] = useState(false)
  const [showKeywordSelect, setShowKeywordSelect] = useState(false)
  const [showKeywordAdd, setShowKeywordAdd] = useState(false)
  const [showKeywordTypeSelect, setShowKeywordTypeSelect] = useState(false)
  const [showMassClassification, setShowMassClassification] = useState(false)
  const [MassClassificationEntry, setMassClassificationEntry] = useState<string[]>([])
  const [massUrls, setMassUrls] = useState<string>('')
  const [classifyValidationMessage, setClassifyValidationMessage] = useState<ClassifyMessages | null>(null)
  const [keywords, setKeywords] = useState<Keyword[]>([])
  const [keyword, setKeyword] = useState<Keyword | null>(null)
  const [selectedKeyword, setSelectedKeyword] = useState<Keyword | null>(null)
  const [showDescription, setShowDescription] = useState(false)
  const [description, setDescription] = useState('')
  const [ticketNumber, setTicketNumber] = useState(0)
  const [keywordType, setKeywordType] = useState<string | null>('')
  const [keywordEntry, setKeywordEntry] = useState<string[]>([])
  const [controlledApps, setControlledApps] = useState<AppListings[]>([])
  const [applications, setApplications] = useState<AppControls[]>([])
  const [showAppSelect, setShowAppSelect] = useState(false)
  const [selectedControlledApps, setSelectedControlledApps] = useState<AppListings | null>(null)
  const [oldApps, setOldApps] = useState<AppControls[]>([])
  const [newApps, setNewApps] = useState<AppControls[]>([])
  const [controlFavicon, setControlFavicon] = useState('')
  const [controlId, setControlId] = useState('controlledapp.')
  const [controlName, setControlName] = useState('')
  const [controlCategory, setControlCategory] = useState('')

  // const [draft, setDraft] = useState(false)
  const [suggestionId, setSuggestionId] = useState<string | null>(null)

  const importUrls = useCallback(
    async (newUrls: string[]) => {
      try {
        const importedUrls = await Promise.all(
          newUrls.map(async (url) => {
            const { exact, valid } = await getURLInfoQuick(url)
            const primarySig = exact.find((e) => e.primarySig === true)
            const urlInfo = primarySig
              ? {
                  signatureId: primarySig.signatureId,
                  url,
                  valid,
                  primarySig: primarySig.primarySig,
                }
              : {
                  signatureId: null,
                  url,
                  valid,
                  primarySig: false,
                }
            return urlInfo
          })
        )
        if (!isMounted()) return
        setUrls(importedUrls)
      } catch (err) {
        throw err
      }
    },
    [isMounted]
  )

  useEffect(() => {
    if (defaultUrls.length !== 0) {
      setType('recat-urls')
      importUrls(defaultUrls)
      setShowUrls(true)
      setOldSignature(null)
      setSignature(null)
    }
  }, [importUrls, defaultUrls])

  const handleClose = () => {
    if (loading) return
    setError('')
    onClose()
    setType(null)
    setShowUrls(false)
    setShowSignatureCreate(false)
    setShowSignatureSelect(false)
    setShowDescription(false)
    setShowAppEdit(false)
    setShowKeywordSelect(false)
    setShowKeywordAdd(false)
    setShowKeywordTypeSelect(false)
    setShowAppSelect(false)
    setSelectedControlledApps(null)
    setShowMassClassification(false)
    setControlId('controlledapp.')
    setControlName('')
    setControlFavicon('')
    setNewApps([])
    setStage(0)
  }

  const handleCloseSave = () => {
    if (loading) return
    onClose()
  }

  const selectType = (value: string) => {
    setType(value)
    if (value === 'create-signature') {
      setShowSignatureCreate(true)
      setOldSignature(null)
      setSignature({
        name: '',
        category: null,
        url: '',
        noise: false,
        enabled: true,
        focus: true,
        reporting: true,
        faviconUrl: '',
        description: '',
        id: '',
        isCategory: false,
        isSubCategory: false,
        isHighLevelSignature: false,
        isLowLevelSignature: false,
        minVersion: 0,
        dependencies: [],
        criteria: [],
        nonExclusiveCriteria: [],
        appstores: [],
        tags: [],
        hidden: false,
      })
      setUrls([])
      setMassClassificationEntry([])
    } else if (value === 'edit-signature') {
      setShowSignatureSelect(true)
      setOldSignature(null)
      setSignature(null)
      setUrls([])
      setOldApps([])
      setNewApps([])
      setMassClassificationEntry([])
    } else if (value === 'recat-urls') {
      setShowUrls(true)
      setOldSignature(null)
      setSignature(null)
      setUrls([])
      setOldApps([])
      setNewApps([])
      setMassClassificationEntry([])
    } else if (value === 'edit-appstores') {
      setShowAppSelect(true)
      setOldSignature(null)
      setSignature(null)
      setUrls([])
      setOldApps([])
      setNewApps([])
      setMassClassificationEntry([])
    } else if (value === 'add-keywords') {
      setShowKeywordSelect(true)
      setOldSignature(null)
      setSignature(null)
      setUrls([])
      setOldApps([])
      setNewApps([])
      setMassClassificationEntry([])
    } else if (value === 'other-request') {
      setShowOtherConfirmation(true)
      setOldSignature(null)
      setSignature(null)
      setUrls([])
      setOldApps([])
      setNewApps([])
      setMassClassificationEntry([])
    } else if (value === 'mass-classification') {
      setShowMassClassification(true)
      setOldSignature(null)
      setSignature(null)
      setUrls([])
      setOldApps([])
      setNewApps([])
    }
  }

  const handleReverseSelectType = () => {
    setError('')
    setType(null)
    setShowUrls(false)
    setShowSignatureCreate(false)
    setShowSignatureSelect(false)
    setShowAppSelect(false)
    setShowAppEdit(false)
    setShowKeywordAdd(false)
    setShowKeywordSelect(false)
    setShowOtherConfirmation(false)
    setShowMassClassification(false)
    setClassifyValidationMessage(null)
  }

  const handleRequestMore = () => {
    setError('')
    setType(null)
    setShowUrls(false)
    setShowSignatureCreate(false)
    setShowSignatureSelect(false)
    setShowDescription(false)
    setShowAppEdit(false)
    setShowAppSelect(false)
    setShowKeywordSelect(false)
    setStage(0)
    setClassifyValidationMessage(null)
  }

  const handleGoToTicket = () => {
    if (!suggestionId) return
    history.push(`/suggestions/${suggestionId}`)
    handleClose()
  }

  const handleSaveRequest = async () => {
    try {
      if (signature === null && keyword === null && description === null) return
      setLoading(true)
      setError('')
      let data = null
      if (signature !== null) {
        if (type === 'create-signature') {
          data = await addCreateSignatureSuggestion({
            signature,
            urls: urls.map((u) => u.url),
            message: description,
            ticket: ticketNumber,
          })
        } else if (type === 'edit-signature') {
          data = await addEditSignatureSuggestion({
            signature,
            message: description,
            ticket: ticketNumber,
          })
        } else if (type === 'recat-urls') {
          data = await addRecatUrlsSuggestion({
            signatureId: signature.id,
            urls: urls.map((u) => u.url),
            message: description,
            ticket: ticketNumber,
          })
        }
      } else if (keyword !== null) {
        if (type === 'add-keywords') {
          data = await addKeywordsSuggestion({
            keywordId: keyword.id,
            keyword_Entry: keywordEntry,
            keyword_Type: keywordType,
            message: description,
            ticket: ticketNumber,
          })
        }
      } else if (selectedControlledApps !== null) {
        if (type === 'edit-appstores') {
          if (selectedControlledApps?.id === 'create-new') {
            data = await addEditAppstoresSuggestion({
              controlId: controlId,
              controlName: controlName,
              newControl: true,
              controlFavicon: controlFavicon,
              category: controlCategory,
              signatureId: '',
              appstores: newApps,
              message: description,
              ticket: ticketNumber,
            })
          } else {
            data = await addEditAppstoresSuggestion({
              controlId:
                controlledApps.find((a) => a.id === selectedControlledApps?.id && a.format === 'APP')?.id || '',
              controlName: '',
              newControl: false,
              controlFavicon: '',
              category: '',
              signatureId: signatures.find((s) => s.id === selectedControlledApps?.id)?.id || '',
              appstores: newApps,
              message: description,
              ticket: ticketNumber,
            })
          }
        }
      } else if (description !== null) {
        if (type === 'other-request') {
          data = await addOtherSuggestion({
            message: description,
            ticket: ticketNumber,
          })
        }
      }

      if (!data) return

      setSuggestionId(data.suggestion.id)
      setShowDescription(false)
      setDescription('')
      setTicketNumber(0)
      setUrls([])
      setKeywordEntry([])
      setKeyword(null)
      setSelectedKeyword(null)
      setKeywordType(null)
      setSelectedSignature(null)
      setStage(-1)
      afterSubmit(data.suggestion)
    } catch (err) {
      const { msg } = handleError(err)
      if (!isMounted()) return
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  const handleKeywordEntryChange = (entry: string[]) => {
    setKeywordEntry(entry)
  }

  const handleAdvanceStage = () => {
    setError('')
    if (stage === 0) {
      if (type === 'create-signature') {
        const success = validateSignature()
        if (!success) return
        setShowSignatureCreate(false)
        setShowUrls(true)
        setErroredFields([])
        if (typeof signature?.url === 'string') {
          getURLInfoQuick(signature.url)
            .then(({ exact, valid }) => {
              const primary = exact.find((e) => e.primarySig === true)
              const urlInfo = primary
                ? {
                    signatureId: primary.signatureId,
                    url: signature.url || '',
                    valid,
                    primarySig: primary.primarySig,
                  }
                : {
                    signatureId: null,
                    url: signature.url || '',
                    valid,
                    primarySig: true,
                  }
              setUrls([urlInfo])
            })
            .catch((err) => {
              console.error(err)
              handleReverseStage()
            })
        }
      } else if (type === 'edit-signature') {
        const foundSignature = signatures.find((sig) => sig.id === selectedSignature?.id)
        if (!foundSignature) return
        setShowSignatureSelect(false)
        setShowSignatureCreate(true)
        setOldSignature(foundSignature)
        setSignature(foundSignature)
        setUrls([])
        setErroredFields([])
      } else if (type === 'recat-urls') {
        // if urls contain IP's reject
        const IPValidation = urls.some((url) => url.url.match(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/))
        if (IPValidation) {
          setError('IP addresses cannot be recategorised')
          return
        }
        setShowSignatureSelect(true)
        setShowUrls(false)
        setErroredFields([])
      } else if (type === 'edit-appstores') {
        setShowAppSelect(false)
        setOldApps(
          applications.filter(
            (app) => app.id === selectedControlledApps?.id || app.signature_id === selectedControlledApps?.id
          )
        )
        setNewApps(
          applications.filter(
            (app) => app.id === selectedControlledApps?.id || app.signature_id === selectedControlledApps?.id
          )
        )
        setShowAppEdit(true)
      } else if (type === 'add-keywords') {
        const foundKeyword = keywords.find((key) => key.id === selectedKeyword?.id)
        if (!foundKeyword) return
        setShowKeywordTypeSelect(true)
        setKeyword(foundKeyword)
        setShowKeywordSelect(false)
      } else if (type === 'other-request') {
        setShowOtherConfirmation(false)
        setShowDescription(true)
      }
    } else if (stage === 1) {
      if (type === 'create-signature') {
        setShowUrls(false)
        setShowDescription(true)
      } else if (type === 'edit-signature') {
        const success = validateSignature()
        if (!success) return
        setShowSignatureCreate(false)
        setShowDescription(true)
      } else if (type === 'recat-urls') {
        const foundSignature = signatures.find((sig) => sig.id === selectedSignature?.id)
        if (!foundSignature) return
        // if url signatureId matches the selected signature then throw error
        const foundUrl = urls.find((url) => url.signatureId === foundSignature.id)
        if (foundUrl) {
          setError('One or more of the urls you selected are already associated with this signature')
          return
        }
        setShowSignatureSelect(false)
        setOldSignature(foundSignature)
        setSignature(foundSignature)
        setShowDescription(true)
      } else if (type === 'edit-appstores') {
        setShowAppEdit(false)
        setShowDescription(true)
      } else if (type === 'add-keywords') {
        setShowKeywordTypeSelect(false)
        setShowKeywordAdd(true)
      }
    } else if (stage === 2 && type === 'add-keywords') {
      setShowKeywordAdd(false)
      setShowDescription(true)
    }
    setStage(stage + 1)
  }

  const handleReverseStage = () => {
    setError('')
    if (stage === 1) {
      if (type === 'create-signature') {
        setShowUrls(false)
        setShowSignatureCreate(true)
      } else if (type === 'edit-signature') {
        setShowSignatureCreate(false)
        setShowSignatureSelect(true)
      } else if (type === 'recat-urls') {
        setShowUrls(true)
        setShowSignatureSelect(false)
      } else if (type === 'edit-appstores') {
        setShowAppEdit(false)
        setShowAppSelect(true)
      } else if (type === 'add-keywords') {
        setShowKeywordTypeSelect(false)
        setShowKeywordSelect(true)
      } else if (type === 'other-request') {
        setShowOtherConfirmation(true)
        setShowDescription(false)
      }
    } else if (stage === 2) {
      if (type === 'create-signature') {
        setShowUrls(true)
        setShowDescription(false)
      } else if (type === 'edit-signature') {
        setShowSignatureCreate(true)
        setShowDescription(false)
      } else if (type === 'recat-urls') {
        setShowSignatureSelect(true)
        setShowDescription(false)
      } else if (type === 'edit-appstores') {
        setShowAppEdit(true)
        setShowDescription(false)
      } else if (type === 'add-keywords') {
        setShowKeywordTypeSelect(true)
        setShowKeywordAdd(false)
      }
    } else if (stage === 3 && type === 'add-keywords') {
      setShowKeywordAdd(true)
      setShowDescription(false)
    }
    setStage(stage - 1)
  }

  const handleChangeSignature = useCallback(
    (newSignature: Signature) => {
      if (newSignature.category !== signature?.category) {
        if (!newSignature.category) {
          return setSignature({ ...newSignature, isSubCategory: false, isCategory: true })
        }
        const parentSignature = signatures.find((s) => s.id === newSignature.category)
        return setSignature({
          ...newSignature,
          isSubCategory: parentSignature?.isCategory ? true : false,
          isCategory: !parentSignature?.category,
        })
      }
      setSignature(newSignature)
    },
    [signature, signatures]
  )

  const handleChangeAppstores = (appstores: AppControls[]) => {
    setNewApps(appstores)
  }

  const handleChangeUrls = (urls: SignatureUrlInfo[]) => {
    setUrls(urls)
  }

  const handleChangeMassUrls = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMassUrls(e.target.value)
  }

  const handleValidateMassUrlClassifications = () => {
    setError('')
    setClassifyValidationMessage(null)
    const urlsArray = massUrls.split('\n').map((url) => url.trim())

    const cleanedUrls: string[] = Array.from(
      new Set(
        urlsArray
          .filter(
            (url) =>
              url !== '' &&
              url.includes('.') &&
              !url.includes(' ') &&
              !url.includes('(') &&
              !url.includes(')') &&
              !url.includes('@')
          )
          .map((url) => url.replace(/^(https?:\/\/)?(www\.)?([^/]+)(.*)$/, '$3'))
      )
    )

    if (urlsArray.length > 199) {
      cleanedUrls.splice(200)
      setClassifyValidationMessage({
        warning: 1,
        msg: 'Only 200 entries accepted, please resubmit if more are required.',
      })
    }

    setMassUrls(cleanedUrls.join('\n'))
    setMassClassificationEntry(cleanedUrls)
  }

  const handleUploadMassClassifications = async () => {
    if (!isMounted()) return
    if (MassClassificationEntry.length === 0) return
    setError('')
    setLoading(true)
    setClassifyValidationMessage(null)
    try {
      const data = await bulkClassify(MassClassificationEntry)
      if (!data) return
      setMassUrls('')
      setMassClassificationEntry([])
      setClassifyValidationMessage({ warning: 0, msg: 'Sites have been sent to Appindex Feeder for classification.' })
    } catch (err) {
      const { msg } = handleError(err)
      if (!isMounted()) return
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  const handleSetSelectedSignature = (e: React.SyntheticEvent, sig: Signature | null | undefined) => {
    setSelectedSignature(sig || null)
  }

  const handleSetSelectedKeyword = (e: React.SyntheticEvent, keyword: Keyword | null | undefined) => {
    setSelectedKeyword(keyword || null)
  }

  const handleSetSelectedKeywordType = (option: string | null | undefined) => {
    setKeywordType(option?.toLowerCase() || '')
  }

  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value)
  }

  const handleChangeTicketNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTicketNumber(parseInt(e.target.value))
  }

  const handleChangeControlledApps = (e: AppListings | null) => {
    setSelectedControlledApps(e)
    setOldApps(applications.filter((app) => app.id === e?.id))
    setNewApps(applications.filter((app) => app.id === e?.id))
  }

  const handleChangeControl = (controlId: string, controlName: string, favicon: string, cat: string) => {
    setControlId(controlId)
    setControlName(controlName)
    setControlFavicon(favicon)
    setControlCategory(cat)
  }

  // const handleToggleDraft = () => {
  //   setDraft(!draft)
  // }

  const validateSignature = () => {
    const errors: ErroredField[] = []
    if (!signature) {
      errors.push({
        field: 'name',
        msg: 'Signature must be selected',
      })
      return errors
    }
    if (signature.name.length < 4) {
      errors.push({
        field: 'name',
        msg: 'Name must be at least 4 characters',
      })
    }
    if (signature.name.length > 40) {
      errors.push({
        field: 'name',
        msg: 'Name must be at most 40 characters',
      })
    }
    if (signature.category && !signatures.some((sig) => sig.id === signature.category)) {
      errors.push({
        field: 'category',
        msg: 'Category does not exist',
      })
    }
    if (!signature.category) {
      errors.push({
        field: 'category',
        msg: 'Signature needs a category',
      })
    }
    if (!signature.url) {
      errors.push({
        field: 'url',
        msg: 'This field is required',
      })
    }
    if (signature.description.length < 10) {
      errors.push({
        field: 'description',
        msg: 'Description must be at least 10 characters',
      })
    }
    if (signature.description.length > 300) {
      errors.push({
        field: 'description',
        msg: 'Description must be at most 300 characters',
      })
    }
    if (type === 'create-signature') {
      if (signatures.some((sig) => sig.name.toLowerCase() === signature.name.toLowerCase())) {
        errors.push({
          field: 'name',
          msg: 'This name already exists',
        })
      }
      const id = `sphirewall.application.${signature.name
        .toLowerCase()
        .replace(/[^\w\s]/gi, '')
        .replace(/ /g, '')}`
      if (signatures.some((sig) => sig.id === id)) {
        errors.push({
          field: 'name',
          msg: 'This name already exists',
        })
      }
      if (signature.category) {
        const categorySignature = signatures.find((sig) => sig.id === signature.category)
        if (categorySignature?.isCategory) {
          setSignature({
            ...signature,
            id,
            isSubCategory: true,
          })
        } else {
          setSignature({
            ...signature,
            id,
          })
        }
      } else {
        setSignature({
          ...signature,
          id,
          isCategory: true,
        })
      }
    }
    if (!signature.id && signatures.some((sig) => sig.url === signature.url)) {
      errors.push({
        field: 'url',
        msg: 'This url already exists',
      })
    }

    if (errors.length !== 0) {
      setErroredFields(errors)
      return false
    }
    setErroredFields([])

    return true
  }

  const signatureHasChanges = useCallback(() => {
    return (signature ? JSON.stringify(signature) : null) !== (oldSignature ? JSON.stringify(oldSignature) : null)
  }, [signature, oldSignature])

  const appsHaveChanges = useCallback(() => {
    return (newApps ? JSON.stringify(newApps) : null) !== (oldApps ? JSON.stringify(oldApps) : null)
  }, [newApps, oldApps])

  const populateKeywords = useCallback(async () => {
    try {
      setLoading(true)
      const data = await getKeywords()
      if (!isMounted()) return
      setKeywords(data.keywords.filter((keyword) => !keyword.deprecated))
    } catch (err) {
      const { msg } = handleError(err)
      if (!isMounted()) return
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }, [isMounted])

  const populateSignatures = useCallback(async () => {
    try {
      setLoading(true)
      const data = await getDetailedSignatures()
      if (!isMounted()) return
      setSignatures(data.signatures)
    } catch (err) {
      const { msg } = handleError(err)
      if (!isMounted()) return
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }, [isMounted])

  const populateControlledApps = useCallback(async () => {
    try {
      setLoading(true)
      const data = await getAppListings()
      if (!isMounted()) return
      let order = data.apps
      if (!data.apps.find((app) => app.id === 'create-new')) {
        order = [{ name: 'Create New..', id: 'create-new', favicon: '', format: 'APP', category: '' }, ...data.apps]
      }
      setControlledApps(order)
    } catch (err) {
      const { msg } = handleError(err)
      if (!isMounted()) return
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }, [isMounted])

  const populateApplications = useCallback(async () => {
    try {
      setLoading(true)
      const data = await getApps()
      if (!isMounted()) return
      setApplications(data.apps)
    } catch (err) {
      const { msg } = handleError(err)
      if (!isMounted()) return
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }, [isMounted])

  useEffect(() => {
    populateSignatures()
    populateKeywords()
    populateControlledApps()
    populateApplications()
  }, [populateSignatures, populateKeywords, populateControlledApps, populateApplications])

  return (
    <Dialog open={open} onClose={handleCloseSave}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                Request for change
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Collapse in={Boolean(error)}>
            <Box>
              <Grid container padding={2} justifyContent="center">
                <Grid item xs={12}>
                  <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
                    {error || ' '}
                  </Alert>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Collapse in={!type}>
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <Grid container justifyContent="end" alignItems="center">
                    <Grid item>
                      <Typography>What would you like to submit a request for?</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" fullWidth onClick={() => selectType('create-signature')}>
                    Create Signature
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" fullWidth onClick={() => selectType('edit-signature')}>
                    Edit Signature
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" fullWidth onClick={() => selectType('recat-urls')}>
                    Recategorise URL(s)
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" fullWidth onClick={() => selectType('mass-classification')}>
                    Mass Categorisation
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" fullWidth onClick={() => selectType('edit-appstores')}>
                    Create/Edit Applications
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" fullWidth onClick={() => selectType('add-keywords')}>
                    New Keyword(s)
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" fullWidth onClick={() => selectType('other-request')} color="error">
                    Other Request
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Collapse in={type && stage === 0 ? true : false}>
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <IconButton onClick={handleReverseSelectType}>
                        <ArrowLeftIcon />
                      </IconButton>
                    </Grid>

                    <Grid item>
                      <Typography>
                        {type === 'create-signature' && 'What should this signature look like?'}
                        {type === 'edit-signature' && 'What signature should be changed?'}
                        {type === 'recat-urls' && 'What urls should we reclassify?'}
                        {type === 'edit-appstores' &&
                          'What signature/controlled application should we edit Applications for?'}
                        {type === 'add-keywords' && 'What keyword would you like to edit?'}
                        {type === 'other-request' && ''}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Collapse in={type && stage === 1 ? true : false}>
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <IconButton onClick={handleReverseStage}>
                        <ArrowLeftIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {type === 'create-signature' && 'What should the urls in this signature look like?'}
                        {type === 'edit-signature' && 'What should this signature look like?'}
                        {type === 'recat-urls' && 'What category should we classify these urls under?'}
                        {type === 'edit-appstores' &&
                          'What Apps should be linked to this signature/controlled application?'}
                        {type === 'add-keywords' && 'What section of keywords are you adding to?'}
                        {type === 'other-request' && 'Please describe your request'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Collapse in={type && stage === 2 ? true : false}>
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <IconButton onClick={handleReverseStage}>
                        <ArrowLeftIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {type === 'create-signature' && 'Please describe why you would like to create this signature'}
                        {type === 'edit-signature' && 'Please describe why you would like to change this signature'}
                        {type === 'recat-urls' && 'Please describe why you would like to reclassify these urls'}
                        {type === 'edit-appstores' && 'Please describe why you would like to edit these applications'}
                        {type === 'add-keywords' && 'What Keywords should be added'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Collapse in={type && stage === 3 ? true : false}>
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <IconButton onClick={handleReverseStage}>
                        <ArrowLeftIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {type === 'add-keywords' && 'Please describe why you would like to add these keywords'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          {/* Keyword Selector */}
          <Collapse in={showKeywordSelect}>
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <KeywordSelect
                    id="keywordID"
                    keywords={keywords}
                    onChange={handleSetSelectedKeyword}
                    defaultKeyword={selectedKeyword}
                  />
                </Grid>
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    onClick={handleAdvanceStage}
                    disabled={!selectedKeyword}
                    loading={loading}
                  >
                    Confirm Signature
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          {/* Application selection */}
          <Collapse in={showAppSelect}>
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <ControlledAppSelector
                    app={null}
                    applications={controlledApps}
                    onChange={handleChangeControlledApps}
                  />
                </Grid>
                {selectedControlledApps?.id === 'create-new' && (
                  <Grid item>
                    <Grid container alignItems="center" justifyContent="center" padding={2} spacing={3}>
                      <Grid item>
                        <Typography variant="h6">New Controlled App</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          This option creates a new application that is not linked to an existing signature. The Id is
                          hidden from public view and should always start with "controlledapp." for consistancy within
                          appindex and other systems.
                        </Typography>
                      </Grid>
                    </Grid>
                    <AddControlDialog
                      controlId={controlId}
                      controlName={controlName}
                      favicon={controlFavicon}
                      controlCategory={signatures.find((sig) => sig.id === controlCategory)?.id || ''}
                      signatures={signatures}
                      editing={false}
                      onChange={handleChangeControl}
                    />
                  </Grid>
                )}
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    onClick={handleAdvanceStage}
                    disabled={
                      !selectedControlledApps ||
                      (selectedControlledApps?.id === 'create-new' && controlId === 'controlledapp.') ||
                      (selectedControlledApps?.id === 'create-new' && controlName.length < 4)
                    }
                    loading={loading}
                  >
                    Confirm
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          {/* Signature selection */}
          <Collapse in={showSignatureSelect}>
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <SignatureSelect
                    id="signatureId"
                    signatures={
                      type === 'recat-urls'
                        ? signatures.filter((sig) => !sig.isCategory && !sig.hidden && sig.enabled)
                        : signatures.filter((sig) => !sig.isCategory)
                    }
                    onChange={handleSetSelectedSignature}
                    defaultSignature={selectedSignature}
                  />
                </Grid>
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    onClick={handleAdvanceStage}
                    disabled={!selectedSignature}
                    loading={loading}
                  >
                    Confirm
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          {/*Keyword Type Selector */}
          <Collapse in={showKeywordTypeSelect}>
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <KeywordTypeSelect
                    id="Keyword Type"
                    onChange={handleSetSelectedKeywordType}
                    selectedType={keywordType || null}
                  />
                </Grid>
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    onClick={handleAdvanceStage}
                    disabled={!keywordType}
                    loading={loading}
                  >
                    Confirm Selection
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Collapse>

          {/* Keyword Editor */}
          <Collapse in={showKeywordAdd}>
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <KeywordAdd
                    keyword={keyword?.id}
                    keywordEntry={keywordEntry}
                    keywordType={keywordType}
                    onChange={handleKeywordEntryChange}
                    onSubmit={handleAdvanceStage}
                  />
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          {/* Application editor layout */}
          <Collapse in={showAppEdit}>
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <ApplicationEdit
                    appstores={newApps}
                    onChange={handleChangeAppstores}
                    id={selectedControlledApps?.format === 'APP' ? selectedControlledApps.id : ''}
                    signature_id={selectedControlledApps?.format === 'SIG' ? selectedControlledApps.id : ''}
                  />
                </Grid>
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    onClick={handleAdvanceStage}
                    disabled={!appsHaveChanges()}
                    loading={loading}
                  >
                    Confirm Application Links
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          {/* Signature editor layout */}
          <Collapse in={showSignatureCreate}>
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  {signature && (
                    <SignatureEdit
                      signature={signature}
                      signatures={signatures.filter((sig) => !sig.isCategory && !sig.hidden && sig.enabled)}
                      onChange={handleChangeSignature}
                      errors={erroredFields}
                    />
                  )}
                </Grid>
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    onClick={handleAdvanceStage}
                    disabled={!signatureHasChanges()}
                    loading={loading}
                  >
                    Confirm Layout
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          {/* Urls editor layout */}
          <Collapse in={showUrls}>
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <UrlsEdit
                    urls={urls}
                    onChange={handleChangeUrls}
                    signatureId={signature?.id}
                    signatures={[]}
                    editSuggestion="False"
                    onChangeSignature={() => null}
                  />
                </Grid>
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    onClick={handleAdvanceStage}
                    disabled={urls.length === 0}
                    loading={loading}
                  >
                    Confirm URLs
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          {/* Mass Categorisation */}
          <Collapse in={showMassClassification}>
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <MassClassification
                    loading={false}
                    urls={massUrls}
                    onChange={handleChangeMassUrls}
                    error={error}
                    classifyValidationMessage={classifyValidationMessage}
                  />
                </Grid>

                <Grid item>
                  <LoadingButton
                    variant="contained"
                    onClick={
                      massUrls === MassClassificationEntry.join('\n')
                        ? handleUploadMassClassifications
                        : handleValidateMassUrlClassifications
                    }
                    disabled={massUrls.length === 0}
                    loading={loading}
                  >
                    {massUrls === MassClassificationEntry.join('\n') ? 'Submit URLs' : 'Validate URLs'}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Collapse in={showOtherConfirmation}>
            <Box>
              <Grid container padding={1} spacing={2} justifyContent="center" alignItems="center">
                <Grid item>
                  <Typography>Please confirm your query does not fall under one of the following requests:</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Link
                    href="https://familyzone.atlassian.net/wiki/spaces/CAT/pages/2694403293218/Request+-+Create+Signature"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Create Signature
                  </Link>
                  <br />
                  <Link
                    href="https://familyzone.atlassian.net/wiki/spaces/CAT/pages/2694401655948/Request+-+Edit+Signature"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Edit Signatures
                  </Link>
                  <br />
                  <Link
                    href="https://familyzone.atlassian.net/wiki/spaces/CAT/pages/2694402375733/Request+-+Re-categorise+URL+s"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Recategorise URL
                  </Link>
                  <br />

                  <Link
                    href="https://familyzone.atlassian.net/wiki/spaces/CAT/pages/2694403883015/Request+-+Edit+Signature+Applications"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Edit Applications
                  </Link>
                  <br />
                  <Link
                    href="https://familyzone.atlassian.net/wiki/spaces/CAT/pages/2694513918030/Request+-+New+Keyword+s"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    New Keywords
                  </Link>
                </Grid>
              </Grid>
              <Grid container padding={2} spacing={3} justifyContent="space-between" alignItems="flex-start">
                <Grid item>
                  <LoadingButton variant="contained" onClick={handleReverseSelectType} loading={loading} color="error">
                    No, go back
                  </LoadingButton>
                </Grid>
                <Grid item>
                  <LoadingButton variant="contained" onClick={handleAdvanceStage} loading={loading} color="success">
                    Yes, I am sure
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          {/* Description */}
          <Collapse in={showDescription}>
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <TextField
                    value={description}
                    fullWidth
                    multiline
                    minRows={2}
                    onChange={handleChangeDescription}
                    disabled={loading}
                    helperText={`${description.length}/Minimum 20 Characters ${description.length >= 20 ? '😀' : '😦'}`}
                  />
                </Grid>
                <Grid item>
                  <Tooltip title="Please ensure all ticket fields are set correctly to ensure the ticket will automatically close.">
                    <TextField
                      value={ticketNumber}
                      fullWidth
                      onChange={handleChangeTicketNumber}
                      disabled={loading}
                      type="number"
                      helperText="Zendesk Ticket Number (optional)"
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    onClick={handleSaveRequest}
                    disabled={description.length < 20}
                    loading={loading}
                    color="secondary"
                  >
                    Confirm Change Request
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          {/* Last menu */}
          <Collapse in={stage === -1}>
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <Typography>
                    {type === 'create-signature' && 'Successfully created the create signature request!'}
                    {type === 'edit-signature' && 'Successfully created the edit signature request!'}
                    {type === 'recat-urls' && 'Successfully created the recat urls request!'}
                    {type === 'edit-appstores' && 'Successfully created the applications request!'}
                    {type === 'add-keywords' && 'Successfully created the add keywords request!'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" fullWidth onClick={handleRequestMore}>
                    Request another change
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" fullWidth onClick={handleGoToTicket}>
                    Go to my ticket
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </div>
      </div>
    </Dialog>
  )
}

export default CreateSuggestionDialog
