import React, { useState, useEffect } from 'react'
import useIsMounted from 'react-is-mounted-hook'

import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Autocomplete from '@mui/material/Autocomplete'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import LoadingButton from '@mui/lab/LoadingButton'

import CloseIcon from '@mui/icons-material/Close'

import { useTheme } from '@mui/material/styles'

import handleError from '../util/Error.tsx'
import { createKeywordEntry, KeywordEntry } from '../util/Api.tsx'

const types = ['exclude', 'definitive', 'keyword', 'matcher']

interface MappingCreateProps {
  open?: boolean
  onClose?: () => void
  onSave?: (keywordEntries: KeywordEntry[]) => void
  defaultKeywordId?: string | null
  defaultType?: string | null
  keywordIds: string[]
}

const MappingCreate = ({
  open = false,
  onClose = () => {},
  onSave = () => {},
  defaultKeywordId = null,
  defaultType = null,
  keywordIds = [],
}: MappingCreateProps) => {
  const theme = useTheme()
  const isMounted = useIsMounted()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [keywordId, setKeywordId] = useState<null | string>(null)
  const [type, setType] = useState<null | string>(null)
  const [value, setValue] = useState('')

  const handleChangeKeywordId = (e: React.SyntheticEvent, newValue: string | null) => {
    setKeywordId(newValue)
  }

  const handleChangeType = (e: React.SyntheticEvent, newValue: string | null) => {
    setType(newValue)
  }

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const handleSave = async () => {
    try {
      if (keywordId === null || type === null || value === '') {
        throw new Error('You must fill in all fields')
      }

      setLoading(true)
      const { keywordEntries } = await createKeywordEntry({
        keywordId,
        type,
        value,
      })
      if (!isMounted()) return

      onClose()
      onSave(keywordEntries)
      setError('')
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!defaultKeywordId) return
    setKeywordId(defaultKeywordId)
  }, [defaultKeywordId])

  useEffect(() => {
    if (!defaultType) return
    setType(defaultType)
  }, [defaultType])

  useEffect(() => {
    setError('')
  }, [open])

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                Create a new keyword entry
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container padding={1} justifyContent="center">
            <Grid item>
              {open && (
                <Collapse in={Boolean(error)}>
                  <Alert severity="error">{error || 'Error'}</Alert>
                </Collapse>
              )}
            </Grid>
          </Grid>
          <Grid container padding={3} spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                id="keywordId"
                options={keywordIds}
                renderInput={(params) => <TextField {...params} label="Keyword Category Id" />}
                defaultValue={defaultKeywordId}
                value={keywordId}
                onChange={handleChangeKeywordId}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="filterType"
                options={types}
                renderInput={(params) => <TextField {...params} label="Filter Type" />}
                defaultValue={defaultType}
                value={type}
                onChange={handleChangeType}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Value" fullWidth value={value} onChange={handleChangeValue} />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    disableElevation
                    loading={loading}
                    onClick={handleSave}
                  >
                    Create Entry
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}

export default MappingCreate
