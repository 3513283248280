import React, { useState, useEffect, useMemo } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Keyword } from '../util/Api.tsx'

interface KeywordSelectionOption {
  label: string
  value: string
  favicon: string | null | undefined
}

const getKeywordOption = (key: Keyword): KeywordSelectionOption => ({
  label: key?.name,
  value: key?.id,
  favicon: key?.faviconUrl,
})

interface KeywordOptionProps {
  favicon: string
  name: string
  [key: string]: any
}

const KeywordOption = ({ favicon = '', name = '', ...props }: KeywordOptionProps) => {
  return (
    <Box
      component="li"
      sx={{
        '& > img': { mr: 2, flexShrink: 0 },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      {...props}
    >
      <img src={favicon} srcSet={favicon} alt="" loading="lazy" width="20" />
      <Typography>{name}</Typography>
    </Box>
  )
}

interface KeywordsSelectProps {
  id?: string
  keywords: Keyword[]
  error?: string
  defaultKeyword?: Keyword | null
  label?: string
  onChange?: (e: React.SyntheticEvent, keywords: Keyword | null) => void
}

const KeywordSelect = ({
  id = '',
  keywords = [],
  error = '',
  defaultKeyword,
  label = '',
  onChange = () => {},
}: KeywordsSelectProps) => {
  const [selectedKeyword, setSelectedKeyword] = useState<KeywordSelectionOption | null>(
    defaultKeyword ? getKeywordOption(defaultKeyword) : null
  )

  const handleChangeSelected = (e: React.SyntheticEvent, newValue: KeywordSelectionOption | null) => {
    setSelectedKeyword(newValue)
    if (!newValue) {
      onChange(e, null)
    } else {
      onChange(e, keywords.find((key) => key.id === newValue.value) || null)
    }
  }

  const handleEquals = (option: KeywordSelectionOption, value: KeywordSelectionOption) => {
    return option?.value === value?.value
  }

  useEffect(() => {
    if (!defaultKeyword) {
      setSelectedKeyword(null)
      return
    }
    setSelectedKeyword(getKeywordOption(defaultKeyword))
  }, [defaultKeyword])

  const options = useMemo(() => keywords.map(getKeywordOption), [keywords])

  const inputLabel = label || 'Select a Keyword signature...'

  return (
    <Autocomplete
      id={id}
      options={options}
      value={selectedKeyword}
      onChange={handleChangeSelected}
      isOptionEqualToValue={handleEquals}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => <KeywordOption favicon={option?.favicon || ''} name={option.label} {...props} />}
      renderInput={(params) => (
        <TextField
          {...params}
          label={inputLabel}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off', // disable autocomplete and autofill
          }}
          error={Boolean(error)}
          helperText={error}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
        />
      )}
    />
  )
}

export default KeywordSelect
