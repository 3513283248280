import { Alert, Box, Button, Collapse, Grid, TextField, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { getKeywordEntries, KeywordEntry } from '../util/Api.tsx'
import handleError from '../util/Error.tsx'
import useIsMounted from 'react-is-mounted-hook'
import { LoadingButton } from '@mui/lab'

interface KeywordAddProps {
  keyword: string | undefined
  onSubmit?: (entry: string[]) => void
  keywordType?: string | null
  keywordEntry: string[] | []
  onChange?: (entry: string[]) => void
}

const KeywordAdd = ({
  keyword = '',
  onSubmit = () => {},
  keywordType = '',
  keywordEntry = [],
  onChange = () => {},
}: KeywordAddProps) => {
  const isMounted = useIsMounted()

  const [entry, setEntry] = useState(keywordEntry.join('\n'))
  const [entryArray, setEntryArray] = useState<string[]>(keywordEntry)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [warning, setWarning] = useState<KeywordEntry[]>([])
  const [showWarning, setShowWarning] = useState(false)
  const [keywordEntriesExisting, setKeywordEntriesExisting] = useState<KeywordEntry[]>([])

  const splitEntry = (keyword: string) => {
    return keyword
      .trim()
      .split('\n')
      .map((s) => s.trim())
  }

  const handleChangeEntry = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowConfirm(false)
    setEntry(e.target.value)
    if (e.target.value.length === 0) {
      return setEntryArray([])
    }
    setEntryArray(splitEntry(e.target.value))
    onChange(splitEntry(e.target.value))
  }

  const handleValidation = () => {
    setLoading(true)
    setShowWarning(false)
    setWarning([])
    setError('')

    const dupecheck: string[] = []
    entryArray.filter((e) => {
      const dupe = dupecheck.includes(e)
      if (!dupe) {
        dupecheck.push(e)
        return false
      }
      return true
    })
    setEntryArray(dupecheck)
    setEntry(dupecheck.join('\n'))
    if (entryArray.length > 200) {
      setError('You may not add more than 200 keywords at once')
    }
    const exact = keywordEntriesExisting.filter((key) =>
      entryArray.some((ent) => ent === key.value && key.id === keyword && key.type === keywordType?.toLowerCase())
    )
    if (exact.length > 0) {
      let errorNames = ''
      exact.map((err) => {
        errorNames = errorNames + ' "' + err.value + '"'
        return `Entry already exists for ${errorNames} in ${keyword} as ${keywordType}`
      })
      setError(`Entry already exists for ${errorNames} in ${keyword} as ${keywordType}`)
      setLoading(false)
      return
    }

    const existing = keywordEntriesExisting.filter((key) => entryArray.some((ent) => ent === key.value))

    if (existing.length > 0) {
      setWarning(existing)
      setShowWarning(true)
    }
    if (exact.length === 0) {
      setShowConfirm(true)
    }
    setLoading(false)
  }

  const getKeywordText = (type: string | null) => {
    switch (type) {
      case 'definitive':
        return "A word or phrase is matched with this definition, it will immediately be marked. Doesn't include the Excluded ruleset"
      case 'keyword':
        return `Works in conjunction with MATCHER to match phrases and mark them as this ruleset`
      case 'matcher':
        return 'Works in conjunction with KEYWORDS to match phrases and mark them as this ruleset'
      case 'exclude':
        return 'Exclude a word from the KEYWORDS + MATCHER ruleset. Definitive phrases are not affected'
      default:
        return ''
    }
  }

  const populateKeywordEntries = useCallback(async () => {
    try {
      setLoading(true)
      const data = await getKeywordEntries()
      if (!isMounted()) return
      setKeywordEntriesExisting(data.keywordEntries)
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      setLoading(false)
    }
  }, [isMounted])

  useEffect(() => {
    populateKeywordEntries()
  }, [populateKeywordEntries])

  const handleConfirm = () => {
    onSubmit(entryArray)
  }

  return (
    <div>
      <Grid container spacing={2} padding={2}>
        <Grid item xs>
          <Typography variant="overline">{keywordType}</Typography>
        </Grid>
        <Grid item xs={12}>
          {getKeywordText(keywordType)}
        </Grid>
      </Grid>
      <Collapse in={showWarning}>
        <Box>
          <Grid container padding={2} justifyContent="center">
            <Grid item xs={12}>
              <Alert severity="warning" variant="outlined" sx={{ mb: 2 }}>
                {!warning ? (
                  ''
                ) : (
                  <Grid>
                    WARNING:
                    {warning.map((warn) => (
                      <Typography>
                        {warn.value} exists in {warn.id} as {warn.type}
                      </Typography>
                    ))}
                  </Grid>
                )}
              </Alert>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
      <Collapse in={Boolean(error)}>
        <Box>
          <Grid container padding={2} justifyContent="center">
            <Grid item xs={12}>
              <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
                {!error
                  ? ' '
                  : error.includes('\n')
                  ? error.split('\n').map((err) => (
                      <>
                        <br />
                        {err}
                      </>
                    ))
                  : error}
              </Alert>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
      <Box sx={{ maxHeight: 650 }}>
        <Grid container padding={1} spacing={1} justifyContent="center">
          <Grid item>
            <Box sx={{ mb: 1 }}></Box>
            <TextField
              multiline
              placeholder={`croxyproxy\npanic attack\nbombs`}
              value={entry}
              onChange={handleChangeEntry}
              minRows={10}
              disabled={loading}
              inputProps={{
                sx: { fontFamily: 'Roboto, monospace' },
              }}
              sx={{ width: 340 }}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container justifyContent="center">
        {!showConfirm ? (
          <Grid item>
            <LoadingButton
              fullWidth
              variant="contained"
              loading={loading}
              disabled={loading || !entry}
              onClick={handleValidation}
            >
              Validate
            </LoadingButton>
          </Grid>
        ) : (
          <Grid item>
            <Button
              fullWidth
              variant="contained"
              disabled={loading || Boolean(error) || !keywordEntry}
              onClick={handleConfirm}
              color={error ? 'error' : 'primary'}
            >
              Confirm Import
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  )
}
export default KeywordAdd
