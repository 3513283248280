import React, { useState } from 'react'
import useIsMounted from 'react-is-mounted-hook'

import { useTheme } from '@mui/material/styles'

import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import LoadingButton from '@mui/lab/LoadingButton'
import handleError from '../util/Error.tsx'
import { syncSignature, obtainDiff } from '../util/Api.tsx'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import JSONInput from 'react-json-editor-ajrm'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Divider } from '@mui/material'
import Link from '@mui/material/Link'
import locale from 'react-json-editor-ajrm/locale/en'

interface CriteriaAlteration {
  added: any[]
  deleted: any[]
}

interface AppAlteration {
  key: string
  oldEntry: any
  newEntry: any
}

interface PublishDialogProps {
  open?: boolean
  onClose?: () => void
  signatureId: string
  syncLabel: string
}

const PublishDialog = ({ open = false, onClose = () => {}, signatureId = '', syncLabel = '' }: PublishDialogProps) => {
  const theme = useTheme()
  const isMounted = useIsMounted()
  const [showComparison, setShowComparison] = useState(false)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [comparison, setComparison] = useState<AppAlteration[]>([])
  const [merge, setMerge] = useState(true)
  const [urls, setUrls] = useState<CriteriaAlteration>({
    added: [],
    deleted: [],
  })
  const [dependancies, setDependancies] = useState<CriteriaAlteration>({
    added: [],
    deleted: [],
  })
  const [nonCriteria, setNonCriteria] = useState<CriteriaAlteration>({
    added: [],
    deleted: [],
  })
  const [criteria, setCriteria] = useState<CriteriaAlteration>({
    added: [],
    deleted: [],
  })
  const [appAlterations, setAppAlterations] = useState<AppAlteration[]>([])

  const blacklistedKeys = [
    'criteria',
    'app_ids',
    'content_hub',
    'dependencies',
    'non_exclusive_criteria',
    'favicon_url',
  ]

  const handleClose = () => {
    if (loading) return
    setShowComparison(false)
    setMerge(true)
    onClose()
  }

  const handleSync = async () => {
    try {
      setLoading(true)
      const { msg } = await syncSignature(signatureId, merge)
      alert(msg)
      onClose()
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  const handleToggleMerge = () => {
    setMerge(!merge)
  }

  const handleShowComparison = async () => {
    try {
      if (showComparison) {
        setShowComparison(false)
        return
      }
      setLoading(true)
      const { data } = await obtainDiff(signatureId)

      let apps: AppAlteration[] = []
      Object.keys(data.new.signature.app_ids).forEach((app) => {
        if (app === 'windows') {
          Object.keys(data.new.signature.app_ids.windows).forEach((win) => {
            if (data.new.signature.app_ids.windows[win] !== data.old.signature.app_ids?.windows?.[win]) {
              apps.push({
                key: win,
                oldEntry: data.old.signature.app_ids.windows?.[win],
                newEntry: data.new.signature.app_ids.windows[win],
              })
            }
          })
        } else if (data.new.signature.app_ids[app] !== data.old.signature.app_ids[app]) {
          apps.push({
            key: app,
            oldEntry: data.old.signature.app_ids[app],
            newEntry: data.new.signature.app_ids[app],
          })
        }
      })
      Object.keys(data.old.signature.app_ids).forEach((app) => {
        if (app === 'windows') {
          Object.keys(data.old.signature.app_ids.windows).forEach((win) => {
            if (data.old.signature.app_ids.windows[win] !== data.new.signature.app_ids?.windows?.[win]) {
              if (!apps.some((key) => key.key === win)) {
                apps.push({
                  key: win,
                  oldEntry: data.old.signature.app_ids.windows[win],
                  newEntry: data.new.signature.app_ids.windows?.[win],
                })
              }
            }
          })
        } else if (data.old.signature.app_ids[app] !== data.new.signature.app_ids[app]) {
          if (!apps.some((key) => key.key === data.old.signature.app_ids[app])) {
            apps.push({
              key: app,
              oldEntry: data.old.signature.app_ids[app],
              newEntry: data.new.signature.app_ids[app],
            })
          }
        }
      })
      setAppAlterations(apps)

      const dataArr: AppAlteration[] = []
      Object.keys(data.new.signature).forEach((key) => {
        if (blacklistedKeys.includes(key)) {
          return
        }
        const oldValue = data.old.signature[key]
        const newValue = data.new.signature[key]
        if ((oldValue === null || oldValue === '') && (newValue === null || newValue === '')) return

        if (oldValue !== newValue) {
          dataArr.push({
            key: key,
            oldEntry: `${oldValue}`,
            newEntry: `${newValue}`,
          })
        }
      })

      let newUrls: string[] = []
      let oldUrls: string[] = []

      let addedCriteria: any[] = []
      let deletedCriteria: any[] = []

      const newCriteria = data.new.signature.criteria
      const oldCriteria = data.old.signature.criteria
      setComparison(dataArr)

      newCriteria.forEach((array: any) => {
        if (array[0].type === 'application.http.hostname') {
          newUrls = array[0].conditions.source_entries
          return
        }
        if (!oldCriteria.some((c: any) => JSON.stringify(c) === JSON.stringify(array))) {
          addedCriteria.push(array)
        }
      })
      oldCriteria.forEach((array: any) => {
        if (array[0].type === 'application.http.hostname') {
          oldUrls = array[0].conditions.source_entries
          return
        }
        if (!newCriteria.some((c: any) => JSON.stringify(c) === JSON.stringify(array))) {
          deletedCriteria.push(array)
        }
      })

      let addedNonCriteria: any[] = []
      let deletedNonCriteria: any[] = []

      const newNonCriteria = data.new.signature.non_exclusive_criteria
      const oldNonCriteria = data.old.signature.non_exclusive_criteria

      newNonCriteria.forEach((array: any) => {
        if (!oldNonCriteria.some((c: any) => JSON.stringify(c) === JSON.stringify(array))) {
          addedNonCriteria.push(array)
        }
      })

      oldNonCriteria.forEach((array: any) => {
        if (!newNonCriteria.some((c: any) => JSON.stringify(c) === JSON.stringify(array))) {
          deletedNonCriteria.push(array)
        }
      })

      setCriteria({
        added: addedCriteria,
        deleted: deletedCriteria,
      })

      setNonCriteria({
        added: addedNonCriteria,
        deleted: deletedNonCriteria,
      })

      setUrls({
        added: newUrls.filter((newUrl) => !oldUrls.includes(newUrl)),
        deleted: oldUrls.filter((oldUrl) => !newUrls.includes(oldUrl)),
      })

      setDependancies({
        added: data.new.signature.dependencies.filter(
          (dependency: string) => !data.old.signature.dependencies.includes(dependency)
        ),
        deleted: data.old.signature.dependencies.filter(
          (dependency: string) => !data.new.signature.dependencies.includes(dependency)
        ),
      })

      setShowComparison(true)
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                Sync Signature
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Collapse in={Boolean(error)}>
            <Box>
              <Grid container padding={2} justifyContent="center">
                <Grid item xs={12}>
                  <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
                    {error || ' '}
                  </Alert>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Grid container padding={2} spacing={2} justifyContent="center" direction="column" alignItems="stretch">
            <Grid item alignSelf="center">
              <Typography>
                Syncing this signature might take a while. This action cannot be reversed. Are you sure you want to do
                this?
              </Typography>
            </Grid>
            <Grid item alignSelf="center">
              <LoadingButton variant="contained" color="secondary" loading={loading} onClick={handleShowComparison}>
                Display changes
              </LoadingButton>
            </Grid>
            <Grid item>
              <Collapse in={showComparison}>
                <Box>
                  <Grid container spacing={1} alignItems="stretch" justifyContent="center" direction="column">
                    {(urls.added?.length > 0 || urls.deleted?.length > 0) && (
                      <Grid item>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            Altered Websites
                          </AccordionSummary>
                          <AccordionDetails aria-controls="panel2a-content" id="panel2a-header">
                            <div
                              style={{
                                border: '1px solid rgba(0, 0, 0, 0.05)',
                              }}
                            >
                              {urls?.deleted?.map((url) => (
                                <div style={{ backgroundColor: 'rgba(255, 36, 36, 0.2)', paddingLeft: 12 }}>
                                  <Typography>
                                    <Link underline="none" href={'https://' + url} rel="noopener" target="_blank">
                                      {url}
                                    </Link>
                                  </Typography>
                                  <Divider />
                                </div>
                              ))}

                              {urls?.added?.map((url) => (
                                <div style={{ backgroundColor: 'rgba(102, 245, 66, 0.2)', paddingLeft: 12 }}>
                                  <Typography>
                                    <Link underline="none" href={'https://' + url} rel="noopener" target="_blank">
                                      {url}
                                    </Link>
                                  </Typography>
                                  <Divider />
                                </div>
                              ))}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    )}
                    {appAlterations.length > 0 && (
                      <Grid item>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            Application Stores
                          </AccordionSummary>
                          <AccordionDetails aria-controls="panel2a-content" id="panel2a-header">
                            {appAlterations.map((key) => (
                              <div>
                                <AccordionDetails aria-controls="panel2a-content" id="panel2a-header">
                                  <Typography style={{ fontWeight: 'bold' }}>{key.key.toUpperCase()}</Typography>
                                  <div style={{ backgroundColor: 'rgba(255, 36, 36, 0.2)', paddingLeft: 12 }}>
                                    <Typography>{key.oldEntry}</Typography>
                                    <Divider />
                                  </div>
                                  <div style={{ backgroundColor: 'rgba(102, 245, 66, 0.2)', paddingLeft: 12 }}>
                                    <Typography>{key.newEntry}</Typography>
                                    <Divider />
                                  </div>
                                </AccordionDetails>
                              </div>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    )}
                    {(criteria.added?.length > 0 || criteria.deleted?.length > 0) && (
                      <Grid item>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            Criteria
                          </AccordionSummary>
                          <AccordionDetails aria-controls="panel2a-content" id="panel2a-header">
                            <Box sx={{ border: '1px solid rgba(0, 0, 0, 0.05)' }}>
                              {criteria?.deleted?.map((criteria) => (
                                <Box sx={{ p: 1, bgcolor: 'rgba(255, 36, 36, 0.2)' }}>
                                  <Typography>Deleted</Typography>
                                  <JSONInput
                                    locale={locale}
                                    viewOnly
                                    placeholder={criteria || []}
                                    theme="light_mitsuketa_tribute"
                                    width="auto"
                                  />
                                  <Divider />
                                </Box>
                              ))}

                              {criteria?.added?.map((criteria) => (
                                <Box sx={{ p: 1, bgcolor: 'rgba(102, 245, 66, 0.2)' }}>
                                  <Typography>Added</Typography>
                                  <JSONInput
                                    locale={locale}
                                    viewOnly
                                    placeholder={criteria || []}
                                    theme="light_mitsuketa_tribute"
                                    width="auto"
                                  />
                                  <Divider />
                                </Box>
                              ))}
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    )}
                    {(nonCriteria.added?.length > 0 || nonCriteria.deleted?.length > 0) && (
                      <Grid item>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            Non Exclusive Criteria
                          </AccordionSummary>
                          <AccordionDetails aria-controls="panel2a-content" id="panel2a-header">
                            <Box sx={{ border: '1px solid rgba(0, 0, 0, 0.05)' }}>
                              {nonCriteria?.deleted?.map((criteria) => (
                                <Box sx={{ p: 1, bgcolor: 'rgba(255, 36, 36, 0.2)' }}>
                                  <Typography>Deleted</Typography>
                                  <JSONInput
                                    locale={locale}
                                    viewOnly
                                    placeholder={criteria || []}
                                    theme="light_mitsuketa_tribute"
                                    width="auto"
                                  />
                                  <Divider />
                                </Box>
                              ))}

                              {nonCriteria?.added?.map((criteria) => (
                                <Box sx={{ p: 1, bgcolor: 'rgba(102, 245, 66, 0.2)' }}>
                                  <Typography>Added</Typography>
                                  <JSONInput
                                    locale={locale}
                                    viewOnly
                                    placeholder={criteria || []}
                                    theme="light_mitsuketa_tribute"
                                    width="auto"
                                  />
                                  <Divider />
                                </Box>
                              ))}
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    )}

                    {(dependancies.added?.length > 0 || dependancies.deleted?.length > 0) && (
                      <Grid item>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            Dependencies
                          </AccordionSummary>
                          <AccordionDetails aria-controls="panel2a-content" id="panel2a-header">
                            <div
                              style={{
                                border: '1px solid rgba(0, 0, 0, 0.05)',
                              }}
                            >
                              {dependancies?.deleted?.map((dependancy) => (
                                <div style={{ backgroundColor: 'rgba(255, 36, 36, 0.2)', paddingLeft: 12 }}>
                                  <Typography>{dependancy}</Typography>
                                  <Divider />
                                </div>
                              ))}

                              {dependancies?.added?.map((dependancy) => (
                                <div style={{ backgroundColor: 'rgba(102, 245, 66, 0.2)', paddingLeft: 12 }}>
                                  <Typography>{dependancy}</Typography>
                                  <Divider />
                                </div>
                              ))}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    )}

                    {comparison.length > 0 && (
                      <Grid item>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            Signature information
                          </AccordionSummary>
                          {comparison.map((key) => (
                            <div>
                              <AccordionDetails aria-controls="panel2a-content" id="panel2a-header">
                                <Typography style={{ fontWeight: 'bold' }}>{key.key.toUpperCase()}</Typography>
                                <div style={{ backgroundColor: 'rgba(255, 36, 36, 0.2)', paddingLeft: 12 }}>
                                  <Typography>{key.oldEntry}</Typography>
                                  <Divider />
                                </div>
                                <div style={{ backgroundColor: 'rgba(102, 245, 66, 0.2)', paddingLeft: 12 }}>
                                  <Typography>{key.newEntry}</Typography>
                                  <Divider />
                                </div>
                              </AccordionDetails>
                            </div>
                          ))}
                        </Accordion>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Collapse>
            </Grid>
            <Grid item alignSelf="center">
              <Typography>
                If enabled ignores errors and will add URIs regardless of whether they are classified under other
                signatures
              </Typography>
            </Grid>
            <Grid item alignSelf="center">
              <FormControlLabel
                control={<Switch checked={Boolean(merge)} onChange={handleToggleMerge} />}
                label="Force"
              />
            </Grid>
            <Grid item alignSelf="center">
              <LoadingButton variant="contained" loading={loading} onClick={handleSync}>
                Sync {syncLabel}
              </LoadingButton>
            </Grid>
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}

export default PublishDialog
