import React, { useState, useEffect, useCallback } from 'react'
import useIsMounted from 'react-is-mounted-hook'

import { useTheme } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import LoadingButton from '@mui/lab/LoadingButton'
import handleError from '../util/Error.tsx'
import { publishSignatures, publishVersion } from '../util/Api.tsx'

interface PublishDialogProps {
  open?: boolean
  onClose?: () => void
}

const PublishDialog = ({ open = false, onClose = () => {} }: PublishDialogProps) => {
  const theme = useTheme()
  const isMounted = useIsMounted()

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const [versionId, setVersionID] = useState('')
  const [versionCreationDate, setVersionCreationDate] = useState('')

  const handleClose = () => {
    if (loading) return
    onClose()
  }

  const populateVersion = useCallback(async () => {
    try {
      const { version } = await publishVersion()
      if (!isMounted()) return
      setVersionID(version.versionId)
      setVersionCreationDate(
        Intl.DateTimeFormat().resolvedOptions().timeZone.substring(0, 7) === 'America'
          ? Intl.DateTimeFormat('en-US', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            }).format(new Date(version.creationDate))
          : Intl.DateTimeFormat('en-GB', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            }).format(new Date(version.creationDate))
      )
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    }
  }, [isMounted])

  useEffect(() => {
    populateVersion()
  }, [populateVersion])

  const handlePublish = async () => {
    try {
      setLoading(true)
      const { msg } = await publishSignatures()
      alert(msg)
      onClose()
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  useEffect(() => {
    if (open) return
    setError('')
  }, [open])

  return (
    <Dialog open={open} onClose={handleClose}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                Publish Signatures
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Collapse in={Boolean(error)}>
            <Box>
              <Grid container padding={2} justifyContent="center">
                <Grid item xs={12}>
                  <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
                    {error || ' '}
                  </Alert>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Grid container padding={2} spacing={2} justifyContent="left">
            <Grid item>
              <Typography>
                Latest build:{' '}
                <Typography display="inline" component="span" fontWeight="bold">
                  {versionId}
                </Typography>
              </Typography>
              <Typography>
                Initiated (local time):{' '}
                <Typography display="inline" component="span" fontWeight="bold">
                  {versionCreationDate}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid container padding={2} spacing={2} justifyContent="center">
            <Grid item>
              <Typography>
                Publishing will create a new bundle. This will become available right away to verdict gateways. Boxes
                will need to have their configurations updated either at the set time in scheduler or by clicking the
                button below.
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Please wait 15 minutes after the initiated time to ensure the publish has been completed.
              </Typography>
            </Grid>
            <Grid item>
              <LoadingButton variant="contained" loading={loading} onClick={handlePublish}>
                Publish Now
              </LoadingButton>
            </Grid>
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}

export default PublishDialog
