import React, { useEffect, useState, useCallback } from 'react'
import useIsMounted from 'react-is-mounted-hook'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Collapse from '@mui/material/Collapse'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Divider from '@mui/material/Divider'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import DeleteIcon from '@mui/icons-material/Delete'
import RefreshIcon from '@mui/icons-material/Refresh'
import QuestionIcon from '@mui/icons-material/QuestionAnswer'

import LoadingButton from '@mui/lab/LoadingButton'

import ReclassifyDialog from './ReclassifyDialog.tsx'
import ConfirmDialog from './ConfirmDialog.tsx'
import {
  getURLInfo,
  getSignatures,
  deleteSignatureURL,
  Signature,
  Url,
  AppstoreSearch,
  promoteUrls,
} from '../util/Api.tsx'
import useStore from '../util/Store.tsx'
import handleError from '../util/Error.tsx'
import LazyImage from './LazyImage.tsx'
import ClassifyDialog from './ClassifyDialog.tsx'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { Icon } from '@mui/material'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'

interface UrlProp {
  website: string
  signatureId: string
}

interface UrlBoxProps {
  count: number
  website: string
  entryType: string
  expires: number
  imported: number
  signatureId: string
  signatureName: string
  primarySig: boolean
  providerCategory: string
  providerDescription: string
  providerId: string
  userDownVotes: number
  admin?: boolean
  onClickReclassify?: (o: UrlProp) => void
  onClickDelete?: (o: UrlProp) => void
  onClickNavigate?: (e: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>, signatureId: string) => void
  onClickSuggestUrl?: (website: string) => void
  onClickPromote?: (o: UrlProp) => void
  googleFavicon?: string
}

const UrlBox = ({
  count,
  website,
  entryType,
  expires,
  imported,
  signatureId,
  signatureName,
  primarySig,
  providerCategory,
  providerDescription,
  providerId,
  userDownVotes,
  admin = false,
  onClickReclassify = () => {},
  onClickDelete = () => {},
  onClickNavigate = () => {},
  onClickSuggestUrl = () => {},
  onClickPromote = () => {},
  googleFavicon,
}: UrlBoxProps) => {
  const handleClickReclassify = () => {
    onClickReclassify({ website, signatureId })
  }

  const handleClickDelete = () => {
    onClickDelete({ website, signatureId })
  }

  const handleClickSuggest = () => {
    onClickSuggestUrl(website)
  }

  const handleClickPromote = () => {
    onClickPromote({ website, signatureId })
  }

  return (
    <Paper variant="outlined">
      <Grid container padding={1} spacing={1} justifyContent="space-between">
        <Grid item>
          <Grid container direction="column" sx={{ mb: 1 }}>
            <Grid item>
              <Link variant="h6" underline="none" href={'https://' + website} rel="noopener" target="_blank">
                {website}
              </Link>
              <br />
              <Typography variant="caption">{entryType === 'ip' ? 'IP' : 'WEBSITE'}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Tooltip title="Google Website">
            <IconButton href={`https://www.google.com/search?q=${website}`} target="_blank" rel="noopener noreferrer">
              <LazyImage src={googleFavicon || ''} />
            </IconButton>
          </Tooltip>
          {!admin && (
            <Tooltip title="Request Reclassify">
              <IconButton onClick={handleClickSuggest}>
                <QuestionIcon color="error" fontSize="large" />
              </IconButton>
            </Tooltip>
          )}
          {admin && primarySig === true && (
            <Tooltip title="Reclassify">
              <IconButton onClick={handleClickReclassify}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          )}
          {admin && (primarySig === false || count === 1) && (
            <Tooltip title="Delete">
              <IconButton onClick={handleClickDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
          {admin && primarySig === false && (
            <Tooltip title="Promote to Primary">
              <IconButton onClick={handleClickPromote}>
                <ArrowUpwardIcon />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <Grid container padding={1} spacing={1} justifyContent="space-between">
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Grid container>
                <Grid item pt={0.5}>
                  <Typography variant="body2">Signature</Typography>
                </Grid>
                {primarySig && (
                  <Grid item>
                    <Icon color="success">
                      <KeyboardDoubleArrowLeftIcon />
                    </Icon>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item sx={{ mt: primarySig ? -1 : 0 }}>
              <Link
                variant="body1"
                underline="none"
                href={`/signature/${signatureId}`}
                onClick={(e) => onClickNavigate(e, signatureId)}
                noWrap
              >
                {signatureName}
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Grid container direction="column" padding={1}>
            <Grid item xs={6}>
              <Typography variant="body2">Provider ID</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" noWrap fontWeight="bold">
                {providerId || 'Human'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="column" padding={1}>
            <Grid item xs={6}>
              <Typography variant="body2">Added</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" noWrap fontWeight="bold">
                {imported
                  ? Intl.DateTimeFormat().resolvedOptions().timeZone.substring(0, 7) === 'America'
                    ? Intl.DateTimeFormat('en-US', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                      }).format(new Date(imported))
                    : Intl.DateTimeFormat('en-GB', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                      }).format(new Date(imported))
                  : 'No record saved'}
              </Typography>
            </Grid>
          </Grid>
          {providerDescription && providerDescription.length > 0 && (
            <Grid container direction="column" padding={1}>
              <Grid item xs={6}>
                <Typography variant="body2">Provider Description</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" noWrap fontWeight="bold">
                  {providerDescription}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container direction="column" padding={1}>
            <Grid item xs={6}>
              <Typography variant="body2">Downvotes</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" noWrap fontWeight="bold">
                {userDownVotes}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="column" padding={1}>
            <Grid item xs={6}>
              <Typography variant="body2">Provider Category</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" noWrap fontWeight="bold">
                {providerCategory || 'Human'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="column" padding={1}>
            <Grid item xs={6}>
              <Typography variant="body2">Expires</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" noWrap fontWeight="bold">
                {expires
                  ? Intl.DateTimeFormat().resolvedOptions().timeZone.substring(0, 7) === 'America'
                    ? Intl.DateTimeFormat('en-US', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                      }).format(new Date(expires * 1000))
                    : Intl.DateTimeFormat('en-GB', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                      }).format(new Date(expires * 1000))
                  : 'Never'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

interface URLSearchProps {
  value: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClickSignature?: (signatureId: string) => void
  onReclassify?: (signatureId: string) => void
  onClose?: () => void
  onClickSuggestUrl?: (website: string) => void
  googleFavicon?: string
}

const URLSearch = ({
  value,
  onChange = () => {},
  onClickSignature = () => {},
  onReclassify = () => {},
  onClose = () => {},
  googleFavicon,
  onClickSuggestUrl = () => {},
}: URLSearchProps) => {
  const isMounted = useIsMounted()

  const [user] = useStore('user')
  const [error, setError] = useState('')
  const [urlsLoading, setUrlsLoading] = useState(false)
  const [signaturesLoading, setSignaturesLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [url, setUrl] = useState('')

  const [signatures, setSignatures] = useState<Signature[]>([])
  const [signatureResults, setSignatureResults] = useState<Signature[]>([])
  const [exactResults, setExactResults] = useState<Url[]>([])
  const [similarResults, setSimilarResults] = useState<Url[]>([])
  const [totalResults, setTotalResults] = useState(0)
  const [appStores, setAppStores] = useState<AppstoreSearch[]>([])
  const [seeMore, setSeeMore] = useState(false)
  const [promoteOpen, setPromoteOpen] = useState(false)

  const [activeUrl, setActiveUrl] = useState<UrlProp | null>(null)
  const [reclassifyOpen, setReclassifyOpen] = useState(false)
  const [classifyOpen, setClassifyOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [signaturesOpen, setSignaturesOpen] = useState(false)
  const [appStoresOpen, setAppStoresOpen] = useState(false)

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (urlsLoading) return
    populateSearch(value, signatures)
  }

  const handleClickSignatureAccordion = () => {
    setSignaturesOpen(!signaturesOpen)
  }

  const handleClickAppStoresAccordion = () => {
    setAppStoresOpen(!appStoresOpen)
  }

  const handleNavigateSignature = (e: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>, signatureId: string) => {
    e.preventDefault()
    onClickSignature(signatureId)
  }

  const populateSearch = useCallback(
    async (search: string, signatureSearch: Signature[]) => {
      try {
        if (!search) {
          setExactResults([])
          setSimilarResults([])
          setSignatureResults([])
          setAppStores([])
          setSeeMore(false)
          setTotalResults(0)
          return
        }
        setUrlsLoading(true)
        const signaturesFiltered = signatureSearch.filter(
          (signature) =>
            signature.name.toLowerCase().includes(search.trim().toLowerCase()) ||
            signature.id.includes(search.trim().toLowerCase())
        )
        setSignatureResults(signaturesFiltered)
        setSignaturesOpen(true)
        setUrl(search)
        const { exact, similar, total, appStores } = await getURLInfo(search.trim())
        if (!isMounted()) return
        setExactResults(exact.sort((a, b) => (a.primarySig > b.primarySig ? -1 : 1)))
        setSimilarResults(similar)
        setTotalResults(total)
        setAppStores(appStores)
        if (signaturesFiltered.length === 0 || exact.length !== 0) {
          setSignaturesOpen(false)
        }
      } catch (err) {
        const { msg } = handleError(err)
        if (!isMounted()) return
        setError(msg)
      } finally {
        if (!isMounted()) return
        setUrlsLoading(false)
      }
    },
    [isMounted]
  )

  const populateSignatures = useCallback(async () => {
    try {
      setSignaturesLoading(true)
      const res = await getSignatures()
      if (!isMounted()) return
      setSignatures(res.signatures)
    } catch (err) {
      const { msg } = handleError(err)
      if (!isMounted()) return
      setError(msg)
    } finally {
      if (!isMounted()) return
      setSignaturesLoading(false)
    }
  }, [isMounted])

  const handleClickReclassify = (url: UrlProp) => {
    setActiveUrl(url)
    setReclassifyOpen(true)
  }

  const handleClickDelete = (url: UrlProp) => {
    setActiveUrl(url)
    setDeleteOpen(true)
  }

  const handleConfirmDelete = useCallback(async () => {
    try {
      if (!activeUrl) return
      setUrlsLoading(true)
      await deleteSignatureURL(activeUrl.signatureId, activeUrl.website)
      if (!isMounted()) return
      setExactResults((oldResults) =>
        oldResults.filter((res) => !(res.signatureId === activeUrl.signatureId && res.website === activeUrl.website))
      )
      setSimilarResults((oldResults) =>
        oldResults.filter((res) => !(res.signatureId === activeUrl.signatureId && res.website === activeUrl.website))
      )
      setError('')
      setDeleteOpen(false)
    } catch (err) {
      const { msg } = handleError(err)
      if (!isMounted()) return
      setError(msg)
    } finally {
      setUrlsLoading(false)
    }
  }, [isMounted, activeUrl])

  const handleClickPromote = (url: UrlProp) => {
    setActiveUrl(url)
    setPromoteOpen(true)
  }

  const handleClosePromote = () => {
    setActiveUrl(null)
    setPromoteOpen(false)
  }

  const handleConfirmPromote = async () => {
    try {
      if (!activeUrl) return
      setUrlsLoading(true)
      await promoteUrls(activeUrl.website, activeUrl.signatureId)
      if (!isMounted()) return
      setUrlsLoading(false)
      setPromoteOpen(false)
      populateSearch(value, signatures)
    } catch (err) {
      const { msg } = handleError(err)
      if (!isMounted()) return
      setError(msg)
      setUrlsLoading(false)
    }
  }

  const handleCloseReclassify = () => {
    setReclassifyOpen(false)
  }

  const handleCloseDelete = () => {
    setDeleteOpen(false)
  }

  const handleAfterReclassify = (signatureId: string) => {
    setReclassifyOpen(false)
    onReclassify(signatureId)
  }

  const handleClickClassify = () => {
    setClassifyOpen(true)
  }

  const handleAfterClassify = (signatureId: string) => {
    setClassifyOpen(false)
    onReclassify(signatureId)
  }

  const handleCloseClassify = () => {
    setClassifyOpen(false)
  }

  const handleToggleSeeMore = (e: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>) => {
    e.preventDefault()
    setSeeMore(!seeMore)
  }

  useEffect(() => {
    populateSignatures()
  }, [populateSignatures])

  useEffect(() => {
    if (exactResults.length !== 0) return
    if (similarResults.length !== 0) return
    if (signatures.length === 0) return
    if (appStores.length !== 0) return
    if (loaded) return
    populateSearch(value, signatures)
    setLoaded(true)
  }, [populateSearch, exactResults, similarResults, value, signatures, loaded, appStores])

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          {error && (
            <Grid container padding={2} justifyContent="center">
              <Grid item xs={12}>
                <Collapse in={Boolean(error)}>
                  <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
                    {error || ' '}
                  </Alert>
                </Collapse>
              </Grid>
            </Grid>
          )}
          <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="URL / IP / SIGNATURE"
                  value={value}
                  fullWidth
                  disabled={urlsLoading || signaturesLoading}
                  onChange={handleSearchChange}
                />
              </form>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                color="primary"
                disableElevation
                loading={urlsLoading || signaturesLoading}
                onClick={handleSubmit}
                fullWidth
              >
                Search
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Collapse in={!urlsLoading && !signaturesLoading}>
          <Grid item xs={12}>
            <Divider />
            <div style={{ maxHeight: 540, minWidth: 400, overflowY: 'auto' }}>
              <Grid container justifyContent="center" alignItems="stretch" direction="column">
                {exactResults.map((result, index) => (
                  <Grid item xs={12} padding={1} key={`url-search-${index}`}>
                    <UrlBox
                      count={exactResults.length}
                      website={result?.website}
                      entryType={result?.entryType}
                      expires={result?.expires}
                      imported={result?.imported}
                      signatureId={result?.signatureId}
                      signatureName={signatures.find((s) => s.id === result?.signatureId)?.name || ''}
                      primarySig={result?.primarySig}
                      providerCategory={result?.providerCategory}
                      providerId={result?.providerId}
                      userDownVotes={result?.userDownVotes}
                      providerDescription={result?.providerDescription}
                      admin={user?.appindexAdmin}
                      onClickReclassify={handleClickReclassify}
                      onClickDelete={handleClickDelete}
                      onClickNavigate={handleNavigateSignature}
                      onClickSuggestUrl={onClickSuggestUrl}
                      onClickPromote={handleClickPromote}
                      googleFavicon={googleFavicon}
                    />
                  </Grid>
                ))}
                {seeMore &&
                  similarResults.map((result, index) => (
                    <Grid item xs={12} padding={1} key={`url-similar-search-${index}`}>
                      <UrlBox
                        count={0}
                        website={result?.website}
                        entryType={result?.entryType}
                        expires={result?.expires}
                        imported={result?.imported}
                        signatureId={result?.signatureId}
                        signatureName={signatures.find((s) => s.id === result?.signatureId)?.name || ''}
                        primarySig={result?.primarySig}
                        providerCategory={result?.providerCategory}
                        providerDescription={result?.providerDescription}
                        providerId={result?.providerId}
                        userDownVotes={result?.userDownVotes}
                        admin={user?.appindexAdmin}
                        onClickReclassify={handleClickReclassify}
                        onClickDelete={handleClickDelete}
                        onClickNavigate={handleNavigateSignature}
                        onClickSuggestUrl={onClickSuggestUrl}
                        googleFavicon={googleFavicon}
                      />
                    </Grid>
                  ))}
                {exactResults.length === 0 && similarResults.length === 0 && (
                  <Grid item xs={12} padding={1} key={`url-no-entry`}>
                    <Paper variant="outlined">
                      <Grid container padding={1} spacing={1} justifyContent="space-between">
                        <Grid item>
                          <Grid container direction="column" sx={{ mb: 1 }}>
                            <Grid item>
                              <Link
                                variant="h6"
                                underline="none"
                                href={'https://' + url}
                                rel="noopener"
                                target="_blank"
                              >
                                {url}
                              </Link>
                              <br />
                              <Typography variant="caption">No Signature</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Tooltip title="Google Website">
                            <IconButton
                              href={`https://www.google.com/search?q=${url}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <LazyImage src={googleFavicon || ''} />
                            </IconButton>
                          </Tooltip>
                          {!user?.appindexAdmin && (
                            <Tooltip title="Request Reclassify">
                              <IconButton onClick={(e) => onClickSuggestUrl(url)}>
                                <QuestionIcon color="error" fontSize="large" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {user?.appindexAdmin && (
                            <Tooltip title="Reclassify">
                              <IconButton onClick={handleClickClassify}>
                                <RefreshIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                )}
                {similarResults.length !== 0 && !seeMore && (
                  <Grid item sx={{ p: 2 }}>
                    <Link onClick={handleToggleSeeMore} href="#" underline="none">
                      See similar results ({similarResults.length} / {totalResults})
                    </Link>
                  </Grid>
                )}
                {similarResults.length !== 0 && seeMore && (
                  <Grid item sx={{ p: 2 }}>
                    <Link onClick={handleToggleSeeMore} href="#" underline="none">
                      See only exact results ({exactResults.length})
                    </Link>
                  </Grid>
                )}
                {signatureResults.length !== 0 && (
                  <Grid item xs={12} padding={1}>
                    <Accordion
                      elevation={0}
                      variant="outlined"
                      expanded={signaturesOpen}
                      onClick={handleClickSignatureAccordion}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Signatures</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container direction="column" padding={1}>
                          {signatureResults.map((signature, index) => (
                            <Grid item key={`signature-search-${index}`}>
                              <Link
                                variant="body1"
                                underline="none"
                                href={`/signature/${signature.id}`}
                                onClick={(e) => handleNavigateSignature(e, signature.id)}
                                noWrap
                              >
                                {signature.name}
                              </Link>
                            </Grid>
                          ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}
                {appStores.length !== 0 && (
                  <Grid item xs={12} padding={1}>
                    <Accordion
                      elevation={0}
                      variant="outlined"
                      expanded={appStoresOpen}
                      onClick={handleClickAppStoresAccordion}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>AppStore Id links</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container direction="column" padding={1}>
                          {appStores.map((appStore, index) => (
                            <Grid item key={`appStore-search-${index}`}>
                              <Link
                                variant="body1"
                                underline="none"
                                href={`/signature/${signatures.find((s) => s.id === appStore?.signature)?.name}`}
                                onClick={(e) => handleNavigateSignature(e, appStore.signature)}
                                noWrap
                              >
                                {signatures.find((s) => s.id === appStore?.signature)?.name}
                              </Link>
                            </Grid>
                          ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
        </Collapse>
      </Grid>
      {activeUrl && (
        <ReclassifyDialog
          open={reclassifyOpen}
          signature={activeUrl?.signatureId}
          url={activeUrl?.website}
          signatures={signatures.filter((sig) => sig.isCategory !== true)}
          onClose={handleCloseReclassify}
          afterReclassify={handleAfterReclassify}
        />
      )}
      {activeUrl && (
        <ConfirmDialog
          title="Confirm Promotion"
          text={`Are you sure you want to promote the url ${activeUrl.website} to ${activeUrl.signatureId} as the primary?`}
          open={promoteOpen}
          color="error"
          onConfirm={handleConfirmPromote}
          onClose={handleClosePromote}
        />
      )}
      <ClassifyDialog
        open={classifyOpen}
        url={url}
        onClose={handleCloseClassify}
        signatures={signatures}
        onClassify={handleAfterClassify}
      />
      <ConfirmDialog
        open={deleteOpen}
        title="Confirm delete"
        text={
          <Typography>
            Are you sure you want to remove{' '}
            <Typography display="inline" component="span" fontWeight="bold">
              {activeUrl?.website}
            </Typography>{' '}
            From{' '}
            <Typography display="inline" component="span" fontWeight="bold">
              {activeUrl?.signatureId?.replace('sphirewall.application.', '')}
            </Typography>
            ?
          </Typography>
        }
        onClose={handleCloseDelete}
        onConfirm={handleConfirmDelete}
        loading={urlsLoading}
      />
    </>
  )
}

export default URLSearch
