import { v4 as uuidv4 } from 'uuid'

export function downloadPdf(base64: string, name: string) {
  let hiddenElement = document.createElement('a')
  hiddenElement.href = base64
  hiddenElement.download = name + '.pdf'
  hiddenElement.click()
}

export function downloadFile(name: string, contents: string, ext: string) {
  let hiddenElement = document.createElement('a')
  hiddenElement.href = `data:text/${ext};charset=utf-8,` + encodeURI(contents)
  hiddenElement.download = name + '.' + ext
  hiddenElement.click()
}

export function convertToCSV(arr: any[]) {
  const array = [Object.keys(arr[0])].concat(arr)

  return array
    .map((it) => {
      return Object.values(it).toString()
    })
    .join('\n')
}

export function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      resolve(reader.result?.toString() || '')
    }
    reader.onerror = function (err) {
      reject(err)
    }
  })
}

export function getUUID() {
  return uuidv4()
}

export const getTimeSince = (date: number) => {
  const now = new Date().getTime()
  const time = new Date(date).getTime()
  const minutes = (now - time) / 1000 / 60
  if (minutes < 1) {
    const value = Math.round(minutes / 60)
    if (value < 5) return 'just now'
    return `${value} seconds ago`
  }
  if (minutes > 60 * 24 * 365) {
    const value = Math.round(minutes / (60 * 24 * 365))
    return `${value} year${value !== 1 ? 's' : ''} ago`
  }
  if (minutes > 60 * 24 * 30) {
    const value = Math.round(minutes / (60 * 24 * 30))
    return `${value} month${value !== 1 ? 's' : ''} ago`
  }
  if (minutes > 60 * 24 * 7) {
    const value = Math.round(minutes / (60 * 24 * 7))
    return `${value} week${value !== 1 ? 's' : ''} ago`
  }
  if (minutes > 60 * 24) {
    const value = Math.round(minutes / (60 * 24))
    return `${value} day${value !== 1 ? 's' : ''} ago`
  }
  if (minutes > 60) {
    const value = Math.round(minutes / 60)
    return `${value} hour${value !== 1 ? 's' : ''} ago`
  }
  const value = Math.round(minutes)
  return `${value} minute${value !== 1 ? 's' : ''} ago`
}
