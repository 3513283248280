import { Dialog, useTheme } from '@mui/material'
import { useState } from 'react'
import QueryProvidersSearch from './QueryProviderSearch.tsx'

interface QueryProvidersDialogProps {
  open: boolean
  onClose: () => void
}

const QueryProvidersDialog = ({ open = false, onClose = () => {} }: QueryProvidersDialogProps) => {
  const theme = useTheme()
  const [website, setWebsite] = useState('')
  const handleClose = () => {
    onClose()
  }

  const handleChangeWebsite = (e: string) => {
    setWebsite(e)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <QueryProvidersSearch website={website} changeWebsite={handleChangeWebsite} />
        </div>
      </div>
    </Dialog>
  )
}

export default QueryProvidersDialog
