import { Dialog, Grid, Paper, TextField } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Input from '@mui/material/Input'
import Switch from '@mui/material/Switch'
import { Tooltip } from '@mui/material'
import LazyImage from './LazyImage.tsx'
import CopyIcon from '@mui/icons-material/ContentCopy'
import React, { useState, useRef, useCallback } from 'react'
import { getBase64 } from '../util/Func.tsx'
import handleError from '../util/Error.tsx'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Keyword } from '../util/Api.tsx'

interface KeywordEditDialogProps {
  open?: boolean
  keyword: Keyword
  onClickEdit?: () => void
  onClose?: () => void
  onChange?: (keyword: Keyword) => void
}

const KeywordEditDialog = ({
  open = false,
  keyword,
  onClickEdit = () => {},
  onClose = () => {},
  onChange = () => {},
}: KeywordEditDialogProps) => {
  const theme = useTheme()
  const fileUpload = useRef<HTMLInputElement | null>(null)
  const [faviconDataUrl, setFaviconDataUrl] = useState<null | string>(null)

  const handleClose = () => {
    setFaviconDataUrl(null)
    onClose()
  }

  const handleChangeUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files) return
      if (e.target.files.length === 0) {
        return setFaviconDataUrl(null)
      }
      const dataUrl = await getBase64(e.target.files[0])
      onChange({ ...keyword, faviconUrl: dataUrl })
      return setFaviconDataUrl(dataUrl)
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    }
  }

  const handleClearFileUpload = useCallback(() => {
    if (!fileUpload?.current?.value) return
    setFaviconDataUrl(null)
    onChange({ ...keyword, faviconUrl: '' })
    fileUpload.current.value = ''
  }, [keyword, onChange])

  const handleCopyText = (text: string) => {
    navigator.clipboard.writeText(text)
  }

  const handleToggleDeprecated = (field: keyof Keyword) => {
    onChange({
      ...keyword,
      [field]: !keyword[field],
    })
  }

  const handleChangeValue = (field: keyof Keyword, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange({
      ...keyword,
      [field]: e.target.value,
    })
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Paper>
        <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
          <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
            <Grid container padding={2} justifyContent="space-between" alignItems="center" wrap="nowrap" minWidth={400}>
              <Grid item>
                <Typography variant="h5" noWrap>
                  Edit Keyword Signature
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container direction="column" alignItems="stretch" justifyContent="center" padding={2} spacing={3}>
              <Grid item>
                <Typography>ID</Typography>
                <Input disabled name="keywordName" value={keyword?.id} fullWidth />
              </Grid>
              <Grid item>
                <Typography>Name</Typography>
                <TextField
                  name="keywordName"
                  fullWidth
                  variant="standard"
                  value={keyword.name}
                  onChange={(e) => handleChangeValue('name', e)}
                />
              </Grid>
              <Grid item>
                <Typography>Favicon</Typography>
              </Grid>
              <Grid container alignItems="stretch" spacing={3} justifyContent="left" padding={3}>
                <Grid item>
                  <Button disabled={!!faviconDataUrl} variant="contained" component="label">
                    Upload
                    <input
                      type="file"
                      hidden
                      accept=".ico,.png,.jpg,.jpeg,.svg"
                      ref={fileUpload}
                      onChange={handleChangeUpload}
                    />
                  </Button>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!faviconDataUrl || !keyword.faviconUrl}
                    sx={{ mx: 1 }}
                    onClick={handleClearFileUpload}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <LazyImage
                    src={
                      faviconDataUrl
                        ? keyword?.faviconUrl || ''
                        : (keyword?.faviconUrl || '') + '?' + new Date().getTime().toString(16)
                    }
                    size={24}
                  />
                </Grid>
                <Grid item sx={{ pr: 2 }}>
                  <Tooltip title="Copy to clipboard">
                    <span>
                      <IconButton
                        onClick={() => (keyword?.faviconUrl ? handleCopyText(keyword?.faviconUrl) : () => {})}
                      >
                        <CopyIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={Boolean(keyword?.deprecated)}
                      onChange={() => handleToggleDeprecated('deprecated')}
                    />
                  }
                  label="Deprecated"
                />
              </Grid>
              <Grid item>
                <Typography>Description</Typography>
                <TextField
                  name="keywordDescription"
                  variant="standard"
                  fullWidth
                  multiline
                  value={keyword?.description}
                  onChange={(e) => handleChangeValue('description', e)}
                  helperText={`${keyword?.description?.length} / 500`}
                />
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={onClickEdit} disabled={!keyword}>
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Paper>
    </Dialog>
  )
}

export default KeywordEditDialog
