import React, { useState, useEffect } from 'react'
import useIsMounted from 'react-is-mounted-hook'

import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Autocomplete from '@mui/material/Autocomplete'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import LoadingButton from '@mui/lab/LoadingButton'

import CloseIcon from '@mui/icons-material/Close'

import { useTheme } from '@mui/material/styles'

import handleError from '../util/Error.tsx'
import { createMapping, editMappings, Mapping } from '../util/Api.tsx'

interface MappingCreateProps {
  open?: boolean
  existingMapping?: Mapping | null
  onClose?: () => void
  onSave?: (mappings: Mapping[]) => void
  signatureIds: string[]
}

const MappingCreate = ({
  open = false,
  existingMapping = null,
  onClose = () => {},
  onSave = () => {},
  signatureIds = [],
}: MappingCreateProps) => {
  const theme = useTheme()
  const isMounted = useIsMounted()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [signatureId, setSignatureId] = useState<string | null>(null)
  const [providerId, setProviderId] = useState('')
  const [providerCategory, setProviderCategory] = useState('')
  const [ttl, setTtl] = useState('')
  const [ranking, setRanking] = useState('')
  const [providerDescription, setProviderDescription] = useState('')

  useEffect(() => {
    if (!existingMapping) {
      setSignatureId('')
      setProviderId('')
      setProviderCategory('')
      setTtl('')
      setRanking('')
      setProviderDescription('')
    } else {
      setSignatureId(existingMapping.signatureId)
      setProviderId(existingMapping.providerId)
      setProviderCategory(existingMapping.providerCategory)
      setTtl(existingMapping.ttl ? (existingMapping.ttl / 86400).toString() : '0')
      setRanking(existingMapping.ranking.toString())
      setProviderDescription(existingMapping.providerDescription ? existingMapping.providerDescription : '')
    }
  }, [existingMapping])

  const handleChangeSignatureId = (e: React.SyntheticEvent, newValue: string | null) => {
    setSignatureId(newValue)
  }

  const handleChangeProviderId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProviderId(e.target.value)
  }

  const handleChangeProviderCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProviderCategory(e.target.value)
  }

  const handleChangeTtl = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTtl((parseInt(e.target.value) || 0).toString())
  }

  const handleChangeProviderDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProviderDescription(e.target.value)
  }

  const handleChangeRanking = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRanking((parseInt(e.target.value) || 0).toString())
  }

  const handleSave = async () => {
    try {
      if (signatureId === null || providerId === '' || providerCategory === '' || ttl === '' || ranking === '') {
        throw new Error('You must fill in all fields.')
      }

      if (providerDescription.length > 255) {
        throw new Error('You cannnot have more than 255 characters for the description.')
      }

      setLoading(true)
      let mappings = []
      const ttlConversion = parseInt(ttl) * 86400
      if (existingMapping) {
        const res = await editMappings({
          signatureId,
          providerId,
          providerCategory,
          providerDescription,
          ttl: ttlConversion,
          ranking: parseInt(ranking),
          editmapping: existingMapping,
        })
        mappings = res.mappings
      } else {
        const res = await createMapping({
          signatureId,
          providerId,
          providerCategory,
          providerDescription,
          ttl: ttlConversion,
          ranking: parseInt(ranking),
        })
        mappings = res.mappings
      }
      if (!isMounted()) return

      onClose()
      onSave(mappings)
      setError('')
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  useEffect(() => {
    setError('')
  }, [open])

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                Create a new categorisation mapping
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container padding={1} justifyContent="center">
            <Grid item>
              {open && (
                <Collapse in={Boolean(error)}>
                  <Alert severity="error">{error || 'Error'}</Alert>
                </Collapse>
              )}
            </Grid>
          </Grid>
          <Grid container padding={3} spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                id="signatureId"
                options={signatureIds}
                renderInput={(params) => <TextField {...params} label="Signature Id" />}
                value={signatureId}
                onChange={handleChangeSignatureId}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Provider" fullWidth value={providerId} onChange={handleChangeProviderId} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Provider ID"
                fullWidth
                value={providerCategory}
                onChange={handleChangeProviderCategory}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Provider Description"
                fullWidth
                value={providerDescription}
                onChange={handleChangeProviderDescription}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField label="TTL (enter in days)" fullWidth value={ttl} onChange={handleChangeTtl} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Ranking" fullWidth value={ranking} onChange={handleChangeRanking} />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    disableElevation
                    loading={loading}
                    onClick={handleSave}
                  >
                    {existingMapping ? 'Save Mapping' : 'Create Mapping'}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}

export default MappingCreate
