import { Autocomplete } from '@mui/lab'
import { Box, TextField, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { Signature } from '../util/Api.tsx'

interface SignatureSelectionOption {
  label: string
  value: string
  favicon: string | null | undefined
}

const getSigOption = (sig: Signature): SignatureSelectionOption => ({
  label: sig?.name,
  value: sig?.id,
  favicon: sig?.faviconUrl,
})

interface SignatureOptionProps {
  favicon: string
  name: string
  [key: string]: any
}

const SignatureOption = ({ favicon = '', name = '', ...props }: SignatureOptionProps) => {
  return (
    <Box
      component="li"
      sx={{
        '& > img': { mr: 2, flexShrink: 0 },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      {...props}
    >
      <img src={favicon} srcSet={favicon} alt="" loading="lazy" width="20" />
      <Typography>{name}</Typography>
    </Box>
  )
}

interface DependencyEditProps {
  signatures: Signature[]
  signature: Signature
  onChange?: (newValue: string[]) => void
}

const DependencyEdit = ({ signatures = [], onChange = () => {}, signature }: DependencyEditProps) => {
  const options = useMemo(
    () => signatures.filter((sig) => sig.isCategory !== true && sig.isSubCategory !== true).map(getSigOption),
    [signatures]
  )

  const [selectedDependencies, setSelectedDependencies] = useState<SignatureSelectionOption[] | undefined>(
    signature ? options.filter((sig) => signature?.dependencies.includes(sig.value)) : undefined
  )

  const handleEquals = (option: SignatureSelectionOption, value: SignatureSelectionOption) => {
    return option?.value === value?.value
  }

  const handleChangeSelected = (e: React.SyntheticEvent, newValue: SignatureSelectionOption[] | undefined) => {
    if (newValue) {
      setSelectedDependencies([...newValue])
    }
    if (!newValue) {
      onChange([])
    } else {
      let dependencies: string[] = []
      signatures.filter((sig) =>
        newValue.map((newV) => {
          if (newV.value.includes(sig.id)) {
            dependencies.push(sig.id)
            return true
          }
          return false
        })
      )
      onChange(dependencies)
    }
  }

  useEffect(() => {
    if (!signature) {
      return
    }
    setSelectedDependencies(options.filter((sig) => signature?.dependencies.includes(sig.value)))
  }, [options, signature])

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={handleEquals}
      filterSelectedOptions
      value={selectedDependencies}
      onChange={handleChangeSelected}
      renderOption={(props, option) => (
        <SignatureOption favicon={option?.favicon || ''} name={option.label} {...props} />
      )}
      renderInput={(params) => <TextField {...params} />}
    />
  )
}

export default DependencyEdit
