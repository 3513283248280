import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import { useTheme } from '@mui/material/styles'

import URLSearch from './URLSearch.tsx'
import { getSignature } from '../util/Api.tsx'

interface URLSearchDialogProps {
  open?: boolean
  value: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClose?: () => void
  onClickSignature?: (signatureId: string) => void
  onReclassify?: (signatureId: string) => void
  onClickSuggestUrl?: (website: string) => void
}

const URLSearchDialog = ({
  open = false,
  value,
  onChange = () => {},
  onClose = () => {},
  onClickSignature = () => {},
  onReclassify = () => {},
  onClickSuggestUrl = () => {},
}: URLSearchDialogProps) => {
  const [googleFavicon, setGoogleFavicon] = useState('')
  const theme = useTheme()

  const handleClickSignature = (signatureId: string) => {
    onClickSignature(signatureId)
    onClose()
  }

  const handleReclassify = (signatureId: string) => {
    onReclassify(signatureId)
    onClose()
  }

  const handleClose = () => {
    onClose()
  }

  const populateFavicon = async () => {
    const data = await getSignature('sphirewall.application.google')
    setGoogleFavicon(data.signature.faviconUrl || '')
  }

  useEffect(() => {
    populateFavicon()
  }, [])

  return (
    <Dialog open={open} onClose={handleClose}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                Signature / URL / IP Search
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <URLSearch
            googleFavicon={googleFavicon || ''}
            value={value}
            onChange={onChange}
            onClose={handleClose}
            onClickSignature={handleClickSignature}
            onReclassify={handleReclassify}
            onClickSuggestUrl={onClickSuggestUrl}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default URLSearchDialog
