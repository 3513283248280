import React, { useState, useEffect, useCallback } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import TableSortLabel from '@mui/material/TableSortLabel'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { Mapping, Signature } from '../util/Api.tsx'
import EditIcon from '@mui/icons-material/Edit'
import { Tooltip } from '@mui/material'

interface MappingsTableProps {
  admin?: boolean
  mappings: Mapping[]
  loading?: boolean
  signatures?: Signature[]
  defaultRows?: number
  hideSignatureHeading?: boolean
  onClick?: (signatureId: string) => void
  onEdit?: (row: Mapping) => void
  onDelete?: (mapping: Mapping) => void
}

const MappingsTable = ({
  admin = false,
  mappings = [],
  signatures = [],
  loading = false,
  defaultRows = 50,
  hideSignatureHeading = false,
  onClick = () => {},
  onEdit = () => {},
  onDelete = () => {},
}: MappingsTableProps) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRows)
  const [orderBy, setOrderBy] = useState<keyof Mapping | 'sigName'>(!hideSignatureHeading ? 'sigName' : 'providerId')
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')

  const getMappings = useCallback(() => {
    const sorted = mappings.sort((a, b) => {
      if (orderBy === 'sigName') {
        const aSig = signatures.find((sig) => sig.id === a.signatureId)
        const bSig = signatures.find((sig) => sig.id === b.signatureId)
        if (!aSig || !bSig) {
          if (a['signatureId'] > b['signatureId']) return order === 'asc' ? 1 : -1
          if (a['signatureId'] < b['signatureId']) return order === 'desc' ? 1 : -1
        } else {
          if (aSig.name > bSig.name) return order === 'asc' ? 1 : -1
          if (aSig.name < bSig.name) return order === 'desc' ? 1 : -1
        }
        return 0
      } else {
        if (!a[orderBy]) return order === 'asc' ? 1 : -1
        if (!b[orderBy]) return order === 'desc' ? 1 : -1
        if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1
        if (a[orderBy] < b[orderBy]) return order === 'desc' ? 1 : -1
        return 0
      }
    })
    const currentPage: (Mapping | null)[] = sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const length = currentPage.length
    if (currentPage.length === rowsPerPage) return currentPage
    for (let i = 0; i < rowsPerPage - length; i++) {
      currentPage.push(null)
    }
    return currentPage
  }, [mappings, page, rowsPerPage, order, orderBy, signatures])

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const handleClickDelete = async (e: React.MouseEvent, mapping: Mapping) => {
    try {
      e.preventDefault()
      e.stopPropagation()
      onDelete(mapping)
    } catch (err) {
      throw err
    }
  }

  const handleClickEdit = (e: React.MouseEvent, mapping: Mapping) => {
    try {
      e.preventDefault()
      e.stopPropagation()
      onEdit(mapping)
    } catch (err) {
      throw err
    }
  }

  const createSortHandler = (id: keyof Mapping | 'sigName') => (e: React.MouseEvent) => {
    if (id !== orderBy) {
      setOrder('asc')
      return setOrderBy(id)
    }
    if (order === 'asc') {
      return setOrder('desc')
    }
    if (order === 'desc') {
      return setOrder('asc')
    }
  }

  useEffect(() => {
    setPage(0)
  }, [mappings])

  return (
    <Paper variant="outlined">
      <TableContainer>
        <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {!hideSignatureHeading && (
                <>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'signatureId'}
                      direction={orderBy === 'signatureId' ? order : 'asc'}
                      onClick={createSortHandler('signatureId')}
                    >
                      Signature ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'sigName'}
                      direction={orderBy === 'sigName' ? order : 'asc'}
                      onClick={createSortHandler('sigName')}
                    >
                      Signature Name
                    </TableSortLabel>
                  </TableCell>
                </>
              )}

              <TableCell>
                <TableSortLabel
                  active={orderBy === 'providerId'}
                  direction={orderBy === 'providerId' ? order : 'asc'}
                  onClick={createSortHandler('providerId')}
                >
                  Provider
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'providerCategory'}
                  direction={orderBy === 'providerCategory' ? order : 'asc'}
                  onClick={createSortHandler('providerCategory')}
                >
                  Provider ID
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'providerDescription'}
                  direction={orderBy === 'providerDescription' ? order : 'asc'}
                  onClick={createSortHandler('providerDescription')}
                >
                  Provider Description
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'ttl'}
                  direction={orderBy === 'ttl' ? order : 'asc'}
                  onClick={createSortHandler('ttl')}
                >
                  TTL
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'ranking'}
                  direction={orderBy === 'ranking' ? order : 'asc'}
                  onClick={createSortHandler('ranking')}
                >
                  Ranking
                </TableSortLabel>
              </TableCell>
              {admin && <TableCell align="right">Delete</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {getMappings().map((row, index) => (
              <TableRow
                hover
                key={`signature-table-item-${index}`}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: row?.signatureId ? 'pointer' : 'default',
                  height: 47,
                  opacity: !row?.signatureId ? 0 : 1,
                }}
                onClick={row?.signatureId ? () => onClick(row.signatureId) : () => {}}
              >
                {!hideSignatureHeading && (
                  <>
                    <TableCell component="th" scope="row">
                      <Typography variant="body2" noWrap sx={{ maxWidth: 280 }}>
                        {row?.signatureId || ''}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography variant="body2" noWrap sx={{ maxWidth: 280 }}>
                        {signatures.find((sig) => sig.id === row?.signatureId)?.name || ''}
                      </Typography>
                    </TableCell>
                  </>
                )}

                <TableCell component="th" scope="row">
                  <Typography variant="body2" noWrap sx={{ maxWidth: 280 }}>
                    {row?.providerId || ''}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" noWrap sx={{ maxWidth: 240 }}>
                    {row?.providerCategory || ''}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" noWrap sx={{ maxWidth: 240 }}>
                    {row?.providerDescription || ''}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" noWrap sx={{ maxWidth: 120 }}>
                    {/* row.ttl is seconds convert to days */}
                    {row?.ttl ? (
                      <Tooltip title={row.ttl}>
                        <Typography variant="inherit">
                          {row.ttl / 86400 > 0
                            ? row.ttl / 86400 < 1
                              ? '< 1 day'
                              : `${row.ttl / 86400} days`
                            : 'No Entry'}
                        </Typography>
                      </Tooltip>
                    ) : (
                      ''
                    )}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" noWrap sx={{ maxWidth: 120 }}>
                    {row?.ranking || ''}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {admin && row?.signatureId && (
                    <>
                      {!hideSignatureHeading && (
                        <IconButton size="small" disabled={loading || !row} onClick={(e) => handleClickEdit(e, row)}>
                          <EditIcon />
                        </IconButton>
                      )}
                      <IconButton size="small" disabled={loading || !row} onClick={(e) => handleClickDelete(e, row)}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={mappings.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default MappingsTable
