import React from 'react'

import Container from '@mui/material/Container'

import FullScreenDialog from '../FullscreenDialog.tsx'
import SignatureDetails from '../layouts/SignatureDetails.tsx'

interface SignatureDetailsDialogProps {
  open?: boolean
  signatureId: string
  signatureName: string
  onClose?: () => void
}

const SignatureDetailsDialog = ({
  open = false,
  signatureId = '',
  signatureName = '',
  onClose = () => {},
}: SignatureDetailsDialogProps) => {
  return (
    <FullScreenDialog open={open} onClose={onClose} title={signatureName}>
      <Container sx={{ pb: 4 }}>
        <SignatureDetails signatureId={signatureId} onExit={onClose} />
      </Container>
    </FullScreenDialog>
  )
}

export default SignatureDetailsDialog
