import React, { useState } from 'react'
import { TransitionGroup } from 'react-transition-group'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import CloseIcon from '@mui/icons-material/Close'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import Paper from '@mui/material/Paper'
import UrlImportDialog from './UrlImportDialog.tsx'
import { Signature, SignatureUrlInfo } from '../util/Api.tsx'
import SignatureSelect from './SignatureSelect.tsx'

interface UrlsEditProps {
  signatureId?: string
  urls: SignatureUrlInfo[]
  onChange: (urls: SignatureUrlInfo[]) => void
  signatures: Signature[]
  onChangeSignature: (signature: Signature) => void
  editSuggestion: string
}

const UrlsEdit = ({
  signatureId = '',
  urls = [],
  onChange = () => {},
  signatures = [],
  editSuggestion = 'False',
  onChangeSignature = () => {},
}: UrlsEditProps) => {
  const [importOpen, setImportOpen] = useState(false)

  const handleCloseImport = () => {
    setImportOpen(false)
  }

  const handleOpenImport = () => {
    setImportOpen(true)
  }

  const handleSubmitImport = (newUrls: SignatureUrlInfo[]) => {
    const oldUrls = urls.filter((url) => !newUrls.some((u) => u.url === url.url))
    onChange([...oldUrls, ...newUrls])
    setImportOpen(false)
  }

  const handleDeleteUrl = (url: SignatureUrlInfo) => {
    const newUrls = urls.filter((u) => u.url !== url.url)
    onChange([...newUrls])
  }

  const handleChangeSignature = (e: React.SyntheticEvent<Element, Event>, signatures: Signature | null) => {
    if (signatures === null) return
    onChangeSignature(signatures)
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item>
          <Fab size="small" color="primary" onClick={handleOpenImport}>
            <AddIcon />
          </Fab>
        </Grid>
        <Grid item xs>
          <Typography variant="overline">Signature Urls</Typography>
        </Grid>
      </Grid>
      {editSuggestion === 'True' && (
        <Grid container>
          <Grid item paddingTop={3}>
            <Typography variant="overline">Change associated signature:</Typography>
          </Grid>
          <Grid item xs={7} padding={1}>
            <SignatureSelect
              id="edit-recat"
              signatures={signatures}
              onChange={handleChangeSignature}
              defaultSignature={signatures.find((sig) => sig.id === signatureId) || null}
            />
          </Grid>
        </Grid>
      )}
      <Paper variant="outlined" sx={{ my: 2, p: 2, height: 300, overflowY: 'scroll' }}>
        <TransitionGroup>
          {urls.map((url, index) => (
            <Collapse key={url.url}>
              <Box sx={{ mb: 1 }}>
                <Paper
                  variant="outlined"
                  sx={url.add ? { backgroundColor: '#e9ffe8' } : url.remove ? { backgroundColor: '#ffe8e8' } : {}}
                >
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <IconButton size="small" onClick={() => handleDeleteUrl(url)}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Typography variant="overline" fontFamily="monospace" sx={{ mx: 1 }}>
                        {url.url}
                      </Typography>
                    </Grid>
                    {!url.valid && (
                      <Grid item>
                        <Typography variant="overline">Invalid url</Typography>
                      </Grid>
                    )}
                    {url.signatureId && (
                      <Grid item>
                        <Typography variant="overline">
                          Recategorise from{' '}
                          <span style={{ color: '#0080ff' }}>
                            {url.signatureId.replace('sphirewall.application.', '')}
                          </span>
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Box>
            </Collapse>
          ))}
        </TransitionGroup>
      </Paper>
      <UrlImportDialog
        open={importOpen}
        signatureId={signatureId}
        onClose={handleCloseImport}
        onSubmit={handleSubmitImport}
      />
    </Box>
  )
}

export default UrlsEdit
