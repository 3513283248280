import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Link,
  Paper,
  Tooltip,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useCallback, useState } from 'react'
import RemoveCircleOutlineOutlined from '@mui/icons-material/RemoveCircleOutlineOutlined'
import handleError from '../util/Error.tsx'
import useIsMounted from 'react-is-mounted-hook'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { testMonitorModeration } from '../util/Api.tsx'

interface Results {
  entry_no: number
  org: string
  type: string
  title: string
  capture: string
  '1_percent': number
  '2_percent': number
  '3_percent': number
  '4_percent': number
  '5_percent': number
}

interface Entry {
  entry_no: number
  org: string
  title: string
  capture: string
}

const thresholds = [0.97433, 0.970035, 0.965272, 0.960632, 0.954087]

const MonitorModeration = () => {
  const isMounted = useIsMounted()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [entries, setEntries] = useState<Entry[]>([{ entry_no: 1, org: 'secondary', title: '', capture: '' }])
  const [inputOpen, setInputOpen] = useState(true)
  const [resultsOpen, setResultsOpen] = useState(false)
  const [results, setResults] = useState<Results[] | []>([])
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')
  const [orderBy, setOrderBy] = useState<keyof Results>('entry_no')
  const [version, setVersion] = useState('')

  const getResults = useCallback(() => {
    const sortedResults = results.sort((a, b) => {
      if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1
      if (a[orderBy] < b[orderBy]) return order === 'desc' ? 1 : -1
      return 0
    })
    return sortedResults
  }, [results, order, orderBy])

  const inputOpenExpand = () => {
    setInputOpen(!inputOpen)
  }

  const resultsOpenExpand = () => {
    setResultsOpen(!resultsOpen)
  }

  const handleAddRow = () => {
    if (entries.length > 29) {
      setError('You cannot submit more than 30 requests')
      return
    }
    if (entries.length !== 0) {
      setEntries([
        ...entries,
        { entry_no: entries[entries.length - 1].entry_no + 1, org: 'secondary', title: '', capture: '' },
      ])
      setInputOpen(true)
    } else {
      setEntries([{ entry_no: 1, org: 'secondary', title: '', capture: '' }])
    }
  }

  const handleRemoveRow = (entry: number) => {
    setEntries(entries.filter((ent, i) => i !== entry))
  }

  const handleChangeType = (event: React.ChangeEvent<HTMLInputElement>, entry: number) => {
    setEntries(
      entries.map((ent, i) => {
        if (i === entry) {
          return { ...ent, org: event.target.value }
        }
        return ent
      })
    )
  }

  const handleChangeTitle = (event: string, entry: number) => {
    setError('')
    if (event.length > 100) {
      setError('Titles cannot be more than 100 characters')
    }
    setEntries(
      entries.map((ent, i) => {
        if (i === entry) {
          return { ...ent, title: event }
        }
        return ent
      })
    )
  }

  const handleChangeCapture = (event: string, entry: number) => {
    setError('')
    if (event.length > 500) {
      setError('Captures cannot be more than 500 characters')
    }
    setEntries(
      entries.map((ent, i) => {
        if (i === entry) {
          return { ...ent, capture: event }
        }
        return ent
      })
    )
  }

  const handleSubmitEntries = async () => {
    try {
      setLoading(true)
      setError('')
      if (!isMounted) return
      if (entries.some((ent) => ent.capture === '')) {
        setError('Captures are required')
        return
      }
      const data = await testMonitorModeration(entries)
      setResults(data.results)
      setVersion(data.version)
      setInputOpen(false)
      setResultsOpen(true)
    } catch (err) {
      const { msg } = handleError(err)
      if (!isMounted()) return
      setError(msg)
    } finally {
      setLoading(false)
    }
  }

  const handleClearEntries = () => {
    setLoading(true)
    setEntries(
      entries.map((ent, ind) => {
        return { ...ent, entry_no: ind, org: 'secondary', title: '', capture: '' }
      })
    )
    setLoading(false)
  }

  const handleClearRows = () => {
    setEntries([{ entry_no: 1, org: 'secondary', title: '', capture: '' }])
  }

  const createSortHandler = (key: keyof Results | 'entry_no') => (e: React.MouseEvent) => {
    if (key !== orderBy) {
      setOrder('asc')
      return setOrderBy(key)
    }
    if (order === 'asc') {
      return setOrder('desc')
    }
    if (order === 'desc') {
      return setOrder('asc')
    }
  }

  return (
    <div>
      <Grid container justifyContent="center" sx={{ mt: 3 }} padding={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={10}>
          <Grid container justifyContent="center" sx={{ mt: 3 }}>
            <Grid item>
              <Typography variant="h3">Monitor Moderation Event Classifier</Typography>
            </Grid>
          </Grid>
          {error && (
            <Grid container justifyContent="center" sx={{ mt: 3 }}>
              <Grid item>
                <Alert severity="error">{error}</Alert>
              </Grid>
            </Grid>
          )}
          <Grid container justifyContent="center" alignItems="center" padding={1} spacing={2} sx={{ mt: 3, mb: 3 }}>
            <Grid item>
              <Typography></Typography>The tool allows you to predict if a capture will NOT be sent for moderation.
            </Grid>
            <Grid item xs={12} />
            <Grid item>
              <Typography></Typography>Everything is sent for Moderation UNLESS it is predicted to be a NON-event The
              higher the score, the more confidence the model has that the capture does NOT need moderation.
            </Grid>
            <Grid item xs={12} />
            <Grid item>
              <Typography>
                Anything 50 or over will NOT be sent for moderation. For more information click{' '}
                <Link href="https://familyzone.atlassian.net/wiki/x/4AAxYHMC" target="_blank" rel="noopener noreferrer">
                  here
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Accordion variant="outlined" expanded={inputOpen}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
              onClick={inputOpenExpand}
            >
              <Typography sx={{ mx: 2 }}>Query</Typography>
              <Typography variant="caption">
                {entries?.length !== 1 ? ` ${entries?.length} Entries` : ` 1 Entry`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justifyContent="center" spacing={2} sx={{ mt: 3 }}>
                <Grid item xs={2}>
                  <Typography sx={{ fontWeight: 'bold' }}>Organisation</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ fontWeight: 'bold' }}>Window Title</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography sx={{ fontWeight: 'bold' }}>Associated Capture</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ borderBottomWidth: 5 }} />
                </Grid>
                {entries.map((entry, i) => (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{ mt: 3 }}
                  >
                    <Grid item xs={2}>
                      <FormControl>
                        <RadioGroup value={entry.org} onChange={(e) => handleChangeType(e, i)} id={`radio-${i}`}>
                          <FormControlLabel value="primary" control={<Radio />} label="Primary" />
                          <FormControlLabel value="secondary" control={<Radio />} label="Secondary" />
                          <FormControlLabel value="teriary" control={<Radio />} label="Tertiary" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>
                        <TextField
                          fullWidth
                          minRows={2}
                          multiline
                          onChange={(e) => handleChangeTitle(e.target.value, i)}
                          value={entry.title}
                          error={entry.title.length > 100}
                          helperText={`${entry.title.length} / 100`}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>
                        <TextField
                          value={entry.capture}
                          fullWidth
                          multiline
                          minRows={2}
                          error={entry.capture.length > 500}
                          onChange={(e) => handleChangeCapture(e.target.value, i)}
                          helperText={`${entry.capture.length} / 500`}
                        />
                      </Typography>
                    </Grid>
                    <Grid item pb={4}>
                      <Fab
                        sx={{ mx: 2 }}
                        color="primary"
                        size="small"
                        disabled={loading}
                        onClick={(e) => handleRemoveRow(i)}
                      >
                        <RemoveCircleOutlineOutlined />
                      </Fab>
                      Remove Row
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Paper>
            <Grid container direction="row" justifyContent="center" alignItems="center" padding={3} spacing={5}>
              <Grid item>
                <Button disabled={!entries || loading || !!error} variant="contained" onClick={handleSubmitEntries}>
                  Submit
                </Button>
              </Grid>
              <Grid item>
                <Button disabled={loading} variant="contained" onClick={handleClearEntries} color="error">
                  Clear Content
                </Button>
              </Grid>
              <Grid item>
                <Button disabled={loading} variant="contained" onClick={handleClearRows} color="error">
                  Delete all additional Rows
                </Button>
              </Grid>
              <Grid item>
                <Typography>
                  <Fab sx={{ mx: 2 }} color="primary" size="small" disabled={loading} onClick={handleAddRow}>
                    <AddIcon />
                  </Fab>
                  New Row
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Grid container justifyContent="center" sx={{ mt: 3 }}>
            <Grid item xs={12}>
              {getResults().length > 0 && (
                <Accordion variant="outlined" expanded={resultsOpen}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                    onClick={resultsOpenExpand}
                  >
                    <Typography sx={{ mx: 2 }}>Results</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer>
                      <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">
                              <TableSortLabel
                                active={orderBy === 'org'}
                                direction={orderBy === 'org' ? order : 'asc'}
                                onClick={createSortHandler('org')}
                              >
                                Organisation
                              </TableSortLabel>
                            </TableCell>
                            <TableCell align="left">
                              <TableSortLabel
                                active={orderBy === 'title'}
                                direction={orderBy === 'title' ? order : 'asc'}
                                onClick={createSortHandler('title')}
                              >
                                Window Title
                              </TableSortLabel>
                            </TableCell>
                            <TableCell align="left">
                              <TableSortLabel
                                active={orderBy === 'capture'}
                                direction={orderBy === 'capture' ? order : 'asc'}
                                onClick={createSortHandler('capture')}
                              >
                                Associated Capture
                              </TableSortLabel>
                            </TableCell>
                            <TableCell align="left">
                              <TableSortLabel
                                active={orderBy === '1_percent'}
                                direction={orderBy === '1_percent' ? order : 'asc'}
                                onClick={createSortHandler('1_percent')}
                              >
                                <Tooltip title={thresholds[0]}>
                                  <Typography>1% ER</Typography>
                                </Tooltip>
                              </TableSortLabel>
                            </TableCell>
                            <TableCell align="left">
                              <TableSortLabel
                                active={orderBy === '2_percent'}
                                direction={orderBy === '2_percent' ? order : 'asc'}
                                onClick={createSortHandler('2_percent')}
                              >
                                <Tooltip title={thresholds[1]}>
                                  <Typography>2% ER</Typography>
                                </Tooltip>
                              </TableSortLabel>
                            </TableCell>
                            <TableCell align="left">
                              <TableSortLabel
                                active={orderBy === '3_percent'}
                                direction={orderBy === '3_percent' ? order : 'asc'}
                                onClick={createSortHandler('3_percent')}
                              >
                                <Tooltip title={thresholds[2]}>
                                  <Typography>3% ER</Typography>
                                </Tooltip>
                              </TableSortLabel>
                            </TableCell>
                            <TableCell align="left">
                              <TableSortLabel
                                active={orderBy === '4_percent'}
                                direction={orderBy === '4_percent' ? order : 'asc'}
                                onClick={createSortHandler('4_percent')}
                              >
                                <Tooltip title={thresholds[3]}>
                                  <Typography>4% ER</Typography>
                                </Tooltip>
                              </TableSortLabel>
                            </TableCell>
                            <TableCell align="left">
                              <TableSortLabel
                                active={orderBy === '5_percent'}
                                direction={orderBy === '5_percent' ? order : 'asc'}
                                onClick={createSortHandler('5_percent')}
                              >
                                <Tooltip title={thresholds[4]}>
                                  <Typography>5% ER</Typography>
                                </Tooltip>
                              </TableSortLabel>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {results?.map((row, index) => (
                            <TableRow
                              hover={Boolean(row)}
                              key={`dependency-${row || ''}-${index}`}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                cursor: !row ? undefined : 'pointer',
                                height: 40,
                                opacity: !row ? 0 : 1,
                              }}
                            >
                              <TableCell component="th" scope="row" align="left">
                                <Typography variant="body2" noWrap>
                                  {row?.org}
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row" align="left">
                                <Typography variant="body2" noWrap>
                                  {row?.title.length > 40 ? row?.title.substring(0, 40) + '...' : row?.title}
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row" align="left">
                                <Typography variant="body2" noWrap>
                                  {row?.capture.length > 40 ? row?.capture.substring(0, 40) + '...' : row?.capture}
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row" align="left">
                                <Typography variant="body2" noWrap>
                                  <Grid item padding={0.3}>
                                    {row?.['1_percent'] >= 0.5
                                      ? `Not Sent (${Math.floor(row?.['1_percent'] * 100)}%)`
                                      : `Sent (${Math.floor(row?.['1_percent'] * 100)}%)`}
                                  </Grid>
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row" align="left">
                                <Typography variant="body2" noWrap>
                                  <Grid item padding={0.3}>
                                    {row?.['2_percent'] >= 0.5
                                      ? `Not Sent (${Math.floor(row?.['2_percent'] * 100)}%)`
                                      : `Sent (${Math.floor(row?.['2_percent'] * 100)}%)`}
                                  </Grid>
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row" align="left">
                                <Typography variant="body2" noWrap>
                                  <Grid item padding={0.3}>
                                    {row?.['3_percent'] >= 0.5
                                      ? `Not Sent (${Math.floor(row?.['3_percent'] * 100)}%)`
                                      : `Sent (${Math.floor(row?.['3_percent'] * 100)}%)`}
                                  </Grid>
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row" align="left">
                                <Typography variant="body2" noWrap>
                                  <Grid item padding={0.3}>
                                    {row?.['4_percent'] >= 0.5
                                      ? `Not Sent (${Math.floor(row?.['4_percent'] * 100)}%)`
                                      : `Sent (${Math.floor(row?.['4_percent'] * 100)}%)`}
                                  </Grid>
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row" align="left">
                                <Typography variant="body2" noWrap>
                                  <Grid item padding={0.3}>
                                    {row?.['5_percent'] >= 0.5
                                      ? `Not Sent (${Math.floor(row?.['5_percent'] * 100)}%)`
                                      : `Sent (${Math.floor(row?.['5_percent'] * 100)}%)`}
                                  </Grid>
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Grid item padding={3}>
                      <Typography variant="body2" sx={{ fontWeight: 2 }} noWrap>
                        Version: {version}
                      </Typography>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default MonitorModeration
