import React, { useState } from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { TransitionGroup } from 'react-transition-group'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import Paper from '@mui/material/Paper'
import AddApplicationDialog from './AddApplicationDialog.tsx'

import { AppControls } from '../util/Api.tsx'

const mappedIds: {
  [key: string]: string
} = {
  playstoreid: 'Google Play Store',
  appleappstore: 'Apple Store',
  'windows-filename': 'Windows - FileName',
  'windows-publisher': 'Windows - Publisher/Company',
  'windows-taskbar': 'Windows - Taskbar Name',
}

interface ApplicationEditProps {
  appstores: AppControls[]
  id: string
  signature_id: string
  onChange: (appstores: AppControls[]) => void
}

const ApplicationEdit = ({ appstores, onChange, id, signature_id }: ApplicationEditProps) => {
  const [importOpen, setImportOpen] = useState(false)
  const [error, setError] = useState('')

  const handleOpenImport = () => {
    setImportOpen(true)
    setError('')
  }

  const handleCloseImport = () => {
    setError('')
    setImportOpen(false)
  }

  const handleSubmitImport = (appId: string, type: string) => {
    if (appstores.some((a) => (a.id === id || a.signature_id === id) && a.type === type)) {
      setError('Duplicate app link')
      return
    }
    const duplicateType = appstores.filter((app) => app.type === type)
    if (duplicateType.length > 0) {
      setError('You cannot have duplicate Platforms')
      return
    }
    onChange([...appstores, { id, appId, signature_id, type, modified: new Date() }])
    setError('')
    setImportOpen(false)
  }

  const handleClickDelete = (appstore: AppControls) => {
    setError('')
    onChange(appstores.filter((a) => !(a.id === appstore.id && a.type === appstore.type)))
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item>
          <Fab size="small" color="primary" onClick={handleOpenImport}>
            <AddIcon />
          </Fab>
        </Grid>
        <Grid item xs>
          <Typography variant="overline">Applications</Typography>
        </Grid>
      </Grid>
      <Paper variant="outlined" sx={{ my: 2, p: 2, height: 240, overflowY: 'scroll' }}>
        <TransitionGroup>
          {appstores?.map((appstore, index) => (
            <Collapse key={`appstore-edit-${appstore.id}-${appstore.type}`}>
              <Box sx={{ mb: 1 }}>
                <Paper variant="outlined">
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <IconButton size="small" onClick={() => handleClickDelete(appstore)}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Typography fontFamily="monospace" sx={{ mx: 1 }}>
                        {appstore.appId} from {mappedIds?.[appstore.type] || appstore.type}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Collapse>
          ))}
        </TransitionGroup>
      </Paper>
      <AddApplicationDialog
        open={importOpen}
        error={error}
        onClose={handleCloseImport}
        onClickAdd={handleSubmitImport}
      />
    </Box>
  )
}

export default ApplicationEdit
