import React, { useState, useEffect, useCallback } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import TableSortLabel from '@mui/material/TableSortLabel'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { KeywordEntry } from '../util/Api.tsx'

interface KeywordEntriesProps {
  admin?: boolean
  keywordEntries: KeywordEntry[]
  loading?: boolean
  onDelete?: (keywordEntry: KeywordEntry) => void
}

const KeywordEntries = ({
  admin = false,
  keywordEntries = [],
  loading = false,
  onDelete = () => {},
}: KeywordEntriesProps) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [orderBy, setOrderBy] = useState<keyof KeywordEntry>('type')
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')

  const getKeywordEntries = useCallback(() => {
    const sorted = keywordEntries.sort((a, b) => {
      if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1
      if (a[orderBy] < b[orderBy]) return order === 'desc' ? 1 : -1
      return 0
    })
    const currentPage: (KeywordEntry | null)[] = sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const length = currentPage.length
    if (currentPage.length === rowsPerPage) return currentPage
    for (let i = 0; i < rowsPerPage - length; i++) {
      currentPage.push(null)
    }
    return currentPage
  }, [keywordEntries, page, rowsPerPage, order, orderBy])

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const handleClickDelete = async (e: React.MouseEvent<HTMLButtonElement>, keywordEntry: KeywordEntry) => {
    try {
      e.preventDefault()
      e.stopPropagation()
      onDelete(keywordEntry)
    } catch (err) {
      throw err
    }
  }

  const createSortHandler = (id: keyof KeywordEntry) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (id !== orderBy) {
      setOrder('asc')
      return setOrderBy(id)
    }
    if (order === 'asc') {
      return setOrder('desc')
    }
    if (order === 'desc') {
      return setOrder('asc')
    }
  }

  useEffect(() => {
    setPage(0)
  }, [keywordEntries])

  return (
    <Paper variant="outlined">
      <TableContainer>
        <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'value'}
                  direction={orderBy === 'value' ? order : 'asc'}
                  onClick={createSortHandler('value')}
                >
                  Value
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'type'}
                  direction={orderBy === 'type' ? order : 'asc'}
                  onClick={createSortHandler('type')}
                >
                  Type
                </TableSortLabel>
              </TableCell>
              {admin && <TableCell align="right">Delete</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {getKeywordEntries().map((row, index) => (
              <TableRow
                key={`keyword-entry-table-item-${index}`}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  height: 47,
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="body2" noWrap sx={{ maxWidth: 400 }}>
                    {row?.value || ''}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant="body2" noWrap sx={{ maxWidth: 100 }}>
                    {row?.type || ''}
                  </Typography>
                </TableCell>
                {admin && (
                  <TableCell align="right">
                    {row?.value && (
                      <IconButton size="small" disabled={loading || !row} onClick={(e) => handleClickDelete(e, row)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={keywordEntries.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default KeywordEntries
