import { Autocomplete, Box, TextField, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { AppControls, AppListings, AppsSelectionProps, ControlledAppsOptionProps } from '../util/Api.tsx'

interface ControlledAppSelectorProps {
  app: AppControls | null
  onChange: (app: AppListings | null) => void
  applications: AppListings[]
  appcontrols?: AppControls[]
  deleting?: boolean
}

export const getControlOption = (app: AppListings): AppsSelectionProps => ({
  label: app?.name,
  value: app?.id,
  favicon: app?.favicon,
  format: app?.format,
})

const ControlledAppSelector = ({
  app,
  onChange = () => {},
  applications,
  appcontrols,
  deleting,
}: ControlledAppSelectorProps) => {
  const [selectedControlledApps, setSelectedControlledApps] = useState<AppsSelectionProps | null>(null)

  const handleChangeControlledApps = (e: AppsSelectionProps | null) => {
    setSelectedControlledApps(e)
    onChange(applications.find((app) => app.id === e?.value) || null)
  }

  const ControlledAppsOption = ({
    favicon = '',
    name = '',
    value = '',
    format = '',
    ...props
  }: ControlledAppsOptionProps) => {
    return (
      <Box
        component="li"
        sx={{
          '& > img': { mr: 2, flexShrink: 0 },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        {...props}
      >
        <img src={favicon} srcSet={favicon} alt="" loading="lazy" width="20" />
        <Typography>
          {name} ({format}) {deleting ? ' (' + appcontrols?.filter((app) => app.id === value).length + ')' : ''}
        </Typography>
      </Box>
    )
  }

  const options = useMemo(() => {
    return [...applications.map(getControlOption)]
  }, [applications])

  useEffect(() => {
    setSelectedControlledApps(options.find((opt) => opt.value === (app?.id || app?.signature_id)) || null)
  }, [options, app?.id, app?.signature_id])

  return (
    <Autocomplete
      options={options}
      value={selectedControlledApps}
      onChange={(e, newValue) => handleChangeControlledApps(newValue)}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <ControlledAppsOption
          favicon={option?.favicon || ''}
          name={option.label}
          value={option.value}
          format={option.format}
          {...props}
        />
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Signature/App..."
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off', // disable autocomplete and autofill
          }}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
        />
      )}
    />
  )
}

export default ControlledAppSelector
