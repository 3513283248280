import React from 'react'
import { useParams, useHistory } from 'react-router'
import SignatureDetails from '../components/layouts/SignatureDetails.tsx'

const Signature = () => {
  const params = useParams<{ id?: string }>()
  const history = useHistory()

  const handleExit = () => {
    history.push('/signatures')
  }

  if (!params?.id) {
    return <></>
  }

  return <SignatureDetails signatureId={params.id} onExit={handleExit} />
}

export default Signature
