import { useCallback, useEffect, useState } from 'react'
import { GenericList } from '../../util/Api.tsx'
import {
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

interface GenericListsTableProps {
  genericLists: GenericList[]
  admin: boolean
  loading: boolean
  selected: GenericList | null
  dark: boolean
  onEdit: (genericList: GenericList) => void
  onDelete: (genericList: GenericList) => void
  onClick: (genericList: GenericList) => void
}

const GenericListsTable = ({
  genericLists,
  admin,
  loading,
  selected,
  dark,
  onEdit = () => {},
  onDelete = () => {},
  onClick = () => {},
}: GenericListsTableProps) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [orderBy, setOrderBy] = useState<keyof GenericList>('name')
  const [order, setOrder] = useState<'asc' | 'desc'>('desc')

  const getMappings = useCallback(() => {
    if (orderBy === 'entries') return []
    const sorted = genericLists.sort((a, b) => {
      if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1
      if (a[orderBy] < b[orderBy]) return order === 'desc' ? 1 : -1
      return 0
    })
    const currentPage: (GenericList | null)[] = sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const length = currentPage.length
    if (currentPage.length === rowsPerPage) return currentPage
    for (let i = 0; i < rowsPerPage - length; i++) {
      currentPage.push(null)
    }
    return currentPage
  }, [genericLists, page, rowsPerPage, order, orderBy])

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const handleClickDelete = async (e: React.MouseEvent, list: GenericList) => {
    try {
      e.preventDefault()
      e.stopPropagation()
      onDelete(list)
    } catch (err) {
      throw err
    }
  }

  const handleClickEdit = async (e: React.MouseEvent, list: GenericList) => {
    onEdit(list)
  }

  const createSortHandler = (id: keyof GenericList) => (e: React.MouseEvent) => {
    if (id !== orderBy) {
      setOrder('asc')
      return setOrderBy(id)
    }
    if (order === 'asc') {
      return setOrder('desc')
    }
    if (order === 'desc') {
      return setOrder('asc')
    }
  }

  useEffect(() => {
    setPage(0)
  }, [genericLists])

  return (
    <Paper variant="outlined">
      <TableContainer>
        <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'id'}
                  direction={orderBy === 'id' ? order : 'asc'}
                  onClick={createSortHandler('id')}
                >
                  List ID
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={createSortHandler('name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>

              {admin && <TableCell align="right">Edit/Delete</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {getMappings().map((row, index) => (
              <TableRow
                hover
                key={`keyword-table-item-${index}`}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: 'pointer',
                  height: 47,
                  backgroundColor:
                    row?.id === selected?.id && !dark ? '#ededed' : row?.id === selected?.id && dark ? '#4b4b4b' : null,
                }}
                onClick={row?.id ? () => onClick(row) : () => {}}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="body2" noWrap sx={{ maxWidth: 480 }}>
                    {row?.id || ''}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant="body2" noWrap sx={{ maxWidth: 480 }}>
                    {row?.name || ''}
                  </Typography>
                </TableCell>

                {admin && (
                  <TableCell align="right">
                    {row && (
                      <IconButton size="small" disabled={loading || !row} onClick={(e) => handleClickEdit(e, row)}>
                        <EditIcon />
                      </IconButton>
                    )}
                    {row && (
                      <IconButton size="small" disabled={loading || !row} onClick={(e) => handleClickDelete(e, row)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={genericLists.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default GenericListsTable
