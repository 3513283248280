import { Alert, Button, Dialog, Grid, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@mui/material/styles'
import { useState } from 'react'
import { AppControls, AppListings } from '../util/Api.tsx'
import DeleteConfirmDialog from './DeleteConfirmDialog.tsx'
import ControlledAppSelector from './ControlledAppSelector.tsx'

interface DeleteControlDialogProps {
  error: string
  onClose: () => void
  listings: AppListings[]
  open: boolean
  onDelete: (id: string) => void
  loading: boolean
  apps: AppControls[]
}

const DeleteControlDialog = ({
  open = false,
  error = '',
  onClose = () => {},
  listings,
  loading,
  apps,
  onDelete = () => {},
}: DeleteControlDialogProps) => {
  const theme = useTheme()
  const [selectedControlledApps, setSelectedControlledApps] = useState<AppListings | null>(null)
  const [confirmOpen, setConfirmOpen] = useState(false)

  const handleClose = () => {
    onClose()
  }
  const handleChangeControlledApps = (e: AppListings | null) => {
    setSelectedControlledApps(e)
  }

  const handleConfirmClose = () => {
    setConfirmOpen(false)
  }

  const handleConfirmOpen = () => {
    setConfirmOpen(true)
  }

  const handleConfirmDelete = () => {
    setConfirmOpen(false)
    if (!selectedControlledApps) return
    onDelete(selectedControlledApps?.id)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} justifyContent="space-between" alignItems="center">
            <Grid item justifyContent="center">
              <Typography variant="h5" noWrap>
                Delete a Controlled App
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          {error && (
            <Grid container justifyContent="center" sx={{ mb: 3 }}>
              <Grid item>
                <Alert severity="error">{error}</Alert>
              </Grid>
            </Grid>
          )}
          <Grid container direction="row" alignItems="center" justifyContent="center" padding={2} spacing={3}>
            <Grid item xs={12}>
              <ControlledAppSelector
                app={null}
                applications={listings}
                onChange={handleChangeControlledApps}
                deleting={true}
                appcontrols={apps}
              />
            </Grid>
            <Grid item>
              <Button variant="contained" fullWidth disabled={!selectedControlledApps} onClick={handleConfirmOpen}>
                Delete
              </Button>
            </Grid>
            <DeleteConfirmDialog
              loading={loading}
              open={confirmOpen}
              name={`this Controlled App`}
              onClose={handleConfirmClose}
              onDelete={handleConfirmDelete}
            />
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}

export default DeleteControlDialog
