import { AxiosError } from 'axios'

export interface AnyError extends Omit<AxiosError, 'isAxiosError' | 'config' | 'toJSON'> {
  isAxiosError?: boolean
  config?: any
  toJSON?: any
}

export default function handleError(err: AnyError | any): {
  msg: string
  stack: string
  status: number
  errors: any[]
  data: any
} {
  const msg = err?.response?.data?.msg || err.message || ''
  const stack = err?.response?.data?.stack || err.stack || ''
  const errors = err?.response?.data?.errors || []
  const status = err?.response?.status || 0
  console.error(msg)
  console.error(stack)

  return {
    msg,
    stack,
    status,
    errors,
    data: err?.response?.data?.data || err?.response?.data,
  }
}
