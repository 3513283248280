import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'

import { useTheme } from '@mui/material/styles'
import { FormControlLabel, Switch } from '@mui/material'
import { GenericList } from '../../util/Api.tsx'

interface AddEditGenericListDialogProps {
  open?: boolean
  loading?: boolean
  error: string
  onClickAdd: (id: string, name: string, enabled: boolean) => void
  onClose: () => void
  type: string
  existingRow: GenericList | null
  onClickEdit: (name: string, enabled: boolean) => void
}

const AddEditGenericList = ({
  open = false,
  loading = false,
  error = '',
  type = 'add',
  onClickAdd = () => {},
  onClickEdit = () => {},
  existingRow,
  onClose = () => {},
}: AddEditGenericListDialogProps) => {
  const theme = useTheme()
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [enabled, setEnabled] = useState(false)

  const handleChangeId = (e: any) => {
    setId(e.target.value)
  }

  const handleChangeName = (e: any) => {
    setName(e.target.value)
  }

  const handleToggleEditEnabled = () => {
    setEnabled(!enabled)
  }

  const handleClickAdd = () => {
    if (!id || !name) return

    if (type === 'edit' && existingRow) {
      onClickEdit(name, enabled)
    } else {
      onClickAdd(id, name, enabled)
    }
  }

  const handleClose = () => {
    onClose()
  }

  useEffect(() => {
    if (existingRow) {
      setId(existingRow.id)
      setName(existingRow.name)
      setEnabled(existingRow.enabled)
    } else {
      setId('')
      setName('')
      setEnabled(false)
    }
  }, [existingRow, type, open])

  return (
    <Dialog open={open} onClose={handleClose}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                {type === 'edit' ? 'Edit' : 'Add'} a List
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          {error && (
            <Grid container justifyContent="center" sx={{ mb: 3 }}>
              <Grid item>
                <Alert severity="error">{error}</Alert>
              </Grid>
            </Grid>
          )}
          <Grid container direction="column" alignItems="stretch" justifyContent="center" padding={2} spacing={3}>
            <Grid item>
              <TextField
                id="id"
                variant="outlined"
                label="ID"
                fullWidth
                value={id}
                onChange={handleChangeId}
                disabled={Boolean(existingRow)}
                helperText={'Enter the ID of the List'}
              />
            </Grid>
            <Grid item>
              <TextField
                id="name"
                variant="outlined"
                label="Name"
                value={name}
                fullWidth
                onChange={handleChangeName}
                helperText={'Enter the name of the List'}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={<Switch checked={Boolean(enabled)} onChange={() => handleToggleEditEnabled()} />}
                label="Enabled"
              />
            </Grid>

            <Grid item>
              <Button variant="contained" fullWidth disabled={loading || !id || !name} onClick={handleClickAdd}>
                {type === 'edit' ? 'Edit' : 'Add'}
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}

export default AddEditGenericList
