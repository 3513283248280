import { useCallback, useEffect, useState } from 'react'
import { getMappings, getSignatures, Mapping, ProviderResponse, queryProviders, Signature } from '../util/Api.tsx'
import useIsMounted from 'react-is-mounted-hook'
import {
  Alert,
  Box,
  Collapse,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

interface QueryProvidersSearchProps {
  website: string
  changeWebsite: (e: string) => void
}

const QueryProvidersSearch = ({ website, changeWebsite = () => {} }: QueryProvidersSearchProps) => {
  const [providersResponse, setProvidersResponse] = useState<ProviderResponse[]>()
  const [mappings, setMappings] = useState<Mapping[]>([])
  const [signatures, setSignatures] = useState<Signature[]>([])
  const isMounted = useIsMounted()

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const handleObtainProviders = useCallback(async () => {
    try {
      setError('')
      setLoading(true)

      if (!website) return
      if (website.length > 253) {
        setError('Website must be less than 253 characters')
      }
      if (website.includes('http://') || website.includes('https://')) {
        changeWebsite(website.replace('http://', '').replace('https://', ''))
      }
      const domainRegex = /^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$/
      if (!domainRegex.test(website)) {
        setError(
          'Website must be a valid domain and cannot include symbols (except a full stop and dashes) e.g. notpurple.com'
        )
      }
      if (!isMounted()) return

      const data = await queryProviders(website)
      if (!data.results) {
        setError(data.msg)
      }
      setProvidersResponse(data.results)
    } catch (e) {
      console.log(e)
      setError('Failed to obtain providers')
    } finally {
      setLoading(false)
    }
  }, [website, changeWebsite, isMounted])

  const handleChangeWebsite = (e: string) => {
    changeWebsite(e)
  }

  const populateMappings = async () => {
    try {
      setLoading(true)
      const data = await getMappings()
      data.mappings.forEach((mapping) => {
        if (mapping.providerDescription === null) {
          mapping.providerDescription = ''
        }
      })
      setMappings(data.mappings)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const populateSignatures = async () => {
    try {
      setLoading(true)
      const data = await getSignatures()
      setSignatures(data.signatures)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setError('')
    populateMappings()
    populateSignatures()
    setProvidersResponse(undefined)
  }, [])

  useEffect(() => {
    if (mappings.length === 0) return
    if (signatures.length === 0) return
    if (loaded) return
    handleObtainProviders()
    setLoaded(true)
  }, [website, loaded, handleObtainProviders, mappings, signatures])

  return (
    <Grid container justifyContent="center" padding={1}>
      <Collapse in={Boolean(error)}>
        <Box>
          <Grid container padding={2} justifyContent="center">
            <Grid item xs={12}>
              <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
                {error || ' '}
              </Alert>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
      <Grid container padding={2} spacing={2} justifyContent="center">
        <Grid item>
          <Typography>
            This function allows querying providers used by Appindex to confirm if a website would be hit by a provider
            along with which one.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              handleObtainProviders()
            }}
          >
            <Grid container padding={2} spacing={2} justifyContent="center">
              <Grid item xs={9}>
                <Typography variant="overline">Domain e.g. google.com</Typography>
                <br />
                <TextField
                  value={website}
                  fullWidth
                  onChange={(e) => handleChangeWebsite(e.target.value)}
                  error={error.length > 0}
                />
              </Grid>
              <Grid item xs={3} pb={2}>
                <Grid item pt={4.5}>
                  <LoadingButton
                    size="large"
                    variant="contained"
                    loading={loading || !loaded}
                    disabled={website.length < 3 || !website.includes('.') || !loaded}
                    onClick={handleObtainProviders}
                  >
                    Query
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
        {providersResponse && providersResponse.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography variant="overline">Results</Typography>
            </Grid>
            {providersResponse.length === 2 &&
            providersResponse[1][0] === 'unclass' &&
            providersResponse[0][1].length === 0 ? (
              'No providers found any categorisation for this website'
            ) : (
              <Grid item xs={12} pb={1}>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableCell>Provider</TableCell>
                      <TableCell>Response</TableCell>
                      <TableCell>Signature(s) Associated</TableCell>
                    </TableHead>
                    <TableBody>
                      {providersResponse
                        .filter((provider) => provider[0] !== 'unclass' && provider[1].length > 0)
                        .map((provider) => (
                          <TableRow>
                            <TableCell>{provider[0]}</TableCell>
                            <TableCell>
                              {typeof provider[1] === 'object'
                                ? provider[1].map((entry) => {
                                    const map = mappings?.find((map) => map.providerCategory === entry)
                                    return map && map.providerDescription
                                      ? map.providerDescription + ',\n'
                                      : entry + ',\n'
                                  })
                                : provider[1]}
                            </TableCell>
                            <TableCell>
                              {typeof provider[1] === 'object'
                                ? provider[1].map((entry) =>
                                    signatures?.find(
                                      (sig) =>
                                        mappings?.find((map) => map.providerCategory === entry)?.signatureId === sig.id
                                    )?.name
                                      ? signatures?.find(
                                          (sig) =>
                                            mappings?.find((map) => map.providerCategory === entry)?.signatureId ===
                                            sig.id
                                        )?.name + ', '
                                      : entry + ', '
                                  )
                                : ''}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default QueryProvidersSearch
