import React, { useState, useEffect, useCallback } from 'react'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
import SearchBar from './SearchBar.tsx'
import Grid from '@mui/material/Grid'
import { VertexTestResult } from '../util/Api.tsx'
import {
  Card,
  Checkbox,
  FormControlLabel,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'

interface KeywordResultsProps {
  admin?: boolean
  keywordResults: VertexTestResult[]
  loading?: boolean
  selected?: string | null
  dark?: boolean
  onClick?: (id: string) => void
}

interface modifiedVertexTestResult {
  scores: { [key: string]: any }
  search: string
  modifiedScores: modifiedScores[]
}

interface modifiedScores {
  name: string
  score: number
}

const VertexResultsTable = ({
  admin,
  keywordResults,
  loading,
  selected,
  dark,
  onClick = () => {},
}: KeywordResultsProps) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [search, setSearch] = useState('')
  const [shownResults, setShownResults] = useState<VertexTestResult[]>([])
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')
  const [orderBy, setOrderBy] = useState<keyof modifiedVertexTestResult>('search')
  const [range, setRange] = useState(0)
  const [other, setOther] = useState(false)

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    setShownResults(keywordResults.filter((results) => results.search.includes(e.target.value)))
    setPage(0)
  }

  const handleChangeOther = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOther(e.target.checked)
  }

  const getResults = useCallback(() => {
    const aboveRange: modifiedVertexTestResult[] = []
    // eslint-disable-next-line array-callback-return
    shownResults.map((result) => {
      const scoresArray: { name: string; score: number }[] = []
      Object.keys(result.scores).forEach((score) => {
        if (result.scores[score] * 100 >= range) {
          if (other && score === 'other') {
            return
          } else {
            scoresArray.push({ name: score, score: Math.round(result.scores[score] * 100) })
          }
        }
      })
      if (!aboveRange.some((entry) => entry.search.includes(result.search))) {
        if (scoresArray.length > 0) {
          aboveRange.push(...[{ scores: result.scores, search: result.search, modifiedScores: scoresArray }])
        }
      }
    })

    const sortSearch = aboveRange.sort((a, b) => {
      if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1
      if (a[orderBy] < b[orderBy]) return order === 'desc' ? 1 : -1
      return 0
    })
    const currentResults: (modifiedVertexTestResult | null)[] = sortSearch.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    )
    const length = currentResults.length
    if (currentResults.length === rowsPerPage) return currentResults
    for (let i = 0; i < rowsPerPage - length; i++) {
      currentResults.push(null)
    }
    return currentResults
  }, [shownResults, page, rowsPerPage, range, other, orderBy, order])

  const createSortHandler = (key: keyof modifiedVertexTestResult | 'search') => (e: React.MouseEvent) => {
    if (key !== orderBy) {
      setOrder('asc')
      return setOrderBy(key)
    }
    if (order === 'asc') {
      return setOrder('desc')
    }
    if (order === 'desc') {
      return setOrder('asc')
    }
  }

  const marks = [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 25,
      label: '25%',
    },
    {
      value: 50,
      label: '50%',
    },
    {
      value: 75,
      label: '75%',
    },

    {
      value: 100,
      label: '100%',
    },
  ]

  function low(value: number) {
    return `${value}%`
  }

  function color(color1: any[], color2: any[], weight: number) {
    var weight2 = 1 - weight
    var rgb = [
      Math.round(color1[0] * weight + color2[0] * weight2),
      Math.round(color1[1] * weight + color2[1] * weight2),
      Math.round(color1[2] * weight + color2[2] * weight2),
    ]
    return rgb
  }

  const yellow = [255, 255, 143]
  const white = [255, 255, 255]
  const red = [255, 0, 0]
  const black = [21, 21, 21]
  const purple = [148, 0, 211]
  const blue = [0, 0, 139]

  const handleSlider = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setRange(newValue)
    }
  }

  useEffect(() => {
    setPage(0)
    setShownResults(keywordResults)
    setSearch('')
  }, [keywordResults])

  return (
    <Paper variant="outlined">
      <Grid container justifyContent="center" alignItems="center" spacing={2} padding={2}>
        <Grid item padding={1}>
          <Box>
            <Typography>AI Confidence</Typography>
            <Slider
              track="inverted"
              aria-labelledby="Percentage Range"
              defaultValue={0}
              marks={marks}
              getAriaValueText={low}
              sx={{ minWidth: '200px' }}
              onChange={handleSlider}
              valueLabelDisplay="auto"
            />
          </Box>
        </Grid>
        <Grid item padding={1}>
          <SearchBar dark value={search} onChange={handleChangeSearch} />
        </Grid>

        <Grid item padding={1}>
          <FormControlLabel
            control={<Checkbox defaultChecked onChange={handleChangeOther} checked={other} />}
            label="Hide Other"
          />
        </Grid>
      </Grid>
      <Table sx={{ minWidth: 650 }} stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'search'}
                direction={orderBy === 'search' ? order : 'asc'}
                onClick={createSortHandler('search')}
              >
                Search
              </TableSortLabel>
            </TableCell>
            <TableCell align="center" sx={{ minWidth: 450 }}>
              Scores
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getResults().map((row, index) => (
            <TableRow
              hover
              key={`keyword-table-item-${index}`}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                cursor: 'pointer',
                height: 40,
                backgroundColor:
                  row?.scores === selected && !dark ? '#ededed' : row?.scores === selected && dark ? '#4b4b4b' : null,
              }}
            >
              <TableCell
                component="th"
                scope="row"
                style={{
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                }}
              >
                <Typography variant="body2" sx={{ maxWidth: 600 }}>
                  {row?.search || ''}
                </Typography>
              </TableCell>
              <TableCell>
                <Box>
                  <Grid container justifyContent="center">
                    {row?.modifiedScores.map((item) => {
                      let backgroundColor
                      const ratio = item.score / 50

                      if (ratio > 1) {
                        if (dark) {
                          backgroundColor = color(purple, blue, ratio - 1)
                        } else {
                          backgroundColor = color(red, yellow, ratio - 1)
                        }
                      } else {
                        if (dark) {
                          backgroundColor = color(blue, black, ratio)
                        } else {
                          backgroundColor = color(yellow, white, ratio)
                        }
                      }

                      backgroundColor = `rgba(${backgroundColor.join(',')}, 0.6)`
                      return (
                        <Grid item padding={0.5}>
                          <Card variant="outlined" sx={{ backgroundColor }}>
                            <Grid item padding={0.3}>
                              {item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()}: {item.score}%
                            </Grid>
                          </Card>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={keywordResults.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default VertexResultsTable
