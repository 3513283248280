import React from 'react'
import styled from '@mui/material/styles/styled'
import Box, { BoxProps } from '@mui/material/Box'

const Table = styled('table', { shouldForwardProp: (prop) => prop !== 'dark' })(({ dark }: { dark: boolean }) => ({
  padding: 0,
  margin: 0,
  ...(dark && {
    color: '#ffffff',
  }),
  width: '100%',
}))

const TableRow = styled('tr', { shouldForwardProp: (prop) => prop !== 'dark' })(({ dark }: { dark: boolean }) => ({
  outlineStyle: 'solid',
  outlineWidth: 2,
  outlineColor: !dark ? '#ebebeb' : '#474747',
  padding: 0,
  margin: 0,
}))

const TableHeader = styled('th', { shouldForwardProp: (prop) => prop !== 'dark' && prop !== 'slim' })(
  ({ dark, slim }: { dark: boolean; slim: boolean }) => ({
    outlineStyle: 'solid',
    outlineWidth: 1,
    outlineColor: !dark ? '#ebebeb' : '#474747',
    padding: !slim ? 4 : 0,
  })
)

const TableCell = styled('td', { shouldForwardProp: (prop) => prop !== 'dark' && prop !== 'slim' })(
  ({ dark, slim }: { dark: boolean; slim: boolean }) => ({
    outlineStyle: 'solid',
    outlineWidth: 1,
    outlineColor: !dark ? '#ebebeb' : '#474747',
    padding: !slim ? 4 : 0,
  })
)

const exampleLayout = {
  headers: ['Header one', 'Header two', 'Header three'],
  body: [
    ['Field 1', 'Value', 'Value two'],
    ['Field 2', 'Value', <div>Html</div>],
  ],
}

interface MaterialTableLayout {
  headers?: (string | JSX.Element)[]
  body?: (string | JSX.Element)[][]
}

interface MaterialTableProps extends BoxProps {
  layout: MaterialTableLayout
  dark?: boolean
}

const MaterialTable = ({ layout = exampleLayout, dark = false, ...props }: MaterialTableProps) => {
  return (
    <Box {...props}>
      <Table dark={dark}>
        <thead>
          <TableRow dark={dark}>
            {layout?.headers?.map?.((header, headerIndex) => (
              <TableHeader key={`table-header-${headerIndex}`} dark={dark} slim={typeof header !== 'string'}>
                {header}
              </TableHeader>
            ))}
          </TableRow>
        </thead>
        <tbody>
          {layout?.body?.map?.((body, bodyIndex) => (
            <TableRow key={`table-row-${bodyIndex}`} dark={dark}>
              {body.map((item, itemIndex) => (
                <TableCell key={`table-item-${bodyIndex}-${itemIndex}`} dark={dark} slim={typeof item !== 'string'}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Box>
  )
}

export default MaterialTable
