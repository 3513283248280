import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { useHistory } from 'react-router'
import { TransitionGroup } from 'react-transition-group'

import { StringParam, useQueryParam } from 'use-query-params'

import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import TravelExploreIcon from '@mui/icons-material/TravelExplore'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Fab from '@mui/material/Fab'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowRightIcon from '@mui/icons-material/ArrowRightAlt'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import SearchIcon from '@mui/icons-material/Search'

import RefreshIcon from '@mui/icons-material/Refresh'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

import SearchBar from '../components/SearchBar.tsx'
import MaterialTable from '../components/MaterialTable.tsx'
import AvatarLetters from '../components/AvatarLetters.tsx'
import EditSuggestionDialog from '../components/EditSuggestionDialog.tsx'

import useStore from '../util/Store.tsx'
import useIsMounted from 'react-is-mounted-hook'

import {
  getAllSuggestions,
  getSuggestionsByDone,
  getSuggestionsByEmail,
  getSuggestionsByPending,
  getSuggestionsByWontdo,
  addSuggestionMessage,
  getSignatures,
  deleteSuggestion,
  editSuggestionMetadata,
  getSuggestionById,
  getURLInfoQuick,
  createSignature,
  editSignature,
  deleteSignatureApp,
  addSignatureApp,
  Suggestion,
  Signature,
  Appstore,
  SuggestionMessage,
  SignatureUrlInfo,
  addKeywordsBulk,
  addSignatureDependencyBulk,
  editRecatUrlsSuggestion,
  reclassifyBulkUrls,
  putAppListing,
} from '../util/Api.tsx'
import LazyImage from '../components/LazyImage.tsx'
import { getTimeSince } from '../util/Func.tsx'
import ConfirmDialog from '../components/ConfirmDialog.tsx'

import imrcvAudio from '../assets/imrcv.wav'
import imsendAudio from '../assets/imsend.wav'
import ringAudio from '../assets/ring.wav'
import handleError from '../util/Error.tsx'
import { SxProps } from '@mui/material'

import ScrollSuggestions from '../components/suggestions/ScrollSuggestions.tsx'

const audio = {
  imrcv: new Audio(imrcvAudio),
  imsend: new Audio(imsendAudio),
  ring: new Audio(ringAudio),
}

interface AppStores extends Appstore {
  appId?: string
}

interface AppstoresEditTableProps {
  appstores?: AppStores[]
  oldAppstores?: AppStores[]
  dark?: boolean
}

const AppstoresEditTable = ({ appstores = [], oldAppstores = [], dark = false }: AppstoresEditTableProps) => {
  const mappedAppstores = useMemo(() => {
    return [
      // Show the deleted ones
      ...oldAppstores
        .filter((a) => !appstores.some((b) => (b.appId || b.id) === (a.appId || a.id) && b.type === a.type))
        .map((a) => ({ ...a, backgroundColor: 'rgba(255, 36, 36, 0.2)', action: 'Delete' })),
      // Show the old ones that still exist
      ...oldAppstores
        .filter((a) => appstores.some((b) => (b.appId || b.id) === (a.appId || a.id) && b.type === a.type))
        .map((a) => ({ ...a, backgroundColor: 'rgba(0, 0, 0, 0.0)', action: 'Unchanged' })),
      // Show the new ones
      ...appstores
        .filter((a) => !oldAppstores.some((b) => (b.appId || b.id) === (a.appId || a.id) && b.type === a.type))
        .map((a) => ({ ...a, backgroundColor: 'rgba(102, 245, 66, 0.2)', action: 'Add' })),
    ]
  }, [appstores, oldAppstores])

  return (
    <MaterialTable
      dark={dark}
      layout={{
        headers: ['Application ID', 'Type', 'Action'],
        body: [
          ...mappedAppstores.map(({ id, appId, type, backgroundColor, action }, index) => [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center', backgroundColor }}>{appId ? appId : id}</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center', backgroundColor }}>{type}</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>{action}</Box>,
          ]),
        ],
      }}
    />
  )
}

interface KeywordAddTableProps {
  dark?: boolean
  keywordType?: string
  keywordEntry?: string[]
}

const KeywordAddTable = ({ dark = false, keywordType = '', keywordEntry = [] }: KeywordAddTableProps) => {
  return (
    <MaterialTable
      dark={dark}
      layout={{
        headers: ['Field', 'Keyword to be Added'],
        body: [
          ...keywordEntry.map((ent) => [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>{keywordType}</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>{ent}</Box>,
          ]),
        ],
      }}
    />
  )
}

interface SignatureEditTableProps {
  oldSignature: Signature
  newSignature: Signature
  signatures?: Signature[]
  dark?: boolean
}

const SignatureEditTable = ({ oldSignature, newSignature, signatures = [], dark = false }: SignatureEditTableProps) => {
  const getBackground = (field: keyof Signature) => {
    if (JSON.stringify(oldSignature?.[field]) !== JSON.stringify(newSignature?.[field])) {
      return 'rgba(252, 186, 3, 0.2)'
    }
    return 'rgba(0, 0, 0, 0.0)'
  }

  if (!oldSignature?.id) {
    return <></>
  }

  return (
    <MaterialTable
      dark={dark}
      layout={{
        headers: ['Field', 'Current Value', 'New Value'],
        body: [
          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>ID</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>{oldSignature.id}</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center', backgroundColor: getBackground('id') }}>
              {newSignature.id}
            </Box>,
          ],
          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Name</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>{oldSignature.name}</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center', backgroundColor: getBackground('name') }}>
              {newSignature.name}
            </Box>,
          ],
          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Category</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
              {signatures.find((s) => s.id === oldSignature.category)?.name}
            </Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center', backgroundColor: getBackground('category') }}>
              {newSignature.category}
            </Box>,
          ],
          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>URL</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>{oldSignature.url}</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center', backgroundColor: getBackground('url') }}>
              {newSignature.url}
            </Box>,
          ],
          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Favicon</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
              <LazyImage src={oldSignature?.faviconUrl || ''} />
            </Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center', backgroundColor: getBackground('faviconUrl') }}>
              <LazyImage src={newSignature?.faviconUrl || ''} />
            </Box>,
          ],
          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Enabled</Box>,
            <Box sx={{ p: 1, textAlign: 'start' }}>
              {oldSignature.enabled ? (
                <CheckIcon fontSize="small" color="success" />
              ) : (
                <CloseIcon fontSize="small" color="error" />
              )}{' '}
            </Box>,
            <Box sx={{ p: 1, textAlign: 'start', backgroundColor: getBackground('enabled') }}>
              {newSignature.enabled ? (
                <CheckIcon fontSize="small" color="success" />
              ) : (
                <CloseIcon fontSize="small" color="error" />
              )}{' '}
            </Box>,
          ],
          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Noise</Box>,
            <Box sx={{ p: 1, textAlign: 'start' }}>
              {oldSignature.noise ? (
                <CheckIcon fontSize="small" color="success" />
              ) : (
                <CloseIcon fontSize="small" color="error" />
              )}{' '}
            </Box>,
            <Box sx={{ p: 1, textAlign: 'start', backgroundColor: getBackground('noise') }}>
              {newSignature.noise ? (
                <CheckIcon fontSize="small" color="success" />
              ) : (
                <CloseIcon fontSize="small" color="error" />
              )}{' '}
            </Box>,
          ],
          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Classwize Focus</Box>,
            <Box sx={{ p: 1, textAlign: 'start' }}>
              {oldSignature.focus ? (
                <CheckIcon fontSize="small" color="success" />
              ) : (
                <CloseIcon fontSize="small" color="error" />
              )}{' '}
            </Box>,
            <Box sx={{ p: 1, textAlign: 'start', backgroundColor: getBackground('focus') }}>
              {newSignature.focus ? (
                <CheckIcon fontSize="small" color="success" />
              ) : (
                <CloseIcon fontSize="small" color="error" />
              )}{' '}
            </Box>,
          ],
          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Reporting</Box>,
            <Box sx={{ p: 1, textAlign: 'start' }}>
              {oldSignature.reporting ? (
                <CheckIcon fontSize="small" color="success" />
              ) : (
                <CloseIcon fontSize="small" color="error" />
              )}{' '}
            </Box>,
            <Box sx={{ p: 1, textAlign: 'start', backgroundColor: getBackground('reporting') }}>
              {newSignature.reporting ? (
                <CheckIcon fontSize="small" color="success" />
              ) : (
                <CloseIcon fontSize="small" color="error" />
              )}{' '}
            </Box>,
          ],
          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Category Type</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
              {oldSignature.isCategory ? 'Theme' : oldSignature.isSubCategory ? 'Category' : 'Signature'}
            </Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center', backgroundColor: getBackground('isCategory') }}>
              {newSignature.isCategory ? 'Theme' : newSignature.isSubCategory ? 'Category' : 'Signature'}
            </Box>,
          ],

          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Tags</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>{oldSignature.tags.join(', ')}</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center', backgroundColor: getBackground('tags') }}>
              {newSignature.tags?.join(', ')}
            </Box>,
          ],

          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Dependencies</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>{oldSignature.dependencies.join(', ')}</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center', backgroundColor: getBackground('dependencies') }}>
              {newSignature.dependencies?.join(', ')}
            </Box>,
          ],

          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Description</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>{oldSignature.description}</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center', backgroundColor: getBackground('description') }}>
              {newSignature.description}
            </Box>,
          ],
        ],
      }}
    />
  )
}

interface SignatureCreateTableProps {
  signature: Signature
  dark?: boolean
  signatures?: Signature[]
}

const SignatureCreateTable = ({ signature, dark = false, signatures = [] }: SignatureCreateTableProps) => {
  return (
    <MaterialTable
      dark={dark}
      layout={{
        headers: ['Field', 'Value'],
        body: [
          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>ID</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>{signature.id}</Box>,
          ],
          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Name</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>{signature.name}</Box>,
          ],
          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Category</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
              {signature.category && (
                <LazyImage src={signatures.find((s) => s.id === signature.category)?.faviconUrl || ''} sx={{ mr: 2 }} />
              )}
              {signatures.find((s) => s.id === signature.category)?.name || (
                <CloseIcon fontSize="small" color="error" />
              )}
            </Box>,
          ],
          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>URL</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
              <Link href={`https://${signature.url}`} target="_blank" rel="noopener noreferrer">
                {signature.url}
              </Link>
            </Box>,
          ],
          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Favicon</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
              <LazyImage src={signature.faviconUrl || ''} />
            </Box>,
          ],
          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Enabled</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
              {signature.enabled ? (
                <CheckIcon fontSize="small" color="success" />
              ) : (
                <CloseIcon fontSize="small" color="error" />
              )}{' '}
            </Box>,
          ],
          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Noise</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
              {signature.noise ? (
                <CheckIcon fontSize="small" color="success" />
              ) : (
                <CloseIcon fontSize="small" color="error" />
              )}{' '}
            </Box>,
          ],
          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Classwize Focus</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
              {signature.focus ? (
                <CheckIcon fontSize="small" color="success" />
              ) : (
                <CloseIcon fontSize="small" color="error" />
              )}{' '}
            </Box>,
          ],
          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Reporting</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
              {signature.reporting ? (
                <CheckIcon fontSize="small" color="success" />
              ) : (
                <CloseIcon fontSize="small" color="error" />
              )}{' '}
            </Box>,
          ],

          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Type</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
              {signature.isCategory ? 'Theme' : signature.isSubCategory ? 'Category' : 'Signature'}
            </Box>,
          ],
          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Tags</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>{signature.tags?.join(', ')}</Box>,
          ],
          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Dependencies</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>{signature.dependencies?.join(', ')}</Box>,
          ],
          [
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Description</Box>,
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>{signature.description}</Box>,
          ],
        ],
      }}
    />
  )
}

interface MessageProps {
  dark?: boolean
  message?: string
  email?: string
  subtitle?: string
  sx?: SxProps
}

const Message = ({ dark = false, message = '', email = '', subtitle = '', sx = {}, ...props }: MessageProps) => {
  return (
    <Paper
      sx={{ borderRadius: 8, backgroundColor: dark ? '#3b3b3b' : '#ffffff', p: 0.5, pl: 1, pr: 1, ...sx }}
      variant="outlined"
      {...props}
    >
      <Grid container padding={1} alignItems="center">
        <Grid item>
          <AvatarLetters email={email} />
        </Grid>
        <Grid item>
          <Typography color="InactiveCaptionText">{email}</Typography>
          <Typography variant="caption" color="text.disabled">
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
      <Grid container padding={1} spacing={1} alignItems="center">
        <Grid item xs={12} zeroMinWidth>
          <Typography sx={{ wordBreak: 'break-word' }}>{message}</Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

interface MessageListProps {
  messages: SuggestionMessage[]
  email: string
  dark?: boolean
  hidden?: boolean
  sx?: SxProps
}

const MessageList = ({
  messages = [],
  email = '',
  dark = false,
  hidden = false,
  sx = {},
  ...props
}: MessageListProps) => {
  const overflowBox = useRef<HTMLElement | null>(null)

  // Perform smooth scroll to bottom of the page when messages reload
  useEffect(() => {
    const interval = setInterval(() => {
      if (!overflowBox.current) return
      overflowBox.current.scrollTop = overflowBox?.current?.scrollHeight
    }, 10)
    const timeout = setTimeout(() => {
      clearInterval(interval)
    }, 400)
    return () => {
      clearTimeout(timeout)
      clearInterval(interval)
    }
  }, [messages, overflowBox])

  return (
    <Box
      sx={{
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        backgroundColor: dark ? '#242424' : '#f7f7f7',
        minHeight: 4,
        ...(sx || {}),
      }}
      {...props}
    >
      {messages[0] && (
        <Box sx={{ p: 1 }}>
          <Message
            dark={dark}
            message={messages[0].message}
            email={messages[0].email}
            subtitle={`Made the suggestion ${getTimeSince(messages[0].created)}`}
          />
        </Box>
      )}

      <Collapse in={!hidden}>
        <Box sx={{ minHeight: 240, maxHeight: 500, overflowY: 'scroll' }} ref={overflowBox}>
          <TransitionGroup>
            {messages.slice(1).map((message, index) => (
              <Collapse key={`message-${index}`}>
                <Grid container justifyContent={email === message.email ? 'right' : 'left'} padding={1}>
                  <Grid item xs={12} sm={8} lg={6}>
                    <Message dark={dark} message={message.message} email={message.email} />
                    <Typography noWrap sx={{ mt: 2, ml: 1 }} variant="caption" color="GrayText">
                      {new Date(message.created).toLocaleDateString('en-US')}{' '}
                      {new Date(message.created).toLocaleTimeString('en-US')}
                    </Typography>
                  </Grid>
                </Grid>
              </Collapse>
            ))}
          </TransitionGroup>
        </Box>
      </Collapse>
    </Box>
  )
}

interface MessageCreateProps {
  dark?: boolean
  loading?: boolean
  hidden?: boolean
  messageCount: number
  onSend?: (message: string) => void
  onClickHide?: () => void
  sx?: SxProps
}

const MessageCreate = ({
  dark = false,
  loading = false,
  hidden = false,
  messageCount = 0,
  onSend = () => {},
  onClickHide = () => {},
  sx = {},
  ...props
}: MessageCreateProps) => {
  const [message, setMessage] = useState('')

  const handleChangeMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value)
  }

  const handleSendMessage = () => {
    onSend(message)
    setMessage('')
  }

  const handleClickHide = () => {
    onClickHide()
  }

  return (
    <Box
      sx={{
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        backgroundColor: dark ? '#242424' : '#f7f7f7',
        ...(sx || {}),
      }}
      {...props}
    >
      <Box sx={{ p: 1 }}>
        <Collapse in={!hidden}>
          <TextField
            name="Message"
            multiline
            minRows={3}
            fullWidth
            value={message}
            disabled={loading}
            onChange={handleChangeMessage}
          />
        </Collapse>
        <Grid container justifyContent="space-between" sx={{ mt: 1 }}>
          <Grid item>
            <Button
              variant="contained"
              disabled={message.length === 0 || loading || hidden}
              onClick={handleSendMessage}
            >
              {hidden ? `${messageCount} ${messageCount === 1 ? 'Message' : 'Messages'}` : 'Send'}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={loading}
              onClick={handleClickHide}
              startIcon={hidden ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
            >
              {hidden ? 'Show chat' : 'Hide chat'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

interface SuggestionMessageDialogProps {
  suggestionId: string
  messages: SuggestionMessage[]
  email: string
  dark?: boolean
  loading?: boolean
  onSendMessage?: (message: string) => void
  sx?: SxProps
}

const SuggestionMessageDialog = ({
  suggestionId = '',
  messages = [],
  email = '',
  dark = false,
  loading = false,
  onSendMessage = () => {},
  sx = {},
  ...props
}: SuggestionMessageDialogProps) => {
  const [hidden, setHidden] = useState(true)

  const handleToggleHidden = () => {
    setHidden(!hidden)
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        boxShadow: '0 12px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
        width: '100%',
        ...(sx || {}),
      }}
      {...props}
    >
      <MessageList hidden={hidden} messages={messages} email={email} dark={dark} />
      <MessageCreate
        hidden={hidden}
        messageCount={messages.length}
        onClickHide={handleToggleHidden}
        onSend={onSendMessage}
        loading={loading}
        dark={dark}
      />
    </Box>
  )
}

interface SuggestionLayoutProps {
  email: string
  disableControls?: boolean
  signatures: Signature[]
  suggestion: Suggestion
  mappedUrls: SignatureUrlInfo[]
  dark?: boolean
  loading?: boolean
  admin?: boolean
  onSendMessage?: (message: string) => void
  onClickApprove?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onClickEdit?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onClickWontdo?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onClickReverseWontdo?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onClickDelete?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onClickGoToSignature?: (e: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>) => void
}

const SuggestionLayout = ({
  email = '',
  disableControls = true,
  signatures = [],
  suggestion,
  mappedUrls = [],
  dark = false,
  loading = false,
  admin = false,
  onSendMessage = () => {},
  onClickApprove = () => {},
  onClickEdit = () => {},
  onClickWontdo = () => {},
  onClickReverseWontdo = () => {},
  onClickDelete = () => {},
  onClickGoToSignature = () => {},
}: SuggestionLayoutProps) => {
  const [user] = useStore('user')

  const handleClickApprove = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (disableControls) return
    onClickApprove(e)
  }

  const handleClickEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (disableControls) return
    onClickEdit(e)
  }

  const handleClickWontdo = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (disableControls) return
    onClickWontdo(e)
  }

  const handleClickReverseWontdo = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (disableControls) return
    onClickReverseWontdo(e)
  }

  const handleClickDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (disableControls) return
    onClickDelete(e)
  }

  const handleNavSignature = (e: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>) => {
    onClickGoToSignature(e)
  }

  const getMappedUrl = useCallback(
    (url: string) => {
      return mappedUrls.find((u) => u.url === url)
    },
    [mappedUrls]
  )

  const handleNavSearch = (url: string) => {
    window.open(`/search/${url}`, '_blank')
  }

  const handleQueryProvidersOpen = (url?: string) => {
    if (url) {
      window.open(`/query-providers/${url}`, '_blank')
    }
  }

  interface similarUrlsProps {
    url: string
    similar: boolean
    similarUrl: string
  }

  const oldSignature = signatures.find((s) => s.id === suggestion.data.signature?.id)
  const [checkedWebsite, setCheckedWebsite] = useState(mappedUrls.map((map) => false))
  const [similarUrls, setSimilarUrls] = useState<similarUrlsProps[] | null>(null)

  const changeChecked = (index: number) => {
    setCheckedWebsite(checkedWebsite.map((checked, i) => (i === index ? true : checked)))
  }

  useEffect(() => {
    const populateSimilarUrls = async () => {
      const urlCheck = mappedUrls.map((mapped) =>
        mapped.url.includes('www.') ? mapped.url.substring(4) : 'www.' + mapped.url
      )

      const checked = await Promise.all(
        urlCheck.map(async (url) => {
          const { exact } = await getURLInfoQuick(url)
          const isSimilar = exact[0]
          const formattedUrl = url.includes('www.') ? url.substring(4) : 'www.' + url

          return {
            url: formattedUrl,
            similar: isSimilar ? true : false,
            similarUrl: url,
          }
        })
      )

      setSimilarUrls(checked)
    }

    setSimilarUrls(null)
    setCheckedWebsite(mappedUrls.map((map) => false))
    populateSimilarUrls()
  }, [mappedUrls])

  return (
    <Box sx={{ height: '100%', position: 'relative', overflowY: 'hidden' }}>
      {suggestion && (
        <Box sx={{ height: '100%', overflowY: 'hidden' }}>
          <Box sx={{ height: '100%', overflowY: 'auto' }}>
            <Grid container padding={1} spacing={1}>
              <Grid item flexGrow={1}>
                <Typography variant="h5">
                  {suggestion.type === 'create-signature' && `Create Signature ${suggestion.data.signature.name}`}
                  {suggestion.type === 'edit-signature' &&
                    `Modify Signature ${
                      signatures.find((s) => s.id === suggestion.data.signature.id)?.name ||
                      suggestion.data.signature.name
                    }`}
                  {suggestion.type === 'recat-urls' &&
                    `Add ${suggestion.data.urls.length} URL${suggestion.data.urls.length === 1 ? '' : 's'} into ${
                      suggestion.data.signature.name
                    }`}
                  {suggestion.type === 'edit-appstores' &&
                    `Edit app links for ${suggestion.data.signature?.name || suggestion.data.controlledApp?.id}`}
                  {suggestion.type === 'new-appstore' && `New Application Request for ${suggestion.data?.controlId}`}
                  {suggestion.type === 'add-keywords' && `Add keywords to ${suggestion.data.keywordId}`}
                  {suggestion.type === 'other-request' && `Custom Request`}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={1}>
                  {admin && (
                    <Grid item>
                      <Tooltip title="Approve change">
                        <Fab
                          size="small"
                          color="success"
                          onClick={handleClickApprove}
                          disabled={suggestion.wontdo || suggestion.done}
                        >
                          <CheckIcon />
                        </Fab>
                      </Tooltip>
                    </Grid>
                  )}
                  {(admin || user.email === suggestion.email) && (
                    <Grid item>
                      <Tooltip title="Edit">
                        <Fab size="small" color="primary" onClick={handleClickEdit} disabled={suggestion.done}>
                          <EditIcon />
                        </Fab>
                      </Tooltip>
                    </Grid>
                  )}
                  {admin && (
                    <Grid item>
                      <Tooltip title={suggestion.wontdo ? 'Reverse Wont-Do' : 'Wont-Do'}>
                        <Fab
                          size="small"
                          color="warning"
                          onClick={suggestion.wontdo ? handleClickReverseWontdo : handleClickWontdo}
                          disabled={suggestion.done}
                        >
                          {suggestion.wontdo ? <RefreshIcon /> : <CloseIcon />}
                        </Fab>
                      </Tooltip>
                    </Grid>
                  )}
                  {(admin || user.email === suggestion.email) && (
                    <Grid item>
                      <Tooltip title="Delete">
                        <Fab size="small" color="error" onClick={handleClickDelete}>
                          <DeleteIcon />
                        </Fab>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1} padding={1}>
              <Grid item xs={12}>
                {suggestion.type === 'edit-signature' && oldSignature && (
                  <SignatureEditTable
                    newSignature={suggestion.data.signature}
                    oldSignature={oldSignature}
                    dark={dark}
                    signatures={signatures}
                  />
                )}
                {suggestion.type === 'create-signature' && (
                  <SignatureCreateTable dark={dark} signature={suggestion.data.signature} signatures={signatures} />
                )}
                {(suggestion.type === 'recat-urls' || suggestion.type === 'create-signature') && mappedUrls && (
                  <Box sx={{ mt: 1 }}>
                    <LinearProgress sx={{ opacity: !mappedUrls || mappedUrls?.length === 0 ? 1 : 0 }} />
                    <MaterialTable
                      dark={dark}
                      layout={{
                        headers: ['URL', 'Action'],
                        body: suggestion.data.urls.map((url: string, index: number) => {
                          const mappedUrl = getMappedUrl(url)
                          if (mappedUrl?.error) {
                            return [
                              <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                  <Grid item>
                                    <Grid container spacing={1}>
                                      <Grid item>
                                        <Grid container alignItems="center" justifyContent="flex-start">
                                          <Grid item>
                                            <Link
                                              href={`https://www.google.com/search?q=site:${url}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              onClick={(e) => changeChecked(index)}
                                              onMouseDown={(e) => changeChecked(index)}
                                            >
                                              <LazyImage
                                                src={
                                                  signatures.find((sig) => sig.id === 'sphirewall.application.google')
                                                    ?.faviconUrl || ''
                                                }
                                              />
                                            </Link>
                                          </Grid>
                                          <Grid item pl={1}>
                                            <IconButton onClick={() => handleQueryProvidersOpen(url)}>
                                              <TravelExploreIcon fontSize="small" />
                                            </IconButton>
                                          </Grid>
                                          <Grid item>
                                            <IconButton onClick={() => handleNavSearch(url)} value={url}>
                                              <SearchIcon fontSize="small" />
                                            </IconButton>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item>
                                        <Typography variant="body1">|</Typography>
                                      </Grid>
                                      <Grid item>
                                        <Link
                                          href={`https://${url}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          onClick={(e) => changeChecked(index)}
                                          onMouseDown={(e) => changeChecked(index)}
                                        >
                                          {url}
                                        </Link>
                                        {similarUrls?.find((similar) => similar.url === url)?.similar && (
                                          <Grid item>
                                            Similar URL found: {''}
                                            <Link
                                              href={`/search/${
                                                similarUrls?.find((similar) => similar.url === url)?.similarUrl
                                              }`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {similarUrls?.find((similar) => similar.url === url)?.similarUrl.trim()}
                                            </Link>
                                          </Grid>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  {admin && (
                                    <Grid item>
                                      {checkedWebsite[index] === true ? (
                                        <CheckIcon sx={{ ml: 1 }} color="success" />
                                      ) : (
                                        <CloseIcon sx={{ ml: 1 }} color="warning" />
                                      )}
                                    </Grid>
                                  )}
                                </Grid>
                              </Box>,
                              <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                                <CloseIcon sx={{ mr: 1 }} color="warning" />
                                {mappedUrl?.error}
                              </Box>,
                            ]
                          }
                          if (mappedUrl?.signatureId) {
                            const mappedSignature = signatures.find((s) => s.id === mappedUrl.signatureId)
                            return [
                              <Box
                                sx={{
                                  p: 1,
                                  display: 'flex',
                                  alignItems: 'center',
                                  backgroundColor: mappedSignature?.tags.find((tag) => tag === 'Critical')
                                    ? '#ff00001a'
                                    : 'transparent',
                                }}
                              >
                                <Grid container justifyContent="space-between" alignItems="center">
                                  <Grid item>
                                    <Grid container spacing={1} alignItems="center" justifyContent="flex-start">
                                      <Grid item>
                                        <Grid container alignItems="center" justifyContent="flex-start">
                                          <Grid item>
                                            <Link
                                              href={`https://www.google.com/search?q=site:${url}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              onClick={(e) => changeChecked(index)}
                                              onMouseDown={(e) => changeChecked(index)}
                                            >
                                              <LazyImage
                                                src={
                                                  signatures.find((sig) => sig.id === 'sphirewall.application.google')
                                                    ?.faviconUrl || ''
                                                }
                                              />
                                            </Link>
                                          </Grid>
                                          <Grid item pl={1}>
                                            <IconButton onClick={() => handleQueryProvidersOpen(url)}>
                                              <TravelExploreIcon fontSize="small" />
                                            </IconButton>
                                          </Grid>
                                          <Grid item>
                                            <IconButton onClick={() => handleNavSearch(url)} value={url}>
                                              <SearchIcon fontSize="small" />
                                            </IconButton>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item>
                                        <Typography variant="body1">|</Typography>
                                      </Grid>
                                      <Grid item>
                                        <Link
                                          href={`https://${url}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          onClick={(e) => changeChecked(index)}
                                          onMouseDown={(e) => changeChecked(index)}
                                        >
                                          {url}
                                        </Link>
                                      </Grid>
                                      {similarUrls?.find((similar) => similar.url === url)?.similar && (
                                        <Grid item>
                                          Similar URL found: {''}
                                          <Link
                                            href={`/search/${
                                              similarUrls?.find((similar) => similar.url === url)?.similarUrl
                                            }`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {similarUrls?.find((similar) => similar.url === url)?.similarUrl.trim()}
                                          </Link>
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                  {admin && (
                                    <Grid item>
                                      {checkedWebsite[index] === true ? (
                                        <CheckIcon sx={{ ml: 1 }} color="success" />
                                      ) : (
                                        <CloseIcon sx={{ ml: 1 }} color="warning" />
                                      )}
                                    </Grid>
                                  )}
                                </Grid>
                              </Box>,
                              <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                                <RefreshIcon sx={{ mr: 1 }} color="primary" />
                                Reclassify from{' '}
                                {mappedSignature?.name ||
                                  mappedUrl?.signatureId?.replace?.('sphirewall.application.', '')?.toUpperCase?.()}
                                {!mappedUrl?.valid ? (
                                  <Box sx={{ pl: 1, display: 'flex', alignItems: 'center' }}>
                                    <CloseIcon sx={{ mr: 1 }} color="warning" />
                                    Invalid URL
                                  </Box>
                                ) : (
                                  ''
                                )}
                              </Box>,
                            ]
                          }
                          return [
                            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                              <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                  <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                      <Grid container alignItems="center" justifyContent="flex-start">
                                        <Grid item>
                                          <Link
                                            href={`https://www.google.com/search?q=site:${url}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={(e) => changeChecked(index)}
                                            onMouseDown={(e) => changeChecked(index)}
                                          >
                                            <LazyImage
                                              src={
                                                signatures.find((sig) => sig.id === 'sphirewall.application.google')
                                                  ?.faviconUrl || ''
                                              }
                                            />
                                          </Link>
                                        </Grid>
                                        <Grid item pl={1}>
                                          <IconButton onClick={() => handleQueryProvidersOpen(url)}>
                                            <TravelExploreIcon fontSize="small" />
                                          </IconButton>
                                        </Grid>
                                        <Grid item>
                                          <IconButton onClick={() => handleNavSearch(url)} value={url}>
                                            <SearchIcon fontSize="small" />
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item>
                                      <Typography variant="body1" fontWeight="bold">
                                        |
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Link
                                        href={`https://${url}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={(e) => changeChecked(index)}
                                        onMouseDown={(e) => changeChecked(index)}
                                      >
                                        {url}
                                      </Link>
                                    </Grid>
                                    {similarUrls?.find((similar) => similar.url === url)?.similar && (
                                      <Grid item>
                                        Similar URL found: {''}
                                        <Link
                                          href={`/search/${
                                            similarUrls?.find((similar) => similar.url === url)?.similarUrl
                                          }`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {similarUrls?.find((similar) => similar.url === url)?.similarUrl.trim()}
                                        </Link>
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                                {admin && (
                                  <Grid item>
                                    {checkedWebsite[index] === true ? (
                                      <CheckIcon sx={{ ml: 1 }} color="success" />
                                    ) : (
                                      <CloseIcon sx={{ ml: 1 }} color="warning" />
                                    )}
                                  </Grid>
                                )}
                              </Grid>
                            </Box>,
                            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }} width={'350px'}>
                              <AddIcon sx={{ mr: 1 }} color="success" />
                              Add URL
                              {!getMappedUrl(url)?.valid ? (
                                <Box sx={{ pl: 1, display: 'flex', alignItems: 'center' }}>
                                  <CloseIcon sx={{ mr: 1 }} color="warning" />
                                  Invalid URL
                                </Box>
                              ) : (
                                ''
                              )}
                            </Box>,
                          ]
                        }),
                      }}
                    />
                    <Box sx={{ p: 1 }}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <Typography display="inline" style={{ fontWeight: 'bold' }}>
                            {suggestion.data.urls.length} URLS
                          </Typography>
                        </Grid>
                        <Grid item>
                          <ArrowRightIcon fontSize="small" />
                        </Grid>
                        <Grid item>
                          <Typography display="inline" style={{ fontWeight: 'bold' }}>
                            {suggestion.data.signature.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                )}
                {suggestion.type === 'edit-appstores' && (
                  <Box sx={{ mt: 1 }}>
                    <AppstoresEditTable
                      appstores={suggestion.data.appstores}
                      oldAppstores={suggestion.data.signature?.appstores || suggestion.data.controlledApp?.apps}
                      dark={dark}
                    />
                  </Box>
                )}
                {suggestion.type === 'new-appstore' && (
                  <Box sx={{ mt: 1 }}>
                    <Box sx={{ mt: 1 }}>
                      <MaterialTable
                        dark={dark}
                        layout={{
                          headers: ['Field', 'New Value'],
                          body: [
                            [
                              <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>ID</Box>,
                              <Box
                                sx={{
                                  p: 1,
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {suggestion.data.controlId}
                              </Box>,
                            ],
                            [
                              <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Name</Box>,
                              <Box
                                sx={{
                                  p: 1,
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {suggestion.data.controlName}
                              </Box>,
                            ],
                            [
                              <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Favicon</Box>,
                              <Box
                                sx={{
                                  p: 1,
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <LazyImage src={suggestion.data.controlFavicon || ''} />
                              </Box>,
                            ],
                            [
                              <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Category</Box>,
                              <Box
                                sx={{
                                  p: 1,
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <LazyImage
                                  src={signatures.find((s) => s.id === suggestion.data?.category)?.faviconUrl || ''}
                                />

                                {suggestion.data?.category}
                              </Box>,
                            ],
                          ],
                        }}
                      />
                    </Box>
                    <Box sx={{ p: 1 }}>
                      <MaterialTable
                        dark={dark}
                        layout={{
                          headers: ['Application ID', 'Type', 'Action'],
                          body: [
                            ...suggestion.data.appstores.map((app: AppStores, index: number) => [
                              <Box
                                sx={{
                                  p: 1,
                                  display: 'flex',
                                  alignItems: 'center',
                                  backgroundColor: 'rgba(102, 245, 66, 0.2)',
                                }}
                              >
                                {app.appId}
                              </Box>,
                              <Box
                                sx={{
                                  p: 1,
                                  display: 'flex',
                                  alignItems: 'center',
                                  backgroundColor: 'rgba(102, 245, 66, 0.2)',
                                }}
                              >
                                {app.type}
                              </Box>,
                              <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>Add</Box>,
                            ]),
                          ],
                        }}
                      />
                    </Box>
                  </Box>
                )}
                {suggestion.type === 'add-keywords' && (
                  <Box sx={{ mt: 1 }}>
                    <KeywordAddTable
                      dark={dark}
                      keywordType={suggestion.data.keywordType}
                      keywordEntry={suggestion.data.keywordEntry}
                    />
                  </Box>
                )}
                {suggestion.type === 'other-request' && (
                  <Box>
                    <Box sx={{ mt: 3, padding: 3 }}>
                      <TextField value={suggestion.data.message} multiline fullWidth disabled minRows={15} />
                    </Box>
                  </Box>
                )}
                <Grid item xs={12}>
                  <Box>
                    {suggestion.wontdo && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <CloseIcon color="error" />
                        <Typography variant="body2" color="error" textAlign="center" sx={{ ml: 1 }}>
                          Marked as Wont-Do
                        </Typography>
                      </Box>
                    )}
                    {suggestion.done && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <CheckIcon color="success" />
                        <Typography variant="body2" color="success" textAlign="center" sx={{ ml: 1 }}>
                          Marked as Done
                        </Typography>
                        {suggestion.type !== 'add-keywords' &&
                          suggestion.type !== 'other-request' &&
                          suggestion.type !== 'edit-appstores' &&
                          suggestion.type !== 'new-appstore' && (
                            <Link
                              variant="body2"
                              sx={{ ml: 1 }}
                              href={`/signature/${suggestion?.data?.signature?.id}`}
                              onClick={handleNavSignature}
                            >
                              {' '}
                              Go to signature
                            </Link>
                          )}
                        {suggestion.type === 'add-keywords' && (
                          <Link variant="body2" sx={{ ml: 1 }} href={`/keywords`} onClick={handleNavSignature}>
                            {' '}
                            Go to keywords
                          </Link>
                        )}
                      </Box>
                    )}
                    {suggestion?.data?.ticket !== 0 && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2" textAlign="center" sx={{ ml: 1 }}>
                          Zendesk Ticket:
                          <Link
                            variant="body2"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ ml: 1 }}
                            href={`https://smoothwall.zendesk.com/agent/tickets/${suggestion?.data?.ticket}`}
                          >
                            {suggestion?.data?.ticket}
                          </Link>
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Box sx={{ height: 200 }} />
          </Box>
          <SuggestionMessageDialog
            suggestionId={suggestion.id}
            messages={suggestion.messages}
            email={email}
            dark={dark}
            loading={loading}
            onSendMessage={onSendMessage}
          />
        </Box>
      )}
    </Box>
  )
}

const PAGE_LIMIT = 15

const Suggestions = () => {
  const history = useHistory()
  const isMounted = useIsMounted()
  const [darkMode] = useStore('darkMode')
  const [user] = useStore('user')
  const [activeSearch, setActiveSearch] = useState('')
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [suggestionLoading, setSuggestionLoading] = useState(false)
  const [suggestions, setSuggestions] = useState<Suggestion[]>([])
  const [activeSuggestion, setActiveSuggestion] = useState<Suggestion | null>(null)
  const [activeSuggestionUrls, setActiveSuggestionUrls] = useState<SignatureUrlInfo[]>([])
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const [confirmWontdoOpen, setConfirmWontdoOpen] = useState(false)
  const [confirmApproveOpen, setConfirmApproveOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [reverseWontdoOpen, setReverseWontdoOpen] = useState(false)
  const [wontdoMessage, setWontdoMessage] = useState('')
  const [signatures, setSignatures] = useState<Signature[]>([])
  const [suggestionToEdit, setSuggestionToEdit] = useState<Suggestion | null>(null)
  const [suggestionsLoading, setSuggestionsLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [lastPage, setLastPage] = useState(false)
  const [stringCategory, setCategory] = useQueryParam('cat', StringParam)
  const category =
    stringCategory !== 'all' &&
    stringCategory !== 'wontdo' &&
    stringCategory !== 'done' &&
    stringCategory !== 'pending' &&
    stringCategory !== 'email'
      ? 'pending'
      : stringCategory
  const [activeSuggestionId, setActiveSuggestionId] = useQueryParam('id', StringParam)

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    if (!e.target.value) {
      setActiveSearch('')
    }
  }

  const handleSubmitSearch = async () => {
    if (!search) return
    setActiveSearch(search)
  }

  const handleChangeWontdoMessage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setWontdoMessage(e.target.value)
  }

  const handleClickEdit = () => {
    setEditOpen(true)
    setSuggestionToEdit(activeSuggestion)
  }

  const handleCloseEdit = () => {
    setEditOpen(false)
  }

  const handleChangeCategory = (e: SelectChangeEvent<'all' | 'wontdo' | 'done' | 'pending' | 'email'>) => {
    if (
      e.target.value !== 'all' &&
      e.target.value !== 'wontdo' &&
      e.target.value !== 'done' &&
      e.target.value !== 'pending' &&
      e.target.value !== 'email'
    )
      return
    setActiveSuggestionId(null)
    setActiveSuggestion(null)
    setPage(1)
    setCategory(e.target.value)
  }

  const getActiveSuggestionUrls = useCallback(async (suggestion: Suggestion) => {
    try {
      if (!suggestion?.data?.urls || suggestion.data.urls.length === 0) return
      if (suggestion?.data?.oldUrls && suggestion?.data?.oldUrls.length !== 0) {
        setActiveSuggestionUrls(suggestion.data.oldUrls?.map((o: SignatureUrlInfo) => ({ ...o, valid: true })))
        return
      }
      // Get the urls in the suggestion and map them to existing signatures if they exist in them
      const results: SignatureUrlInfo[] = await Promise.all(
        suggestion.data.urls.map(async (url: string) => {
          try {
            const { exact, valid } = await getURLInfoQuick(url)
            return {
              url,
              signatureId: exact?.[0]?.signatureId,
              valid,
              primarySig: true,
            }
          } catch (err) {
            const { msg } = handleError(err)
            return {
              url,
              signatureId: null,
              error: msg,
              primarySig: true,
            }
          }
        })
      )
      setActiveSuggestionUrls(results)
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    }
  }, [])

  const handleSetActiveSuggestion = useCallback(
    async (suggestion: Suggestion) => {
      try {
        if (signatures.length === 0) return
        if (activeSuggestion?.id === suggestion?.id) return

        setActiveSuggestionId(suggestion.id)
      } catch (err) {
        const { msg } = handleError(err)
        alert(msg)
      }
    },
    [signatures, activeSuggestion, setActiveSuggestionId]
  )

  const handleToggleApproveOpen = () => {
    setConfirmApproveOpen(!confirmApproveOpen)
  }

  const handleConfirmApprove = async () => {
    try {
      if (!activeSuggestion) return

      setLoading(true)
      await editSuggestionMetadata({
        id: activeSuggestion.id,
        done: true,
        wontdo: false,
        ticket: activeSuggestion.data?.ticket,
      })
      if (activeSuggestion.type === 'create-signature') {
        const signature = activeSuggestion.data.signature
        try {
          await createSignature(signature.id, {
            category: signature.category,
            name: signature.name,
            autoCategory: true,
          })
        } catch (err) {
          await editSuggestionMetadata({
            id: activeSuggestion.id,
            done: false,
            wontdo: false,
          })
          throw err
        }
      }
      if (activeSuggestion.type === 'create-signature' || activeSuggestion.type === 'edit-signature') {
        const signature = activeSuggestion.data.signature
        try {
          await editSignature(signature.id, {
            autoCategory: true,
            category: signature.category,
            name: signature.name,
            description: signature.description,
            enabled: signature.enabled,
            noise: signature.noise,
            url: signature.url,
            faviconDataUrl: signature.faviconUrl || null,
            consumer: signature.consumer,
            focus: signature.focus,
            tags: signature.tags,
            reporting: signature.reporting,
            hidden: signature.hidden,
          })
          await addSignatureDependencyBulk(signature.id, signature.dependencies)
        } catch (err) {
          await editSuggestionMetadata({
            id: activeSuggestion.id,
            done: false,
            wontdo: false,
            ticket: activeSuggestion.data?.ticket,
          })
          throw err
        }
      }
      if (
        (activeSuggestion.type === 'recat-urls' || activeSuggestion.type === 'create-signature') &&
        activeSuggestion.data.urls.length !== 0
      ) {
        const urls = activeSuggestion.data.urls.map((u: string) => {
          return activeSuggestionUrls.find((a) => a.url === u)
        })
        const data = await reclassifyBulkUrls(urls, activeSuggestion?.data?.signature?.id, activeSuggestion?.id)
        if (data.success === false) {
          await editSuggestionMetadata({
            id: activeSuggestion.id,
            done: false,
            wontdo: false,
            ticket: activeSuggestion.data?.ticket,
          })
          alert('The import could not be processed please review logs\n')
          return
        }

        await editRecatUrlsSuggestion({
          id: activeSuggestion.id,
          signatureId: activeSuggestion.data?.signature.id,
          urls: activeSuggestion.data?.urls,
          oldUrls: data.old_signatures,
          ticket: activeSuggestion.data?.ticket,
        })
        alert(
          'The following urls were successfully processed: \n' +
            data.old_signatures
              .map((u) => (u.signatureId ? `${u.url} => Recat from ${u.signatureId}` : `${u.url} => Added`))
              .reduce((a, b) => a + '\n' + b)
        )
      }
      if (activeSuggestion.type === 'edit-appstores') {
        if (!activeSuggestion.data.signature && !activeSuggestion.data.controlledApp) return
        // deletes any records that will no longer exist
        if (!activeSuggestion.data.signature) {
          // do the same as delete and adding of new appstores but for controlledapp
          activeSuggestion.data.controlledApp?.apps
            .filter(
              (a: AppStores) =>
                !activeSuggestion.data.appstores.some(
                  (b: AppStores) => b?.appId === (a?.appId ? a.appId : a.id) && b.type === a.type
                )
            )
            .map(async (a: AppStores) => {
              try {
                await deleteSignatureApp(activeSuggestion.data.controlledApp?.id, '', a?.appId ? a.appId : '', a.type)
                return
              } catch (err) {
                await editSuggestionMetadata({
                  id: activeSuggestion.id,
                  done: false,
                  wontdo: false,
                })
                throw err
              }
            })
          //wait 1 second
          await new Promise((res) => setTimeout(res, 3000))
          // now add the new ones
          activeSuggestion.data.appstores
            .filter(
              (a: AppStores) =>
                !activeSuggestion.data.controlledApp.apps.some(
                  (b: AppStores) => (b?.appId ? b.appId : b.id) === a?.appId && b.type === a.type
                )
            )
            .map(async (a: AppStores) => {
              try {
                await addSignatureApp(activeSuggestion.data.controlledApp?.id, '', a?.appId || '', a.type)
                return
              } catch (err) {
                await editSuggestionMetadata({
                  id: activeSuggestion.id,
                  done: false,
                  wontdo: false,
                })
                throw err
              }
            })
        } else {
          activeSuggestion.data.signature.appstores
            .filter(
              (a: AppStores) =>
                !activeSuggestion.data.appstores.some(
                  (b: AppStores) => b?.appId === (a?.appId ? a.appId : a.id) && b.type === a.type
                )
            )
            .map(async (a: AppStores) => {
              try {
                await deleteSignatureApp('', activeSuggestion.data.signature?.id, a?.appId || '', a.type)
                return
              } catch (err) {
                await editSuggestionMetadata({
                  id: activeSuggestion.id,
                  done: false,
                  wontdo: false,
                })
                throw err
              }
            })
          //wait 1 second
          await new Promise((res) => setTimeout(res, 3000))
          // adds the new ones
          activeSuggestion.data.appstores
            .filter(
              (a: AppStores) =>
                !activeSuggestion.data.signature?.appstores.some(
                  (b: AppStores) => (b?.appId ? b.appId : b.id) === a?.appId && b.type === a.type
                )
            )
            .map(async (a: AppStores) => {
              try {
                await addSignatureApp('', activeSuggestion.data.signature?.id, a?.appId || '', a.type)
              } catch (err) {
                await editSuggestionMetadata({
                  id: activeSuggestion.id,
                  done: false,
                  wontdo: false,
                })
                throw err
              }
            })
        }
      }
      if (activeSuggestion.type === 'new-appstore') {
        try {
          await putAppListing(
            activeSuggestion.data.controlId,
            activeSuggestion.data.controlName,
            activeSuggestion.data.controlFavicon,
            activeSuggestion.data.category
          )
          activeSuggestion.data.appstores.map(async (a: AppStores) => {
            try {
              await addSignatureApp(activeSuggestion.data.controlId, '', a?.appId || '', a.type)
              return
            } catch (err) {
              await editSuggestionMetadata({
                id: activeSuggestion.id,
                done: false,
                wontdo: false,
              })
              throw err
            }
          })
        } catch (err) {
          await editSuggestionMetadata({
            id: activeSuggestion.id,
            done: false,
            wontdo: false,
          })
          throw err
        }
      }
      if (activeSuggestion.type === 'add-keywords') {
        try {
          await addKeywordsBulk({
            id: activeSuggestion.data.keywordId,
            keywordEntry: activeSuggestion.data.keywordEntry,
            keywordType: activeSuggestion.data.keywordType,
          })
        } catch (err) {
          await editSuggestionMetadata({
            id: activeSuggestion.id,
            done: false,
            wontdo: false,
          })
          throw err
        }
      }
      if (activeSuggestion.type === 'other-request') {
        try {
          await editSuggestionMetadata({
            id: activeSuggestion.id,
            done: true,
            wontdo: false,
          })
        } catch (err) {
          await editSuggestionMetadata({
            id: activeSuggestion.id,
            done: false,
            wontdo: false,
          })
          throw err
        }
      }
      await refreshSuggestions()
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    } finally {
      setLoading(false)
      setConfirmApproveOpen(false)
    }
  }

  const handleToggleWontdoOpen = () => {
    setConfirmWontdoOpen(!confirmWontdoOpen)
  }

  const handleToggleReverseWontdoOpen = () => {
    setReverseWontdoOpen(!reverseWontdoOpen)
  }

  const handleToggleDeleteOpen = () => {
    setConfirmDeleteOpen(!confirmDeleteOpen)
  }

  const handleConfirmWontdo = async () => {
    try {
      if (!activeSuggestion) return

      setLoading(true)
      // Add the wont do message
      await addSuggestionMessage({
        id: activeSuggestion.id,
        message: wontdoMessage,
        ticket: 0,
      })
      // Edit the suggestion to set wont do to true
      await editSuggestionMetadata({
        id: activeSuggestion.id,
        done: false,
        wontdo: true,
        message: wontdoMessage,
        ticket: activeSuggestion.data?.ticket,
      })
      setConfirmWontdoOpen(false)
      setWontdoMessage('')
      setActiveSuggestionId(null)
      setActiveSuggestion(null)
      await refreshSuggestions()
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  const handleReverseWontdo = async () => {
    try {
      if (!activeSuggestion) return

      setLoading(true)
      await addSuggestionMessage({
        id: activeSuggestion.id,
        message: wontdoMessage,
        ticket: activeSuggestion?.data?.ticket,
      })
      await editSuggestionMetadata({
        id: activeSuggestion.id,
        done: false,
        wontdo: false,
        message: wontdoMessage,
      })
      setReverseWontdoOpen(false)
      setWontdoMessage('')
      setActiveSuggestionId(null)
      setActiveSuggestion(null)
      await refreshSuggestions()
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  const handleConfirmDelete = async () => {
    try {
      if (!activeSuggestion) return

      setLoading(true)
      await deleteSuggestion({ id: activeSuggestion.id })
      setConfirmDeleteOpen(false)
      setSuggestions(suggestions.filter((s) => s.id !== activeSuggestion.id))
      setActiveSuggestionId(null)
      setActiveSuggestion(null)
      await refreshSuggestions()
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  const handleSendMessage = async (message: string) => {
    try {
      if (!activeSuggestion) return

      setSuggestionLoading(true)
      const data = await addSuggestionMessage({
        id: activeSuggestion.id,
        message,
        ticket: activeSuggestion?.data?.ticket,
      })
      audio.imsend.play()
      setActiveSuggestion({ ...data.suggestion })
      setSuggestions(
        suggestions.map((suggestion) => {
          if (suggestion.id === activeSuggestion.id) {
            return data.suggestion
          }
          return suggestion
        })
      )
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    } finally {
      if (!isMounted) return
      setSuggestionLoading(false)
    }
  }

  const handleNavSignature = (e: React.MouseEvent<HTMLSpanElement | HTMLAnchorElement>) => {
    e.preventDefault()
    history.push(`/signature/${activeSuggestion?.data?.signature?.id}`)
  }

  const handleNavKeywords = (e: React.MouseEvent<HTMLSpanElement | HTMLAnchorElement>) => {
    e.preventDefault()
    history.push(`/keywords`)
  }

  const populateSuggestions = useCallback(
    async (limit: number, pageNumber: number) => {
      let data: {
        msg: string
        suggestions: Suggestion[]
        total: number
      }
      switch (category) {
        case 'pending':
          data = await getSuggestionsByPending(limit, pageNumber, activeSearch)
          break
        case 'email':
          data = await getSuggestionsByEmail(limit, pageNumber, activeSearch)
          break
        case 'done':
          data = await getSuggestionsByDone(limit, pageNumber, activeSearch)
          break
        case 'wontdo':
          data = await getSuggestionsByWontdo(limit, pageNumber, activeSearch)
          break
        default:
          data = await getAllSuggestions(limit, pageNumber, activeSearch)
          break
      }
      return data
    },
    [category, activeSearch]
  )

  const refreshInitialSuggestions = useCallback(async () => {
    try {
      setSuggestionsLoading(true)
      const data = await populateSuggestions(PAGE_LIMIT, 1)
      if (!isMounted()) return
      setSuggestions(data.suggestions)
      setLastPage(data.suggestions.length >= data.total)
      setPage(1)
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    } finally {
      if (!isMounted()) return
      setSuggestionsLoading(false)
    }
  }, [isMounted, populateSuggestions, setPage])

  useEffect(() => {
    refreshInitialSuggestions()
  }, [refreshInitialSuggestions])

  const refreshSuggestions = useCallback(async () => {
    try {
      setSuggestionsLoading(true)
      const data = await populateSuggestions(page * PAGE_LIMIT, 1)
      if (!isMounted()) return
      setSuggestions(data.suggestions)
      setLastPage(data.suggestions.length >= data.total)

      if (!activeSuggestion || !activeSuggestion.id) return

      const newActiveSuggestion = data.suggestions.find((s) => s.id === activeSuggestion.id)
      if (!newActiveSuggestion) return

      if (
        activeSuggestion.wontdo !== newActiveSuggestion.wontdo ||
        activeSuggestion.done !== newActiveSuggestion.done ||
        JSON.stringify(activeSuggestion.data) !== JSON.stringify(newActiveSuggestion.data)
      ) {
        // Suggestion has been modified
        setActiveSuggestion(newActiveSuggestion)
        audio.ring.play()
        return
      }
      if (JSON.stringify(activeSuggestion.messages) !== JSON.stringify(newActiveSuggestion.messages)) {
        audio.imrcv.play()
        setActiveSuggestion(newActiveSuggestion)
      }
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    } finally {
      if (!isMounted()) return
      setSuggestionsLoading(false)
    }
  }, [activeSuggestion, page, isMounted, populateSuggestions])

  const handlePageIncrement = useCallback(async () => {
    try {
      setSuggestionsLoading(true)
      const data = await populateSuggestions(PAGE_LIMIT, page + 1)
      setPage(page + 1)
      setSuggestions([...suggestions, ...data.suggestions])
      setLastPage(suggestions.length + data.suggestions.length >= data.total)
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    } finally {
      if (!isMounted()) return
      setSuggestionsLoading(false)
    }
  }, [suggestions, page, isMounted, populateSuggestions, setPage])

  useEffect(() => {
    const timeout = setTimeout(() => {
      refreshSuggestions()
    }, 10000)
    const interval = setInterval(() => {
      refreshSuggestions()
    }, 15000)
    return () => {
      clearInterval(interval)
      clearTimeout(timeout)
    }
  }, [refreshSuggestions])

  const populateSignatures = useCallback(async () => {
    try {
      const data = await getSignatures()
      if (!isMounted()) return
      setSignatures(data.signatures)
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    }
  }, [isMounted])

  useEffect(() => {
    populateSignatures()
    const interval = setInterval(() => {
      populateSignatures()
    }, 60000)
    return () => {
      clearInterval(interval)
    }
  }, [populateSignatures])

  // When the params id or suggestions is updated, set the active suggestion to the suggestion with that ID
  const populateIdChange = useCallback(async () => {
    try {
      if (!activeSuggestionId) return
      if (activeSuggestion && activeSuggestion.id === activeSuggestionId) return
      let existingSuggestion = suggestions.find((s) => s.id === activeSuggestionId)
      if (!existingSuggestion) {
        const data = await getSuggestionById(activeSuggestionId)
        if (!isMounted()) return
        existingSuggestion = data.suggestion
      }
      setActiveSuggestion(existingSuggestion)
      getActiveSuggestionUrls(existingSuggestion)
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    }
  }, [activeSuggestionId, suggestions, activeSuggestion, getActiveSuggestionUrls, isMounted])

  useEffect(() => {
    populateIdChange()
  }, [populateIdChange])

  return (
    <Box sx={{ height: 'calc(100vh - 140px)' }}>
      <Box>
        <Grid container padding={1} alignItems="center">
          <Grid item sx={{ mx: 2 }}>
            <SearchBar
              value={search}
              onChange={handleChangeSearch}
              dark={!darkMode}
              button
              disabled={loading}
              onClickButton={handleSubmitSearch}
              onSubmit={handleSubmitSearch}
            />
          </Grid>
          <Grid item sx={{ mx: 2 }}>
            <Select id="demo-simple-select" value={category} onChange={handleChangeCategory}>
              <MenuItem value={'pending'}>Open Tickets</MenuItem>
              <MenuItem value={'email'}>My Tickets</MenuItem>
              <MenuItem value={'all'}>All Tickets</MenuItem>
              <MenuItem value={'done'}>Done Tickets</MenuItem>
              <MenuItem value={'wontdo'}>Wont-DO Tickets</MenuItem>
            </Select>
          </Grid>
          <Grid item>
            <IconButton onClick={refreshSuggestions} disabled={suggestionsLoading}>
              <RefreshIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        direction="row"
        alignItems="stretch"
        justifyContent="stretch"
        alignContent="stretch"
        sx={{ height: '100%' }}
      >
        <Grid item xs={12} sm={4} md={4} lg={3} sx={{ height: '100%' }}>
          <ScrollSuggestions
            dark={darkMode}
            suggestions={suggestions}
            signatures={signatures}
            activeSuggestion={activeSuggestion}
            onChangeActive={handleSetActiveSuggestion}
            onIncrementPage={handlePageIncrement}
            loading={suggestionsLoading}
            disableScroll={lastPage}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={9} sx={{ height: '100%' }}>
          <Box sx={{ overflowY: 'auto', height: '100%' }}>
            {activeSuggestion && activeSuggestion.type !== 'add-keywords' && (
              <SuggestionLayout
                email={user?.email}
                disableControls={loading}
                signatures={signatures}
                suggestion={activeSuggestion}
                mappedUrls={activeSuggestionUrls}
                dark={darkMode}
                admin={!!user?.appindexAdmin}
                onSendMessage={handleSendMessage}
                loading={suggestionLoading}
                onClickApprove={handleToggleApproveOpen}
                onClickEdit={handleClickEdit}
                onClickWontdo={handleToggleWontdoOpen}
                onClickReverseWontdo={handleToggleReverseWontdoOpen}
                onClickDelete={handleToggleDeleteOpen}
                onClickGoToSignature={handleNavSignature}
              />
            )}
            {activeSuggestion && activeSuggestion.type === 'add-keywords' && (
              <SuggestionLayout
                email={user?.email}
                disableControls={loading}
                signatures={signatures}
                suggestion={activeSuggestion}
                mappedUrls={activeSuggestionUrls}
                dark={darkMode}
                admin={!!user?.appindexAdmin}
                onSendMessage={handleSendMessage}
                loading={suggestionLoading}
                onClickApprove={handleToggleApproveOpen}
                onClickEdit={handleClickEdit}
                onClickWontdo={handleToggleWontdoOpen}
                onClickReverseWontdo={handleToggleReverseWontdoOpen}
                onClickDelete={handleToggleDeleteOpen}
                onClickGoToSignature={handleNavKeywords}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <EditSuggestionDialog
        open={editOpen}
        suggestion={suggestionToEdit}
        oldSuggestion={suggestionToEdit}
        mappedUrls={activeSuggestionUrls}
        onClose={handleCloseEdit}
        afterSubmit={refreshSuggestions}
        message={handleSendMessage}
      />
      <ConfirmDialog
        loading={loading}
        open={confirmApproveOpen}
        title="Confirm Approve"
        text="Are you sure you want to approve this suggestion? Changes are made immediately. This cannot be undone."
        buttonText="Make changes"
        color="success"
        onConfirm={handleConfirmApprove}
        onClose={handleToggleApproveOpen}
      />
      <ConfirmDialog
        loading={loading}
        open={confirmWontdoOpen}
        title="Mark as 'Wont Do'"
        text="Type your wont-do message above"
        buttonText="Mark as wont do"
        color="secondary"
        onConfirm={handleConfirmWontdo}
        onClose={handleToggleWontdoOpen}
        disabled={!wontdoMessage}
      >
        <Box sx={{ pl: 2, pr: 2 }}>
          <TextField fullWidth multiline minRows={2} value={wontdoMessage} onChange={handleChangeWontdoMessage} />
        </Box>
      </ConfirmDialog>
      <ConfirmDialog
        loading={loading}
        open={reverseWontdoOpen}
        title="Reverse 'Wont Do'"
        text="Type the reason why this suggestion is do-able now"
        buttonText="Reverse wont-do"
        color="secondary"
        onConfirm={handleReverseWontdo}
        onClose={handleToggleReverseWontdoOpen}
        disabled={!wontdoMessage}
      >
        <Box sx={{ pl: 2, pr: 2 }}>
          <TextField fullWidth multiline minRows={2} value={wontdoMessage} onChange={handleChangeWontdoMessage} />
        </Box>
      </ConfirmDialog>
      <ConfirmDialog
        loading={loading}
        open={confirmDeleteOpen}
        title="Confirm Delete"
        text="Are you sure you want to delete this suggestion? This cannot be undone."
        buttonText="Delete Suggestion"
        onConfirm={handleConfirmDelete}
        onClose={handleToggleDeleteOpen}
      />
    </Box>
  )
}

export default Suggestions
