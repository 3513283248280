import { ImageClassifierCategory, Signature } from '../util/Api.tsx'
import { Box, Button, Dialog, Grid, IconButton, Input, Slider, TextField, Typography, useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Alert from '@mui/material/Alert'
import { SyntheticEvent, useEffect, useState } from 'react'
import SignatureSelect from './SignatureSelect.tsx'

interface AddClassiderCategoryProps {
  open: boolean
  editing: boolean
  error: string
  loading: boolean
  classifierCategory: ImageClassifierCategory | null
  signatures: Signature[]
  onAdd: (label: string, signature_id: string | undefined, threshold: number) => void
  onEdit: (
    classifierCategory: ImageClassifierCategory,
    label: string,
    signature_id: string | undefined,
    threshold: number
  ) => void
  onClose: () => void
}

const AddClassifierCategory = ({
  open = false,
  editing,
  classifierCategory,
  signatures,
  loading,
  error,
  onAdd = () => {},
  onEdit = () => {},
  onClose,
}: AddClassiderCategoryProps) => {
  const theme = useTheme()
  const [signature, setSignature] = useState<Signature | undefined>()
  const [label, setLabel] = useState<string | null>(null)
  const [threshold, setThrestold] = useState<number>(0)

  const handleChangeLabel = (e: any) => {
    setLabel(e?.target.value || null)
  }

  const handleChangeSignature = (e: SyntheticEvent<Element, Event>, returnSignature: Signature | null) => {
    if (returnSignature === null) {
      setSignature(undefined)
      return
    }
    setSignature(returnSignature)
  }

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setThrestold(newValue as number)
  }

  const handleChangeThreshold = (event: React.ChangeEvent<HTMLInputElement>) => {
    setThrestold(event.target.value === '' ? 0 : Number(event.target.value))
  }

  const handleBlur = () => {
    if (threshold < 0) {
      setThrestold(0)
    } else if (threshold > 100) {
      setThrestold(100)
    }
  }

  const handleClose = () => {
    onClose()
  }

  const handleClickAdd = () => {
    if (!label) return
    if (editing === true) {
      if (!classifierCategory) return
      onEdit(classifierCategory, label, signature?.id, threshold)
    } else {
      onAdd(label, signature?.id, threshold)
    }
  }

  useEffect(() => {
    setLabel(classifierCategory?.label || null)
    setSignature(
      classifierCategory?.signature_id
        ? signatures.find((sig) => sig.id === classifierCategory.signature_id)
        : undefined
    )
    setThrestold(classifierCategory?.threshold || 0)
  }, [classifierCategory, signatures])

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                {editing === true ? 'Edit' : 'Add'} Category
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          {error && (
            <Grid container justifyContent="center" sx={{ mb: 3 }}>
              <Grid item>
                <Alert severity="error">{error}</Alert>
              </Grid>
            </Grid>
          )}
          <Grid container direction="column" alignItems="stretch" justifyContent="center" padding={2} spacing={3}>
            <Grid item>
              <Typography variant="overline" noWrap gutterBottom>
                Category:
              </Typography>
              <TextField id="term" variant="outlined" fullWidth onChange={handleChangeLabel} value={label || ''} />
            </Grid>
            <Grid item>
              <Typography variant="body1" noWrap gutterBottom>
                A mapping will automatically be created based on the signature selected.
              </Typography>

              <SignatureSelect
                signatures={signatures}
                id={signature?.id}
                label="Associated Signature"
                onChange={handleChangeSignature}
                defaultSignature={signature}
              />
            </Grid>
            <Grid item>
              <Box sx={{ width: 500, pl: 12 }}>
                <Typography gutterBottom>Threshold</Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <Slider
                      value={typeof threshold === 'number' ? threshold : 0}
                      onChange={handleSliderChange}
                      aria-labelledby="input-slider"
                    />
                  </Grid>
                  <Grid item>
                    <Input
                      value={threshold}
                      size="small"
                      onChange={handleChangeThreshold}
                      onBlur={handleBlur}
                      inputProps={{
                        step: 5,
                        min: 0,
                        max: 100,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                fullWidth
                disabled={(label ? label.length < 3 : true) || loading}
                onClick={handleClickAdd}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}

export default AddClassifierCategory
