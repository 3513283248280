import { Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import QueryProvidersSearch from '../components/QueryProviderSearch.tsx'

const QueryProviders = () => {
  const params = useParams<{ url?: string }>()
  const [search, setSearch] = useState('')

  const handleChangeWebsite = (e: string) => {
    setSearch(e)
  }

  useEffect(() => {
    if (!params?.url) return
    setSearch(params.url)
  }, [params])

  return (
    <Grid container justifyContent="center" padding={1} width="100%">
      <Grid container padding={2} justifyContent="space-between" alignItems="center" width="50%">
        <QueryProvidersSearch website={search} changeWebsite={handleChangeWebsite} />
      </Grid>
    </Grid>
  )
}

export default QueryProviders
