import React from 'react'

import { useTheme } from '@mui/material/styles'

import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import LoadingButton from '@mui/lab/LoadingButton'

interface DeleteConfirmDialogProps {
  name?: string
  open: boolean
  error?: string
  loading?: boolean
  onDelete: () => void
  onClose: () => void
}

const DeleteConfirmDialog = ({
  name = '',
  open = false,
  error = '',
  loading = false,
  onDelete = () => {},
  onClose = () => {},
}: DeleteConfirmDialogProps) => {
  const theme = useTheme()

  const handleClose = () => {
    if (loading) return
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                Confirm Delete
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Collapse in={Boolean(error)}>
            <Box>
              <Grid container padding={2} justifyContent="center">
                <Grid item xs={12}>
                  <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
                    {error || ' '}
                  </Alert>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Grid container padding={2} spacing={2} justifyContent="space-between">
            <Grid item>
              <Typography>Are you sure you want to permanently delete {name}? This cannot be undone.</Typography>
            </Grid>
            <Grid item>
              <LoadingButton color="error" variant="contained" loading={loading} onClick={onDelete}>
                Delete
              </LoadingButton>
            </Grid>
            <Grid item>
              <LoadingButton variant="contained" loading={loading} onClick={onClose}>
                Cancel
              </LoadingButton>
            </Grid>
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}

export default DeleteConfirmDialog
