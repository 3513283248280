import { Alert, Fab, Grid, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import useIsMounted from 'react-is-mounted-hook'
import { getDirectives, Directive, getDetailedSignatures, Signature } from '../util/Api.tsx'
import handleError from '../util/Error.tsx'
import useStore from '../util/Store.tsx'
import AddIcon from '@mui/icons-material/Add'
import SearchBar from '../components/SearchBar.tsx'
import DirectivesTable from '../components/DirectivesTable.tsx'
import DirectiveDetails from '../components/DirectivesDetails.tsx'
import AddDirectiveDialog from '../components/AddDirectiveDialog.tsx'

const Directives = () => {
  const isMounted = useIsMounted()
  const [darkMode] = useStore('darkMode')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [user] = useStore('user')
  const [search, setSearch] = useState('')
  const [directives, setDirectives] = useState<Directive[]>([])
  const [selectedDirective, setSelectedDirective] = useState<Directive | null>(null)
  const [shownDirectives, setShownDirectives] = useState<Directive[]>([])
  const [addOpen, setAddOpen] = useState(false)
  const [signatures, setSignatures] = useState<Signature[]>([])
  const [deleteEnabled, setDeleteEnabled] = useState(false)

  const populateDirectives = useCallback(async () => {
    try {
      setError('')
      setLoading(true)
      const data = await getDirectives()
      const sig = await getDetailedSignatures()

      if (!isMounted()) return
      setSignatures(sig.signatures)
      setDirectives(data.directives)
      if (search) {
        setShownDirectives(
          data.directives.filter((directive) => directive.id.includes(search) || directive.name.includes(search))
        )
        return
      }
      setShownDirectives(data.directives)
    } catch (err) {
      const { msg } = handleError(err)
      if (!isMounted()) return
      setError(msg)
    } finally {
      setLoading(false)
    }
  }, [isMounted, search])

  const handleAddClose = () => {
    setAddOpen(false)
  }

  const handleAddOpen = () => {
    setAddOpen(true)
  }

  const handleEditDirectives = (selected: Directive) => {
    setSelectedDirective({ ...selected })
  }

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    if (e.target.value === '') {
      setShownDirectives(directives)
      return
    }
    setShownDirectives(
      directives.filter((directive) => directive.id.includes(search) || directive.name.includes(e.target.value))
    )
  }
  const handleOnDelete = async (dir: Directive[]) => {
    setSelectedDirective(null)
    setDirectives(dir)
    setError('')
    if (search) {
      setShownDirectives(dir.filter((directive) => directive.id.includes(search) || directive.name.includes(search)))
      return
    }
    setShownDirectives(dir)
  }

  const handleReload = async (dir: Directive[], id: string) => {
    setDirectives(dir)
    setError('')
    if (search) {
      setShownDirectives(dir.filter((directive) => directive.id.includes(search) || directive.name.includes(search)))
      setSelectedDirective(dir.find((directive) => directive.id === id) ?? null)
      if (selectedDirective?.enabled === Boolean(true)) {
        setDeleteEnabled(true)
      } else {
        setDeleteEnabled(false)
      }
      return
    }
    setShownDirectives(dir)
    setSelectedDirective(dir.find((directive) => directive.id === id) ?? null)
    if (selectedDirective?.enabled === Boolean(true)) {
      setDeleteEnabled(true)
    } else {
      setDeleteEnabled(false)
    }
  }

  const handleClickDirective = async (dir: Directive) => {
    if (directives.length === 0) return
    if (dir.enabled === Boolean(true)) {
      setDeleteEnabled(true)
    } else {
      setDeleteEnabled(false)
    }
    setSelectedDirective(dir)
  }

  useEffect(() => {
    populateDirectives()
  }, [populateDirectives])

  return (
    <div>
      <Grid container justifyContent="center" sx={{ mt: 3 }} padding={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={10}>
          <Grid container justifyContent="center" sx={{ mt: 3 }}>
            <Grid item>
              <Typography variant="h3">Content Modifications</Typography>
            </Grid>
          </Grid>
          {error && (
            <Grid container justifyContent="center" sx={{ mt: 3 }}>
              <Grid item>
                <Alert severity="error">{error}</Alert>
              </Grid>
            </Grid>
          )}
          <Grid container justifyContent="center" alignItems="center" padding={1} spacing={2} sx={{ mt: 3, mb: 3 }}>
            <Grid item>
              <Typography>
                Content Modifications are specific settings that other products can use and update without hardcoding
                values in their code
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="start" spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Grid container spacing={1} alignItems="center" sx={{ mb: 2 }}>
                <Grid item>
                  {user?.appindexAdmin && (
                    <Fab sx={{ mx: 2 }} color="primary" size="small" disabled={loading} onClick={handleAddOpen}>
                      <AddIcon />
                    </Fab>
                  )}
                </Grid>
                <Grid item>
                  <SearchBar value={search} dark={!darkMode} button onChange={handleChangeSearch} />
                </Grid>
              </Grid>

              <DirectivesTable
                directives={shownDirectives}
                selected={selectedDirective?.id}
                onClick={handleClickDirective}
                dark={darkMode}
                loading={loading}
              />
            </Grid>

            {selectedDirective !== null && (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mb: 2, mt: 7 }}>
                <DirectiveDetails
                  admin={user?.appindexAdmin}
                  selected={selectedDirective}
                  loading={loading}
                  dark={darkMode}
                  onDelete={handleOnDelete}
                  signatures={signatures}
                  onReload={handleReload}
                  onChange={handleEditDirectives}
                  deleteEnabled={deleteEnabled}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <AddDirectiveDialog open={addOpen} onClose={handleAddClose} dark={user?.appindexAdmin} onAdd={handleReload} />
    </div>
  )
}

export default Directives
