import { Autocomplete } from '@mui/lab'
import { Chip, TextField } from '@mui/material'
import { useMemo } from 'react'
import { Signature } from '../util/Api.tsx'

interface TagsEditProps {
  selectedTags: string[]
  signatures: Signature[]
  onChange?: (newValue: string[]) => void
}

const TagsEdit = ({ selectedTags = [], onChange = () => {}, signatures }: TagsEditProps) => {
  const signatureTags = useMemo(() => {
    const tags: string[] = []
    signatures.forEach((signature) => {
      signature?.tags?.forEach((tag) => {
        if (tags.includes(tag)) return
        tags.push(tag)
      })
    })
    return tags
  }, [signatures])

  const handleChangeSelected = (e: React.SyntheticEvent, newValue: string[]) => {
    onChange(newValue)
  }

  return (
    <Autocomplete
      multiple
      options={signatureTags}
      freeSolo
      value={selectedTags}
      onChange={handleChangeSelected}
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: string, index: number) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => <TextField {...params} label="Press enter to create new Tag" />}
    />
  )
}

export default TagsEdit
