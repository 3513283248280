import React, { useRef, useCallback } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Signature, Suggestion } from '../../util/Api.tsx'
import { LoadingButton } from '@mui/lab'
import SuggestionCard from './SuggestionCard.tsx'

const NoSuggestionContent = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', p: 2, width: '100%' }}>
      <Typography color="text.disabled">
        Looks like there's nothing to see here!
        <br /> Try another ticket category or search
      </Typography>
    </Box>
  )
}

interface ScrollSuggestionLoaderProps {
  loading?: boolean
  onClick?: () => void
  disableScroll?: boolean
}

const ScrollSuggestionLoader = ({ loading, onClick = () => {}, disableScroll }: ScrollSuggestionLoaderProps) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', p: 2, width: '100%' }}>
      {!disableScroll ? (
        <LoadingButton onClick={onClick} loading={loading}>
          Load More
        </LoadingButton>
      ) : (
        <></>
      )}
    </Box>
  )
}

interface ScrollSuggestionsProps {
  dark: boolean
  suggestions: Suggestion[]
  signatures: Signature[]
  activeSuggestion: Suggestion | null
  loading: boolean
  disableScroll: boolean
  onChangeActive: (suggestion: Suggestion) => void
  onIncrementPage: () => void
}

const ScrollSuggestions = ({
  dark,
  suggestions,
  signatures,
  activeSuggestion,
  loading,
  disableScroll,
  onChangeActive,
  onIncrementPage,
}: ScrollSuggestionsProps) => {
  const containerEl = useRef<HTMLDivElement>(null)

  const handleScroll = useCallback(() => {
    if (!containerEl.current) return
    if (loading || disableScroll) return
    const { scrollTop, scrollHeight, clientHeight } = containerEl.current
    if (scrollTop + clientHeight + 100 > scrollHeight) {
      onIncrementPage()
    }
  }, [loading, disableScroll, onIncrementPage])

  return (
    <Box
      ref={containerEl}
      onScroll={handleScroll}
      sx={{ height: '100%', backgroundColor: dark ? '#242424' : '#f7f7f7', overflowY: 'scroll' }}
    >
      <Grid container padding={1} spacing={1}>
        {suggestions.map((suggestion) => (
          <Grid item key={`suggestion-${suggestion.id}`} xs={12}>
            <SuggestionCard
              suggestion={suggestion}
              signatures={signatures}
              selected={suggestion.id === activeSuggestion?.id}
              onClick={() => onChangeActive(suggestion)}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <ScrollSuggestionLoader loading={loading} onClick={handleScroll} disableScroll={disableScroll} />
        </Grid>
        {suggestions.length === 0 && !loading ? (
          <Grid item xs={12}>
            <NoSuggestionContent />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Box>
  )
}

export default ScrollSuggestions
