import React, { useState, useEffect, useCallback, useRef } from 'react'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import handleError from '../util/Error.tsx'
import LazyImage from './LazyImage.tsx'
import {
  addCondition,
  JSONCondition,
  deleteConditionId,
  deleteDirectives,
  Directive,
  editDirective,
  JSONAction,
  Signature,
  deleteDirectiveAction,
  addDirectiveAction,
} from '../util/Api.tsx'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Button,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  Link,
  TextField,
} from '@mui/material'
import locale from 'react-json-editor-ajrm/locale/en'
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteConfirmDialog from './DeleteConfirmDialog.tsx'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useIsMounted from 'react-is-mounted-hook'
import CopyIcon from '@mui/icons-material/ContentCopy'
import Switch from '@mui/material/Switch'
import { Box } from '@mui/system'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { getBase64 } from '../util/Func.tsx'
import SignatureSelect from './SignatureSelect.tsx'
import DirectiveConditionsTable from './DirectiveConditionsTable.tsx'
import JSONInput from 'react-json-editor-ajrm'

const conditionTypeValues: string[] = ['Domain', 'Signature']
const link: string =
  'https://familyzone.atlassian.net/wiki/spaces/CAT/pages/2694437503197/Content+Modification+Directives+Specs'

interface DirectivesTableProps {
  admin?: boolean
  selected: Directive
  loading?: boolean
  dark?: boolean
  onDelete?: (dir: Directive[]) => void
  onReload?: (dir: Directive[], id: string) => void
  onChange?: (dir: Directive) => void
  signatures: Signature[]
  deleteEnabled?: boolean
}

const DirectiveDetails = ({
  admin,
  loading,
  selected,
  dark = false,
  onDelete = () => {},
  onReload = () => {},
  onChange = () => {},
  signatures = [],
  deleteEnabled,
}: DirectivesTableProps) => {
  const isMounted = useIsMounted()
  const [deleteDirectiveOpen, setDeleteDirectiveOpen] = useState(false)
  const [deleteAction, setDeleteAction] = useState<JSONAction | null>(null)
  const [deleteCondition, setDeleteCondition] = useState<JSONCondition | null>(null)
  const [directiveError, setDirectiveError] = useState('')
  const [conditionType, setConditionType] = useState<string | null>('Domain')
  const [directiveLoading, setDirectiveLoading] = useState(false)
  const [hovering, setHovering] = useState(0)
  const [domain, setDomain] = useState('')

  const [action, setAction] = useState('')
  const [actionTarget, setActionTarget] = useState('')
  const [actionType, setActionType] = useState('')
  const [actionValue, setActionValue] = useState('')

  const [faviconDataUrl, setfaviconDataUrl] = useState<null | string>(null)
  const fileUpload = useRef<HTMLInputElement | null>(null)
  const [selectedSignature, setSelectedSignature] = useState<Signature | null>(null)

  const [conditionsOpen, setConditionsOpen] = useState(false)
  const [actionsOpen, setActionsOpen] = useState(false)
  const [conditionsError, setConditionsError] = useState('')
  const [actionsError, setActionsError] = useState('')

  const handleDeleteDirectiveOpen = () => {
    setDeleteDirectiveOpen(true)
  }

  const handleCloseDeleteDirective = () => {
    setDeleteDirectiveOpen(false)
  }

  const handleOpenConditionDelete = (condition: JSONCondition) => {
    setDeleteCondition(condition)
  }

  const handleCloseDeleteCondition = () => {
    setDeleteCondition(null)
  }

  const handleOpenActionsDelete = (action: JSONAction) => {
    setDeleteAction(action)
  }

  const handleCloseDeleteAction = () => {
    setDeleteAction(null)
  }

  const handleConditionAdd = useCallback(async () => {
    try {
      if (!selected) return
      setDirectiveLoading(true)
      setConditionsError('')
      if (!conditionType) return
      if (conditionType === 'Domain') {
        if (!domain) return
        const data = await addCondition(conditionType, domain, selected.id)
        onReload(data.directives, selected.id)
      }
      if (conditionType === 'Signature') {
        if (!selectedSignature) return
        const data = await addCondition(conditionType, selectedSignature.id, selected.id)
        onReload(data.directives, selected.id)
      }
    } catch (err) {
      const { msg } = handleError(err)
      setConditionsError(msg)
    } finally {
      setDirectiveLoading(false)
    }
  }, [conditionType, domain, onReload, selected, selectedSignature])

  const handleActionsAdd = useCallback(async () => {
    try {
      if (!selected) return
      setActionsError('')
      setDirectiveLoading(true)
      const data = await addDirectiveAction(action, actionTarget, actionType, actionValue, selected.id)
      onReload(data.directives, selected.id)
    } catch (err) {
      const { msg } = handleError(err)
      setActionsError(msg)
    } finally {
      setDirectiveLoading(false)
    }
  }, [action, actionTarget, actionType, actionValue, onReload, selected])

  const handleDeleteCondition = useCallback(async () => {
    try {
      if (!selected) return

      if (!deleteCondition) return
      setDirectiveLoading(true)
      setConditionsError('')
      const data = await deleteConditionId(deleteCondition.id, selected.id)
      onReload(data.directives, selected.id)
      setDeleteCondition(null)
    } catch (err) {
      const { msg } = handleError(err)
      setConditionsError(msg)
    } finally {
      setDirectiveLoading(false)
    }
  }, [selected, deleteCondition, onReload])

  const handleDeleteAction = useCallback(async () => {
    try {
      if (!selected) return

      if (!deleteAction) return
      setDirectiveLoading(true)
      setActionsError('')
      const data = await deleteDirectiveAction(deleteAction.id, selected.id)
      onReload(data.directives, selected.id)
      setDeleteAction(null)
    } catch (err) {
      const { msg } = handleError(err)
      setActionsError(msg)
    } finally {
      setDirectiveLoading(false)
    }
  }, [selected, deleteAction, onReload])

  const handleClearFileUpload = useCallback(() => {
    if (!fileUpload.current) return
    if (!selected) return
    setfaviconDataUrl(null)
    onChange({ ...selected, favicon_url: selected?.favicon_url || null })
    fileUpload.current.value = ''
  }, [onChange, selected])

  const handleChangeUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!selected) return
      if (!e?.target?.files) return

      setDirectiveLoading(true)
      setDirectiveError('')
      if (e.target.files.length === 0) {
        return setfaviconDataUrl(null)
      }
      const dataUrl = await getBase64(e.target.files[0])
      onChange({ ...selected, favicon_url: dataUrl })
      return setfaviconDataUrl(dataUrl)
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    } finally {
      if (!isMounted()) return
      setDirectiveLoading(false)
    }
  }

  const handleChangeValue = async (
    field: keyof Directive,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    try {
      if (!selected) return

      onChange({ ...selected, [field]: e.target.value })
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    }
  }

  const handleSave = async () => {
    try {
      if (!selected) return
      setDirectiveLoading(true)
      setDirectiveError('')
      const data = await editDirective(
        selected.id,
        selected.name,
        selected.description,
        selected.enabled,
        faviconDataUrl || selected.favicon_url
      )
      handleClearFileUpload()
      onReload(data.directives, selected.id)
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    } finally {
      if (!isMounted()) return
      setDirectiveLoading(false)
    }
  }

  const handleSetSelectedSignature = (e: React.SyntheticEvent, sig: Signature | null | undefined) => {
    setSelectedSignature(sig || null)
  }

  const handleChangeDomain = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDomain(e.target.value)
  }

  const handleChangeAction = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAction(e.target.value)
  }

  const handleChangeActionTarget = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setActionTarget(e.target.value)
  }

  const handleChangeActionType = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setActionType(e.target.value)
  }

  const handleChangeActionValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setActionValue(e.target.value)
  }

  const handleDeleteDirective = async () => {
    try {
      if (!selected?.id) return
      setDirectiveLoading(true)
      setDirectiveError('')
      const data = await deleteDirectives(selected.id)
      if (!isMounted()) return
      onDelete(data.directives)
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    } finally {
      if (!isMounted()) return
      setDirectiveLoading(false)
      setDeleteDirectiveOpen(false)
    }
  }

  useEffect(() => {
    if (selected.conditions.length !== 0) {
      setConditionsOpen(true)
    }

    if (selected.conditions.length === 0) {
      setConditionsOpen(false)
    }
  }, [selected.conditions.length])

  useEffect(() => {
    if (selected.actions.length !== 0) {
      setActionsOpen(true)
    }

    if (selected.actions.length === 0) {
      setActionsOpen(false)
    }
  }, [selected?.actions?.length])

  useEffect(() => {
    setfaviconDataUrl(null)
  }, [selected.id])

  const handleConditionExpand = () => {
    setConditionsOpen(!conditionsOpen)
  }

  const handleActionsExpand = () => {
    setActionsOpen(!actionsOpen)
  }

  const handleChangeType = (newValue: string | null) => {
    setDomain('')
    setConditionType(newValue)
  }

  const toggleEnabled = () => {
    onChange({ ...selected, enabled: !Boolean(selected.enabled) })
  }

  const handleCopyText = (text: string) => {
    navigator.clipboard.writeText(text)
  }

  return (
    <Paper variant="outlined">
      <Grid container>
        <Grid container spacing={3} padding={2}>
          <Grid item xs={12}>
            {directiveError && (
              <Grid>
                <Alert>{directiveError}</Alert>
              </Grid>
            )}
          </Grid>
          <Grid item xs={6}>
            <Box onMouseEnter={() => setHovering(2)} onMouseLeave={() => setHovering(0)}>
              <Grid container>
                <Grid item xs={10}>
                  <TextField
                    variant="standard"
                    label="Display Name"
                    fullWidth
                    value={selected.name}
                    onChange={(e) => handleChangeValue('name', e)}
                  />
                </Grid>
                <Grid item>
                  {hovering === 2 && (
                    <Tooltip title="Copy to clipboard">
                      <span>
                        <IconButton disabled={!selected.name} onClick={() => handleCopyText(selected.name)}>
                          <CopyIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box onMouseEnter={() => setHovering(1)} onMouseLeave={() => setHovering(0)}>
              <Grid container>
                <Grid item xs={10}>
                  <TextField variant="standard" label="ID" disabled fullWidth value={selected.id} />
                </Grid>

                <Grid item>
                  {hovering === 1 && (
                    <Tooltip title="Copy to clipboard">
                      <span>
                        <IconButton disabled={!selected.id} onClick={() => handleCopyText(selected.id)}>
                          <CopyIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={6} mt={4} padding={3}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item>
                <Tooltip title="If the Content Mod is not enabled, the mod will not show in the daily publish">
                  <FormControlLabel
                    control={<Switch checked={Boolean(selected.enabled)} onChange={toggleEnabled} />}
                    label="Content Mod Enabled"
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Box onMouseEnter={() => setHovering(5)} onMouseLeave={() => setHovering(0)}>
              <Paper variant="outlined">
                <Grid container alignItems="center" spacing={2} padding={1}>
                  <Grid item>
                    <Typography variant="overline">Favicon</Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2} padding={1}>
                  <Grid item>
                    <Button disabled={!!faviconDataUrl || loading || !admin} variant="contained" component="label">
                      Upload
                      <input
                        type="file"
                        hidden
                        accept=".ico,.png,.jpg,.jpeg,.svg"
                        ref={fileUpload}
                        onChange={handleChangeUpload}
                      />
                    </Button>
                  </Grid>
                  <Grid item>
                    <IconButton disabled={!faviconDataUrl || !admin} sx={{ mx: 1 }} onClick={handleClearFileUpload}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <LazyImage
                      src={
                        faviconDataUrl
                          ? `${selected?.favicon_url || ''}`
                          : `${selected?.favicon_url || ''}?${new Date().getTime().toString(16)}`
                      }
                      size={24}
                    />
                  </Grid>
                  {hovering === 5 && (
                    <Grid item sx={{ pr: 2 }}>
                      <Tooltip title="Copy to clipboard">
                        <span>
                          <IconButton onClick={() => handleCopyText(selected?.favicon_url || '')}>
                            <CopyIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Box>
          </Grid>

          <Grid item xs={6} sm={12}>
            <Box onMouseEnter={() => setHovering(3)} onMouseLeave={() => setHovering(0)}>
              <Grid container>
                <Grid item xs={9}>
                  <Tooltip title="What the Content Mod will do or alter">
                    <Typography variant="overline">Description</Typography>
                  </Tooltip>
                  <TextField
                    value={selected.description}
                    fullWidth
                    multiline
                    minRows={3}
                    onChange={(e) => handleChangeValue('description', e)}
                    helperText={`${selected?.description?.length} / 500`}
                  />
                </Grid>
                <Grid item xs={1} mt={3}>
                  {hovering === 3 && (
                    <Tooltip title="Copy to clipboard">
                      <span>
                        <IconButton
                          disabled={!selected.description}
                          onClick={() => handleCopyText(selected.description)}
                        >
                          <CopyIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} spacing={3}>
            <Accordion variant="outlined" expanded={conditionsOpen}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                onClick={handleConditionExpand}
              >
                <Typography sx={{ mx: 2 }}>Conditions</Typography>
                <Typography variant="caption">
                  {selected.conditions?.length !== 1 ? ` ${selected.conditions?.length} Entries` : ` 1 Entry`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3} padding={2}>
                  {conditionsError && (
                    <Grid item xs={12}>
                      <Alert severity="error">{conditionsError}</Alert>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Tooltip
                      title={
                        <Typography>
                          <Link href={link} color="secondary">
                            Click here for more information
                          </Link>
                        </Typography>
                      }
                    >
                      <Typography>
                        What websites are to be affected for this rule, this can be a signature or a domain.
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Add new Condition</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Autocomplete
                      id="condition_type"
                      options={conditionTypeValues}
                      renderInput={(params) => <TextField {...params} fullWidth label="Type" />}
                      value={conditionType}
                      onChange={(e, newValue) => handleChangeType(newValue)}
                    />
                  </Grid>
                  {conditionType === 'Signature' && (
                    <Grid item xs={4}>
                      <SignatureSelect
                        id="signatureId"
                        signatures={signatures}
                        onChange={handleSetSelectedSignature}
                        defaultSignature={selectedSignature}
                      />
                    </Grid>
                  )}
                  {conditionType === 'Domain' && (
                    <Grid item xs={4}>
                      <TextField
                        variant="standard"
                        label="Domain"
                        fullWidth
                        value={domain}
                        onChange={handleChangeDomain}
                      />
                    </Grid>
                  )}
                  {conditionType && (
                    <Grid item xs={4}>
                      <Button
                        variant="contained"
                        color={dark ? 'secondary' : 'primary'}
                        disabled={loading || directiveLoading}
                        onClick={handleConditionAdd}
                      >
                        Save Condition
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Existing Conditions</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} spacing={3} padding={1}>
                  <DirectiveConditionsTable
                    conditions={selected.conditions}
                    dark={dark}
                    loading={loading}
                    onDelete={handleOpenConditionDelete}
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Grid item xs={12} sm={12} spacing={3}>
              <Accordion variant="outlined" expanded={actionsOpen}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                  onClick={handleActionsExpand}
                >
                  <Typography sx={{ mx: 2 }}>Actions</Typography>
                  <Typography variant="caption">
                    {selected.actions?.length !== 1 ? ` ${selected.actions?.length} Entries` : ` 1 Entry`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container direction="row" spacing={2} padding={2}>
                    {actionsError && (
                      <Grid item xs={12}>
                        <Alert severity="error">{actionsError}</Alert>
                      </Grid>
                    )}
                    <Grid item xs={8}>
                      <Tooltip
                        title={
                          <Typography>
                            <Link href={link} color="secondary">
                              Click here for more information
                            </Link>
                          </Typography>
                        }
                      >
                        <Typography>Actions describe what data is manipulated and that result.</Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography>Add new Action</Typography>
                    </Grid>
                    <Grid item xs={4} sm={5}>
                      <TextField
                        variant="standard"
                        label="Action"
                        fullWidth
                        value={action}
                        onChange={handleChangeAction}
                      />
                    </Grid>
                    <Grid item xs={4} sm={5}>
                      <TextField
                        variant="standard"
                        label="Target"
                        fullWidth
                        value={actionTarget}
                        onChange={handleChangeActionTarget}
                      />
                    </Grid>
                    <Grid item xs={4} sm={5}>
                      <TextField
                        variant="standard"
                        label="Type"
                        fullWidth
                        value={actionType}
                        onChange={handleChangeActionType}
                      />
                    </Grid>
                    <Grid item xs={4} sm={5}>
                      <TextField
                        variant="standard"
                        label="Value"
                        fullWidth
                        value={actionValue}
                        onChange={handleChangeActionValue}
                      />
                    </Grid>
                    <Grid item xs={3} sm={3} spacing={3}>
                      <Button
                        variant="contained"
                        fullWidth
                        disabled={loading}
                        color={dark ? 'secondary' : 'primary'}
                        onClick={handleActionsAdd}
                      >
                        Add Action
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} spacing={3} padding={2}>
                    <Grid item>
                      <Typography>Existing Actions</Typography>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid container direction="row" spacing={2} padding={2} sx={{ mb: 1, mt: 2 }}>
                      {selected.actions?.map((action, index) => (
                        <Grid item xs={6} sm={6} md={6} key={`actions-${index}`}>
                          <Paper elevation={3}>
                            <Grid container justifyContent="end" padding={1} spacing={1}>
                              <Grid item>
                                <Fab
                                  size="small"
                                  color="primary"
                                  onClick={() =>
                                    window.navigator.clipboard.writeText(JSON.stringify(action?.json, null, 2))
                                  }
                                >
                                  <CopyIcon />
                                </Fab>
                              </Grid>
                              <Grid item>
                                <Fab
                                  size="small"
                                  color="error"
                                  onClick={() => handleOpenActionsDelete(action)}
                                  disabled={loading}
                                >
                                  <DeleteIcon />
                                </Fab>
                              </Grid>
                            </Grid>

                            {typeof action?.json === 'object' && (
                              <JSONInput
                                locale={locale}
                                viewOnly
                                placeholder={action?.json || []}
                                confirmGood={false}
                                theme={dark ? 'dark_vscode_tribute' : 'light_mitsuketa_tribute'}
                                height="400"
                                width="auto"
                              />
                            )}
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Grid item xs={12}>
                <Grid container padding={3} spacing={2} justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Button
                      variant="contained"
                      color={dark ? 'secondary' : 'primary'}
                      disabled={loading || loading || directiveLoading}
                      onClick={handleSave}
                    >
                      Save Content Mod
                    </Button>
                  </Grid>
                  <Grid item>
                    {(deleteEnabled || selected.conditions.length > 0 || selected.actions.length > 0) && (
                      <Typography variant="caption">Must not be Enabled, contain Conditions or Actions </Typography>
                    )}
                    <Button
                      variant="contained"
                      color="error"
                      disabled={
                        deleteEnabled ||
                        loading ||
                        directiveLoading ||
                        selected.conditions.length > 0 ||
                        selected.actions.length > 0
                      }
                      startIcon={<DeleteIcon />}
                      onClick={handleDeleteDirectiveOpen}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <DeleteConfirmDialog
        loading={loading}
        open={deleteDirectiveOpen}
        name={`${selected?.id}`}
        onClose={handleCloseDeleteDirective}
        onDelete={handleDeleteDirective}
      />
      <DeleteConfirmDialog
        loading={loading}
        open={!!deleteCondition}
        name={`Condition`}
        onClose={handleCloseDeleteCondition}
        onDelete={handleDeleteCondition}
      />
      <DeleteConfirmDialog
        loading={loading}
        open={!!deleteAction}
        name={`Action`}
        onClose={handleCloseDeleteAction}
        onDelete={handleDeleteAction}
      />
    </Paper>
  )
}

export default DirectiveDetails
