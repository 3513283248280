import React, { useCallback, useRef } from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import handleError from '../util/Error.tsx'
import LazyImage from './LazyImage.tsx'
import { getBase64 } from '../util/Func.tsx'
import SignatureSelect from './SignatureSelect.tsx'
import { Signature } from '../util/Api.tsx'
import DependencyEdit from './DependencyEdit.tsx'
import TagsEdit from './TagsEdit.tsx'

interface SignatureEditError {
  field: keyof Signature
  msg: string
}

interface SignatureEditProps {
  signature: Signature
  signatures: Signature[]
  errors?: SignatureEditError[]
  onChange?: (signature: Signature) => void
}

const SignatureEdit = ({ signature, signatures = [], errors = [], onChange = () => {} }: SignatureEditProps) => {
  const fileUpload = useRef(null)

  const handleChangeField = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: keyof Signature) => {
    onChange({
      ...signature,
      [field]: e.target.value,
    })
  }

  const handleToggleFieldBool = (field: keyof Signature) => {
    onChange({
      ...signature,
      [field]: !signature[field],
    })
  }

  const handleSetCategory = (e: React.SyntheticEvent, sig: Signature | null) => {
    onChange({
      ...signature,
      category: sig?.id || null,
    })
  }

  const handleClearField = (field: keyof Signature) => {
    onChange({
      ...signature,
      [field]: '',
    })
  }

  const handleChangeUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files) return
      if (e.target.files.length === 0) {
        onChange({
          ...signature,
          faviconUrl: '',
        })
        return
      }
      const dataUrl = await getBase64(e.target.files[0])
      onChange({
        ...signature,
        faviconUrl: dataUrl,
      })
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    }
  }

  const getFieldError = useCallback(
    (field: string) => {
      return errors.find((err) => err.field === field)?.msg || ''
    },
    [errors]
  )

  const handleChangeDependency = async (newValue: string[]) => {
    const isDependencyLoop = newValue.some((s: string) => {
      return signatures
        .map((sig) => {
          if (sig.id === s) {
            if (sig.dependencies.includes(signature.id)) {
              alert('Adding ' + sig.id + ' will create a dependency loop and must be removed')
              return true
            }
          }
          return false
        })
        .some(Boolean)
    })

    if (isDependencyLoop) return
    onChange({
      ...signature,
      dependencies: newValue || [],
    })
  }

  const handleChangeTags = async (newValue: string[]) => {
    if (!signature) return
    onChange({
      ...signature,
      tags: newValue,
    })
  }

  const signatureName = signature?.name || ''
  const signatureType = signature?.isSubCategory ? 'Category' : signature?.isCategory ? 'Theme' : 'Signature'
  const signatureUrl = signature?.url || ''
  const signatureDescription = signature?.description || ''

  return (
    <Grid container spacing={2} sx={{ mt: 4 }}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container justifyContent="center" alignItems="center" spacing={1}>
              <Grid item flexGrow={1}>
                <Typography variant="overline">Display Name</Typography>
                <br />
                <TextField
                  value={signatureName}
                  fullWidth
                  onChange={(e) => handleChangeField(e, 'name')}
                  error={Boolean(getFieldError('name'))}
                  helperText={getFieldError('name')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container justifyContent="center" alignItems="center" spacing={1}>
              <Grid item flexGrow={1}>
                <Typography variant="overline">Category Type</Typography>
                <Typography variant="h6">{signatureType}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container justifyContent="center" alignItems="center" spacing={1}>
              <Grid item flexGrow={1}>
                <Typography variant="overline">Parent Category ID</Typography>
                <br />
                {signature?.category !== undefined && (
                  <SignatureSelect
                    id="categoryId"
                    defaultSignature={signatures.find((sig) => sig.id === signature.category) || null}
                    signatures={signatures.filter((sig) => !(sig.isSubCategory === false && sig.isCategory === false))}
                    onChange={handleSetCategory}
                    error={getFieldError('category')}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typography variant="overline">Noise</Typography>
            <br />
            <Switch checked={Boolean(signature?.noise)} onChange={(e) => handleToggleFieldBool('noise')} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container justifyContent="center" alignItems="center" spacing={1}>
              <Grid item flexGrow={1}>
                <Typography variant="overline">Main URL</Typography>
                <br />
                <TextField
                  value={signatureUrl}
                  fullWidth
                  onChange={(e) => handleChangeField(e, 'url')}
                  error={Boolean(getFieldError('url'))}
                  helperText={getFieldError('url')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typography variant="overline">Enabled</Typography>
            <br />
            <Switch checked={Boolean(signature?.enabled)} onChange={(e) => handleToggleFieldBool('enabled')} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Typography variant="overline">Classwize Focus</Typography>
            <br />
            <Switch checked={Boolean(signature?.focus)} onChange={(e) => handleToggleFieldBool('focus')} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typography variant="overline">Reporting</Typography>
            <br />
            <Switch checked={Boolean(signature?.reporting)} onChange={(e) => handleToggleFieldBool('reporting')} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container sx={{ mb: 2 }} alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="overline">Favicon</Typography>
            <LazyImage src={signature?.faviconUrl || ''} />
          </Grid>
          <Grid item>
            <Button variant="contained" component="label">
              Upload Favicon
              <input type="file" hidden accept=".ico,.png,.jpg,.jpeg" ref={fileUpload} onChange={handleChangeUpload} />
            </Button>
          </Grid>
          {signature?.faviconUrl && (
            <Grid item>
              <IconButton onClick={() => handleClearField('faviconUrl')}>
                <CloseIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={9}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="overline">Tags</Typography>
            <br />
            <TagsEdit signatures={signatures} selectedTags={signature?.tags} onChange={handleChangeTags} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={9}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="overline">Dependencies</Typography>
            <br />
            <DependencyEdit signatures={signatures} signature={signature} onChange={handleChangeDependency} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="overline">Signature Description</Typography>
            <br />
            <TextField
              value={signatureDescription}
              fullWidth
              multiline
              minRows={2}
              onChange={(e) => handleChangeField(e, 'description')}
              error={Boolean(getFieldError('description'))}
              helperText={getFieldError('description')}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SignatureEdit
