import { Button, Divider, Grid, IconButton, TextField, Typography } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import handleError from '../util/Error.tsx'
import { getBase64 } from '../util/Func.tsx'
import LazyImage from './LazyImage.tsx'
import CloseIcon from '@mui/icons-material/Close'
import { Signature } from '../util/Api.tsx'
import SignatureSelect from './SignatureSelect.tsx'

interface AddControlDialogProps {
  favicon: string
  controlId: string
  controlName: string
  controlCategory: string
  signatures: Signature[]
  editing: boolean
  onChange: (controlId: string, controlName: string, favicon: string, cat: string) => void
}

const AddControlDialog = ({
  favicon = '',
  controlId = '',
  controlName = '',
  controlCategory,
  signatures,
  editing = false,
  onChange = () => {},
}: AddControlDialogProps) => {
  const fileUpload = useRef(null)
  const [localFavicon, setLocalFavicon] = useState(favicon)
  const [id, setId] = useState(controlId)
  const [name, setName] = useState(controlName)
  const [category, setCategory] = useState(controlCategory)

  const handleChangeConId = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(e.target.value, name, favicon, category)
  }

  const handleChangeConName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(id, e.target.value, favicon, category)
  }

  const handleChangeLinkedSignature = (e: React.SyntheticEvent, sigs: Signature | null) => {
    onChange(id, name, favicon, sigs?.id ? sigs.id : '')
  }

  const handleChangeFavicon = (favicon: string) => {
    setLocalFavicon(favicon)
    onChange(id, name, favicon, category)
  }

  const handleChangeUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files) return
      if (e.target.files.length === 0) {
        setLocalFavicon('')
        return
      }
      const dataUrl = await getBase64(e.target.files[0])
      onChange(id, name, dataUrl, category)
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    }
  }

  const populateEntries = useCallback(() => {
    setId(controlId)
    setLocalFavicon(favicon)
    setName(controlName)
    setCategory(controlCategory)
  }, [controlId, favicon, controlName, controlCategory])

  useEffect(() => {
    populateEntries()
  }, [populateEntries])

  return (
    <Grid>
      <Grid container direction="column" alignItems="center" justifyContent="center" padding={2} spacing={2}>
        <Grid item>
          <Grid container sx={{ mb: 2 }} alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="overline">Favicon</Typography>
              <LazyImage src={localFavicon || ''} />
            </Grid>
            <Grid item>
              <Button variant="contained" component="label">
                Upload Favicon
                <input
                  type="file"
                  hidden
                  accept=".ico,.png,.jpg,.jpeg"
                  ref={fileUpload}
                  onChange={handleChangeUpload}
                />
              </Button>
            </Grid>
            {favicon && (
              <Grid item>
                <IconButton onClick={() => handleChangeFavicon('')}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="overline">ID</Typography>
          <br />
          <TextField value={id} sx={{ minWidth: '300px' }} onChange={(e) => handleChangeConId(e)} disabled={editing} />
        </Grid>

        <Grid item>
          <Typography variant="overline">Name</Typography>
          <br />
          <TextField value={name} sx={{ minWidth: '300px' }} onChange={(e) => handleChangeConName(e)} />
        </Grid>

        <Grid item xs={12} minWidth="320px">
          <SignatureSelect
            signatures={signatures.filter((sig) => sig.isCategory || sig.isSubCategory)}
            defaultSignature={signatures.find((sig) => sig.id === category)}
            onChange={handleChangeLinkedSignature}
            label={'Select a category to be linked to'}
          />
        </Grid>
      </Grid>
      <Divider />
    </Grid>
  )
}

export default AddControlDialog
