import React, { useEffect, useCallback } from 'react'
import { useHistory } from 'react-router'

import Grid from '@mui/material/Grid'
import { validateToken } from '../util/Api.tsx'
import useStore from '../util/Store.tsx'

const Home = () => {
  const history = useHistory()
  const [token, setToken] = useStore('token')

  const vaidateLogin = useCallback(async () => {
    try {
      if (!token) return
      await validateToken()
      history.push('/appindex-manager')
    } catch (err) {
      setToken(null)
    }
  }, [token, setToken, history])

  useEffect(() => {
    vaidateLogin()
  }, [vaidateLogin])

  return (
    <Grid container justifyContent="center" sx={{ mt: 4 }}>
      <Grid item xs={12} sm={8} md={6} lg={4}></Grid>
    </Grid>
  )
}

export default Home
