import React, { useState, useCallback } from 'react'

import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Autocomplete from '@mui/material/Autocomplete'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'

import { useTheme } from '@mui/material/styles'
import { Signature } from '../util/Api.tsx'

interface AddDependencyDialogProps {
  open?: boolean
  loading?: boolean
  signatures?: Signature[]
  error?: string
  onClickAdd?: (dependency: string | null) => void
  onClose?: () => void
}

const AddDependencyDialog = ({
  open = false,
  loading = false,
  signatures = [],
  error,
  onClickAdd = () => {},
  onClose = () => {},
}: AddDependencyDialogProps) => {
  const theme = useTheme()
  const [dependency, setDependency] = useState<string | null>(null)

  const handleGetSignatureIds = useCallback(() => {
    return [...signatures.map((sig) => sig.id), '']
  }, [signatures])

  const handleChangeDependency = (newValue: string | null) => {
    setDependency(newValue)
  }

  const handleClickAdd = () => {
    onClickAdd(dependency)
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                Add a Dependency
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          {error && (
            <Grid container justifyContent="center" sx={{ mb: 3 }}>
              <Grid item>
                <Alert severity="error">{error}</Alert>
              </Grid>
            </Grid>
          )}
          <Grid container direction="column" alignItems="stretch" justifyContent="center" padding={2} spacing={3}>
            <Grid item>
              <Autocomplete
                id="dependencyIds"
                sx={{ minWidth: 350 }}
                options={handleGetSignatureIds()}
                renderInput={(params) => <TextField {...params} fullWidth label="Signature dependency ID" />}
                value={dependency}
                onChange={(e, newValue) => handleChangeDependency(newValue)}
              />
            </Grid>
            <Grid item>
              <Button variant="contained" fullWidth disabled={loading || !dependency} onClick={handleClickAdd}>
                Add
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}

export default AddDependencyDialog
