import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router'
import useIsMounted from 'react-is-mounted-hook'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Fab from '@mui/material/Fab'
import Alert from '@mui/material/Alert'

import AddIcon from '@mui/icons-material/Add'

import MappingsTable from '../components/MappingsTable.tsx'
import SearchBar from '../components/SearchBar.tsx'
import MappingCreateDialog from '../components/MappingCreateDialog.tsx'
import DeleteConfirmDialog from '../components/DeleteConfirmDialog.tsx'

import { getSignatures, getMappings, deleteMapping, Mapping, Signature } from '../util/Api.tsx'
import handleError from '../util/Error.tsx'
import useStore from '../util/Store.tsx'

interface MappingSearch extends Mapping {
  search: string
}

const Mappings = () => {
  const isMounted = useIsMounted()
  const history = useHistory()
  const [user] = useStore('user')
  const [darkMode] = useStore('darkMode')
  const [signatureIds, setSignatureIds] = useState<string[]>([])
  const [signatures, setSignatures] = useState<Signature[]>([])
  const [mappings, setMappings] = useState<MappingSearch[]>([])
  const [shownMappings, setShownMappings] = useState<MappingSearch[]>([])
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState('')
  const [createOpen, setCreateOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [activeMapping, setActiveMapping] = useState<Mapping | null>(null)
  const [error, setError] = useState('')
  const [editingMapping, setEditingMapping] = useState<Mapping | null>(null)

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    if (e.target.value === '') {
      setShownMappings(mappings)
    }
  }

  const handleSubmitSearch = () => {
    setShownMappings(mappings.filter((mapping) => mapping.search.includes(search.toLowerCase())))
  }

  const handleDelete = async () => {
    try {
      if (!activeMapping?.signatureId) return
      setLoading(true)
      const { mappings: newMappings } = await deleteMapping({
        signatureId: activeMapping.signatureId,
        providerId: activeMapping.providerId,
        providerCategory: activeMapping.providerCategory,
      })
      if (!isMounted()) return

      handleReloadMappings(newMappings)
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
      setDeleteOpen(false)
    }
  }

  const populateMappings = useCallback(async () => {
    try {
      setLoading(true)
      const data = await getMappings()
      if (!isMounted()) return

      const newMappings = data.mappings.map((mapping) => ({
        ...mapping,
        search:
          `${mapping.signatureId} ${mapping.providerId} ${mapping.providerCategory} ${mapping.ttl} ${mapping.ranking}`.toLowerCase(),
      }))

      setMappings(newMappings)
      setShownMappings(newMappings)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [isMounted])

  const populateSignatures = useCallback(async () => {
    try {
      const data = await getSignatures()
      if (!isMounted()) return

      setSignatureIds(data.signatures.map((signature) => signature.id))
      setSignatures(data.signatures)
    } catch (err) {
      handleError(err)
    }
  }, [isMounted])

  const handleSignatureClick = async (id: string) => {
    try {
      history.push(`/signature/${id}`)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }

  const handleOpenCreateDialog = () => {
    setCreateOpen(true)
  }

  const handleCloseCreateDialog = () => {
    setEditingMapping(null)
    setCreateOpen(false)
  }

  const handleMappingEdit = (row: Mapping) => {
    setEditingMapping(row)
    setCreateOpen(true)
  }

  const handleOpenDelete = (mapping: Mapping) => {
    setDeleteOpen(true)
    setActiveMapping(mapping)
  }

  const handleCloseDelete = () => {
    setDeleteOpen(false)
  }

  const handleReloadMappings = (newMappings: Mapping[]) => {
    const newMappingSearches: MappingSearch[] = newMappings.map((mapping) => ({
      ...mapping,
      search: `${mapping.signatureId} ${mapping.providerId} ${mapping.providerCategory} ${mapping.ttl} ${mapping.ranking}`,
    }))
    setMappings(newMappingSearches)
    setShownMappings(newMappingSearches.filter((mapping) => mapping.search.includes(search)))
    setEditingMapping(null)
  }

  useEffect(() => {
    populateSignatures()
    populateMappings()
  }, [populateSignatures, populateMappings])

  return (
    <Grid container justifyContent="center" sx={{ mt: 3 }} padding={3}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={10}>
        <Grid container justifyContent="center" sx={{ mt: 3 }}>
          <Grid item>
            <Typography variant="h3">Appindex Categorisation Mappings</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" padding={1} spacing={2} sx={{ mt: 3, mb: 3 }}>
          {user?.appindexAdmin && (
            <Grid item>
              <Fab color="primary" size="small" disabled={loading} onClick={handleOpenCreateDialog}>
                <AddIcon />
              </Fab>
            </Grid>
          )}
          <Grid item>
            <SearchBar
              value={search}
              dark={!darkMode}
              onChange={handleChangeSearch}
              button
              onSubmit={handleSubmitSearch}
              onClickButton={handleSubmitSearch}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          {error && (
            <Grid item>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
        </Grid>
        <Grid container justifyContent="start" spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MappingsTable
              admin={user?.appindexAdmin}
              mappings={shownMappings}
              loading={loading}
              onClick={handleSignatureClick}
              signatures={signatures}
              onEdit={handleMappingEdit}
              onDelete={handleOpenDelete}
            />
          </Grid>
        </Grid>
        <MappingCreateDialog
          existingMapping={editingMapping}
          signatureIds={signatureIds}
          open={createOpen}
          onClose={handleCloseCreateDialog}
          onSave={handleReloadMappings}
        />
        <DeleteConfirmDialog
          loading={loading}
          open={deleteOpen}
          name={`${activeMapping?.signatureId} mapping`}
          onClose={handleCloseDelete}
          onDelete={handleDelete}
        />
      </Grid>
    </Grid>
  )
}

export default Mappings
