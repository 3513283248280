import React, { useState, useCallback, useEffect } from 'react'
import useIsMounted from 'react-is-mounted-hook'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import Collapse from '@mui/material/Collapse'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused'
import DeleteIcon from '@mui/icons-material/Delete'
import SearchBar from './SearchBar.tsx'
import { Downvote, getDownvotes, purgeDownvotes, purgeSingleDownvote, snoozeDownvote } from '../util/Api.tsx'
import useStore from '../util/Store.tsx'
import handleError from '../util/Error.tsx'
import { useTheme } from '@mui/material/styles'
import {
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material'
import FullScreenDialog from './FullscreenDialog.tsx'

interface DonvotesDialogProps {
  open: boolean
  onClose?: () => void
  onClickWebsite?: (website: string) => void
}

const DownvotesDialog = ({ open = false, onClose = () => {}, onClickWebsite = () => {} }: DonvotesDialogProps) => {
  const isMounted = useIsMounted()
  const theme = useTheme()
  const [user] = useStore('user')
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState<Downvote[]>([])
  const [shownResults, setShownResults] = useState<Downvote[]>([])
  const [error, setError] = useState('')
  const [orderBy, setOrderBy] = useState<keyof Downvote>('downvotes')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState<'asc' | 'desc'>('desc')
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [unclassified, setUnclassified] = useState(false)
  const [excludehuman, setExcludeHuman] = useState(true)

  const orderVotes = useCallback(() => {
    const sortedApps = shownResults?.sort((a, b) => {
      if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1
      if (a[orderBy] < b[orderBy]) return order === 'desc' ? 1 : -1
      return 0
    })
    const currentvotes: any[] = sortedApps.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const length = currentvotes.length
    if (currentvotes.length === rowsPerPage) return currentvotes
    for (let i = 0; i < rowsPerPage - length; i++) {
      currentvotes.push('')
    }
    return currentvotes
  }, [shownResults, page, rowsPerPage, orderBy, order])

  const handleNavigateWebsite = (e: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>, website: string) => {
    e.preventDefault()
    onClickWebsite(website)
    onClose()
  }

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    if (e.target.value) {
      if (unclassified) {
        setShownResults(
          results.filter(
            (result) => result.website.includes(e.target.value) && result.category.toLowerCase() === 'unclassified'
          )
        )
      } else {
        setShownResults(
          results.filter(
            (result) =>
              result.website.includes(e.target.value) || result.category.toLowerCase().includes(e.target.value)
          )
        )
      }
    } else {
      if (unclassified) {
        setShownResults(results.filter((result) => result.category.toLowerCase() === 'unclassified'))
      } else {
        setShownResults(results)
      }
    }
  }

  const handleClickPurge = useCallback(async () => {
    try {
      setLoading(true)
      await purgeDownvotes()
      if (!isMounted()) return
      setResults([])
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }, [isMounted])

  const handleClickSinglePurge = useCallback(
    async (website: string) => {
      try {
        setLoading(true)
        const { downvotes } = await purgeSingleDownvote(website)
        if (!isMounted()) return
        setResults(downvotes)
        setError('')
      } catch (err) {
        const { msg } = handleError(err)
        setError(msg)
      } finally {
        if (!isMounted()) return
        setLoading(false)
      }
    },
    [isMounted]
  )

  const handleClickSnooze = useCallback(
    async (website: string) => {
      try {
        setLoading(true)
        const { downvotes } = await snoozeDownvote(website)
        if (!isMounted()) return
        setResults(downvotes)
        setError('')
      } catch (err) {
        const { msg } = handleError(err)
        setError(msg)
      } finally {
        if (!isMounted()) return
        setLoading(false)
      }
    },
    [isMounted]
  )

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const createSortHandler = (vote: keyof Downvote) => (e: React.MouseEvent) => {
    if (vote !== orderBy) {
      setOrder('asc')
      return setOrderBy(vote)
    }
    if (order === 'asc') {
      return setOrder('desc')
    }
    if (order === 'desc') {
      return setOrder('asc')
    }
  }

  const handleShowUnclassified = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUnclassified(e.target.checked)
  }

  const handleShowExclude = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExcludeHuman(e.target.checked)
  }

  const populateDownvotes = useCallback(async () => {
    try {
      setLoading(true)
      if (excludehuman) {
        const { downvotes } = await getDownvotes(true)
        if (!isMounted()) return
        setResults(downvotes)
      } else {
        const { downvotes } = await getDownvotes(false)
        if (!isMounted()) return
        setResults(downvotes)
      }
      setError('')
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }, [isMounted, excludehuman])

  useEffect(() => {
    if (!open) return
    populateDownvotes()
  }, [populateDownvotes, open, excludehuman])

  useEffect(() => {
    if (unclassified === true) {
      setShownResults(
        results.filter((result) => result.website.includes(search) && result.category.toLowerCase() === 'unclassified')
      )
    } else {
      setShownResults(
        results.filter((result) => result.website.includes(search) || result.category.toLowerCase().includes(search))
      )
    }
  }, [results, search, unclassified])

  return (
    <FullScreenDialog open={open} onClose={onClose}>
      <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
        <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
        <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
          <Grid item>
            <Typography variant="h5" noWrap>
              Downvotes
            </Typography>
          </Grid>
        </Grid>
        <Grid container padding={1} justifyContent="center">
          <Grid item xs={12}>
            <Collapse in={Boolean(error)}>
              <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
                {error || 'Error'}
              </Alert>
            </Collapse>
          </Grid>
        </Grid>
        <Grid container padding={3} justifyContent="center">
          <Grid item padding={2}>
            <Paper variant="outlined">
              <SearchBar value={search} onChange={handleChangeSearch} />
            </Paper>
          </Grid>
          <Grid item padding={2}>
            <Paper></Paper>
            <FormControlLabel
              control={<Checkbox defaultChecked onChange={handleShowUnclassified} checked={unclassified} />}
              label="Show only Unclassified"
            />
          </Grid>
          <Grid item padding={2}>
            <Paper></Paper>
            <FormControlLabel
              control={<Checkbox defaultChecked onChange={handleShowExclude} checked={excludehuman} />}
              label="Hide Human edited domains"
            />
          </Grid>
          <Grid item xs={10}>
            <Paper variant="outlined">
              <TableContainer>
                <Table sx={{ overflowY: 'auto' }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <TableSortLabel
                          active={orderBy === 'website'}
                          direction={orderBy === 'website' ? order : 'asc'}
                          onClick={createSortHandler('website')}
                        >
                          Site
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">
                        <TableSortLabel
                          active={orderBy === 'category'}
                          direction={orderBy === 'category' ? order : 'asc'}
                          onClick={createSortHandler('category')}
                        >
                          Current Category
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="center">
                        <TableSortLabel
                          active={orderBy === 'downvotes'}
                          direction={orderBy === 'downvotes' ? order : 'asc'}
                          onClick={createSortHandler('downvotes')}
                        >
                          Votes
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="Sets downvotes for the URL to 0">
                          <Typography variant="inherit">Delete</Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="Sets downvotes for the URL to -1000">
                          <Typography variant="inherit">Snooze</Typography>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderVotes().map((downvote, index) => (
                      <TableRow
                        hover={Boolean(downvote)}
                        key={`apps-${downvote || ''}-${index}`}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          cursor: !downvote ? undefined : 'pointer',
                          height: 40,
                          opacity: !downvote ? 0 : 1,
                        }}
                      >
                        <TableCell component="th" scope="row" align="left">
                          <Typography variant="body2" noWrap>
                            <Link
                              underline="none"
                              href={`/search/${downvote.website}`}
                              onClick={(e) => handleNavigateWebsite(e, downvote.website)}
                            >
                              {downvote.website}
                            </Link>
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row" align="left">
                          <Typography variant="body2" noWrap>
                            {downvote.category}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <Typography variant="body2" noWrap>
                            {downvote.downvotes}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            size="small"
                            disabled={loading || !downvote}
                            onClick={(e) => handleClickSinglePurge(downvote.website)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            size="small"
                            disabled={loading || !downvote}
                            onClick={(e) => handleClickSnooze(downvote.website)}
                          >
                            <NotificationsPausedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
                component="div"
                count={shownResults.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            {user?.appindexAdmin && (
              <Grid container padding={2} justifyContent="center">
                <Grid item xs={12}>
                  <Button variant="contained" color="error" disabled={loading} onClick={handleClickPurge}>
                    Purge
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </FullScreenDialog>
  )
}

export default DownvotesDialog
