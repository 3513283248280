import {
  Grid,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import { JSONCondition } from '../util/Api.tsx'
import DeleteIcon from '@mui/icons-material/Delete'
import { useCallback, useState } from 'react'

interface DirectiveConditionsTableProps {
  conditions: JSONCondition[]
  dark: boolean
  loading?: boolean
  onDelete: (condition: JSONCondition) => void
}

const DirectiveConditionsTable = ({
  conditions = [],
  dark = false,
  loading = false,
  onDelete = () => {},
}: DirectiveConditionsTableProps) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [orderBy, setOrderBy] = useState<keyof JSONCondition>('value')
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')

  const getMappings = useCallback(() => {
    const sorted = conditions.sort((a, b) => {
      if (a[orderBy] || '' > (b[orderBy] || '')) return order === 'asc' ? 1 : -1
      if (a[orderBy] || '' < (b[orderBy] || '')) return order === 'desc' ? 1 : -1
      return 0
    })
    const currentPage: (JSONCondition | null)[] = sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const length = currentPage.length
    if (currentPage.length === rowsPerPage) return currentPage
    for (let i = 0; i < rowsPerPage - length; i++) {
      currentPage.push(null)
    }
    return currentPage
  }, [order, orderBy, page, rowsPerPage, conditions])

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const createSortHandler = (value: keyof JSONCondition) => (e: React.MouseEvent) => {
    if (value !== orderBy) {
      setOrder('asc')
      return setOrderBy(value)
    }
    if (order === 'asc') {
      return setOrder('desc')
    }
    if (order === 'desc') {
      return setOrder('asc')
    }
  }

  return (
    <Grid>
      <TableContainer>
        <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'type'}
                  direction={orderBy === 'type' ? order : 'asc'}
                  onClick={createSortHandler('type')}
                >
                  Domain/Signature
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'value'}
                  direction={orderBy === 'value' ? order : 'asc'}
                  onClick={createSortHandler('value')}
                >
                  Domain/Signature
                </TableSortLabel>
              </TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getMappings().map((row, index) => (
              <TableRow
                hover
                key={`keyword-table-item-${index}`}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: 'pointer',
                  height: 47,
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="body2" noWrap sx={{ maxWidth: 280 }}>
                    {row?.type || ''}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant="body2" noWrap sx={{ maxWidth: 280 }}>
                    {row?.value || ''}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {row?.value && (
                    <IconButton size="small" disabled={loading || !row} onClick={(e) => onDelete(row)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={conditions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Grid>
  )
}

export default DirectiveConditionsTable
