import React, { useState, useEffect, useCallback } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import TableSortLabel from '@mui/material/TableSortLabel'

import LazyImage from './LazyImage.tsx'
import { Signature } from '../util/Api.tsx'

interface SignatureTableProps {
  signatures: Signature[]
  shownSignatures: Signature[]
  loading?: boolean
  onClick?: (id: string) => void
}

const SignatureTable = ({
  shownSignatures = [],
  signatures,
  loading = false,
  onClick = () => {},
}: SignatureTableProps) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [orderBy, setOrderBy] = useState<keyof Signature | 'type'>('name')
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')

  const getSignatures = useCallback(() => {
    const sortedUrls = shownSignatures.sort((a, b) => {
      if (orderBy === 'type') {
        if (a.isCategory && !b.isCategory) return order === 'asc' ? 1 : -1
        if (b.isCategory && !a.isCategory) return order === 'desc' ? 1 : -1
        if (a.isSubCategory && !b.isSubCategory) return order === 'asc' ? 1 : -1
        if (b.isSubCategory && !a.isSubCategory) return order === 'desc' ? 1 : -1
        return 0
      } else {
        if ((a[orderBy] || '') > (b[orderBy] || '')) return order === 'asc' ? 1 : -1
        if ((a[orderBy] || '') < (b[orderBy] || '')) return order === 'desc' ? 1 : -1
        return 0
      }
    })
    const currentUrls: (Signature | null)[] = sortedUrls.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const length = currentUrls.length
    if (currentUrls.length === rowsPerPage) return currentUrls
    for (let i = 0; i < rowsPerPage - length; i++) {
      currentUrls.push(null)
    }
    return currentUrls
  }, [shownSignatures, page, rowsPerPage, order, orderBy])

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const handleSignatureClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>, id: string | null) => {
    if (!id) return
    e.preventDefault()
    e.stopPropagation()
    onClick(id)
  }

  const createSortHandler = (id: keyof Signature | 'type') => (e: React.MouseEvent) => {
    if (id !== orderBy) {
      setOrder('asc')
      return setOrderBy(id)
    }
    if (order === 'asc') {
      return setOrder('desc')
    }
    if (order === 'desc') {
      return setOrder('asc')
    }
  }

  useEffect(() => {
    setPage(0)
  }, [shownSignatures])

  return (
    <Paper variant="outlined">
      <TableContainer>
        <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Icon</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={createSortHandler('name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'id'}
                  direction={orderBy === 'id' ? order : 'asc'}
                  onClick={createSortHandler('id')}
                >
                  Signature
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'type'}
                  direction={orderBy === 'type' ? order : 'asc'}
                  onClick={createSortHandler('type')}
                >
                  Type
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'category'}
                  direction={orderBy === 'category' ? order : 'asc'}
                  onClick={createSortHandler('category')}
                >
                  Parent Display Name
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === 'url'}
                  direction={orderBy === 'url' ? order : 'asc'}
                  onClick={createSortHandler('url')}
                >
                  URL
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getSignatures().map((row, index) => (
              <TableRow
                hover
                key={`signature-table-item-${index}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer', height: 40 }}
                onClick={row?.id ? () => onClick(row.id) : () => {}}
              >
                <TableCell component="th" scope="row">
                  {row?.id && <LazyImage src={row.faviconUrl || ''} size={24} />}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant="body2" noWrap sx={{ maxWidth: 280 }}>
                    {row?.name || ''}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" noWrap sx={{ maxWidth: 240 }}>
                    {row?.id || ''}
                  </Typography>
                </TableCell>
                <TableCell>
                  {row?.isCategory ? (
                    <Typography variant="body2" sx={{ color: '#3694E3', maxWidth: 90 }} noWrap>
                      Theme
                    </Typography>
                  ) : row?.isSubCategory ? (
                    <Typography variant="body2" sx={{ color: '#189AB4', maxWidth: 90 }} noWrap>
                      Category
                    </Typography>
                  ) : (
                    row?.id && (
                      <Typography variant="body2" sx={{ color: '#6ECDD7', maxWidth: 90 }} noWrap>
                        Signature
                      </Typography>
                    )
                  )}
                </TableCell>
                <TableCell>
                  <Typography variant="body2" noWrap sx={{ maxWidth: 240 }}>
                    {row?.category !== 'None' && row?.category && (
                      <Link underline="none" onClick={(e) => handleSignatureClick(e, row.category)}>
                        {signatures.find((sig) => sig.id === row?.category)?.name || ''}
                      </Link>
                    )}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2" noWrap flexDirection="row-reverse">
                    {row?.url !== 'None' && row?.url && (
                      <Link underline="none" href={'https://' + row.url} rel="noopener" target="_blank">
                        {row.url}
                      </Link>
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={shownSignatures.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default SignatureTable
