import React, { SyntheticEvent, useMemo } from 'react'
import { useState, useEffect, useCallback } from 'react'
import { useHistory, useParams } from 'react-router'
import useIsMounted from 'react-is-mounted-hook'
import Tree from 'react-d3-tree'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import LinearProgress from '@mui/material/LinearProgress'
import Button from '@mui/material/Button'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import SearchBar from '../components/SearchBar.tsx'
import LazyImage from '../components/LazyImage.tsx'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Criteria, getDetailedSignatures, getSignatureURLsCount, Signature } from '../util/Api.tsx'
import handleError from '../util/Error.tsx'
import useStore from '../util/Store.tsx'
import useMediaQuery from '@mui/material/useMediaQuery'
import { getUUID } from '../util/Func.tsx'
import SignatureCreate from '../components/SignatureCreate.tsx'
import SignatureDetailsDialog from '../components/dialogs/SignatureDetailsDialog.tsx'
import { Autocomplete } from '@mui/lab'
import { Chip, TextField } from '@mui/material'

interface NodeDatum {
  hierarchyId: string
  name: string
  search: string
  isSubCategory?: boolean
  isCategory?: boolean
  category?: string | null
  id: string
  url?: string | null
  enabled?: boolean
  noise?: boolean
  description?: string
  type?: string
  faviconUrl?: string | null
  dependencies: string[]
  tags: string[]
  criteria: Criteria[]
  nonExclusiveCriteria: Criteria[]
  children: NodeDatum[]
  collapsedChildren: NodeDatum[]
  matchesSearch?: boolean
  uncategorized?: boolean
  isMain?: boolean
  invalid?: boolean
  isCollapsed?: boolean
  totalCollapsedChildren?: number
  parentId?: string
  invisible?: boolean
  focus: boolean
  reporting: boolean
  hidden: boolean
}

export const useCenteredTree = (
  defaultTranslate = { x: 0, y: 0 }
): [{ x: number; y: number }, (containerElem: HTMLElement | null) => void, (x: number, y: number) => void] => {
  const [containerEl, setContainerEl] = useState<HTMLElement | null>(null)
  const [translate, setTranslate] = useState(defaultTranslate)

  const containerRef = useCallback((containerElem: HTMLElement | null) => {
    if (containerElem !== null) {
      setContainerEl(containerElem)
    }
  }, [])

  const setCentered = useCallback(
    (addWidth = 0, addHeight = 0) => {
      if (containerEl !== null) {
        const { width, height } = containerEl.getBoundingClientRect()
        setTranslate({ x: width / 2 + addWidth, y: height / 2 + addHeight })
      }
    },
    [containerEl]
  )

  useEffect(() => {
    setCentered()
  }, [setCentered])

  return [translate, containerRef, setCentered]
}

interface SignatureTreeItemProps {
  nodeDatum: NodeDatum
  onCollapseNode?: (nodeDatum: NodeDatum, e: React.MouseEvent<SVGElement>) => void
  onClickNode?: (nodeDatum: NodeDatum, e: React.MouseEvent<SVGElement>) => void
  onUncollapseParent?: (nodeDatum: NodeDatum, e: React.MouseEvent<SVGElement>) => void
  darkMode?: boolean
}

const SignatureTreeItem = ({
  nodeDatum,
  onCollapseNode = () => {},
  onClickNode = () => {},
  onUncollapseParent = () => {},
  darkMode = false,
}: SignatureTreeItemProps) => {
  if (nodeDatum?.invisible) {
    return (
      <g>
        <text
          fill={darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)'}
          x="15"
          dy="5"
          strokeWidth="0"
          onClick={(e) => onUncollapseParent(nodeDatum, e)}
        >
          {nodeDatum?.totalCollapsedChildren || 0} Collapsed Items
        </text>
      </g>
    )
  }

  const getOpacity = () => {
    if (nodeDatum?.matchesSearch === false) {
      return 0.7
    }
    if (!nodeDatum?.enabled) {
      return 0.8
    }
    return 1
  }

  const getColor = () => {
    if (nodeDatum?.matchesSearch === false) {
      return '#A0A0A0'
    }
    if (!nodeDatum?.enabled) {
      return '#8C8C8C'
    }
    if (nodeDatum?.uncategorized) {
      return '#ffffff'
    }
    if (nodeDatum?.isCategory) {
      return '#3694E3'
    }
    if (nodeDatum?.isSubCategory) {
      return '#189AB4'
    }
    return '#6ECDD7'
  }

  const getFields = () => {
    const fields: {
      text: string
      color: string
      darkColor: string
    }[] = []
    if (!nodeDatum?.id || nodeDatum.id === 'appindex') return fields
    const dependencies = nodeDatum.dependencies
    const criteria = nodeDatum.criteria
    const nonExclusiveCriteria = nodeDatum.nonExclusiveCriteria
    const url = nodeDatum.url
    if (dependencies.length !== 0) {
      fields.push({
        text: dependencies.length + ' ' + (dependencies.length === 1 ? 'Dependency' : 'Dependencies'),
        color: 'rgb(0,144,214)',
        darkColor: 'rgb(74,196,255)',
      })
    }
    if (criteria.length !== 0 || nonExclusiveCriteria.length !== 0) {
      fields.push({
        text: criteria.length + ' Criteria / ' + nonExclusiveCriteria.length + ' Non-Exclusive',
        color: 'rgb(151,156,0)',
        darkColor: 'rgb(248,255,30)',
      })
    }
    if (url) {
      fields.push({
        text: url,
        color: 'rgb(168,118,0)',
        darkColor: 'rgb(255,184,16)',
      })
    }
    return fields
  }

  return (
    <g>
      {nodeDatum?.isMain ? (
        <circle
          onClick={(e) => onCollapseNode(nodeDatum, e)}
          stroke={darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)'}
          strokeWidth={1}
          strokeDasharray="none"
          strokeLinecap="butt"
          strokeDashoffset={0}
          strokeLinejoin="miter"
          strokeMiterlimit={1}
          fill={getColor()}
          opacity={getOpacity()}
          fillRule="nonzero"
          vectorEffect="non-scaling-stroke"
          cx="0"
          cy="0"
          r="20"
        />
      ) : nodeDatum?.isCategory ? (
        <polygon
          onClick={(e) => onCollapseNode(nodeDatum, e)}
          stroke={darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)'}
          strokeWidth={1}
          strokeDasharray="none"
          strokeLinecap="butt"
          strokeDashoffset={0}
          strokeLinejoin="miter"
          strokeMiterlimit={2}
          fill={getColor()}
          opacity={getOpacity()}
          fillRule="nonzero"
          vectorEffect="non-scaling-stroke"
          points="-50,-50 -50,50 50,50 50,-50 "
          transform="matrix(0.52 0 0 0.52 0 0)"
        />
      ) : nodeDatum?.isSubCategory ? (
        <polygon
          onClick={(e) => onCollapseNode(nodeDatum, e)}
          stroke={darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)'}
          strokeWidth={1}
          strokeDasharray="none"
          strokeLinecap="butt"
          strokeDashoffset={0}
          strokeLinejoin="miter"
          strokeMiterlimit={2}
          fill={getColor()}
          opacity={getOpacity()}
          fillRule="nonzero"
          vectorEffect="non-scaling-stroke"
          points="-15.5,37.4 -37.4,15.5 -37.4,-15.5 -15.5,-37.4 15.5,-37.4 37.4,-15.5 37.4,15.5 15.5,37.4 "
          transform="matrix(0.52 0 0 0.52 0 0)"
        />
      ) : nodeDatum?.invalid ? (
        <polygon
          onClick={(e) => onCollapseNode(nodeDatum, e)}
          stroke={darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)'}
          strokeWidth={1}
          strokeDasharray="none"
          strokeLinecap="butt"
          strokeDashoffset={0}
          strokeLinejoin="miter"
          strokeMiterlimit={2}
          fill="rgb(231,43,43)"
          opacity={getOpacity()}
          fillRule="nonzero"
          vectorEffect="non-scaling-stroke"
          points="0,-60 45,0 0,60 -45,0 "
          transform="matrix(0.52 0 0 0.52 0 0)"
        />
      ) : (
        <polygon
          onClick={(e) => onCollapseNode(nodeDatum, e)}
          stroke={darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)'}
          strokeWidth={1}
          strokeDasharray="none"
          strokeLinecap="butt"
          strokeDashoffset={0}
          strokeLinejoin="miter"
          strokeMiterlimit={2}
          fill={getColor()}
          opacity={getOpacity()}
          fillRule="nonzero"
          vectorEffect="non-scaling-stroke"
          points="0,-60 45,0 0,60 -45,0 "
          transform="matrix(0.52 0 0 0.52 0 0)"
        />
      )}

      {nodeDatum?.faviconUrl && (
        <image
          onClick={(e) => onCollapseNode(nodeDatum, e)}
          href={nodeDatum?.faviconUrl}
          x="-9"
          y="-9"
          height="18px"
          width="18px"
        />
      )}

      <text
        x="35"
        y="-15"
        xmlSpace="preserve"
        fontSize="22"
        letterSpacing={1.2}
        stroke="none"
        fill={nodeDatum?.matchesSearch ? '#DE781F' : darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)'}
        opacity={getOpacity()}
        onClick={(e) => onClickNode(nodeDatum, e)}
      >
        <tspan
          style={{
            fill: nodeDatum?.matchesSearch ? '#DE781F' : darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
          }}
        >
          {nodeDatum.name} {!nodeDatum.enabled ? ' (Disabled)' : ''}
        </tspan>
      </text>

      {nodeDatum?.id && nodeDatum.id !== 'appindex' && (
        <text
          fill={darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)'}
          x="35"
          dy="5"
          strokeWidth="0.4"
          opacity={getOpacity()}
          onClick={(e) => onClickNode(nodeDatum, e)}
        >
          {nodeDatum?.id}
        </text>
      )}
      {getFields().map((field, index) => (
        <text
          key={`${nodeDatum?.id}-field-${index}`}
          fill={darkMode ? field.darkColor : field.color}
          x="35"
          dy={5 + 20 * (index + 1)}
          strokeWidth="0"
          opacity={getOpacity()}
          onClick={(e) => onClickNode(nodeDatum, e)}
        >
          {field.text}
        </text>
      ))}
    </g>
  )
}

const getDependencyHierarchy = (signatures: Signature[]): NodeDatum => {
  const mappedSignatures: NodeDatum[] = signatures.map((signature) => ({
    hierarchyId: signature.id + '-' + getUUID(),
    name: signature.name,
    search: `${signature.id.replace('sphirewall.application.', '')} ${signature.name} ${signature.url}`.toLowerCase(),
    isSubCategory: signature.isSubCategory,
    isCategory: signature.isCategory,
    category: signature.category,
    id: signature.id,
    url: signature.url,
    enabled: signature.enabled,
    noise: signature.noise,
    description: signature.description,
    type: signature.isCategory ? 'Theme' : signature.isSubCategory ? 'Category' : 'Signature',
    faviconUrl: signature.faviconUrl,
    dependencies: signature.dependencies,
    criteria: signature.criteria,
    tags: signature.tags,
    nonExclusiveCriteria: signature.nonExclusiveCriteria,
    children: [],
    collapsedChildren: [],
    focus: signature.focus,
    reporting: signature.reporting,
    hidden: signature.hidden,
  }))

  const populateChildren = (signature: NodeDatum) => {
    let newSignature = {
      ...signature,
    }
    if (newSignature.dependencies.length === 0) {
      return newSignature
    } else {
      newSignature.children = newSignature.dependencies
        .map((dependency) => {
          const foundDependency = mappedSignatures.find((sig) => sig.id === dependency)
          if (!foundDependency) {
            return {
              hierarchyId: dependency + '-' + getUUID(),
              name: `Invalid Dependency ${dependency}`,
              search: dependency.toLowerCase(),
              isSubCategory: false,
              isCategory: false,
              category: null,
              id: dependency,
              url: null,
              enabled: false,
              noise: signature.noise,
              description: signature.description,
              type: 'Signature',
              faviconUrl: null,
              dependencies: [],
              criteria: [],
              nonExclusiveCriteria: [],
              tags: signature.tags,
              invalid: true,
              children: [],
              collapsedChildren: [],
              focus: signature.focus,
              reporting: signature.reporting,
              hidden: signature.hidden,
            }
          }
          return populateChildren(foundDependency)
        })
        .filter(Boolean)
    }
    return newSignature
  }

  let hierarchy = mappedSignatures
    .filter((signature) => {
      const parents = mappedSignatures.filter((sig) => sig.dependencies.includes(signature.id))
      if (parents.length === 0 && signature.dependencies.length !== 0) {
        return true
      }
      return false
    })
    .map((signature) => ({
      ...signature,
      children: signature.dependencies.map((sigId) => {
        const foundSignature = mappedSignatures.find((sig) => sig.id === sigId)
        if (!foundSignature) {
          return {
            hierarchyId: sigId + '-' + getUUID(),
            name: 'Errored Signature',
            search: sigId.toLowerCase(),
            isSubCategory: false,
            isCategory: false,
            category: null,
            id: sigId,
            url: null,
            enabled: false,
            noise: signature.noise,
            description: signature.description,
            type: 'Signature',
            faviconUrl: null,
            dependencies: [],
            criteria: [],
            nonExclusiveCriteria: [],
            invalid: true,
            tags: signature.tags,
            children: [],
            collapsedChildren: [],
            focus: signature.focus,
            reporting: signature.reporting,
            hidden: signature.hidden,
          }
        }
        return populateChildren(foundSignature)
      }),
    }))

  return {
    name: 'Appindex',
    id: 'appindex',
    hierarchyId: 'appindex',
    search: 'appindex',
    children: hierarchy,
    enabled: true,
    isCategory: true,
    isSubCategory: false,
    isMain: true,
    dependencies: [],
    criteria: [],
    tags: [],
    nonExclusiveCriteria: [],
    collapsedChildren: [],
    focus: false,
    reporting: false,
    hidden: false,
  }
}

const getSignatureHierarchy = (signatures: Signature[]): NodeDatum => {
  const mappedSignatures: NodeDatum[] = signatures.map((signature) => ({
    hierarchyId: signature.id + '-' + getUUID(),
    name: signature.name,
    search: `${signature.id.replace('sphirewall.application.', '')} ${signature.name} ${signature.url}`.toLowerCase(),
    isSubCategory: signature.isSubCategory,
    isCategory: signature.isCategory,
    category: signature.category,
    id: signature.id,
    url: signature.url,
    enabled: signature.enabled,
    noise: signature.noise,
    description: signature.description,
    type: signature.isCategory ? 'Theme' : signature.isSubCategory ? 'Category' : 'Signature',
    faviconUrl: signature.faviconUrl,
    dependencies: signature.dependencies,
    tags: signature.tags,
    criteria: signature.criteria,
    nonExclusiveCriteria: signature.nonExclusiveCriteria,
    children: [],
    collapsedChildren: [],
    focus: signature.focus,
    reporting: signature.reporting,
    hidden: signature.hidden,
  }))
  const hierarchy: NodeDatum[] = []
  const notTopLevel = mappedSignatures.filter((signature) => {
    if (!signature.category) {
      hierarchy.push(signature)
      return false
    }
    return true
  })
  const notMidLevel = notTopLevel.filter((signature) => {
    const parentCategory = hierarchy.find((topSig) => topSig.id === signature.category)
    if (parentCategory) {
      parentCategory.children.push(signature)
      return false
    }
    return true
  })
  const uncategorized = notMidLevel.filter((signature) => {
    const parentCategory = hierarchy.find((topSig) => topSig.children.some((child) => child.id === signature.category))
    if (parentCategory) {
      const parentSubCategory = parentCategory.children.find((subChild) => subChild.id === signature.category)
      if (parentSubCategory) {
        parentSubCategory.children.push(signature)
        return false
      }
    }
    return true
  })
  hierarchy.push(...uncategorized.map((uncat) => ({ ...uncat, uncategorized: true })))
  return {
    name: 'Appindex',
    id: 'appindex',
    hierarchyId: 'appindex',
    search: 'appindex',
    children: hierarchy,
    enabled: true,
    isCategory: true,
    isSubCategory: false,
    isMain: true,
    dependencies: [],
    criteria: [],
    nonExclusiveCriteria: [],
    collapsedChildren: [],
    tags: [],
    focus: false,
    reporting: false,
    hidden: false,
  }
}

const toggleHierarchyChildren = (hierarchy: NodeDatum, open: boolean, hierarchyIdExceptions: string[]) => {
  if (!hierarchyIdExceptions) {
    hierarchyIdExceptions = []
  }
  let newHierarchy = { ...hierarchy }
  if (open && newHierarchy.isCollapsed) {
    newHierarchy.children = [
      ...newHierarchy.children.filter((child) => !child?.invisible),
      ...newHierarchy.collapsedChildren,
    ]
    newHierarchy.collapsedChildren = []
    newHierarchy.isCollapsed = false
  } else if (!open && !newHierarchy.isCollapsed && newHierarchy.children.length !== 0) {
    const collapsedChildren: NodeDatum[] = []
    const keptChildren: NodeDatum[] = []
    newHierarchy.children.forEach((child) => {
      if (hierarchyIdExceptions.includes(child.hierarchyId)) {
        keptChildren.push(child)
      } else {
        collapsedChildren.push(child)
      }
    })
    if (collapsedChildren.length !== 0) {
      newHierarchy.collapsedChildren = collapsedChildren.map((subChild) =>
        toggleHierarchyChildren(subChild, open, hierarchyIdExceptions)
      )
      newHierarchy.children = [
        ...keptChildren,
        {
          name: 'Invisible Signature',
          invisible: true,
          totalCollapsedChildren: collapsedChildren.length,
          parentId: newHierarchy.hierarchyId,
          id: 'invis-' + getUUID(),
          hierarchyId: 'invis-' + getUUID(),
          children: [],
          collapsedChildren: [],
          dependencies: [],
          criteria: [],
          nonExclusiveCriteria: [],
          tags: [],
          search: '',
          focus: newHierarchy.focus,
          reporting: false,
          hidden: false,
        },
      ]
      newHierarchy.isCollapsed = true
    }
  }
  return newHierarchy
}

const toggleAllType = (hierarchy: NodeDatum, type: string, open: boolean) => {
  let newHierarchy = { ...hierarchy }
  newHierarchy.children = newHierarchy.children.map((child) => {
    let newChild = child
    if (newChild.type === type) {
      newChild = toggleHierarchyChildren(newChild, open, [])
    } else {
      newChild = toggleAllType(newChild, type, open)
    }
    return newChild
  })
  return newHierarchy
}

const toggleChildren = (hierarchy: NodeDatum, hierarchyId: string) => {
  let newHierarchy = { ...hierarchy }

  if (newHierarchy.hierarchyId === hierarchyId) {
    newHierarchy = toggleHierarchyChildren(newHierarchy, newHierarchy?.isCollapsed || false, [])
    return { hierarchy: newHierarchy, found: true }
  }

  let found = false
  newHierarchy.children = newHierarchy.children.map((child) => {
    if (found) {
      return child
    }
    // If not found already
    let newChild = child
    if (newChild.hierarchyId === hierarchyId) {
      // If signature name matches this child's signature id, hide all it's children
      // and mark as found
      found = true
      newChild = toggleHierarchyChildren(newChild, newChild?.isCollapsed || false, [])
    } else {
      // If signature name does not match this child's signature id, look through it's children and search for it
      const toggleResult = toggleChildren(newChild, hierarchyId)
      if (toggleResult.found) {
        newChild = toggleResult.hierarchy
        found = true
      }
    }
    return newChild
  })
  return { hierarchy: newHierarchy, found }
}

const getQueriedHierarchy = (hierarchy: NodeDatum, search: string, tags: string | null) => {
  let found = false
  let newHierarchy = { ...hierarchy }
  if (tags) {
    if (
      newHierarchy?.search &&
      newHierarchy?.search.includes(search) &&
      ((newHierarchy?.tags && newHierarchy?.tags.includes(tags)) ||
        (newHierarchy?.hasOwnProperty(tags.toLowerCase()) && (newHierarchy as any)[tags.toLowerCase()] === true))
    ) {
      newHierarchy.matchesSearch = true
      found = true
    } else {
      newHierarchy.matchesSearch = false
    }
  } else {
    if (newHierarchy?.search && newHierarchy?.search.includes(search)) {
      newHierarchy.matchesSearch = true
      found = true
    } else {
      newHierarchy.matchesSearch = false
    }
  }

  if (newHierarchy.children.length === 0) {
    return { hierarchy: newHierarchy, found }
  }

  let foundChildIds: string[] = []
  newHierarchy.children = newHierarchy.children.map((child) => {
    let newChild = { ...child }
    const queryResult = getQueriedHierarchy(newChild, search, tags)
    if (queryResult.found) {
      found = true
      foundChildIds.push(newChild.hierarchyId)
    }
    return queryResult.hierarchy
  })
  newHierarchy = toggleHierarchyChildren(newHierarchy, false, foundChildIds)
  return { hierarchy: newHierarchy, found }
}

const searchHierarchy = (hierarchy: NodeDatum, search: string, tag: string | null) => {
  let newHierarchy = { ...hierarchy }
  newHierarchy = getQueriedHierarchy(newHierarchy, search, tag).hierarchy
  return newHierarchy
}

interface UrlCountSignature extends Signature {
  urlsCount?: number
}

const SignatureTree = () => {
  const isMounted = useIsMounted()
  const history = useHistory()
  const params = useParams<{ id?: string }>()
  const [translate, containerRef, setCentered] = useCenteredTree()
  const [darkMode] = useStore('darkMode')
  const [user] = useStore('user')
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(true)
  const [signatures, setSignatures] = useState<UrlCountSignature[]>([])
  const [signatureHierarchy, setSignatureHierarchy] = useState<NodeDatum | null>(null)
  const [dependencyHierarchy, setDependencyHierarchy] = useState<NodeDatum | null>(null)
  const [shownHierarchy, setShownHierarchy] = useState<NodeDatum | null>(null)
  const [popupOpen, setPopupOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<{ getBoundingClientRect: () => any } | null>(null)
  const [activeSignature, setActiveSignature] = useState<UrlCountSignature | null>(null)
  const [selectedTags, setSelectedTags] = useState<string | null>('')

  const [horizontalTree, setHorizontalTree] = useState(true)
  const [dependencyView, setDependencyView] = useState(false)
  const [ranInitialPopulation, setRanInitialPopulation] = useState(false)

  const [collapsedCategories, setCollapsedCategories] = useState(false)
  const [collapsedSubCategories, setCollapsedSubCategories] = useState(false)

  const [createOpen, setCreateOpen] = useState(false)
  const [createSignatureCategory, setCreateSignatureCategory] = useState<UrlCountSignature | null>(null)

  const [signatureDetailsOpen, setSignatureDetailsOpen] = useState(false)

  const vlg = useMediaQuery('(max-height:1090px)')
  const vsm = useMediaQuery('(max-height:980px)')
  const vxs = useMediaQuery('(max-height:840px)')

  const handleOpenSignatureDetails = () => {
    setSignatureDetailsOpen(true)
  }

  const handleCloseSignatureDetails = () => {
    setSignatureDetailsOpen(false)
    handleRefreshHierarchy()
  }

  const signatureFields = useMemo(() => ['Focus', 'Reporting', 'Enabled', 'Noise', 'Hidden'], [])

  const signatureTags = useMemo(() => {
    const tags: string[] = []
    signatures.forEach((signature) => {
      signature?.tags?.forEach((tag) => {
        if (tags.includes(tag)) return
        tags.push(tag)
      })
    })
    tags.push(...signatureFields.map((field) => field))
    return tags
  }, [signatures, signatureFields])

  const handleSubmitSearch = useCallback(
    (phrase: string, showDependencies: boolean, tag: string | null) => {
      let newHierarchy
      if (!signatureHierarchy || !dependencyHierarchy) return
      if (phrase) {
        if (showDependencies) {
          newHierarchy = searchHierarchy(
            dependencyHierarchy,
            phrase.toLowerCase().replace('sphirewall.application.', ''),
            tag
          )
        } else {
          newHierarchy = searchHierarchy(
            signatureHierarchy,
            phrase.toLowerCase().replace('sphirewall.application.', ''),
            tag
          )
        }
      } else {
        if (showDependencies) {
          newHierarchy = searchHierarchy(dependencyHierarchy, '', tag)
        } else {
          newHierarchy = searchHierarchy(signatureHierarchy, '', tag)
        }
      }
      setCentered(1 * Math.random(), 1 * Math.random())
      return setShownHierarchy({ ...newHierarchy })
    },
    [signatureHierarchy, dependencyHierarchy, setCentered]
  )

  const handleClearSearch = () => {
    if (dependencyView) {
      setShownHierarchy(dependencyHierarchy)
    } else {
      setShownHierarchy(signatureHierarchy)
    }
    setSearch('')
    setSelectedTags('')
    if (params.id) {
      history.push('/signature-tree')
    }
  }

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      if (selectedTags) {
        handleSubmitSearch(e.target.value, dependencyView, selectedTags)
      } else {
        if (dependencyView) {
          setShownHierarchy(dependencyHierarchy)
        } else {
          setShownHierarchy(signatureHierarchy)
        }
      }
    }
    setSearch(e.target.value)
    if (params.id) {
      history.push('/signature-tree')
    }
  }

  const handleChangeSelected = (event: SyntheticEvent<Element, Event>, value: string | null) => {
    setSelectedTags(value)
    if (value) {
      handleSubmitSearch(search, dependencyView, value)
    } else {
      if (search) {
        handleSubmitSearch(search, dependencyView, value)
      } else {
        if (dependencyView) {
          setShownHierarchy(dependencyHierarchy)
        } else {
          setShownHierarchy(signatureHierarchy)
        }
      }
      setSelectedTags('')
      if (params.id) {
        history.push('/signature-tree')
      }
    }
  }

  const handleClickDependencyView = () => {
    setCollapsedCategories(false)
    setCollapsedSubCategories(false)
    if (search) {
      handleSubmitSearch(search, !dependencyView, selectedTags)
    } else {
      if (dependencyView) {
        setShownHierarchy(signatureHierarchy)
      } else {
        setShownHierarchy(dependencyHierarchy)
      }
    }
    setDependencyView(!dependencyView)
    setCentered(1 * Math.random(), 1 * Math.random())
  }

  const handleClickTreeOrientation = () => {
    setHorizontalTree(!horizontalTree)
    setCentered(1 * Math.random(), 1 * Math.random())
  }

  const handleClickSubmitSearch = () => {
    if (!search) return
    handleSubmitSearch(search, dependencyView, selectedTags)
  }

  const handleClickNode = useCallback(
    async (data: NodeDatum, e: React.MouseEvent<SVGElement>) => {
      try {
        if (!data?.id) return
        const x = e.clientX
        const y = e.clientY
        const foundSignature = signatures.find((s) => s.id === data.id)
        if (!foundSignature) return

        setActiveSignature(foundSignature)

        const getBoundingClientRect = () => ({
          width: 0,
          height: 0,
          top: y,
          right: x,
          bottom: y,
          left: x,
        })

        setAnchorEl({
          getBoundingClientRect,
        })
        setPopupOpen(true)
        if (foundSignature?.urlsCount) return
        const { count } = await getSignatureURLsCount(data.id)
        if (!isMounted()) return
        setSignatures((sigs) => sigs.map((s) => (s.id !== data.id ? s : { ...s, urlsCount: count })))
      } catch (err) {
        handleError(err)
      }
    },
    [signatures, isMounted]
  )

  const handleToggleSubCategories = () => {
    if (!shownHierarchy) return

    let newHierarchy = shownHierarchy
    if (collapsedCategories && collapsedSubCategories) {
      newHierarchy = toggleAllType(newHierarchy, 'Theme', collapsedCategories)
      newHierarchy = toggleAllType(newHierarchy, 'Category', true)
      setCollapsedCategories(!collapsedCategories)
      setCollapsedSubCategories(!collapsedSubCategories)
    } else {
      newHierarchy = toggleAllType(newHierarchy, 'Category', collapsedSubCategories)
      setCollapsedSubCategories(!collapsedSubCategories)
    }
    setShownHierarchy({ ...newHierarchy })
  }

  const handleToggleCategories = () => {
    if (!shownHierarchy) return

    const newHierarchy = toggleAllType(shownHierarchy, 'Theme', collapsedCategories)
    setCollapsedCategories(!collapsedCategories)
    if (!collapsedCategories) {
      setCollapsedSubCategories(true)
    }
    setShownHierarchy({ ...newHierarchy })
  }

  const handleCollapseNode = (data: NodeDatum) => {
    if (!shownHierarchy) return

    const id = data?.hierarchyId
    if (data.children.length === 0) return
    if (!id) return
    const toggleRes = toggleChildren(shownHierarchy, id)
    setShownHierarchy({ ...toggleRes.hierarchy })
  }

  const handleUncollapseParentNode = (data: NodeDatum) => {
    if (!shownHierarchy) return

    const id = data?.parentId
    if (!id) return
    const toggleRes = toggleChildren(shownHierarchy, id)
    setShownHierarchy({ ...toggleRes.hierarchy })
  }

  const handleNavigateSignatureCategory = (e: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>) => {
    if (!activeSignature) return

    e.preventDefault()
    history.push(`/signature/${activeSignature.category}`)
  }

  const handleCloseCreate = () => {
    setCreateOpen(false)
  }

  const handleOpenCreateChild = () => {
    if (!activeSignature) return

    setCreateOpen(true)
    setCreateSignatureCategory(signatures.find((s) => s.id === activeSignature.id) || null)
  }

  const handleOpenCreateSibling = () => {
    if (!activeSignature) return

    setCreateOpen(true)
    setCreateSignatureCategory(signatures.find((s) => s.id === activeSignature.category) || null)
  }

  const handleRefreshHierarchy = useCallback(async () => {
    try {
      if (!shownHierarchy) return

      setLoading(true)
      const data = await getDetailedSignatures()
      if (!isMounted()) return

      setSignatures(data.signatures)
      const newSignatureHierarchy = getSignatureHierarchy(data.signatures)
      const newDependencyHierarchy = getDependencyHierarchy(data.signatures)
      setSignatureHierarchy(newSignatureHierarchy)
      setDependencyHierarchy(newDependencyHierarchy)

      if (dependencyView) {
      } else {
        const newHierarchy = {
          ...shownHierarchy,
          children: shownHierarchy.children
            .map((existingCat) => {
              const newCat = newSignatureHierarchy.children.find((c) => c.id === existingCat.id)
              if (!newCat) return null

              let catOverride: 'children' | 'collapsedChildren' = 'children'
              if (existingCat.collapsedChildren && !existingCat.children.some((c) => !c.invisible)) {
                // If is collapsed
                catOverride = 'collapsedChildren'
              }
              const newSubcats = existingCat[catOverride]
                .map((existingSubcat) => {
                  const newSubcat = newCat.children.find((c) => c.id === existingSubcat.id)
                  if (!newSubcat) return null

                  let subcatOverride: 'children' | 'collapsedChildren' = 'children'
                  if (existingSubcat.collapsedChildren && !existingSubcat.children.some((c) => !c.invisible)) {
                    // If is collapsed
                    subcatOverride = 'collapsedChildren'
                  }
                  const newSigs = existingSubcat[subcatOverride]
                    .map((existingSig: NodeDatum) => {
                      const newSig = newSubcat.children.find((c) => c.id === existingSig.id)
                      if (!newSig) return null
                      return newSig
                    })
                    .filter((s): s is NodeDatum => Boolean(s))

                  existingSubcat[subcatOverride] = newSigs

                  if (!search) {
                    const updatedSigs = newSubcat.children.filter((cat) => {
                      const foundCat = existingSubcat[subcatOverride].find((c) => c.id === cat.id)
                      return !foundCat
                    })
                    existingSubcat[subcatOverride] = [...existingSubcat[subcatOverride], ...updatedSigs]
                  }

                  existingSubcat.faviconUrl = newSubcat.faviconUrl
                  existingSubcat.name = newSubcat.name
                  existingSubcat.description = newSubcat.description

                  return existingSubcat
                })
                .filter((s): s is NodeDatum => Boolean(s))

              existingCat[catOverride] = newSubcats

              if (!search) {
                const updatedSubcats = newCat.children.filter((cat) => {
                  const foundCat = existingCat[catOverride].find((c) => c.id === cat.id)
                  return !foundCat
                })
                existingCat[catOverride] = [...existingCat[catOverride], ...updatedSubcats]
              }

              existingCat.faviconUrl = newCat.faviconUrl
              existingCat.name = newCat.name
              existingCat.description = newCat.description

              return existingCat
            })
            .filter((s): s is NodeDatum => Boolean(s)),
        }
        if (!search) {
          const updatedCats = newSignatureHierarchy.children.filter((cat) => {
            const foundCat = newHierarchy.children.find((c) => c.id === cat.id)
            return !foundCat
          })
          newHierarchy.children = [...newHierarchy.children, ...updatedCats]
        }
        setShownHierarchy(newHierarchy)
      }
    } catch (err) {
      console.error(err)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }, [isMounted, dependencyView, shownHierarchy, search])

  const getTreeHeight = useCallback(() => {
    return vxs ? '68vh' : vsm ? '78vh' : vlg ? '80vh' : '84vh'
  }, [vlg, vsm, vxs])

  const populateSignatures = useCallback(async () => {
    try {
      setLoading(true)
      const data = await getDetailedSignatures()
      if (!isMounted()) return

      setSignatures(data.signatures)
      setSignatureHierarchy(getSignatureHierarchy(data.signatures))
      setDependencyHierarchy(getDependencyHierarchy(data.signatures))
    } catch (err) {
      handleError(err)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }, [isMounted])

  useEffect(() => {
    // ON mount, populate signatures
    populateSignatures()
  }, [populateSignatures])

  useEffect(() => {
    // ON signature update, populate hierarchy
    if (ranInitialPopulation) return
    if (!signatureHierarchy || !dependencyHierarchy) return
    setRanInitialPopulation(true)
    if (params?.id) {
      setSearch(params.id)
      handleSubmitSearch(params.id, dependencyView, selectedTags)
      return
    }
    if (dependencyView) {
      setShownHierarchy(dependencyHierarchy)
    } else {
      setShownHierarchy(signatureHierarchy)
    }
  }, [
    params,
    signatureHierarchy,
    dependencyHierarchy,
    ranInitialPopulation,
    dependencyView,
    handleSubmitSearch,
    selectedTags,
  ])

  useEffect(() => {
    // ON mount, add listener
    const listener = () => {
      setPopupOpen(false)
    }
    window.addEventListener('pointerdown', listener)
    return () => {
      window.removeEventListener('pointerdown', listener)
    }
  }, [])

  const id = popupOpen ? 'virtual-element-popper' : undefined

  return (
    <>
      <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
      <Grid container padding={2} spacing={2}>
        <Grid item mt={1}>
          <SearchBar
            value={search}
            onChange={handleChangeSearch}
            onSubmit={handleClickSubmitSearch}
            dark={!darkMode}
            button
            disabled={loading}
            onClickButton={handleClickSubmitSearch}
          />
        </Grid>
        <Grid item minWidth={250}>
          <Autocomplete
            options={signatureTags}
            value={selectedTags}
            onChange={handleChangeSelected}
            disabled={loading}
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => <TextField {...params} label="Tags" variant="standard" size="small" />}
          />
        </Grid>
        <Grid item mt={1}>
          <Button variant="contained" disabled={loading || (!search && !selectedTags)} onClick={handleClearSearch}>
            Clear search
          </Button>
        </Grid>
        <Grid item mt={1}>
          <Button variant="contained" disabled={loading} onClick={handleClickTreeOrientation}>
            {horizontalTree ? 'Horizontal' : 'Vertical'}
          </Button>
        </Grid>
        <Grid item mt={1}>
          <Button variant="contained" disabled={loading} onClick={handleClickDependencyView}>
            Switch to {!dependencyView ? 'Dependency View' : 'Category View'}
          </Button>
        </Grid>
        <Grid item mt={1}>
          <Button
            variant="contained"
            disabled={loading || dependencyView || !!search || !!selectedTags}
            onClick={handleToggleCategories}
          >
            {collapsedCategories ? 'Show all Categories' : 'Hide all Categories'}
          </Button>
        </Grid>
        <Grid item mt={1}>
          <Button
            variant="contained"
            disabled={loading || dependencyView || !!search || !!selectedTags} // Fix: Convert `selectedTags` to boolean
            onClick={handleToggleSubCategories}
          >
            {collapsedSubCategories ? 'Show all Signatures' : 'Hide all Signatures'}
          </Button>
        </Grid>
        <Grid item mt={1}>
          <IconButton onClick={handleRefreshHierarchy} disabled={loading}>
            <RefreshIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container padding={2}>
        <Grid item xs={12}>
          <Paper style={{ width: '100%' }} variant="outlined">
            {shownHierarchy && (
              <div id="treeWrapper" style={{ width: '100%', height: getTreeHeight() }} ref={containerRef}>
                <Tree
                  data={shownHierarchy}
                  translate={translate}
                  zoom={0.3}
                  orientation={horizontalTree ? 'horizontal' : 'vertical'}
                  separation={{
                    siblings: horizontalTree ? 0.8 : 2.8,
                    nonSiblings: horizontalTree ? 1.2 : 3.2,
                  }}
                  renderCustomNodeElement={(rd3tProps) =>
                    SignatureTreeItem({
                      ...rd3tProps,
                      // @ts-ignore
                      nodeDatum: rd3tProps.nodeDatum,
                      onClickNode: handleClickNode,
                      onCollapseNode: handleCollapseNode,
                      onUncollapseParent: handleUncollapseParentNode,
                      darkMode,
                    })
                  }
                  pathFunc="diagonal"
                  depthFactor={horizontalTree ? 500 : 400}
                  pathClassFunc={() => (darkMode ? 'tree-link-dark' : 'tree-link')}
                />
              </div>
            )}
          </Paper>
        </Grid>
        <SignatureCreate
          open={createOpen}
          onClose={handleCloseCreate}
          defaultCategory={createSignatureCategory}
          signatures={signatures}
          onSave={handleRefreshHierarchy}
        />
        <Popper id={id} open={popupOpen} anchorEl={anchorEl} transition placement="bottom-start">
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper elevation={2} sx={{ maxWidth: 300, minWidth: 250 }}>
                {activeSignature && (
                  <>
                    <Grid container padding={1} spacing={1} justifyContent="start" wrap="nowrap">
                      <Grid item zeroMinWidth flexGrow={1}>
                        <Grid container wrap="nowrap" justifyContent="start" spacing={1} alignItems="center">
                          <Grid item>
                            <LazyImage src={activeSignature.faviconUrl || ''} size={24} />
                          </Grid>
                          <Grid item zeroMinWidth>
                            <Typography variant="h6" noWrap>
                              {activeSignature.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Open signature details">
                          <IconButton onClick={handleOpenSignatureDetails}>
                            <ExitToAppIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    {activeSignature.description && (
                      <Grid container padding={1} spacing={2} direction="column">
                        <Grid item>
                          <Typography variant="body1">{activeSignature.description}</Typography>
                        </Grid>
                      </Grid>
                    )}
                    {activeSignature.url && (
                      <Grid container direction="column" padding={1}>
                        <Grid item xs={12} zeroMinWidth>
                          <Typography variant="body2" noWrap>
                            Website
                          </Typography>
                          <Link
                            variant="body2"
                            underline="none"
                            href={'https://' + activeSignature.url}
                            rel="noopener"
                            target="_blank"
                          >
                            {activeSignature.url}
                          </Link>
                        </Grid>
                      </Grid>
                    )}
                    {activeSignature.category && (
                      <Grid container direction="column" padding={1}>
                        <Grid item xs={12} zeroMinWidth>
                          <Typography variant="body2" noWrap>
                            Parent
                          </Typography>
                          <Link
                            variant="body2"
                            noWrap
                            underline="none"
                            href="#"
                            onClick={handleNavigateSignatureCategory}
                          >
                            {signatures.find((s) => s.id === activeSignature.category)?.name}
                          </Link>
                        </Grid>
                      </Grid>
                    )}
                    <Grid container>
                      <Grid item xs={6}>
                        <Grid container direction="column" padding={1}>
                          <Grid item xs={6}>
                            <Typography variant="body2">Criteria</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2" noWrap fontWeight="bold">
                              {activeSignature?.criteria?.length}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container direction="column" padding={1}>
                          <Grid item xs={6}>
                            <Typography variant="body2">Non-Exclusive Criteria</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2" noWrap fontWeight="bold">
                              {activeSignature?.nonExclusiveCriteria?.length}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container direction="column" padding={1}>
                          <Grid item xs={6}>
                            <Typography variant="body2">Dependencies</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2" noWrap fontWeight="bold">
                              {activeSignature?.dependencies?.length}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container direction="column" padding={1}>
                          <Grid item xs={6}>
                            <Typography variant="body2">Type</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2" noWrap fontWeight="bold">
                              {activeSignature?.isSubCategory
                                ? 'Category'
                                : activeSignature?.isCategory
                                ? 'Theme'
                                : 'Signature'}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container direction="column" padding={1}>
                          <Grid item xs={6}>
                            <Typography variant="body2">Enabled</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2" noWrap fontWeight="bold">
                              {activeSignature.enabled ? 'Yes' : 'No'}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container direction="column" padding={1}>
                          <Grid item xs={6}>
                            <Typography variant="body2">URLs</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2" noWrap fontWeight="bold">
                              {signatures?.find((s) => s.id === activeSignature.id)?.urlsCount || '...'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {user?.appindexAdmin && (
                      <Grid
                        container
                        padding={1}
                        spacing={1}
                        direction="column"
                        justifyContent="stretch"
                        alignItems="stretch"
                      >
                        {(activeSignature.isSubCategory || activeSignature.isCategory) && (
                          <Grid item>
                            <Button fullWidth variant="contained" onClick={handleOpenCreateChild}>
                              Create Child
                            </Button>
                          </Grid>
                        )}
                        <Grid item>
                          <Button fullWidth variant="contained" onClick={handleOpenCreateSibling}>
                            Create Sibling
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </Paper>
            </Fade>
          )}
        </Popper>
      </Grid>
      <SignatureDetailsDialog
        open={signatureDetailsOpen}
        onClose={handleCloseSignatureDetails}
        signatureId={activeSignature?.id || ''}
        signatureName={activeSignature?.name || ''}
      />
    </>
  )
}

export default SignatureTree
