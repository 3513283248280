import {
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { AppControls, AppListings } from '../util/Api.tsx'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import LazyImage from './LazyImage.tsx'

interface Labels extends AppControls {
  name: string
}

interface AppControlTableProps {
  loading: boolean
  admin: boolean
  apps: AppControls[]
  listings: AppListings[]
  onDelete?: (app: AppControls) => void
  onEdit: (app: AppControls) => void
}

export const AppControlTable = ({
  apps,
  listings,
  loading = false,
  admin = false,
  onDelete = () => {},
  onEdit = () => {},
}: AppControlTableProps) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [orderBy, setOrderBy] = useState<keyof Labels>('id')
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')
  const [shownApps, setShownApps] = useState<AppControls[]>([])

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const handleClickDelete = (e: React.MouseEvent, row: AppControls) => {
    e.preventDefault()
    e.stopPropagation()
    onDelete(row)
  }

  const handleClickEdit = (e: React.MouseEvent, row: AppControls) => {
    e.preventDefault()
    e.stopPropagation()
    onEdit(row)
  }

  const createSortHandler = (id: keyof Labels) => (e: React.MouseEvent) => {
    if (id !== orderBy) {
      setOrder('asc')
      return setOrderBy(id)
    }
    if (order === 'asc') {
      return setOrder('desc')
    }
    if (order === 'desc') {
      return setOrder('asc')
    }
  }

  const getApps = useCallback(() => {
    const sortedApps = shownApps?.sort((a, b) => {
      if (orderBy === 'id') {
        if ((a.id || a.signature_id) > (b.id || b.signature_id)) return order === 'asc' ? 1 : -1
        if ((a.id || a.signature_id) < (b.id || b.signature_id)) return order === 'desc' ? 1 : -1
      } else if (orderBy === 'name') {
        const aname = listings.find((list) => list.id === (a.signature_id || a.id))?.name || ''
        const bname = listings.find((list) => list.id === (b.signature_id || b.id))?.name || ''
        if (aname.toLowerCase() > bname.toLowerCase()) return order === 'asc' ? 1 : -1
        if (aname.toLowerCase() < bname.toLowerCase()) return order === 'desc' ? 1 : -1
      } else if (orderBy === 'modified') {
        const atime = new Date(a[orderBy]).getTime()
        const btime = new Date(b[orderBy]).getTime()
        if (atime > btime) return order === 'asc' ? 1 : -1
        if (atime < btime) return order === 'desc' ? 1 : -1
      } else {
        if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1
        if (a[orderBy] < b[orderBy]) return order === 'desc' ? 1 : -1
      }
      return 0
    })
    const currentApps: any[] = sortedApps.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const length = currentApps.length
    if (currentApps.length === rowsPerPage) return currentApps
    for (let i = 0; i < rowsPerPage - length; i++) {
      currentApps.push('')
    }
    return currentApps
  }, [shownApps, page, rowsPerPage, order, orderBy, listings])

  useEffect(() => {
    setPage(0)
    setShownApps(apps)
  }, [apps])

  return (
    <Paper variant="outlined">
      <TableContainer>
        <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Icon</TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'id'}
                  direction={orderBy === 'id' ? order : 'asc'}
                  onClick={createSortHandler('id')}
                >
                  Category ID
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={createSortHandler('name')}
                >
                  Category Name
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'type'}
                  direction={orderBy === 'type' ? order : 'asc'}
                  onClick={createSortHandler('type')}
                >
                  Platform
                </TableSortLabel>
              </TableCell>

              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'appId'}
                  direction={orderBy === 'appId' ? order : 'asc'}
                  onClick={createSortHandler('appId')}
                >
                  App
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                <TableSortLabel
                  active={orderBy === 'modified'}
                  direction={orderBy === 'modified' ? order : 'asc'}
                  onClick={createSortHandler('modified')}
                >
                  Last Modified
                </TableSortLabel>
              </TableCell>
              {admin && <TableCell align="right">Delete</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {getApps().map((row: AppControls, index) => (
              <TableRow
                hover={Boolean(row)}
                key={`apps-${row || ''}-${index}`}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: !row ? undefined : 'pointer',
                  height: 40,
                  opacity: !row ? 0 : 1,
                }}
              >
                <TableCell component="th" scope="row" align="left">
                  <LazyImage
                    src={listings.find((list) => list.id === (row.id || row.signature_id))?.favicon || ''}
                    size={24}
                  />
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                  {row?.id ? (
                    <Typography variant="body2">{row.id}</Typography>
                  ) : (
                    <Typography variant="body2">
                      {listings.find((list) => list.id === (row.id || row.signature_id))?.id || row.signature_id}
                    </Typography>
                  )}
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                  <Typography variant="body2">
                    {listings.find((list) => list.id === (row.id ? row.id : row.signature_id))?.name}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body2" noWrap>
                    {row.type}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body2" noWrap>
                    {row.appId ? (row.appId.length > 120 ? row.appId.substring(0, 120) + '...' : row.appId) : row.appId}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2" noWrap>
                    {row.modified
                      ? Intl.DateTimeFormat().resolvedOptions().timeZone.substring(0, 7) === 'America'
                        ? Intl.DateTimeFormat('en-US', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                          }).format(new Date(row.modified))
                        : Intl.DateTimeFormat('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                          }).format(new Date(row.modified))
                      : 'No Recorded Date'}
                  </Typography>
                </TableCell>

                {admin && (
                  <TableCell align="right">
                    <IconButton size="small" disabled={loading || !row} onClick={(e) => handleClickEdit(e, row)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton size="small" disabled={loading || !row} onClick={(e) => handleClickDelete(e, row)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={apps.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default AppControlTable
