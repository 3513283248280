import { Dialog, Divider, Grid, Typography } from '@mui/material'
import searchImg from '../assets/search.png'
import confluence from '../assets/confluence.png'
import searchQuery from '../assets/searchquery.png'
import requestChange from '../assets/requestChange.png'
import request from '../assets/request.png'
import signatures from '../assets/signatures.png'
import monitor from '../assets/monitor.png'
import tree from '../assets/tree.png'
import accident from '../assets/daysAccident.png'
import { Box } from '@mui/system'
import useStore from '../util/Store.tsx'
import { useCallback, useEffect, useState } from 'react'
import { getLastOutage } from '../util/Api.tsx'

const AppindexManagerIndex = () => {
  const [darkmode] = useStore('darkMode')
  const [imageOpen, setImageOpen] = useState(false)
  const [imageMaxed, setImageMaxed] = useState<string | undefined>(undefined)
  const [outage, setOutage] = useState<number>(0)
  const [showOutage, setShowOutage] = useState<boolean>(false)

  const handleMaxImage = (e: any | null, img: string | undefined) => {
    setImageOpen(!imageOpen)
    setImageMaxed(img)
  }

  const fetchOutage = useCallback(async () => {
    const data = await getLastOutage()
    if (data.outage) {
      setOutage(Math.floor((new Date().getTime() - parseInt(data.outage)) / 86400000))
      setShowOutage(true)
    }
  }, [])

  useEffect(() => {
    fetchOutage()
  }, [fetchOutage])

  return (
    <div>
      <Grid container justifyContent="center" sx={{ mt: 3 }}>
        <Grid item>
          <Grid container justifyContent="center" alignItems="center" spacing={8} sx={{ mb: 3 }}>
            <Grid item>
              <Grid container justifyContent="center" sx={{ mt: 3 }}>
                <Grid item>
                  <Typography variant="h3">Welcome to Appindex Manager</Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" alignItems="center" padding={1} spacing={2} sx={{ mt: 3, mb: 3 }}>
                <Grid item>
                  <Typography>
                    Manager is for viewing, requesting classifications or reclassifications of websites, categories,
                    keywords, and altering signatures.
                  </Typography>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    padding={1}
                    spacing={2}
                    sx={{ mt: 3, mb: 3 }}
                  >
                    <Grid item>
                      <Typography>
                        This page will provide information on what can be done in Manager and what options are available
                        to you.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {showOutage && (
              <Grid item>
                <Box pt={5} pl={outage > 9 ? (outage > 99 ? 6.75 : 8.5) : 10} position="absolute">
                  <Typography variant="h3" fontWeight="bold" sx={{ color: 'black' }}>
                    {outage}
                  </Typography>
                </Box>
                {<img src={accident} alt="" />}
              </Grid>
            )}
          </Grid>
          <Grid container justifyContent="center" alignItems="center" spacing={2} sx={{ mb: 3 }}>
            <Grid item>
              <Box sx={darkmode ? { bgcolor: 'gray' } : { bgcolor: 'lightblue' }}>
                <Grid container justifyContent="center">
                  <Grid item xs={5}>
                    <Grid
                      container
                      justifyContent="center"
                      alignContent="center"
                      padding={1}
                      spacing={2}
                      sx={{ mt: 3, mb: 3 }}
                    >
                      <Grid item>
                        <Typography variant="h4">Search</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid container padding={3} spacing={3}>
                        <Grid item>
                          <Typography>
                            At the top left of the page is the search box which will allow you to query a website, IP
                            address, Signature, or App Id in Appindex.
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            To submit a search press{' '}
                            <Typography display="inline" component="span" sx={{ fontWeight: 'bold' }}>
                              ENTER
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            The results returned show (in order from top down):
                            <li>The Signature associated with the query</li>
                            <li>
                              Provider ID - the system that classified the query (Guardian, Brightcloud, proxy
                              handlerhandler, or an engineer)
                            </li>
                            <li>Provider Category - the returned result from the provider</li>
                            <li>Added - the date the URL was reclassified</li>
                            <li>Expires - when the URL with be reassesed</li>
                            <li>
                              Downvote - how many times the website has been claimed to be miscategorised by students
                            </li>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>For more information please visit our Wiki</Typography>
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item>
                          <a
                            href="https://familyzone.atlassian.net/wiki/spaces/CAT/pages/2694558613505/Search"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {<img src={confluence} alt="" />}
                          </a>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container justifyContent="center" spacing={5} padding={3} mt={3}>
                      <Grid item>{<img src={searchImg} alt="" width="100%" />}</Grid>
                      <Grid item>
                        {<img src={searchQuery} alt="" width="100%" onClick={(e) => handleMaxImage(e, searchQuery)} />}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Grid container justifyContent="center">
                  <Grid item xs={5}>
                    <Grid
                      container
                      justifyContent="center"
                      alignContent="center"
                      padding={1}
                      spacing={2}
                      sx={{ mt: 3, mb: 3 }}
                    >
                      <Grid item>
                        <Typography variant="h4">Request a Change</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid container padding={3} spacing={3}>
                        <Grid item>
                          <Typography>
                            On the top right of the page is an option to request a change, this change allows you to
                            request:
                            <li>
                              <a
                                href="https://familyzone.atlassian.net/wiki/spaces/CAT/pages/2694403293218/Request+-+Create+Signature"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                A new Signature.
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://familyzone.atlassian.net/wiki/spaces/CAT/pages/2694401655948/Request+-+Edit+Signature"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                An edit for an existing Signature .
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://familyzone.atlassian.net/wiki/spaces/CAT/pages/2694402375733/Request+-+Re-categorise+URL+s"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Recategorisation of a URL.
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://familyzone.atlassian.net/wiki/spaces/CAT/pages/2694403883015/Request+-+Edit+Signature+Applications"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Adding/Removing a Appstore link.
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://familyzone.atlassian.net/wiki/spaces/CAT/pages/2694513918030/Request+-+New+Keyword+s"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                New Keywords.
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://familyzone.atlassian.net/wiki/spaces/CAT/pages/2694514475083/Request+-+Other+Request"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Other requests.
                              </a>
                            </li>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            Once the request has been submitted you can review the request or add messages with
                            additional information through the <a href="/suggestions">Requests</a> page. These requests
                            then get processed by a member of the Categorisation squad will either Accepted or marked as
                            Wont-Do (please review the comment if this is the case), this member can also contact you
                            through the messages functionality in requests.
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            For a live-trace of when your request is actioned go through slack on the Channels{' '}
                            <Typography display="inline" component="span" sx={{ fontWeight: 'bold' }}>
                              #urls
                            </Typography>
                            (for the requests itself) and{' '}
                            <Typography display="inline" component="span" sx={{ fontWeight: 'bold' }}>
                              #appindex_changes
                            </Typography>
                            for each added/recategorised URL.
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>For more information please visit our Wiki</Typography>
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item>
                          <a
                            href="https://familyzone.atlassian.net/wiki/spaces/CAT/pages/2694402670597/Request+Changes"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {<img src={confluence} alt="" />}
                          </a>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container justifyContent="center">
                      <Grid item padding={3}>
                        {<img src={requestChange} alt="" width="100%" />}
                      </Grid>
                      <Grid item>
                        {<img src={request} alt="" width="100%" onClick={(e) => handleMaxImage(e, request)} />}
                      </Grid>
                      <Grid item>
                        <Typography sx={{ typography: 'subtitle2' }}>Example request</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={darkmode ? { bgcolor: 'gray' } : { bgcolor: 'lightblue' }}>
                <Grid container justifyContent="center">
                  <Grid item xs={5}>
                    <Grid
                      container
                      justifyContent="center"
                      alignContent="center"
                      padding={1}
                      spacing={2}
                      sx={{ mt: 3, mb: 3 }}
                    >
                      <Grid item>
                        <Typography variant="h4">Appindex</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid container padding={3} spacing={3}>
                        <Grid item>
                          <Typography>
                            This contains all information used by Schools, these include Signatures, the Tree view (a
                            easier way to digest signatures) and Keywords
                          </Typography>
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item xs={12}>
                          <a href="/signatures">
                            <Typography variant="h6">Signatures</Typography>
                          </a>
                        </Grid>
                        <Grid item>
                          <Typography>
                            This page shows all signatures with the appindex system, these signatures contain some (or
                            all) of the following:
                            <li>Urls - what Websites/IPs will be hit against this signature</li>
                            <li>
                              Dependancies - if a signature is focused these signatures will also be allowed (usually
                              required for a website to function)
                            </li>
                            <li>Criteria - complex rules that may affect what is blocked (e.g. ports)</li>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            These useful information from the signatures themselves include:
                            <li>
                              Parent Category ID - the parent signature which will affect whether the signature is
                              allowed or blocked
                            </li>
                            <li>
                              URL - this is the main URL associated with the signature, if this signature is focused
                              this website is loaded for all students.
                            </li>
                            <li>Classwize Focus - if enabled the signature will show in the focus list in classwize</li>
                            <li>Hidden - if ticked you can ignore as it is NOT shown in ANY system except appindex</li>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>For more information please visit our Wiki</Typography>
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item>
                          <a
                            href="https://familyzone.atlassian.net/wiki/spaces/CAT/pages/2694558418036/Signatures"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {<img src={confluence} alt="" />}
                          </a>
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item>
                          <a href="/signature-tree">
                            <Typography variant="h6">Tree View</Typography>
                          </a>
                        </Grid>
                        <Grid item>
                          <Typography>
                            This page allows you to view all Signatures in a tree format sorted by parent categories,
                            this allows you to easily visualize which Signatures would be affected when
                            allowing/blocking a parent signature.
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            You can also search for specific Signatures here along with Signatures that contain certain
                            tags. You can change to dependency view to identify which Dependancies are required for each
                            Signature.
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>For more information please visit our Wiki</Typography>
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item>
                          <a
                            href="https://familyzone.atlassian.net/wiki/spaces/CAT/pages/2694558418043/Tree+View"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {<img src={confluence} alt="" />}
                          </a>
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item>
                          <a href="/keywords">
                            <Typography variant="h6">Keywords</Typography>
                          </a>
                        </Grid>
                        <Grid item>
                          <Typography>
                            This page shows all keywords associated with red flags. These include:
                            <li>
                              Definitive keywords (if the word is in the student's phrase the phrase is immediately his
                              as a red flag)
                            </li>
                            <li>
                              Keywords - Keywords require a Keyword and a Matcher, these in combination provide a red
                              flag if the keyword involved matches the matcher for example: "I'm having a panic attack"
                              Panic attack is a keyword, while I'm is a matcher marking this as a red flag, however, if
                              I searched "about panic attacks" because about is a exclude this would not be hit, nor
                              would hitting panic attack by itself.
                            </li>
                            <li>
                              Exclude - A word or phrase that if seen will exclude the phrase from being marked as a Red
                              Flag, however only has an effect on Keywords and will be ignored when a Definitive is hit.
                            </li>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            You are also able to query phrases against the system and confirm if those phrases get hit
                            as red flags (ignoring vertex results), you can also query the data squads Vertex AI using
                            this test system.
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>For more information please visit our Wiki</Typography>
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item>
                          <a
                            href="https://familyzone.atlassian.net/wiki/spaces/CAT/pages/2694558418029/Keywords"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {<img src={confluence} alt="" />}
                          </a>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={3} padding={2}>
                    <Grid container justifyContent="center" alignItems="center" padding={3}>
                      <Grid item>
                        <Typography sx={{ typography: 'subtitle2' }}>Signatures</Typography>
                      </Grid>
                      <Grid item xs={12} />
                      <Grid item>
                        {
                          <img
                            src={signatures}
                            alt="Signatures Page"
                            width="350px"
                            height="600"
                            onClick={(e) => handleMaxImage(e, signatures)}
                          />
                        }
                      </Grid>
                      <Grid item xs={12} padding={2} />
                      <Typography sx={{ typography: 'subtitle2' }}>Tree View</Typography>
                      <Grid item xs={12} />
                      <Grid item>
                        {<img src={tree} alt="" onClick={(e) => handleMaxImage(e, tree)} width="350px" height="600" />}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {imageMaxed === monitor ? (
          <Dialog open={imageOpen} onClick={(e) => handleMaxImage(e, undefined)} maxWidth={false}>
            <img src={imageMaxed} alt="" width="2096" height="644" />
          </Dialog>
        ) : (
          <Dialog open={imageOpen} onClick={(e) => handleMaxImage(e, undefined)}>
            <img src={imageMaxed} alt="" />
          </Dialog>
        )}
      </Grid>
    </div>
  )
}

export default AppindexManagerIndex
