import { Alert, Fab, Grid, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import useIsMounted from 'react-is-mounted-hook'

import DeleteConfirmDialog from '../components/DeleteConfirmDialog.tsx'
import SearchBar from '../components/SearchBar.tsx'
import useStore from '../util/Store.tsx'
import AddIcon from '@mui/icons-material/Add'
import {
  addSignatureApp,
  AppControls,
  AppListings,
  deleteControlledApp,
  deleteSignatureApp,
  editApplication,
  editAppListing,
  getAppListings,
  getApps,
  getSignatures,
  putAppListing,
  Signature,
} from '../util/Api.tsx'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import handleError from '../util/Error.tsx'
import AppControlTable from '../components/AppControlTable.tsx'
import AddAppControl, { AddApp } from '../components/AddAppControl.tsx'
import DeleteControlDialog from '../components/DeleteControlDialog.tsx'
import EditControlledApp from '../components/EditControlledApp.tsx'

const Applications = () => {
  const isMounted = useIsMounted()
  const [user] = useStore('user')
  const [darkMode] = useStore('darkMode')
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState('')
  const [createOpen, setCreateOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [error, setError] = useState('')
  const [applications, setApplications] = useState<AppControls[]>([])

  const [shownApplications, setShownApplications] = useState<AppControls[]>([])
  const [appListings, setAppListings] = useState<AppListings[]>([])
  const [activeApp, setActiveApp] = useState<AppControls | null>(null)
  const [deleteControlOpen, setDeleteControlOpen] = useState(false)
  const [editing, setEditing] = useState(false)
  const [editControlDialogOpen, setEditControlDialogOpen] = useState(false)
  const [signatures, setSignatures] = useState<Signature[]>([])

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== '') {
      setShownApplications(
        applications.filter(
          (app) =>
            app?.appId.toLowerCase().includes(e.target.value.toLowerCase()) ||
            app?.id.toLowerCase().includes(e.target.value.toLowerCase()) ||
            app?.type.toLowerCase().includes(e.target.value.toLowerCase()) ||
            app?.signature_id.toLowerCase().includes(e.target.value.toLowerCase()) ||
            appListings
              .find((list) => list.id === (app.id || app.signature_id))
              ?.name.toLowerCase()
              .includes(e.target.value.toLowerCase())
        )
      )
    } else {
      setShownApplications(applications)
    }
    setSearch(e.target.value)
  }

  const handleCreateDialog = () => {
    setActiveApp(null)
    setEditing(false)
    setCreateOpen(true)
  }

  const handleCloseAdd = () => {
    setError('')
    setCreateOpen(false)
    setActiveApp(null)
    setEditing(false)
  }

  const handleCloseDelete = () => {
    setError('')
    setDeleteOpen(false)
    setActiveApp(null)
  }

  const handleOpenDelete = (app: AppControls) => {
    setActiveApp(app)
    setDeleteOpen(true)
  }

  const handleEditOpen = (app: AppControls) => {
    setActiveApp(app)
    setEditing(true)
    setCreateOpen(true)
  }

  const handleEditControlApp = async (favicon: string, id: string, name: string, category: string) => {
    try {
      setLoading(true)
      setError('')
      if (!isMounted()) return
      await editAppListing(id, name, favicon, category)
      setEditControlDialogOpen(false)
      setSearch('')
      populateAll()
    } catch (err) {
      const { msg } = handleError(err)
      if (!isMounted()) return
      setError(msg)
    } finally {
      setLoading(false)
    }
  }

  const handleEditControlAppDialogClose = () => {
    setEditControlDialogOpen(false)
  }

  const handleSaveEdit = async (app: AddApp, oldApp: AppControls) => {
    try {
      setLoading(true)
      setError('')
      if (!isMounted()) return
      if (app.id === 'create-new') {
        if (!app.newId || !app.name) {
          setError('Please include a ID and Name')
          return
        }
        await putAppListing(app.newId, app.name, app.favicon, app.category)
      }
      await editApplication(app, oldApp)
      setCreateOpen(false)
      setSearch('')
      populateAll()
    } catch (err) {
      const { msg } = handleError(err)
      if (!isMounted()) return
      setError(msg)
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async () => {
    try {
      setLoading(true)
      setError('')
      if (!isMounted()) return
      if (!activeApp) return

      await deleteSignatureApp(activeApp.id, activeApp.signature_id, activeApp.appId, activeApp.type)
      setDeleteOpen(false)
      setSearch('')
      populateApps()
    } catch (err) {
      const { msg } = handleError(err)
      if (!isMounted()) return
      setError(msg)
    } finally {
      setLoading(false)
    }
  }

  const handleAdd = async (app: AddApp) => {
    try {
      setLoading(true)
      setError('')
      if (!isMounted()) return
      if (app.id === 'create-new') {
        if (!app.newId || !app.name) {
          setError('Please include a ID and Name')
          return
        }
        await putAppListing(app.newId, app.name, app.favicon, app.category)
        await addSignatureApp(app.newId, '', app.appId, app.type)
      } else if (app.format === 'APP') {
        await addSignatureApp(app.id, '', app.appId, app.type)
      } else if (app.format === 'SIG') {
        await addSignatureApp('', app.id, app.appId, app.type)
      }
      setCreateOpen(false)
      setSearch('')
      populateAll()
    } catch (err) {
      const { msg } = handleError(err)
      if (!isMounted()) return
      setError(msg)
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteControlAppDialogOpen = () => {
    setDeleteControlOpen(true)
  }

  const handleEditControlAppDialogOpen = () => {
    setEditControlDialogOpen(true)
  }

  const handleDeleteControlAppDialogClose = () => {
    setError('')
    setDeleteControlOpen(false)
  }

  const handleDeleteControlApp = async (id: string) => {
    try {
      setLoading(true)
      setError('')
      if (!isMounted()) return
      await deleteControlledApp(id)
      setError('')
      setDeleteControlOpen(false)
      populateAll()
    } catch (err) {
      const { msg } = handleError(err)
      if (!isMounted()) return
      setError(msg)
    } finally {
      setLoading(false)
    }
  }

  const populateApps = useCallback(async () => {
    try {
      if (!isMounted()) return
      const data = await getApps()
      setApplications(data.apps)
      setShownApplications(data.apps)
    } catch (err) {
      const { msg } = handleError(err)
      if (!isMounted()) return
      setError(msg)
    }
  }, [isMounted])

  const populateAppListings = useCallback(async () => {
    try {
      if (!isMounted()) return
      const data = await getAppListings()
      let order = data.apps
      if (!data.apps.find((app) => app.id === 'create-new')) {
        order = [{ name: 'Create New..', id: 'create-new', favicon: '', format: 'APP' }, ...data.apps]
      }
      setAppListings(order)
    } catch (err) {
      const { msg } = handleError(err)
      if (!isMounted()) return
      setError(msg)
    }
  }, [isMounted])

  const populateSignatures = useCallback(async () => {
    try {
      if (!isMounted()) return
      const data = await getSignatures()
      setSignatures(data.signatures)
    } catch (err) {
      const { msg } = handleError(err)
      if (!isMounted()) return
      setError(msg)
    }
  }, [isMounted])

  const populateAll = useCallback(async () => {
    try {
      setLoading(true)
      await Promise.all([populateApps(), populateAppListings(), populateSignatures()])
    } finally {
      setLoading(false)
    }
  }, [populateAppListings, populateApps, populateSignatures])

  useEffect(() => {
    populateAll()
  }, [populateAll])

  return (
    <Grid container justifyContent="center" sx={{ mt: 3 }} padding={3}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={10}>
        <Grid container justifyContent="center" sx={{ mt: 3 }}>
          <Grid item>
            <Typography variant="h3">App ID Controls</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" spacing={2} sx={{ mt: 3, mb: 3 }}>
          {user?.appindexAdmin && (
            <Grid item>
              <Fab color="primary" size="small" disabled={loading} onClick={handleCreateDialog}>
                <AddIcon />
              </Fab>
            </Grid>
          )}
          <Grid item>
            <SearchBar value={search} dark={!darkMode} onChange={handleChangeSearch} button />
          </Grid>
        </Grid>
        {user?.appindexAdmin && (
          <Grid container justifyContent="center" alignItems="center" spacing={1} sx={{ mt: 3, mb: 3 }}>
            <Grid item>
              <Typography>Controlled Apps:</Typography>
            </Grid>
            <Grid item>
              <Fab color="primary" size="small" disabled={loading} onClick={handleEditControlAppDialogOpen}>
                <EditIcon />
              </Fab>
            </Grid>
            <Grid item>
              <Fab color="error" size="small" disabled={loading} onClick={handleDeleteControlAppDialogOpen}>
                <DeleteIcon />
              </Fab>
            </Grid>
          </Grid>
        )}
        <Grid container justifyContent="center">
          {error && (
            <Grid item>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
        </Grid>
        <Grid container justifyContent="start" spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <AppControlTable
              loading={loading}
              admin={user?.appindexAdmin}
              apps={shownApplications}
              listings={appListings}
              onDelete={handleOpenDelete}
              onEdit={handleEditOpen}
            />
          </Grid>
        </Grid>
        <EditControlledApp
          open={editControlDialogOpen}
          onClose={handleEditControlAppDialogClose}
          loading={loading}
          signatures={signatures}
          error={error}
          listings={appListings.filter((list) => list.format === 'APP' && !list.id.includes('create-new'))}
          onSave={handleEditControlApp}
        />
        <AddAppControl
          open={createOpen}
          loading={loading}
          listings={appListings}
          app={activeApp}
          error={error}
          signatures={signatures}
          onClose={handleCloseAdd}
          onClickAdd={handleAdd}
          edit={editing}
          onClickEdit={handleSaveEdit}
        />
        <DeleteControlDialog
          error={error}
          open={deleteControlOpen}
          listings={appListings.filter((list) => list.format === 'APP' && !list.id.includes('create-new'))}
          onClose={handleDeleteControlAppDialogClose}
          onDelete={handleDeleteControlApp}
          loading={loading}
          apps={applications}
        />

        <DeleteConfirmDialog
          loading={loading}
          open={deleteOpen}
          name={`this App ID`}
          onClose={handleCloseDelete}
          onDelete={handleDelete}
        />
      </Grid>
    </Grid>
  )
}

export default Applications
