import React, { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Grid } from '@mui/material'

interface KeywordSelectionOption {
  label: string
}

const KeywordOption = ({ label = '', ...props }: KeywordSelectionOption) => {
  return (
    <Box
      component="li"
      sx={{
        '& > img': { mr: 2, flexShrink: 0 },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      {...props}
    >
      <Typography>{label}</Typography>
    </Box>
  )
}

const options = ['Definitive', 'Keyword', 'Matcher', 'Exclude']

interface KeywordTypeSelectProps {
  id?: string
  error?: string
  selectedType?: string | null
  label?: string
  onChange?: (option: string | null) => void
}

const KeywordTypeSelect = ({
  id = '',
  error = '',
  label = '',
  selectedType = null,
  onChange = () => {},
}: KeywordTypeSelectProps) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null)

  const handleChangeSelected = (e: React.SyntheticEvent, newValue: string | null) => {
    setSelectedOption(newValue)
    if (!newValue) {
      onChange('')
    } else {
      onChange(newValue)
    }
  }

  useEffect(() => {
    if (!selectedType) {
      setSelectedOption(null)
      return
    }
    setSelectedOption(options.find((opt) => opt.toLowerCase() === selectedType) || null)
  }, [selectedType])

  const getKeywordText = (type: string | null | undefined) => {
    switch (type) {
      case 'definitive':
        return "A word or phrase is matched with this definition, it will immediately be marked. Doesn't include the Excluded ruleset"
      case 'keyword':
        return `Works in conjunction with MATCHER to match phrases and mark them as this ruleset`
      case 'matcher':
        return 'Works in conjunction with KEYWORDS to match phrases and mark them as this ruleset'
      case 'exclude':
        return 'Exclude a word from the KEYWORDS + MATCHER ruleset. Definitive phrases are not affected'
      default:
        return ''
    }
  }

  const inputLabel = label || 'Select an option for more details'

  return (
    <Grid container>
      <Grid item xs={12}>
        <Autocomplete
          id={id}
          options={options}
          onChange={handleChangeSelected}
          value={selectedOption}
          getOptionLabel={(option) => option}
          renderOption={(props, option) => <KeywordOption label={option} {...props} />}
          renderInput={(params) => (
            <TextField
              {...params}
              label={inputLabel}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off', // disable autocomplete and autofill
              }}
              error={Boolean(error)}
              helperText={error}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} margin={2}>
        {getKeywordText(selectedOption?.toLowerCase())}
      </Grid>
    </Grid>
  )
}

export default KeywordTypeSelect
