import React from 'react'

const Reports = () => {
  return (
    <div style={{ width: '100%', height: '80vh' }}>
      <iframe
        title="Reports"
        src="https://datastudio.google.com/embed/reporting/7c707dcd-59ea-4f61-81db-ce5c166ef8d1/page/p_lwpgp02vnc"
        frameBorder="0"
        style={{ border: 0, width: '100%', height: '100%' }}
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default Reports
