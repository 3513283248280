import React, { MouseEvent, useCallback } from 'react'

import Avatar from '@mui/material/Avatar'
import * as colors from '@mui/material/colors'
import { styled } from '@mui/material/styles'
import Badge from '@mui/material/Badge'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#2e2e2e',
    color: '#fff',
    fontWeight: 'bold',
  },
}))

interface AvatarLettersProps {
  email: string
  badgeContent?: any
  sx?: any
  ref?: any
  onClick?: (e: MouseEvent<HTMLSpanElement>) => void
}

const AvatarLetters = ({ email, badgeContent, sx, ref, onClick = () => {} }: AvatarLettersProps) => {
  const handleClick = (e: MouseEvent<HTMLSpanElement>) => {
    onClick(e)
  }

  const getFirstLetter = useCallback(() => {
    // Grab first character
    return email?.[0]?.toUpperCase()
  }, [email])

  const getSecondLetter = useCallback(() => {
    // Grab first letter in the word after the '.' or second letter in the email
    return email.split('@')[0].split('.')?.[1]?.[0]?.toUpperCase?.() || email?.[1]?.toUpperCase()
  }, [email])

  const getColor = useCallback(() => {
    let color
    const letter = getFirstLetter()?.toLowerCase()
    switch (letter) {
      case 'a':
        color = colors.deepOrange[500]
        break
      case 'b':
        color = colors.orange[500]
        break
      case 'c':
        color = colors.amber[500]
        break
      case 'd':
        color = colors.yellow[500]
        break
      case 'e':
        color = colors.deepPurple[500]
        break
      case 'f':
        color = colors.lightGreen[500]
        break
      case 'g':
        color = colors.green[500]
        break
      case 'h':
        color = colors.teal[500]
        break
      case 'i':
        color = colors.cyan[500]
        break
      case 'j':
        color = colors.lightBlue[500]
        break
      case 'k':
        color = colors.blue[500]
        break
      case 'l':
        color = colors.indigo[500]
        break
      case 'm':
        color = colors.lime[500]
        break
      case 'n':
        color = colors.purple[500]
        break
      case 'o':
        color = colors.pink[500]
        break
      case 'p':
        color = colors.red[500]
        break
      case 'q':
        color = colors.deepOrange[500]
        break
      case 'r':
        color = colors.orange[500]
        break
      case 's':
        color = colors.amber[500]
        break
      case 't':
        color = colors.yellow[500]
        break
      case 'u':
        color = colors.lime[500]
        break
      case 'v':
        color = colors.lightGreen[500]
        break
      case 'w':
        color = colors.green[500]
        break
      case 'x':
        color = colors.teal[500]
        break
      case 'y':
        color = colors.cyan[500]
        break
      case 'z':
        color = colors.lightBlue[500]
        break
      default:
        color = colors.lightBlue[500]
    }
    return color
  }, [getFirstLetter])

  return (
    <StyledBadge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={badgeContent}
      sx={{ mr: 1, cursor: 'pointer', ...sx }}
      onClick={handleClick}
    >
      <Avatar alt={email} sx={{ bgcolor: getColor() }} ref={ref}>
        {getFirstLetter() || 'A'}
        {getSecondLetter() || ''}
      </Avatar>
    </StyledBadge>
  )
}

export default AvatarLetters
