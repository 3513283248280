import { Alert, Grid, Link } from '@mui/material'
import { useEffect, useState } from 'react'
import { getBlockmanUrl } from '../util/Api.tsx'

const Blockman = () => {
  const [blockmanUrl, setBlockmanUrl] = useState('')
  const [error, setError] = useState('')

  const populateBlockmanUrl = async () => {
    try {
      // Gets the correct Blockman URL from upstream environment variables
      const data = await getBlockmanUrl()
      if (data.msg) {
        setError(data.msg)
        return
      }
      setBlockmanUrl(data.url)
    } catch (error) {
      console.error('Failed to get Blockman URL:', error)
    }
  }

  useEffect(() => {
    populateBlockmanUrl()
  }, [])

  return (
    <Grid container justifyContent="center" pt={4}>
      {error && (
        <Grid item>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <iframe
          src={'http://' + blockmanUrl}
          title="Blockman"
          width="100%"
          style={{ border: 'none', overflow: 'hidden', minHeight: '1000px', height: '95%' }}
        />
      </Grid>
      <Grid>
        If the following frame does not work click{' '}
        <Link href={'http://' + blockmanUrl} target="_blank" rel="noopener noreferrer">
          here
        </Link>
      </Grid>
    </Grid>
  )
}

export default Blockman
