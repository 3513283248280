import React, { useState, useEffect, useCallback } from 'react'
import useIsMounted from 'react-is-mounted-hook'

import { useTheme } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import LoadingButton from '@mui/lab/LoadingButton'
import handleError from '../util/Error.tsx'
import { getAvaliableVersions, rollbackSignatures, versions } from '../util/Api.tsx'
import ConfirmDialog from './ConfirmDialog.tsx'
import { Autocomplete, TextField } from '@mui/material'

interface RollbackDialogProps {
  open?: boolean
  onClose?: () => void
}

const RollbackDialog = ({ open = false, onClose = () => {} }: RollbackDialogProps) => {
  const theme = useTheme()
  const isMounted = useIsMounted()

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const [versionId, setVersionID] = useState<number>(0)
  const [avaliableVersions, setAvaliableVersions] = useState<versions[]>([])
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [message, setMessage] = useState('')

  const handleClose = () => {
    if (loading) return
    setError('')
    setMessage('')
    setVersionID(0)
    onClose()
  }

  const populateVersion = useCallback(async () => {
    try {
      if (!open) return
      setLoading(true)
      const data = await getAvaliableVersions()
      if (!isMounted()) return
      setAvaliableVersions(data.versions)
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }, [isMounted, open])

  useEffect(() => {
    populateVersion()
  }, [populateVersion])

  const handleConfirmOpen = () => {
    setConfirmOpen(true)
  }

  const handleConfirmClose = () => {
    setConfirmOpen(false)
  }

  const handleConfirm = async () => {
    try {
      setLoading(true)
      setConfirmOpen(false)
      setError('')
      setMessage('')
      const { msg } = await rollbackSignatures(versionId)
      setMessage(msg)
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  useEffect(() => {
    if (open) return
    setError('')
  }, [open])

  return (
    <Dialog open={open} onClose={handleClose}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                Rollback Signatures
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Collapse in={Boolean(error)}>
            <Box>
              <Grid container padding={2} justifyContent="center">
                <Grid item xs={12}>
                  <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
                    {error || ' '}
                  </Alert>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Collapse in={Boolean(message)}>
            <Box>
              <Grid container padding={2} justifyContent="center">
                <Grid item xs={12}>
                  {message.includes('Rollback successful') ? (
                    <Alert severity="success" variant="outlined" sx={{ mb: 2 }}>
                      {message}
                    </Alert>
                  ) : (
                    <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
                      {message || ' '}
                    </Alert>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Grid container padding={2} spacing={2} justifyContent="center">
            <Grid item>
              <Typography>
                A Rollback will revert the current version of the Signatures to the selected version. This will generate
                a new version containing data from the selected version. Once started this cannot be undone. Please
                check # appindex_changes for information on the rollback as it is running.
              </Typography>
            </Grid>
            <Grid minWidth={300} padding={2}>
              <Autocomplete
                id="version"
                options={avaliableVersions}
                value={avaliableVersions.find((v) => v.versionId === versionId) || null}
                getOptionLabel={(option) =>
                  `${option.versionId} - ${
                    Intl.DateTimeFormat().resolvedOptions().timeZone.substring(0, 7) === 'America'
                      ? Intl.DateTimeFormat('en-US', {
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                        }).format(new Date(option.creationDate))
                      : Intl.DateTimeFormat('en-GB', {
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                        }).format(new Date(option.creationDate))
                  }`
                }
                onChange={(e, value) => {
                  setVersionID(value?.versionId || 0)
                  setError('')
                }}
                disabled={loading}
                renderInput={(params) => <TextField {...params} label="Select Version" />}
              />
            </Grid>
            <Grid item>
              <Typography>
                Please wait 10 minutes after the initiated time to ensure the Rollback has been completed.
              </Typography>
            </Grid>
            <Grid item>
              <LoadingButton variant="contained" loading={loading} onClick={handleConfirmOpen}>
                Rollback
              </LoadingButton>
            </Grid>
          </Grid>
        </div>
      </div>
      <ConfirmDialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        onConfirm={handleConfirm}
        disabled={loading}
        title="Confirm Rollback"
        text={`Are you sure you want to rollback to the selected version?`}
      />
    </Dialog>
  )
}

export default RollbackDialog
