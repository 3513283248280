import React, { useState, useEffect, useCallback } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'
import TableSortLabel from '@mui/material/TableSortLabel'
import Fab from '@mui/material/Fab'

import SearchBar from './SearchBar.tsx'

interface DependencyTableProps {
  dependencies?: string[]
  loading?: boolean
  admin?: boolean
  onClick?: (dependency: string) => void
  onDelete?: (dependency: string) => void
  onAdd?: () => void
}

const DependencyTable = ({
  dependencies = [],
  loading = false,
  admin = false,
  onClick = () => {},
  onDelete = () => {},
  onAdd = () => {},
}: DependencyTableProps) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')
  const [search, setSearch] = useState('')
  const [shownDependencies, setShownDependencies] = useState<string[]>([])

  const getDependencies = useCallback(() => {
    const sortedDependencies = shownDependencies.sort((a, b) => {
      if (a > b) return order === 'asc' ? 1 : -1
      if (a < b) return order === 'desc' ? 1 : -1
      return 0
    })
    const currentDependencies = sortedDependencies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const length = currentDependencies.length
    if (currentDependencies.length === rowsPerPage) return currentDependencies
    for (let i = 0; i < rowsPerPage - length; i++) {
      currentDependencies.push('')
    }
    return currentDependencies
  }, [shownDependencies, page, rowsPerPage, order])

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    setShownDependencies(dependencies.filter((dep) => dep.includes(e.target.value)))
    setPage(0)
  }

  const handleClickAdd = () => {
    onAdd()
  }

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const handleClickDelete = (e: React.MouseEvent, dependency: string) => {
    e.preventDefault()
    e.stopPropagation()
    onDelete(dependency)
  }

  const handleToggleSort = (e: React.SyntheticEvent) => {
    if (order === 'asc') {
      return setOrder('desc')
    }
    if (order === 'desc') {
      return setOrder('asc')
    }
  }

  useEffect(() => {
    setPage(0)
    setShownDependencies(dependencies)
    setSearch('')
  }, [dependencies])

  return (
    <Paper variant="outlined">
      <Grid container justifyContent="center" alignItems="center" spacing={2} padding={2}>
        {admin && (
          <Grid item>
            <Fab size="small" color="primary" onClick={handleClickAdd}>
              <AddIcon />
            </Fab>
          </Grid>
        )}
        <Grid item>
          <SearchBar dark value={search} onChange={handleChangeSearch} />
        </Grid>
      </Grid>
      <TableContainer>
        <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <TableSortLabel active={true} direction={order} onClick={handleToggleSort}>
                  Signature ID
                </TableSortLabel>
              </TableCell>
              {admin && <TableCell align="right">Delete</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {getDependencies().map((row, index) => (
              <TableRow
                hover={Boolean(row)}
                key={`dependency-${row || ''}-${index}`}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: !row ? undefined : 'pointer',
                  height: 40,
                  opacity: !row ? 0 : 1,
                }}
                onClick={row ? () => onClick(row) : () => {}}
              >
                <TableCell component="th" scope="row" align="left">
                  <Typography variant="body2" noWrap>
                    {row}
                  </Typography>
                </TableCell>
                {admin && (
                  <TableCell align="right">
                    <IconButton size="small" disabled={loading || !row} onClick={(e) => handleClickDelete(e, row)}>
                      <CloseIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={dependencies.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default DependencyTable
