import React, { useState } from 'react'

import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Autocomplete from '@mui/material/Autocomplete'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'

import { useTheme } from '@mui/material/styles'
import { PlatformOption, platforms } from '../util/Api.tsx'
import { Link, Tooltip } from '@mui/material'

interface AddApplicationDialogProps {
  open?: boolean
  loading?: boolean
  error: string
  onClickAdd: (id: string, type: string) => void
  onClose: () => void
}

const AddApplicationDialog = ({
  open = false,
  loading = false,
  error = '',
  onClickAdd = () => {},
  onClose = () => {},
}: AddApplicationDialogProps) => {
  const theme = useTheme()
  const [id, setId] = useState('')
  const [type, setType] = useState('')
  const [platform, setPlatform] = useState<PlatformOption | null>(null)
  const [validated, setValidated] = useState(false)
  const [validateRegex, setValidateRegex] = useState('')
  const [appError, setAppError] = useState('')

  const handleChangeAppId = (e: any) => {
    setAppError('')
    if (platform?.id.startsWith('windows')) {
      try {
        new RegExp(e.target.value)
      } catch (err: any) {
        setAppError(err.message)
        setId(e.target.value)
        setValidated(false)
        return
      }
    }
    try {
      if (validateRegex.length > 0) {
        if (new RegExp(e.target.value, 'i').test(validateRegex)) {
          setValidated(true)
        } else {
          setValidated(false)
        }
      }
    } catch (err) {
      return
    }
    setId(e.target.value)
  }

  const handleChangeValidateRegex = (e: any) => {
    setAppError('')
    try {
      if (new RegExp(id, 'i').test(e.target.value)) {
        setValidated(true)
      } else {
        setValidated(false)
      }
    } catch (err: any) {
      setValidateRegex(e.target.value)
      return
    }
    setValidateRegex(e.target.value)
  }

  const handleChangeType = (newValue: PlatformOption | null) => {
    setPlatform(newValue)
    setType(newValue?.id || '')
  }

  const handleClickAdd = () => {
    if (!id || !type) return
    onClickAdd(id, type)
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                Add a Application
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          {error && (
            <Grid container justifyContent="center" sx={{ mb: 3 }}>
              <Grid item>
                <Alert severity="error">{error}</Alert>
              </Grid>
            </Grid>
          )}
          <Grid container direction="column" alignItems="stretch" justifyContent="center" padding={2} spacing={3}>
            <Grid item>
              <Typography variant="body1" noWrap>
                All entries for Windows for RegEx{' '}
                <Link
                  href="https://familyzone.atlassian.net/wiki/spaces/CAT/pages/2694619791375/Request+-+Create+Edit+Applications+Windows+Only#Getting-Application-Details"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  format
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Autocomplete
                id="app_platform"
                sx={{ minWidth: 350 }}
                options={platforms}
                renderInput={(params) => <TextField {...params} fullWidth label="Platform" />}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={platform}
                onChange={(e, newValue) => handleChangeType(newValue)}
              />
            </Grid>
            <Grid item>
              <TextField
                id="appId"
                variant="outlined"
                label="Application ID"
                fullWidth
                onChange={handleChangeAppId}
                helperText={appError}
              />
            </Grid>
            {type.startsWith('windows') && (
              <Grid item>
                <Grid container alignItems="center" justifyContent="start">
                  <Grid item xs={11}>
                    <TextField
                      id="testRegex"
                      label="Test RegEx"
                      variant="outlined"
                      sx={{ minWidth: '300px' }}
                      value={validateRegex}
                      onChange={handleChangeValidateRegex}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    {validated ? (
                      <Tooltip title="Regex matches" placement="top">
                        <CheckIcon color="primary" fontSize="large" />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Regex does not match" placement="top">
                        <CloseIcon color="error" fontSize="large" />
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item>
              <Button variant="contained" fullWidth disabled={loading || !id || !type} onClick={handleClickAdd}>
                Add
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}

export default AddApplicationDialog
