import React, { useState, useEffect, useCallback } from 'react'
import useIsMounted from 'react-is-mounted-hook'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Fab from '@mui/material/Fab'
import Alert from '@mui/material/Alert'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import AddIcon from '@mui/icons-material/Add'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'

import KeywordsTable from '../components/KeywordsTable.tsx'
import KeywordEntriesTable from '../components/KeywordEntriesTable.tsx'
import SearchBar from '../components/SearchBar.tsx'
import DeleteConfirmDialog from '../components/DeleteConfirmDialog.tsx'
import KeywordCreateDialog from '../components/KeywordCreateDialog.tsx'
import KeywordTestDialog from '../components/KeywordTestDialog.tsx'
import KeywordEntryCreateDialog from '../components/KeywordEntryCreateDialog.tsx'
import KeywordEditDialog from '../components/KeywordEditDialog.tsx'

import {
  getKeywords,
  getKeywordEntries,
  deleteKeyword,
  deleteKeywordEntry,
  editKeywords,
  Keyword,
  KeywordEntry,
} from '../util/Api.tsx'
import handleError from '../util/Error.tsx'
import useStore from '../util/Store.tsx'

const Keywords = () => {
  const isMounted = useIsMounted()
  const [user] = useStore('user')
  const [darkMode] = useStore('darkMode')
  const [keywords, setKeywords] = useState<Keyword[]>([])
  const [shownKeywords, setShownKeywords] = useState<Keyword[]>([])
  const [keywordEntries, setKeywordEntries] = useState<KeywordEntry[]>([])
  const [selectedKeywordCategory, setSelectedKeywordCategory] = useState<'nondeprecated' | 'deprecated'>(
    'nondeprecated'
  )
  const [selectedKeyword, setSelectedKeyword] = useState<string | null>(null)
  const [selectedCategory, setSelectedCategory] = useState<'exclude' | 'definitive' | 'keyword' | 'matcher' | 'all'>(
    'all'
  )
  const [shownKeywordEntries, setShownKeywordEntries] = useState<KeywordEntry[]>([])
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState('')
  const [keywordCreateOpen, setKeywordCreateOpen] = useState(false)
  const [keywordTestOpen, setKeywordTestOpen] = useState(false)
  const [entryCreateOpen, setEntryCreateOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteName, setDeleteName] = useState('')
  const [activeKeyword, setActiveKeyword] = useState<Keyword | null>(null)
  const [activeEntry, setActiveEntry] = useState<KeywordEntry | null>(null)
  const [error, setError] = useState('')
  const [editOpen, setEditOpen] = useState(false)
  const [alteredKeyword, setAlteredKeyword] = useState<Keyword | null>(null)

  const handleDeleteClose = () => {
    setDeleteOpen(false)
    setActiveKeyword(null)
    setActiveEntry(null)
  }

  const handleOpenDeleteKeyword = (keyword: Keyword) => {
    setActiveKeyword(keyword)
    setDeleteOpen(true)
    setDeleteName(`"${keyword.id}"`)
  }

  const handleClickEdit = (keyword?: Keyword) => {
    if (!keyword) {
      setError('')
    } else if (!editOpen) {
      setError('')
      setAlteredKeyword(keywords.find((e) => e.id === keyword.id) || null)
    }
    setEditOpen(!editOpen)
  }

  const handleOpenDeleteEntry = (entry: KeywordEntry) => {
    setActiveEntry(entry)
    setDeleteOpen(true)
    setDeleteName(`"${entry.value}"`)
  }

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    if (e.target.value === '') {
      if (selectedCategory === 'all') {
        setShownKeywordEntries(keywordEntries.filter((entry) => entry.id === selectedKeyword))
        return
      }
      setShownKeywordEntries(
        keywordEntries.filter((entry) => entry.id === selectedKeyword && entry.type === selectedCategory)
      )
    }
  }

  const handleSubmitSearch = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    if (selectedCategory === 'all') {
      setShownKeywordEntries(
        keywordEntries.filter(
          (entry) => entry.id === selectedKeyword && entry.value.toLowerCase().includes(search.toLowerCase())
        )
      )
      return
    }
    setShownKeywordEntries(
      keywordEntries.filter(
        (entry) =>
          entry.id === selectedKeyword &&
          entry.type === selectedCategory &&
          entry.value.toLowerCase().includes(search.toLowerCase())
      )
    )
  }

  const handleReloadKeywords = (newKeywords: Keyword[]) => {
    setKeywords(newKeywords)
    if (selectedKeywordCategory === 'nondeprecated') {
      setShownKeywords(newKeywords.filter((keyword) => !keyword.deprecated))
      return
    }
    setShownKeywords(newKeywords.filter((keyword) => keyword.deprecated))
  }

  const handleReloadKeywordEntries = (newEntries: KeywordEntry[]) => {
    setKeywordEntries(newEntries)
    if (selectedCategory === 'all') {
      setShownKeywordEntries(
        newEntries.filter((entry) => entry.id === selectedKeyword && entry.value.includes(search.toLowerCase()))
      )
      return
    }
    setShownKeywordEntries(
      newEntries.filter(
        (entry) =>
          entry.id === selectedKeyword && entry.type === selectedCategory && entry.value.includes(search.toLowerCase())
      )
    )
  }

  const handleCreateKeyword = async (newKeywords: Keyword[]) => {
    handleReloadKeywords(newKeywords)
    populateKeywordEntries()
  }

  const handleCreateEntry = async (newEntries: KeywordEntry[]) => {
    handleReloadKeywordEntries(newEntries)
  }

  const handleDelete = async () => {
    try {
      setLoading(true)

      if (activeKeyword) {
        const { keywords: newKeywords } = await deleteKeyword({
          id: activeKeyword.id,
        })
        if (!isMounted()) return
        handleReloadKeywords(newKeywords)
      } else if (activeEntry) {
        const { keywordEntries: newKeywordEntries } = await deleteKeywordEntry({
          keywordId: activeEntry.id,
          type: activeEntry.type,
          value: activeEntry.value,
        })
        if (!isMounted()) return
        handleReloadKeywordEntries(newKeywordEntries)
      }
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
      setDeleteOpen(false)
    }
  }

  const handleEditKeyword = (keyword: Keyword) => {
    setAlteredKeyword({ ...keyword })
  }

  const handleConfirmEdit = async () => {
    try {
      if (!alteredKeyword) return
      await editKeywords({
        id: alteredKeyword.id,
        name: alteredKeyword.name,
        description: alteredKeyword.description,
        faviconDataUrl: alteredKeyword.faviconUrl || null,
        deprecated: alteredKeyword.deprecated,
      })
      setEditOpen(false)
      setAlteredKeyword(null)
      populateKeywords()
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    }
  }

  const populateKeywordEntries = useCallback(async () => {
    try {
      setLoading(true)
      const data = await getKeywordEntries()
      if (!isMounted()) return

      setKeywordEntries(data.keywordEntries)
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      setLoading(false)
    }
  }, [isMounted])

  const populateKeywords = useCallback(async () => {
    try {
      const data = await getKeywords()
      if (!isMounted()) return

      setKeywords(data.keywords)
      setShownKeywords(data.keywords.filter((keyword) => !keyword.deprecated))
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    }
  }, [isMounted])

  const handleClickKeyword = async (id: string) => {
    if (keywordEntries.length === 0) return
    setSelectedKeyword(id)
    if (selectedCategory === 'all') {
      setShownKeywordEntries(
        keywordEntries.filter((entry) => entry.id === id && entry.value.includes(search.toLowerCase()))
      )
      return
    }
    setShownKeywordEntries(
      keywordEntries.filter(
        (entry) => entry.id === id && entry.type === selectedCategory && entry.value.includes(search.toLowerCase())
      )
    )
  }

  const handleOpenKeywordCreate = () => {
    setKeywordCreateOpen(true)
  }

  const handleCloseKeywordCreate = () => {
    setKeywordCreateOpen(false)
  }

  const handleOpenKeywordTest = () => {
    setKeywordTestOpen(true)
  }

  const handleCloseKeywordTest = () => {
    setKeywordTestOpen(false)
  }

  const handleOpenEntryCreate = () => {
    setEntryCreateOpen(true)
  }

  const handleCloseEntryCreate = () => {
    setEntryCreateOpen(false)
  }

  const handleChangeCategory = (
    e: React.MouseEvent<HTMLElement>,
    newValue: 'exclude' | 'definitive' | 'keyword' | 'matcher' | 'all'
  ) => {
    if (!newValue) return
    setSelectedCategory(newValue)
    if (newValue === 'all') {
      setShownKeywordEntries(
        keywordEntries.filter((entry) => entry.id === selectedKeyword && entry.value.includes(search.toLowerCase()))
      )
      return
    }
    setShownKeywordEntries(
      keywordEntries.filter(
        (entry) => entry.id === selectedKeyword && entry.type === newValue && entry.value.includes(search.toLowerCase())
      )
    )
  }

  const handleChangeKeywordCategory = (e: React.MouseEvent<HTMLElement>, newValue: 'nondeprecated' | 'deprecated') => {
    setSelectedKeywordCategory(newValue)
    if (newValue === 'nondeprecated') {
      setShownKeywords(keywords.filter((keyword) => !keyword.deprecated))
      return
    }
    setShownKeywords(keywords.filter((keyword) => keyword.deprecated))
  }

  useEffect(() => {
    populateKeywords()
    populateKeywordEntries()
  }, [populateKeywordEntries, populateKeywords])

  return (
    <Grid container justifyContent="center" sx={{ mt: 3 }} padding={3}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid container justifyContent="center" sx={{ mt: 3 }}>
          <Grid item>
            <Typography variant="h3">Appindex Search Keywords</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" sx={{ mt: 1 }}>
          {error && (
            <Grid item>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
        </Grid>
        <Grid container justifyContent="start" spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Grid container spacing={1} alignItems="center" sx={{ mb: 2 }}>
              <Grid item>
                <ToggleButtonGroup
                  value={selectedKeywordCategory}
                  exclusive
                  onChange={handleChangeKeywordCategory}
                  aria-label="type deprecated"
                >
                  <ToggleButton value="nondeprecated" aria-label="nondeprecated">
                    Non Deprecated
                  </ToggleButton>
                  <ToggleButton value="deprecated" aria-label="deprecated">
                    Deprecated
                  </ToggleButton>
                </ToggleButtonGroup>
                {user?.appindexAdmin && (
                  <Fab sx={{ mx: 2 }} color="primary" size="small" disabled={loading} onClick={handleOpenKeywordCreate}>
                    <AddIcon />
                  </Fab>
                )}
                {
                  <Fab
                    sx={{ mx: 2 }}
                    variant="extended"
                    color="primary"
                    size="medium"
                    disabled={loading}
                    onClick={handleOpenKeywordTest}
                  >
                    <PlayCircleFilledIcon /> Test
                  </Fab>
                }
              </Grid>
            </Grid>
            <KeywordsTable
              admin={user?.appindexAdmin}
              keywords={shownKeywords}
              loading={loading}
              selected={selectedKeyword}
              dark={darkMode}
              onClick={handleClickKeyword}
              onEdit={handleClickEdit}
              onDelete={handleOpenDeleteKeyword}
            />
          </Grid>
          {selectedKeyword && (
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Grid container spacing={1} justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                <Grid item>
                  <ToggleButtonGroup
                    value={selectedCategory}
                    exclusive
                    onChange={handleChangeCategory}
                    aria-label="type category"
                  >
                    <ToggleButton value="all" aria-label="all">
                      All
                    </ToggleButton>
                    <ToggleButton value="exclude" aria-label="exclude">
                      Exclude
                    </ToggleButton>
                    <ToggleButton value="definitive" aria-label="definitive">
                      Definitive
                    </ToggleButton>
                    <ToggleButton value="keyword" aria-label="keyword">
                      Keyword
                    </ToggleButton>
                    <ToggleButton value="matcher" aria-label="matcher">
                      Matcher
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {user?.appindexAdmin && (
                    <Fab sx={{ mx: 2 }} color="primary" size="small" disabled={loading} onClick={handleOpenEntryCreate}>
                      <AddIcon />
                    </Fab>
                  )}
                </Grid>
                <Grid item>
                  <SearchBar
                    button
                    value={search}
                    dark={!darkMode}
                    onChange={handleChangeSearch}
                    onSubmit={handleSubmitSearch}
                    onClickButton={handleSubmitSearch}
                  />
                </Grid>
              </Grid>
              <KeywordEntriesTable
                admin={user?.appindexAdmin}
                keywordEntries={shownKeywordEntries}
                loading={loading}
                onDelete={handleOpenDeleteEntry}
              />
            </Grid>
          )}
        </Grid>
        <DeleteConfirmDialog
          open={deleteOpen}
          name={deleteName}
          loading={loading}
          error={error}
          onClose={handleDeleteClose}
          onDelete={handleDelete}
        />
        <KeywordCreateDialog open={keywordCreateOpen} onClose={handleCloseKeywordCreate} onSave={handleCreateKeyword} />
        <KeywordTestDialog
          open={keywordTestOpen}
          dark={darkMode}
          onClose={handleCloseKeywordTest}
          keywordIds={keywords
            .filter((keyword) => !keyword.deprecated)
            .map((keyword) => keyword.id)
            .concat('all')}
        />
        <KeywordEntryCreateDialog
          open={entryCreateOpen}
          onClose={handleCloseEntryCreate}
          keywordIds={keywords.filter((keyword) => !keyword.deprecated).map((keyword) => keyword.id)}
          defaultKeywordId={selectedKeyword}
          defaultType={selectedCategory !== 'all' ? selectedCategory : null}
          onSave={handleCreateEntry}
        />
        {alteredKeyword && (
          <KeywordEditDialog
            open={editOpen}
            onClickEdit={handleConfirmEdit}
            onChange={handleEditKeyword}
            onClose={handleClickEdit}
            keyword={alteredKeyword}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default Keywords
