import React, { useState, useEffect, useCallback } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import SearchBar from './SearchBar.tsx'
import Grid from '@mui/material/Grid'
import { KeywordTestResult } from '../util/Api.tsx'
import { TableSortLabel } from '@mui/material'

interface KeywordResultsProps {
  admin?: boolean
  keywordResults: KeywordTestResult[]
  loading?: boolean
  selected?: string | null
  dark?: boolean
  onClick?: (id: string) => void
}

const KeywordResultsTable = ({
  admin,
  keywordResults,
  loading,
  selected,
  dark = false,
  onClick = () => {},
}: KeywordResultsProps) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [search, setSearch] = useState('')
  const [shownResults, setShownResults] = useState<KeywordTestResult[]>([])
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')
  const [orderBy, setOrderBy] = useState<keyof KeywordTestResult>('keyword_signature')

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    setShownResults(keywordResults.filter((keywordResults) => keywordResults.search.includes(e.target.value)))
    setPage(0)
  }

  const getResults = useCallback(() => {
    const sortSearch = shownResults.sort((a, b) => {
      if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1
      if (a[orderBy] < b[orderBy]) return order === 'desc' ? 1 : -1
      return 0
    })
    const currentResults: (KeywordTestResult | null)[] = sortSearch.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    )
    const length = currentResults.length
    if (currentResults.length === rowsPerPage) return currentResults
    for (let i = 0; i < rowsPerPage - length; i++) {
      currentResults.push(null)
    }
    return currentResults
  }, [shownResults, page, rowsPerPage, order, orderBy])

  const createSortHandler = (key: keyof KeywordTestResult | 'search') => (e: React.MouseEvent) => {
    if (key !== orderBy) {
      setOrder('asc')
      return setOrderBy(key)
    }
    if (order === 'asc') {
      return setOrder('desc')
    }
    if (order === 'desc') {
      return setOrder('asc')
    }
  }

  useEffect(() => {
    setPage(0)
    setShownResults(keywordResults)
    setSearch('')
  }, [keywordResults])

  return (
    <Paper variant="outlined">
      <Grid container justifyContent="center" alignItems="center" spacing={2} padding={2}>
        <Grid item>
          <SearchBar dark value={search} onChange={handleChangeSearch} />
        </Grid>
      </Grid>
      <TableContainer>
        <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
        <Table sx={{ minWidth: 650 }} stickyHeader size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'keyword_signature'}
                  direction={orderBy === 'keyword_signature' ? order : 'asc'}
                  onClick={createSortHandler('keyword_signature')}
                >
                  Keyword Id
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'search'}
                  direction={orderBy === 'search' ? order : 'asc'}
                  onClick={createSortHandler('search')}
                >
                  Search
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'reason'}
                  direction={orderBy === 'reason' ? order : 'asc'}
                  onClick={createSortHandler('reason')}
                >
                  Reason
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'words'}
                  direction={orderBy === 'words' ? order : 'asc'}
                  onClick={createSortHandler('words')}
                >
                  Words
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getResults().map((row, index) => (
              <TableRow
                hover
                key={`keyword-table-item-${index}`}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: 'pointer',
                  height: 40,
                  backgroundColor:
                    row?.keyword_signature === selected && !dark
                      ? '#ededed'
                      : row?.keyword_signature === selected && dark
                      ? '#4b4b4b'
                      : null,
                }}
                onClick={row?.keyword_signature ? () => onClick(row.keyword_signature) : () => {}}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="body2" noWrap sx={{ maxWidth: 280 }}>
                    {row?.keyword_signature || ''}
                  </Typography>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                  }}
                >
                  <Typography variant="body2" sx={{ maxWidth: 600 }}>
                    {row?.search || ''}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant="body2" noWrap sx={{ maxWidth: 280 }}>
                    {row?.reason || ''}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant="body2" noWrap sx={{ maxWidth: 280 }}>
                    {row?.words.join(', ') || ''}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={keywordResults.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default KeywordResultsTable
