import React, { useState, useCallback, useEffect, useMemo } from 'react'
import useIsMounted from 'react-is-mounted-hook'
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login'

import { Alert, Button, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import LoadingButton from '@mui/lab/LoadingButton'

import {
  getGoogleClientId,
  getIsProd,
  login,
  loginQueHasItems,
  setHeader,
  runLoginQue,
  loginGoogle,
  iapLogin,
  DecodedUser,
} from './Api.tsx'
import useStore from './Store.tsx'
import handleError from './Error.tsx'

interface loginData {
  user: DecodedUser
  token: string
  msg: string
}

const LoginOverlay = () => {
  const isMounted = useIsMounted()
  const [user, setUser] = useStore('user')
  const [token, setToken] = useStore('token')

  const [clientId, setClientId] = useState('')
  const [loading, setLoading] = useState(false)
  const [iapLoading, setIapLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [isProd, setIsProd] = useState(false)

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value)
  }

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value)
  }

  const performLoginActions = useCallback(
    (data: loginData) => {
      setUser(data.user)
      setToken(data.token)
      setHeader('Authorization', data.token)
      runLoginQue()
    },
    [setToken, setUser]
  )

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true)
      const data = await login({ email, password })
      if (!isMounted()) return
      setError('')
      performLoginActions(data)
    } catch (err) {
      if (!isMounted()) return
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }, [email, password, isMounted, performLoginActions])

  const handleGoogleLogin = useCallback(
    async (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      try {
        setLoading(true)
        const data = await loginGoogle({
          callback: `https://${window.location.hostname}`,
          code: res.code ? res.code : '',
          whitelabel: window.location.hostname,
        })
        if (!isMounted()) return
        setError('')
        performLoginActions(data)
      } catch (err) {
        const { msg } = handleError(err)
        setError(msg)
      } finally {
        if (!isMounted) return
        setLoading(false)
      }
    },
    [isMounted, performLoginActions]
  )

  const handleGoogleError = (e?: any, data?: any) => {
    console.log(e, data)
  }

  const tryIapLogin = useCallback(async () => {
    try {
      setIapLoading(true)
      const data = await iapLogin()
      if (!isMounted()) return
      setError('')
      performLoginActions(data)
    } catch (err) {
      const { msg } = handleError(err)
      console.error(msg)
    } finally {
      if (!isMounted()) return
      setIapLoading(false)
    }
  }, [isMounted, performLoginActions])

  const populateClientId = useCallback(async () => {
    try {
      setLoading(true)
      const data = await getGoogleClientId()
      setClientId(data.clientId)
    } catch (err) {
      if (!isMounted()) return
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }, [isMounted])

  const populateIsProd = useCallback(async () => {
    try {
      const res = await getIsProd()
      setIsProd(res.isProd)
    } catch (err) {
      if (!isMounted()) return
      const { msg } = handleError(err)
      setError(msg)
    }
  }, [isMounted])

  useEffect(() => {
    populateClientId()
  }, [populateClientId])

  useEffect(() => {
    populateIsProd()
  }, [populateIsProd])

  const queHasItems = loginQueHasItems()

  const showDialog = useMemo(() => !token || !user || queHasItems, [token, user, queHasItems])

  useEffect(() => {
    tryIapLogin()
  }, [tryIapLogin, showDialog])

  return (
    <Dialog open={showDialog} fullWidth>
      <Grid container justifyContent="center" padding={2}>
        <Grid item>
          <Typography variant="h5" textAlign="center">
            Login to Appindex Manager
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" padding={2}>
        {error && (
          <Grid item>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
      </Grid>
      <Grid container justifyContent="center" direction="column" alignItems="stretch" padding={2} spacing={2}>
        {isProd === false && (
          <>
            <Grid item>
              <TextField
                fullWidth
                name="login-email"
                placeholder="example@familyzone.com"
                label="Email"
                variant="standard"
                value={email}
                onChange={handleEmailChange}
                disabled={iapLoading}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                name="login-password"
                placeholder="********"
                type="password"
                label="Password"
                variant="standard"
                value={password}
                onChange={handlePasswordChange}
                disabled={iapLoading}
              />
            </Grid>
            <Grid item sx={{ my: 2 }}>
              <LoadingButton
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                loading={loading}
                disabled={iapLoading}
              >
                Login
              </LoadingButton>
            </Grid>
            <Grid item>Or</Grid>
          </>
        )}

        <Grid item>
          {clientId && (
            <GoogleLogin
              clientId={clientId}
              responseType="code"
              render={(renderProps) => (
                <Button
                  fullWidth
                  variant="contained"
                  disabled={renderProps.disabled || iapLoading}
                  onClick={renderProps.onClick}
                >
                  Login with Google
                </Button>
              )}
              buttonText="Login"
              onSuccess={handleGoogleLogin}
              onFailure={handleGoogleError}
              cookiePolicy={'single_host_origin'}
            />
          )}
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default LoginOverlay
