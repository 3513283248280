import React from 'react'

import { styled, alpha } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import IconButton from '@mui/material/IconButton'

interface SearchProps {
  dark: boolean
}

const Search = styled('form', { shouldForwardProp: (prop) => prop !== 'dark' })<SearchProps>(({ theme, dark }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  ...(!dark
    ? {
        backgroundColor: alpha(theme.palette.common.white, 0.1),
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
      }
    : {
        backgroundColor: alpha(theme.palette.common.black, 0.08),
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.black, 0.06),
        },
      }),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}))

const SearchIconWrapperButton = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 0.5),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}))

const SearchIconButton = styled(IconButton)(() => ({
  zIndex: 2,
}))

interface SearchBarProps {
  value: string
  disabled?: boolean
  dark?: boolean
  button?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void
  onClickButton?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const SearchBar = ({
  value,
  disabled = false,
  dark = false,
  button = false,
  onChange = () => {},
  onSubmit = () => {},
  onClickButton = () => {},
}: SearchBarProps) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit(e)
  }

  return (
    <Search onSubmit={handleSubmit} dark={dark}>
      {button ? (
        <SearchIconWrapperButton>
          <SearchIconButton color="inherit" disabled={disabled} onClick={onClickButton}>
            <SearchIcon />
          </SearchIconButton>
        </SearchIconWrapperButton>
      ) : (
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
      )}

      <StyledInputBase
        placeholder="Search…"
        inputProps={{ 'aria-label': 'search' }}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </Search>
  )
}

export default SearchBar
