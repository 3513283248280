import React, { useState, useCallback } from 'react'

import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTheme } from '@mui/material/styles'
import Alert from '@mui/material/Alert'
import useIsMounted from 'react-is-mounted-hook'
import {
  getDetailedSignatures,
  editCreateSignatureSuggestion,
  editEditSignatureSuggestion,
  editRecatUrlsSuggestion,
  editEditAppstoresSuggestion,
  Suggestion,
  Signature,
  Appstore,
  SignatureUrlInfo,
  editAddKeywordsSuggestion,
  editOtherSuggestion,
  editNewAppstoreSuggestion,
  addSuggestionMessage,
} from '../util/Api.tsx'
import handleError from '../util/Error.tsx'
import { useEffect } from 'react'
import UrlsEdit from './UrlsEdit.tsx'
import SignatureEdit from './SignatureEdit.tsx'
import ApplicationEdit from './ApplicationEdit.tsx'
import KeywordAdd from './KeywordAdd.tsx'
import { TextField } from '@mui/material'
import AddControlDialog from './AddControlDialog.tsx'

interface EditSuggestionDialogProps {
  open: boolean
  suggestion?: Suggestion | null
  mappedUrls?: SignatureUrlInfo[]
  onClose?: () => void
  afterSubmit?: (suggestion: Suggestion) => void
  message?: (message: string) => void
  oldSuggestion?: Suggestion | null
}

interface ValidationError {
  field: keyof Signature
  msg: string
}

const EditSuggestionDialog = ({
  open = false,
  suggestion = null,
  mappedUrls = [],
  onClose = () => {},
  afterSubmit = () => {},
  message = () => {},
  oldSuggestion = null,
}: EditSuggestionDialogProps) => {
  const theme = useTheme()
  const isMounted = useIsMounted()
  const [error, setError] = useState('')
  const [signatures, setSignatures] = useState<Signature[]>([])
  const [loading, setLoading] = useState(false)
  const [erroredFields, setErroredFields] = useState<ValidationError[]>([])
  const [confirmed, setConfirmed] = useState(false)

  const [suggestionData, setSuggestionData] = useState<Suggestion['data'] | null>(null)
  const [description, setDescription] = useState('')

  const [suggestionMappedUrls, setSuggestionMappedUrls] = useState<SignatureUrlInfo[]>([])

  useEffect(() => {
    if (!suggestion) return
    setSuggestionData(suggestion.data)
    if (signatures) {
      if (suggestion.type === 'recat-urls') {
        setSignatures(signatures.filter((sig) => !sig.hidden && sig.enabled))
      }
    }
    setDescription(suggestion?.data?.message)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestion])

  useEffect(() => {
    if (!mappedUrls) return
    setSuggestionMappedUrls(mappedUrls)
  }, [mappedUrls])

  const handleClose = () => {
    if (loading) return
    setConfirmed(false)
    onClose()
  }

  const handleConfirm = () => {
    if (suggestion?.type === 'edit-signature' || suggestion?.type === 'create-signature') {
      const success = validateSignature()
      if (!success) return
    }
    setConfirmed(true)
  }

  const handleUnconfirm = () => {
    setConfirmed(false)
  }

  const handleConfirmUpdate = async () => {
    try {
      if (!suggestionData) return
      if (!suggestion) return
      if (!oldSuggestion) return
      if (suggestionData.urls && suggestionData.urls.length !== suggestionMappedUrls.length) return
      setLoading(true)
      let data = null
      if (suggestion.type === 'create-signature') {
        data = await editCreateSignatureSuggestion({
          id: suggestion.id,
          signature: suggestionData.signature,
          urls: suggestionData.urls,
          ticket: suggestionData?.ticket,
        })
      } else if (suggestion.type === 'edit-signature') {
        data = await editEditSignatureSuggestion({
          id: suggestion.id,
          signature: suggestionData.signature,
          ticket: suggestionData?.ticket,
        })
      } else if (suggestion.type === 'recat-urls') {
        // if urls.signatureId === signature.id
        const IPValidation = suggestionData.urls.some((url: string) => url.match(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/))
        if (IPValidation) {
          setError('IP addresses cannot be recategorised')
          return
        }
        data = await editRecatUrlsSuggestion({
          id: suggestion.id,
          signatureId: suggestionData.signature.id,
          urls: suggestionData.urls,
          oldUrls: [],
          ticket: suggestionData?.ticket,
        })
        if (oldSuggestion['data'].signature.id !== suggestionData.signature.id) {
          message(`Signature changed from ${oldSuggestion['data'].signature.name} to ${suggestionData.signature.name}`)
        }
      } else if (suggestion.type === 'edit-appstores') {
        data = await editEditAppstoresSuggestion({
          id: suggestion.id,
          appstores: suggestionData.appstores,
          ticket: suggestionData?.ticket,
        })
      } else if (suggestion.type === 'new-appstore') {
        data = await editNewAppstoreSuggestion({
          id: suggestion.id,
          controlId: suggestionData.controlId,
          controlName: suggestionData.controlName,
          controlFavicon: suggestionData.controlFavicon,
          category: suggestionData.controlCategory,
          ticket: suggestionData?.ticket,
        })
      } else if (suggestion.type === 'add-keywords') {
        data = await editAddKeywordsSuggestion({
          id: suggestion.id,
          keywordId: suggestionData.keywordId,
          keywordEntry: suggestionData.keywordEntry,
          keywordType: suggestionData.keywordType,
          ticket: suggestionData?.ticket,
        })
      } else if (suggestion.type === 'other-request') {
        data = await editOtherSuggestion({ id: suggestion.id, message: description })
      }
      if (!data) return
      await new Promise((resolve) => setTimeout(resolve, 1000))
      await addSuggestionMessage({
        id: suggestion.id,
        message: 'Edited ticket',
        ticket: suggestionData?.ticket,
      })
      afterSubmit(data.suggestion)
      setConfirmed(false)
      onClose()
      setError('')
    } catch (err) {
      const { msg } = handleError(err)
      if (!isMounted()) return
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  const handleChangeSignature = (signature: Signature) => {
    setSuggestionData({ ...suggestionData, signature })
  }

  const handleChangeUrls = (urls: SignatureUrlInfo[]) => {
    setError('')
    // if url is already associated with the signature
    const foundUrl = urls.find((url: SignatureUrlInfo) => url.signatureId === suggestionData?.signature.id)
    if (foundUrl) {
      setError('One or more of the urls you selected are already associated with this signature')
      return
    }
    setSuggestionData({ ...suggestionData, urls: urls.map((u) => u.url) })
    setSuggestionMappedUrls(urls)
  }

  const handleChangeReCatSignature = (signatures: Signature) => {
    setSuggestionData({ ...suggestionData, signature: signatures })
  }

  const handleAddKeywords = (entry: string[]) => {
    setSuggestionData({ ...suggestionData, keywordEntry: entry })
  }

  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value)
  }

  const validateSignature = () => {
    const errors: ValidationError[] = []
    if (!suggestionData) return false
    if (suggestionData.signature.name.length < 4) {
      errors.push({
        field: 'name',
        msg: 'Name must be at least 4 characters',
      })
    }
    if (suggestionData.signature.name.length > 40) {
      errors.push({
        field: 'name',
        msg: 'Name must be at most 40 characters',
      })
    }
    if (suggestionData.signature.category && !signatures.some((sig) => sig.id === suggestionData.signature.category)) {
      errors.push({
        field: 'category',
        msg: 'Category does not exist',
      })
    }
    if (!suggestionData.signature.category) {
      errors.push({
        field: 'category',
        msg: 'Signature needs a category',
      })
    }
    if (!suggestionData.signature.url) {
      errors.push({
        field: 'url',
        msg: 'This field is required',
      })
    }
    if (suggestionData.signature.description.length < 10) {
      errors.push({
        field: 'description',
        msg: 'Description must be at least 10 characters',
      })
    }
    if (suggestionData.signature.description.length > 300) {
      errors.push({
        field: 'description',
        msg: 'Description must be at most 300 characters',
      })
    }
    if (suggestion?.type === 'create-signature') {
      if (signatures.some((sig) => sig.name.toLowerCase() === suggestionData.signature.name.toLowerCase())) {
        errors.push({
          field: 'name',
          msg: 'This name already exists',
        })
      }
      const id = `sphirewall.application.${suggestionData.signature.name
        .toLowerCase()
        .replace(/[^\w\s]/gi, '')
        .replace(/ /g, '')}`
      if (signatures.some((sig) => sig.id === id)) {
        errors.push({
          field: 'name',
          msg: 'This name already exists',
        })
      }
      if (suggestionData.signature.category) {
        const categorySignature = signatures.find((sig) => sig.id === suggestionData.signature.category)
        if (categorySignature?.isCategory) {
          setSuggestionData({
            ...suggestionData,
            signature: {
              ...suggestionData.signature,
              id,
              isSubCategory: true,
            },
          })
        } else {
          setSuggestionData({
            ...suggestionData,
            signature: {
              ...suggestionData.signature,
              id,
            },
          })
        }
      } else {
        setSuggestionData({
          ...suggestionData,
          signature: {
            ...suggestionData.signature,
            id,
            isCategory: true,
          },
        })
      }
    }
    if (suggestion?.type === 'create-signature' && signatures.some((sig) => sig.url === suggestionData.signature.url)) {
      errors.push({
        field: 'url',
        msg: 'This url already exists',
      })
    }

    if (errors.length !== 0) {
      setErroredFields(errors)
      return false
    }
    setErroredFields([])

    return true
  }

  const handleChangeAppstores = (appstores: Appstore[]) => {
    setSuggestionData({ ...suggestionData, appstores })
  }

  const handleChangeNewControl = (controlId: string, controlName: string, favicon: string, cat: string) => {
    setSuggestionData({
      ...suggestionData,
      controlId: controlId,
      controlName: controlName,
      controlFavicon: favicon,
      controlCategory: cat,
    })
  }

  const handleRevertSignature = () => {
    if (!suggestion) return
    setSuggestionData({ ...suggestionData, signature: suggestion.data.signature })
  }

  const handleRevertUrls = () => {
    if (!suggestion) return
    setSuggestionData({ ...suggestionData, urls: suggestion.data.urls })
    setSuggestionMappedUrls(mappedUrls)
  }

  const populateSignatures = useCallback(async () => {
    try {
      setLoading(true)
      const data = await getDetailedSignatures()
      if (!isMounted()) return
      setSignatures(data.signatures)
    } catch (err) {
      handleError(err)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }, [isMounted])

  useEffect(() => {
    populateSignatures()
  }, [populateSignatures])

  return (
    <Dialog open={open} onClose={handleClose}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Collapse in={Boolean(error)}>
            <Box>
              <Grid container padding={2} justifyContent="center">
                <Grid item xs={12}>
                  <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
                    {error || ' '}
                  </Alert>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Grid container padding={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                Edit Suggestion
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Collapse in={confirmed}>
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <IconButton onClick={handleUnconfirm}>
                        <ArrowLeftIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Typography>Confirm your changes?</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          {/* Application editor layout */}
          <Collapse in={suggestion?.type === 'edit-appstores' && !confirmed}>
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <ApplicationEdit
                    appstores={suggestionData?.appstores}
                    onChange={handleChangeAppstores}
                    id={''}
                    signature_id={''}
                  />
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          {/* New Controlled App layout */}
          <Collapse in={suggestion?.type === 'new-appstore' && !confirmed}>
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <AddControlDialog
                    controlId={suggestionData?.controlId}
                    favicon={suggestionData?.controlFavicon}
                    controlName={suggestionData?.controlName}
                    controlCategory={suggestionData?.controlCategory}
                    signatures={signatures}
                    editing={false}
                    onChange={handleChangeNewControl}
                  />
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          {/* Signature editor layout */}
          <Collapse
            in={(suggestion?.type === 'create-signature' || suggestion?.type === 'edit-signature') && !confirmed}
          >
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <SignatureEdit
                    signature={suggestionData?.signature}
                    signatures={signatures}
                    onChange={handleChangeSignature}
                    errors={erroredFields}
                  />
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          {/* Urls editor layout */}
          <Collapse
            in={
              (suggestion?.type === 'recat-urls' && !confirmed) ||
              (suggestion?.type === 'create-signature' && confirmed)
            }
          >
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <UrlsEdit
                    urls={suggestionMappedUrls}
                    onChange={handleChangeUrls}
                    signatureId={suggestionData?.signature?.id}
                    signatures={signatures}
                    onChangeSignature={handleChangeReCatSignature}
                    editSuggestion="True"
                  />
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Collapse in={suggestion?.type === 'add-keywords' && !confirmed}>
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <KeywordAdd
                    keyword={suggestionData?.keywordId}
                    keywordEntry={suggestionData?.keywordEntry}
                    keywordType={suggestionData?.keywordType}
                    onSubmit={handleConfirmUpdate}
                    onChange={handleAddKeywords}
                  />
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Collapse in={suggestion?.type === 'other-request'}>
            <Box>
              <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <TextField
                    value={description}
                    fullWidth
                    multiline
                    minRows={2}
                    onChange={handleChangeDescription}
                    disabled={loading}
                    helperText={`${description?.length}/25 Characters ${description?.length >= 25 ? '😀' : '😦'}`}
                  />
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Collapse
            in={
              (suggestion?.type === 'edit-signature' && !confirmed) ||
              (suggestion?.type === 'create-signature' && !confirmed)
            }
          >
            <Box>
              <Grid container padding={2} spacing={2} alignItems="center">
                <Grid item>
                  <LoadingButton variant="contained" onClick={handleConfirm} loading={loading}>
                    Confirm Signature
                  </LoadingButton>
                </Grid>
                <Grid item>
                  <IconButton onClick={handleRevertSignature}>
                    <RefreshIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Collapse in={(suggestion?.type === 'create-signature' && confirmed) || suggestion?.type === 'recat-urls'}>
            <Box>
              <Grid container padding={2} spacing={2} alignItems="center">
                <Grid item>
                  <LoadingButton variant="contained" onClick={handleConfirmUpdate} loading={loading}>
                    Confirm URLs
                  </LoadingButton>
                </Grid>
                <Grid item>
                  <IconButton onClick={handleRevertUrls}>
                    <RefreshIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Collapse
            in={
              (suggestion?.type === 'edit-signature' && confirmed) ||
              suggestion?.type === 'edit-appstores' ||
              suggestion?.type === 'other-request' ||
              suggestion?.type === 'new-appstore'
            }
          >
            <Box>
              <Grid container padding={2} spacing={2} alignItems="center">
                <Grid item>
                  <LoadingButton variant="contained" onClick={handleConfirmUpdate} loading={loading}>
                    Confirm Changes
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </div>
      </div>
    </Dialog>
  )
}

export default EditSuggestionDialog
