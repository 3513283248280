import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import URLSearch from '../components/URLSearch.tsx'

const Search = () => {
  const history = useHistory()
  const params = useParams<{ url?: string }>()
  const [search, setSearch] = useState('')

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const handleClickSignature = (signatureId: string) => {
    history.push(`/signature/${signatureId}`)
  }

  const handleReclassify = (signatureId: string) => {
    history.push(`/signature/${signatureId}`)
  }

  useEffect(() => {
    if (!params?.url) return
    setSearch(params.url)
  }, [params])

  return (
    <Container sx={{ mt: 12 }}>
      <Grid container justifyContent="center" padding={1}>
        <Grid item xs={12} sm={8} md={6}>
          <Paper variant="outlined">
            <Grid container padding={2} justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h5" noWrap>
                  Signature / URL / IP Search
                </Typography>
              </Grid>
            </Grid>
            <URLSearch
              value={search}
              onChange={handleChangeSearch}
              onClickSignature={handleClickSignature}
              onReclassify={handleReclassify}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Search
