import { Alert, Box, Grid, TextField, Typography, useMediaQuery } from '@mui/material'
import { useCallback } from 'react'
import { ClassifyMessages } from './CreateSuggestionDialog'

interface MassClassificationProps {
  loading: boolean
  urls: string
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  error: string
  classifyValidationMessage: ClassifyMessages | null
}

const MassClassification = ({
  loading = false,
  error = '',
  urls,
  onChange = () => {},
  classifyValidationMessage,
}: MassClassificationProps) => {
  const FONT_SIZE = 14
  const LINE_HEIGHT = 1.68
  const sm = useMediaQuery('(min-width:600px)')

  const calcTextInfo = useCallback(() => {
    return sm
      ? {
          textboxWidth: 340,
          maxChars: 36,
        }
      : {
          textboxWidth: 200,
          maxChars: 20,
        }
  }, [sm])

  return (
    <Box>
      <Grid container spacing={2} justifyContent="center" alignContent="center">
        {error && (
          <Grid container justifyContent="center" sx={{ mb: 3 }}>
            <Grid item>
              <Alert severity="error">{error}</Alert>
            </Grid>
          </Grid>
        )}
        {classifyValidationMessage && classifyValidationMessage?.warning === 0 && (
          <Grid container justifyContent="center" sx={{ mb: 3 }}>
            <Grid item>
              <Alert severity="success">{classifyValidationMessage?.msg}</Alert>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body1">
            Submit URLs to be initially categorised into appindex. This is for when customers ask for lists of URL to be
            categorised. This will not re-categorise URLs that already have a category.
          </Typography>
        </Grid>
        <Grid item>
          <Box sx={{ mb: 1 }}></Box>
          <TextField
            multiline
            fullWidth
            placeholder={`google.com\nhello.com\nreports.google.com`}
            value={urls}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onChange(e)}
            minRows={10}
            disabled={loading}
            inputProps={{
              sx: { fontSize: FONT_SIZE, lineHeight: LINE_HEIGHT, fontFamily: 'Roboto, monospace' },
            }}
            sx={{ width: calcTextInfo().textboxWidth }}
            helperText={`${urls.split('\n').length}/Max 200 lines`}
          />
        </Grid>
        {classifyValidationMessage && classifyValidationMessage?.warning === 1 && (
          <Grid container justifyContent="center" sx={{ mb: 3 }}>
            <Grid item>
              <Alert severity="warning">{classifyValidationMessage?.msg}</Alert>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default MassClassification
