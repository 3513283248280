import React, { useState, useEffect } from 'react'
import useIsMounted from 'react-is-mounted-hook'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete'
import LoadingButton from '@mui/lab/LoadingButton'

import { useTheme } from '@mui/material/styles'
import KeywordResultsTable from '../components/KeywordResultsTable.tsx'
import FullScreenDialog from '../components/FullscreenDialog.tsx'
import VertexResultsTable from '../components/VertexResultsTable.tsx'

import handleError from '../util/Error.tsx'
import { testKeywords, KeywordTestResult, testVertex, VertexTestResult } from '../util/Api.tsx'
import { Button, Tab, Tabs } from '@mui/material'

interface KeywordTestProps {
  open?: boolean
  onClose?: () => void
  keywordIds: string[]
  dark: boolean
}

const KeywordTest = ({ open = false, onClose = () => {}, keywordIds = [], dark = false }: KeywordTestProps) => {
  const theme = useTheme()
  const isMounted = useIsMounted()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [phrases, setPhrases] = useState('')
  const [results, setResults] = useState<KeywordTestResult[]>([])
  const [resultsVertex, setResultsVertex] = useState<VertexTestResult[]>([])
  const [keywordId, setKeywordId] = useState('all')
  const [showKeywordResults, setShowKeywordResults] = useState(false)
  const [showVertexResults, setShowVertexResults] = useState(false)
  const [selector, setSelector] = useState(0)

  const handleChangePhrases = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhrases(e.target.value)
  }

  const handleChangeKeywordId = (e: React.SyntheticEvent, newValue: string | null) => {
    setKeywordId(newValue ?? 'all')
  }

  const handleRunKeywords = async () => {
    try {
      setLoading(true)
      if (phrases === '') {
        setError('Please enter search terms')
        return
      }

      if (selector === 1) {
        const data = await testVertex({
          phrases,
        })
        if (!isMounted()) return
        setResultsVertex(data.results)
        setShowVertexResults(true)
        setShowKeywordResults(false)
      } else {
        const data = await testKeywords({
          keywordId,
          phrases,
        })
        if (!isMounted()) return
        setResults(data.results)
        setShowVertexResults(false)
        setShowKeywordResults(true)
      }
      setError('')
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  const handlegeneration = async () => {
    try {
      setLoading(true)
      setShowKeywordResults(true)
      const data = await testKeywords({
        keywordId,
        phrases: '',
      })
      if (!isMounted()) return

      setResults(data.results)
      setError('')
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  const handleClear = async () => {
    setPhrases('')
  }

  const handleSelector = (event: React.SyntheticEvent, newValue: number) => {
    setSelector(newValue)
    setShowVertexResults(false)
    setShowKeywordResults(false)
  }

  useEffect(() => {
    setError('')
  }, [open])

  return (
    <FullScreenDialog open={open} onClose={onClose}>
      <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
        <Grid container padding={2} spacing={2} justifyContent="center" alignItems="center">
          <Grid item>
            <Typography variant="h5" noWrap>
              Test Keyword Lists
            </Typography>
          </Grid>
        </Grid>
        <Grid container padding={1} justifyContent="center">
          <Grid item>
            {open && (
              <Collapse in={Boolean(error)}>
                <Alert severity="error">{error || 'Error'}</Alert>
              </Collapse>
            )}
          </Grid>
        </Grid>
        <Grid container padding={1} justifyContent="center">
          <Tabs value={selector} onChange={handleSelector}>
            <Tab label="Appindex Keywords" />
            <Tab label="Vertex AI" />
          </Tabs>
        </Grid>
        <Grid container padding={3} spacing={2} justifyContent="center">
          <Grid item xs={8}>
            <TextField
              label="Enter phrases to be matched"
              fullWidth
              value={phrases}
              multiline
              rows={4}
              onChange={handleChangePhrases}
            />
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={1}>
              {selector === 0 && (
                <Grid item xs={3}>
                  <Autocomplete
                    id="keywordId"
                    size="small"
                    options={keywordIds}
                    renderInput={(params) => <TextField {...params} label="Keyword Category Id" />}
                    defaultValue={'all'}
                    value={keywordId}
                    onChange={handleChangeKeywordId}
                  />
                </Grid>
              )}
              <Grid item>
                <LoadingButton
                  variant="contained"
                  size="large"
                  color="primary"
                  disableElevation
                  loading={loading}
                  onClick={handleRunKeywords}
                >
                  Evaluate
                </LoadingButton>
              </Grid>
              {selector === 0 && (
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    size="large"
                    color="secondary"
                    disableElevation
                    loading={loading}
                    onClick={handlegeneration}
                  >
                    Latest Student Searches
                  </LoadingButton>
                </Grid>
              )}
              <Grid item>
                <Button variant="contained" size="large" color="error" disableElevation onClick={handleClear}>
                  Clear Text
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            {showKeywordResults && <KeywordResultsTable keywordResults={results} loading={loading} dark={dark} />}
            {showVertexResults && <VertexResultsTable keywordResults={resultsVertex} loading={loading} dark={dark} />}
          </Grid>
        </Grid>
      </div>
    </FullScreenDialog>
  )
}

export default KeywordTest
