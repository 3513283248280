import React, { useEffect, useState, useCallback } from 'react'
import useIsMounted from 'react-is-mounted-hook'
import { getDetailedSignatures, Signature } from '../util/Api.tsx'
import handleError from '../util/Error.tsx'
import Link from '@mui/material/Link'
import styled from '@mui/material/styles/styled'
import { ThemeProvider } from '@mui/material/styles'
import { lightTheme } from '../App.tsx'
import { CssBaseline } from '@mui/material'

const Container = styled('div')`
  margin: 1.2em;
`

const HeaderText = styled('div')`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #000000;
  margin-bottom: 12px;
`

const SubtitleText = styled('div')`
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  display: flex;
  align-items: flex-end;
  color: #42526e;
`

const Table = styled('table')`
  margin: 0px;
  padding: 0px;
  border: 0px;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  text-align: left;
  margin-top: 2em;
  border-spacing: 0px;
  flex-grow: 0;
`

const TableRowHeader = styled('tr')`
  margin: 0px;
  padding: 0px;
  border: 0px;
  background-color: #e7f9ff;
`

const TableHeader = styled('th')`
  margin: 0px;
  padding: 1em;
  border: 0px;
  flex-grow: 0;
  min-width: 200px;
`

const TableRowCategory = styled('tr')`
  margin: 0px;
  padding: 0px;
  border: 0px;
  background-color: #344563;
  color: #fff;
  font-style: normal;
  font-weight: 400;
`

const TableCellCategory = styled('td')`
  margin: 0px;
  padding: 1em;
  border: 0px;
  flex-grow: 0;
  align-items: center;
`

const CategoryContainer = styled('div')`
  margin: 0px;
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: start;
`

const CategoryFavicon = styled('img')`
  display: inline;
  height: 18px;
  padding-right: 12px;
  filter: brightness(1900%);
  image-rendering: crisp-edges;
`

const CategoryTypography = styled('div')`
  text-align: center;
  padding: 0px;
  margin-top: 1px;
`

const TableRow = styled('tr')`
  margin: 0px;
  padding: 0px;
  border: 0px;
`

interface TableCellProps {
  highlighted: boolean
}

const TableCell = styled('td', { shouldForwardProp: (prop) => prop !== 'highlighted' })<TableCellProps>`
  margin: 0px;
  padding: 1em;
  border: 0px;
  background-color: ${(props) => (props.highlighted ? 'rgba(244, 245, 247, 0.6)' : 'rgba(244, 245, 247, 0.0)')};
  flex-grow: 0;
`

const SignaturesPrint = () => {
  const [signatures, setSignatures] = useState<Signature[]>([])
  const isMounted = useIsMounted()

  const populateSignatures = useCallback(async () => {
    try {
      const data = await getDetailedSignatures()
      if (!isMounted()) return
      setSignatures(data.signatures.filter((sig) => sig.enabled))
      setTimeout(() => {
        window.print()
      }, 1200)
    } catch (err) {
      handleError(err)
    }
  }, [isMounted])

  useEffect(() => {
    populateSignatures()
  }, [populateSignatures])

  const now = new Date()

  const categories = signatures.filter((sig) => sig.isCategory).filter((sig) => !sig.hidden)
  const subcategories = signatures.filter((sig) => sig.isSubCategory).filter((sig) => !sig.hidden)

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <Container>
        <HeaderText>Category Descriptions</HeaderText>
        <SubtitleText>Generated at: {now.toLocaleDateString()}</SubtitleText>
        <Table>
          <TableRowHeader>
            <TableHeader>Category</TableHeader>
            <TableHeader>Example URL</TableHeader>
            <TableHeader>Description</TableHeader>
          </TableRowHeader>
          {categories.map((cat) => (
            <>
              <TableRowCategory>
                <TableCellCategory>
                  <CategoryContainer>
                    <CategoryFavicon src={cat?.faviconUrl || ''} />
                    <CategoryTypography>{cat.name}</CategoryTypography>
                  </CategoryContainer>
                </TableCellCategory>
                <TableCellCategory>{cat?.url || ''}</TableCellCategory>
                <TableCellCategory>{cat.description}</TableCellCategory>
              </TableRowCategory>
              {subcategories
                .filter((subcat) => subcat.category === cat.id)
                .map((subcat, index) => (
                  <TableRow>
                    <TableCell highlighted={index % 2 === 0}>{subcat.name}</TableCell>
                    <TableCell highlighted={index % 2 === 0}>
                      <Link href={subcat.url || ''} underline="none" sx={{ color: '#094C99' }}>
                        {subcat.url}
                      </Link>
                    </TableCell>
                    <TableCell highlighted={index % 2 === 0}>{subcat.description}</TableCell>
                  </TableRow>
                ))}
            </>
          ))}
        </Table>
      </Container>
    </ThemeProvider>
  )
}

export default SignaturesPrint
