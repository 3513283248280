import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'

import { useTheme } from '@mui/material/styles'
import { AppControls, AppListings, PlatformOption, Signature, platforms } from '../util/Api.tsx'
import AddControlDialog from './AddControlDialog.tsx'

import CheckIcon from '@mui/icons-material/Check'
import ControlledAppSelector from './ControlledAppSelector.tsx'

export interface AddApp extends AppListings {
  newId: string
  appId: string
  type: string
  category: string
}

interface AddAppControlProps {
  open: boolean
  loading: boolean
  onClose: () => void
  error: string
  listings: AppListings[]
  onClickAdd: (app: AddApp) => void
  onClickEdit: (app: AddApp, oldapp: AppControls) => void
  app: AppControls | null
  signatures: Signature[]
  edit: boolean
}

const AddAppControl = ({
  listings,
  open = false,
  loading = false,
  onClose = () => {},
  onClickAdd = () => {},
  onClickEdit = () => {},
  signatures,
  error = '',
  app = null,
  edit = false,
}: AddAppControlProps) => {
  const theme = useTheme()
  const [id, setId] = useState('')
  const [type, setType] = useState('')
  const [appError, setAppError] = useState('')
  const [platform, setPlatform] = useState<PlatformOption | null>(null)
  const [category, setCategory] = useState('')
  const [selectedControlledApps, setSelectedControlledApps] = useState<AppListings | null>(null)
  const [favicon, setFavicon] = useState('')
  const [controlledId, setControlledId] = useState('controlledapp.')
  const [controlledName, setControlledName] = useState('')
  const [validated, setValidated] = useState(false)
  const [validateRegex, setValidateRegex] = useState('')

  const handleChangeAppId = (e: any) => {
    setAppError('')
    if (platform?.id.startsWith('windows')) {
      try {
        new RegExp(e.target.value.toLowerCase())
      } catch (err: any) {
        setAppError(err.message)
        setId(e.target.value)
        setValidated(false)
        return
      }
    }
    try {
      if (validateRegex.length > 0) {
        if (new RegExp(e.target.value.toLowerCase(), 'i').test(validateRegex)) {
          setValidated(true)
        } else {
          setValidated(false)
        }
      }
    } catch (err) {
      return
    }
    setId(e.target.value.toLowerCase())
  }

  const handleChangeValidateRegex = (e: any) => {
    setAppError('')
    try {
      if (new RegExp(id, 'i').test(e.target.value)) {
        setValidated(true)
      } else {
        setValidated(false)
      }
    } catch (err: any) {
      setValidateRegex(e.target.value)
      return
    }
    setValidateRegex(e.target.value)
  }

  const handleChangeType = (newValue: PlatformOption | null) => {
    setPlatform(newValue)
    setType(newValue?.id || '')
  }

  const handleChangeControl = (controlId: string, controlName: string, favicon: string, cat: string) => {
    setControlledId(controlId)
    setControlledName(controlName)
    setFavicon(favicon)
    setCategory(cat)
  }

  const handleChangeControlledApps = (e: AppListings | null) => {
    setSelectedControlledApps(e)
  }

  const handleClickAdd = () => {
    if (!id || !type || !selectedControlledApps) return
    const temp: AddApp = {
      appId: id,
      type: type,
      id: selectedControlledApps?.id,
      format: selectedControlledApps?.format,
      newId: controlledId,
      name: controlledName,
      category: category,
      favicon: favicon,
    }
    onClickAdd(temp)
  }

  const handleEditSave = () => {
    if (!id || !type || !selectedControlledApps || !app) return
    const temp: AddApp = {
      appId: id,
      type: type,
      id: selectedControlledApps?.id,
      format: selectedControlledApps?.format,
      newId: controlledId,
      category: category,
      name: controlledName,
      favicon: favicon,
    }
    onClickEdit(temp, app)
  }

  const handleClose = () => {
    setSelectedControlledApps(null)
    setFavicon('')
    setControlledId('controlledapp.')
    setControlledName('')
    setId('')
    setType('')
    setPlatform(null)
    onClose()
  }

  const populateEntry = useCallback(() => {
    if (!app) return
    setId(app.appId)
    setSelectedControlledApps(listings.find((listing) => listing.id === (app.id ? app.id : app.signature_id)) || null)
    setPlatform(platforms.find((plat) => plat.id === app.type) || null)
    setType(app.type)
    setFavicon('')
    setControlledId('')
    setControlledName('')
    setCategory('')
    setValidateRegex('')
  }, [app, listings])

  useEffect(() => {
    populateEntry()
  }, [populateEntry])

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <Box sx={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <Box sx={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} justifyContent="space-between" alignItems="center">
            <Grid item justifyContent="center">
              {edit ? (
                <Typography variant="h5" noWrap>
                  Edit a Application
                </Typography>
              ) : (
                <Typography variant="h5" noWrap>
                  Add a Application
                </Typography>
              )}
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          {error && (
            <Grid container justifyContent="center" sx={{ mb: 3 }}>
              <Grid item>
                <Alert severity="error">{error}</Alert>
              </Grid>
            </Grid>
          )}
          <Grid container direction="row" alignItems="center" justifyContent="center" padding={2} spacing={3}>
            <Grid item>
              {edit ? (
                <Typography variant="body1">
                  You can edit an app by changing its platform, id or the signature/controlled app the id is apart of
                  (or create a new controlled app)
                </Typography>
              ) : (
                <Typography variant="body1">
                  You can add a new app by associating it with a signature or with a seperate controlled app, allowing
                  you to keep the app standalone or linked.
                </Typography>
              )}
            </Grid>
            <Grid item xs={9}>
              <ControlledAppSelector
                app={edit ? app : null}
                applications={listings}
                onChange={handleChangeControlledApps}
              />
            </Grid>
          </Grid>
          <Divider />
          {selectedControlledApps?.id === 'create-new' && (
            <Grid item>
              <Grid container alignItems="center" justifyContent="center" padding={2} spacing={3}>
                <Grid item>
                  <Typography variant="h6">New Controlled App</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    This option creates a new application that is not linked to an existing signature.
                  </Typography>
                </Grid>
              </Grid>
              <AddControlDialog
                controlId={controlledId}
                controlName={controlledName}
                controlCategory={category}
                signatures={signatures}
                favicon={favicon}
                editing={false}
                onChange={handleChangeControl}
              />
              <Divider />
            </Grid>
          )}
          <Grid container alignItems="stretch" justifyContent="center" padding={2} spacing={3}>
            <Grid item>
              <Typography variant="h6">Application Details</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center" justifyContent="center" padding={2} spacing={2}>
            <Grid item>
              <Typography variant="overline">Platform</Typography>
              <br />
              <Autocomplete
                id="app_platform"
                options={platforms}
                sx={{ minWidth: 300 }}
                renderInput={(params) => <TextField {...params} fullWidth />}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={platform}
                onChange={(e, newValue) => handleChangeType(newValue)}
              />
            </Grid>
            <Grid item>
              {type.startsWith('windows') ? (
                <Typography variant="overline">RegEx (case insensitive)</Typography>
              ) : (
                <Typography variant="overline">App Id</Typography>
              )}
              <br />
              <TextField
                id="appId"
                variant="outlined"
                sx={{ minWidth: '300px' }}
                value={id}
                onChange={handleChangeAppId}
                helperText={appError}
              />
            </Grid>
            {type.startsWith('windows') && (
              <Grid item xs={10}>
                <Grid container alignItems="center" justifyContent="start" padding={1} paddingLeft={11} spacing={3}>
                  <Grid item xs={10}>
                    <Typography variant="overline">Test Regex</Typography>
                    <br />
                    <TextField
                      id="testRegEx"
                      variant="outlined"
                      sx={{ minWidth: '300px' }}
                      value={validateRegex}
                      onChange={handleChangeValidateRegex}
                    />
                  </Grid>

                  <Grid item>
                    <br />
                    {validated ? (
                      <Tooltip title="Regex matches" placement="top">
                        <CheckIcon color="primary" fontSize="large" />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Regex does not match" placement="top">
                        <CloseIcon color="error" fontSize="large" />
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid item>
            <Grid container alignItems="center" justifyContent="center" padding={2} spacing={3}>
              <Grid item>
                {edit ? (
                  <Button
                    variant="contained"
                    sx={{ minWidth: '125px' }}
                    disabled={
                      loading ||
                      !id ||
                      !type ||
                      !selectedControlledApps ||
                      !!appError ||
                      (selectedControlledApps?.id === 'create-new' &&
                        (controlledId === 'controlledapp.' || !controlledName))
                    }
                    onClick={handleEditSave}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{ minWidth: '125px' }}
                    disabled={
                      loading ||
                      !id ||
                      !type ||
                      !selectedControlledApps ||
                      !!appError ||
                      (selectedControlledApps?.id === 'create-new' &&
                        (controlledId === 'controlledapp.' || !controlledName))
                    }
                    onClick={handleClickAdd}
                  >
                    Add
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  )
}

export default AddAppControl
