import React, { useState, useEffect } from 'react'
import useIsMounted from 'react-is-mounted-hook'

import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import LoadingButton from '@mui/lab/LoadingButton'

import CloseIcon from '@mui/icons-material/Close'

import { useTheme } from '@mui/material/styles'

import handleError from '../util/Error.tsx'
import { createKeyword, Keyword } from '../util/Api.tsx'

interface KeywordCreateProps {
  open?: boolean
  onClose?: () => void
  onSave?: (keywords: Keyword[]) => void
}

const KeywordCreate = ({ open = false, onClose = () => {}, onSave = () => {} }: KeywordCreateProps) => {
  const theme = useTheme()
  const isMounted = useIsMounted()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  const handleChangeId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setId(e.target.value)
  }

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value)
  }

  const handleSave = async () => {
    try {
      if (id === '' || name === '' || description === '') {
        throw new Error('You must fill in all fields')
      }

      setLoading(true)
      const { keywords } = await createKeyword({
        id,
        name,
        description,
      })
      if (!isMounted()) return

      onClose()
      onSave(keywords)
      setError('')
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  useEffect(() => {
    setError('')
  }, [open])

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                Create a new keyword category
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container padding={1} justifyContent="center">
            <Grid item>
              {open && (
                <Collapse in={Boolean(error)}>
                  <Alert severity="error">{error || 'Error'}</Alert>
                </Collapse>
              )}
            </Grid>
          </Grid>
          <Grid container padding={3} spacing={2}>
            <Grid item xs={12}>
              <TextField label="ID" fullWidth value={id} disabled={loading} onChange={handleChangeId} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Name" fullWidth value={name} disabled={loading} onChange={handleChangeName} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                fullWidth
                value={description}
                disabled={loading}
                onChange={handleChangeDescription}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    disableElevation
                    loading={loading}
                    onClick={handleSave}
                  >
                    Create Keyword Category
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}

export default KeywordCreate
