import { useCallback, useEffect, useState } from 'react'
import { GenericListEntry } from '../../util/Api.tsx'
import {
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

interface GenericListsEntryTableProps {
  admin?: boolean
  entries: GenericListEntry[]
  loading?: boolean
  onDelete?: (list: GenericListEntry) => void
}

const GenericListsEntryTable = ({ admin, entries, loading, onDelete = () => {} }: GenericListsEntryTableProps) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [orderBy, setOrderBy] = useState<keyof GenericListEntry>('entry')
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')

  const getKeywordEntries = useCallback(() => {
    const sorted = entries.sort((a, b) => {
      if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1
      if (a[orderBy] < b[orderBy]) return order === 'desc' ? 1 : -1
      return 0
    })
    const currentPage: (GenericListEntry | null)[] = sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const length = currentPage.length
    if (currentPage.length === rowsPerPage) return currentPage
    for (let i = 0; i < rowsPerPage - length; i++) {
      currentPage.push(null)
    }
    return currentPage
  }, [entries, page, rowsPerPage, order, orderBy])

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const handleClickDelete = async (e: React.MouseEvent<HTMLButtonElement>, list: GenericListEntry) => {
    try {
      e.preventDefault()
      e.stopPropagation()
      onDelete(list)
    } catch (err) {
      throw err
    }
  }

  const createSortHandler = (id: keyof GenericListEntry) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (id !== orderBy) {
      setOrder('asc')
      return setOrderBy(id)
    }
    if (order === 'asc') {
      return setOrder('desc')
    }
    if (order === 'desc') {
      return setOrder('asc')
    }
  }

  useEffect(() => {
    setPage(0)
  }, [entries])

  return (
    <Paper variant="outlined">
      <TableContainer>
        <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'entry'}
                  direction={orderBy === 'entry' ? order : 'asc'}
                  onClick={createSortHandler('entry')}
                >
                  Entry
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'imported'}
                  direction={orderBy === 'imported' ? order : 'asc'}
                  onClick={createSortHandler('imported')}
                >
                  Imported Date
                </TableSortLabel>
              </TableCell>
              {admin && <TableCell align="right">Delete</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {getKeywordEntries().map((row, index) => (
              <TableRow
                key={`keyword-entry-table-item-${index}`}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  height: 47,
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="body2" noWrap sx={{ maxWidth: 950 }}>
                    {row?.entry || ''}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant="body2" noWrap sx={{ maxWidth: 300 }}>
                    {row?.imported
                      ? Intl.DateTimeFormat().resolvedOptions().timeZone.substring(0, 7) === 'America'
                        ? Intl.DateTimeFormat('en-US', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                          }).format(new Date(row?.imported))
                        : Intl.DateTimeFormat('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                          }).format(new Date(row?.imported))
                      : ''}
                  </Typography>
                </TableCell>
                {admin && (
                  <TableCell align="right">
                    {row?.entry && (
                      <IconButton size="small" disabled={loading || !row} onClick={(e) => handleClickDelete(e, row)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={entries.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default GenericListsEntryTable
