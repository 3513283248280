import {
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { ImageClassifierEntries } from '../util/Api.tsx'
import { useCallback, useEffect, useState } from 'react'

interface ImageClassifierTableProps {
  classifierEntries: ImageClassifierEntries[]
  loading?: boolean
  dark?: boolean
  onEdit?: (classifier: ImageClassifierEntries) => void
  onDelete?: (classifier: ImageClassifierEntries) => void
}

const ImageClassiferEntryTable = ({
  classifierEntries,
  loading,
  dark = false,
  onEdit = () => {},
  onDelete = () => {},
}: ImageClassifierTableProps) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [orderBy, setOrderBy] = useState<keyof ImageClassifierEntries>('term')
  const [order, setOrder] = useState<'asc' | 'desc'>('desc')

  const getMappings = useCallback(() => {
    const sorted = classifierEntries.sort((a, b) => {
      if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1
      if (a[orderBy] < b[orderBy]) return order === 'desc' ? 1 : -1
      return 0
    })
    const currentPage: (ImageClassifierEntries | null)[] = sorted.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    )
    const length = currentPage.length
    if (currentPage.length === rowsPerPage) return currentPage
    for (let i = 0; i < rowsPerPage - length; i++) {
      currentPage.push(null)
    }
    return currentPage
  }, [classifierEntries, page, rowsPerPage, order, orderBy])

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const handleClickDelete = async (e: React.MouseEvent, Entry: ImageClassifierEntries) => {
    try {
      e.preventDefault()
      e.stopPropagation()
      onDelete(Entry)
    } catch (err) {
      throw err
    }
  }

  const handleClickEdit = async (e: React.MouseEvent, Entry: ImageClassifierEntries) => {
    onEdit(Entry)
  }

  const createSortHandler = (id: keyof ImageClassifierEntries) => (e: React.MouseEvent) => {
    if (id !== orderBy) {
      setOrder('asc')
      return setOrderBy(id)
    }
    if (order === 'asc') {
      return setOrder('desc')
    }
    if (order === 'desc') {
      return setOrder('asc')
    }
  }

  useEffect(() => {
    setPage(0)
  }, [classifierEntries])

  return (
    <Paper variant="outlined">
      <TableContainer>
        <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell width="30%">
                <TableSortLabel
                  active={orderBy === 'term'}
                  direction={orderBy === 'term' ? order : 'asc'}
                  onClick={createSortHandler('term')}
                >
                  Term
                </TableSortLabel>
              </TableCell>
              <TableCell width="30%">
                <TableSortLabel
                  active={orderBy === 'notes'}
                  direction={orderBy === 'notes' ? order : 'asc'}
                  onClick={createSortHandler('notes')}
                >
                  Notes
                </TableSortLabel>
              </TableCell>
              <TableCell width="30%">
                <TableSortLabel
                  active={orderBy === 'imported'}
                  direction={orderBy === 'imported' ? order : 'asc'}
                  onClick={createSortHandler('imported')}
                >
                  Imported Date
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">Edit/Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getMappings().map((row, index) => (
              <TableRow
                hover
                key={`keyword-table-item-${index}`}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: 'pointer',
                  height: 47,
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="body2" noWrap sx={{ maxWidth: 280 }}>
                    {row?.term || ''}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant="body2" noWrap sx={{ maxWidth: 280 }}>
                    {row?.notes || ''}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" noWrap sx={{ maxWidth: 280 }}>
                    {row
                      ? row?.imported
                        ? Intl.DateTimeFormat().resolvedOptions().timeZone.substring(0, 7) === 'America'
                          ? Intl.DateTimeFormat('en-US', {
                              day: 'numeric',
                              month: 'short',
                              year: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              second: 'numeric',
                            }).format(new Date(row?.imported))
                          : Intl.DateTimeFormat('en-GB', {
                              day: 'numeric',
                              month: 'short',
                              year: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              second: 'numeric',
                            }).format(new Date(row?.imported))
                        : 'No Recorded Date'
                      : ''}
                  </Typography>
                </TableCell>

                <TableCell align="right">
                  {row && (
                    <IconButton size="small" disabled={loading || !row} onClick={(e) => handleClickEdit(e, row)}>
                      <EditIcon />
                    </IconButton>
                  )}
                  {row && (
                    <IconButton size="small" disabled={loading || !row} onClick={(e) => handleClickDelete(e, row)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={classifierEntries.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default ImageClassiferEntryTable
