import React, { useState, useEffect, useCallback } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import TableSortLabel from '@mui/material/TableSortLabel'
import LazyImage from './LazyImage.tsx'
import { Directive } from '../util/Api.tsx'

interface DirectivesTableProps {
  admin?: boolean
  directives: Directive[]
  loading?: boolean
  selected?: string
  dark?: boolean
  onClick?: (dir: Directive) => void
}

const DirectivesTable = ({ directives, loading, selected, dark = false, onClick = () => {} }: DirectivesTableProps) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [orderBy, setOrderBy] = useState<keyof Directive>('id')
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')

  const getMappings = useCallback(() => {
    const sorted = directives.sort((a, b) => {
      if (a[orderBy] || '' > (b[orderBy] || '')) return order === 'asc' ? 1 : -1
      if (a[orderBy] || '' < (b[orderBy] || '')) return order === 'desc' ? 1 : -1
      return 0
    })
    const currentPage: (Directive | null)[] = sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const length = currentPage.length
    if (currentPage.length === rowsPerPage) return currentPage
    for (let i = 0; i < rowsPerPage - length; i++) {
      currentPage.push(null)
    }
    return currentPage
  }, [directives, page, rowsPerPage, orderBy, order])

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const createSortHandler = (id: keyof Directive) => (e: React.MouseEvent) => {
    if (id !== orderBy) {
      setOrder('asc')
      return setOrderBy(id)
    }
    if (order === 'asc') {
      return setOrder('desc')
    }
    if (order === 'desc') {
      return setOrder('asc')
    }
  }

  useEffect(() => {
    setPage(0)
  }, [directives])

  return (
    <Paper variant="outlined">
      <TableContainer>
        <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Favicon</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'id'}
                  direction={orderBy === 'id' ? order : 'asc'}
                  onClick={createSortHandler('id')}
                >
                  Content Mod
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={createSortHandler('name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getMappings().map((row, index) => (
              <TableRow
                hover
                key={`keyword-table-item-${index}`}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: 'pointer',
                  height: 47,
                  backgroundColor:
                    row?.id === selected && !dark ? '#ededed' : row?.id === selected && dark ? '#4b4b4b' : null,
                }}
                onClick={row ? () => onClick(row) : () => {}}
              >
                <TableCell component="th" scope="row">
                  {row && <LazyImage src={row.favicon_url || ''} size={24} />}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant="body2" noWrap sx={{ maxWidth: 280 }}>
                    {row?.id || ''}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant="body2" noWrap sx={{ maxWidth: 280 }}>
                    {row?.name || ''}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={directives.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default DirectivesTable
