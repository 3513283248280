import { Alert, Box, Button, Dialog, Divider, Grid, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'

import { useTheme } from '@mui/material/styles'
import { AppListings, Signature } from '../util/Api.tsx'
import AddControlDialog from './AddControlDialog.tsx'
import ControlledAppSelector from './ControlledAppSelector.tsx'

interface EditControlledAppProps {
  open: boolean
  error: string
  loading: boolean
  onClose: () => void
  listings: AppListings[]
  signatures: Signature[]
  onSave: (favicon: string, id: string, name: string, category: string) => void
}

const EditControlledApp = ({
  open = false,
  error = '',
  loading = false,
  signatures,
  onClose = () => {},
  onSave = () => {},
  listings,
}: EditControlledAppProps) => {
  const theme = useTheme()
  const [selectedControlledApps, setSelectedControlledApps] = useState<AppListings | null>(null)
  const [favicon, setFavicon] = useState('')
  const [id, setId] = useState('controlledapp.')
  const [name, setName] = useState('')
  const [category, setCategory] = useState('')

  const handleClose = () => {
    onClose()
  }

  const handleChangeControl = (controlId: string, controlName: string, favicon: string, cat: string) => {
    setId(controlId)
    setName(controlName)
    setFavicon(favicon)
    setCategory(cat)
  }

  const handleChangeControlledApps = (e: AppListings | null) => {
    setSelectedControlledApps(e)
    setId(e?.id || '')
    setName(e?.name || '')
    setFavicon(e?.favicon || '')
    setCategory(e?.category || '')
  }

  const handleEditSave = () => {
    setSelectedControlledApps(null)
    onSave(favicon, id, name, category)
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <Box sx={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <Box sx={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} justifyContent="space-between" alignItems="center">
            <Grid item justifyContent="center">
              <Typography variant="h5" noWrap>
                Edit a Application
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          {error && (
            <Grid container justifyContent="center" sx={{ mb: 3 }}>
              <Grid item>
                <Alert severity="error">{error}</Alert>
              </Grid>
            </Grid>
          )}
          <Grid container direction="row" alignItems="center" justifyContent="center" padding={2} spacing={3}>
            <Grid item>
              <Typography variant="body1">Select a Controlled App to edit</Typography>
            </Grid>
            <Grid item xs={9}>
              <ControlledAppSelector app={null} applications={listings} onChange={handleChangeControlledApps} />
            </Grid>
          </Grid>
          <Divider />
          {selectedControlledApps?.id && (
            <Grid item>
              <Grid container alignItems="center" justifyContent="center" padding={2} spacing={3}>
                <Grid item>
                  <Typography variant="h6">Controlled App</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    You can edit a Controlled App by changing the name and/or the icon.
                  </Typography>
                </Grid>
              </Grid>
              <AddControlDialog
                controlId={id}
                controlName={name}
                favicon={favicon}
                controlCategory={signatures.find((sig) => sig.id === category)?.id || ''}
                signatures={signatures}
                editing={true}
                onChange={handleChangeControl}
              />
            </Grid>
          )}
          <Grid item>
            <Grid container alignItems="center" justifyContent="center" padding={2} spacing={3}>
              <Grid item>
                <Button
                  variant="contained"
                  sx={{ minWidth: '125px' }}
                  disabled={loading || !id || !name}
                  onClick={handleEditSave}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  )
}

export default EditControlledApp
