import { Signature, WeightedPhrase } from '../util/Api.tsx'
import React from 'react'

import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Dialog from '@mui/material/Dialog'
import { useEffect } from 'react'
import { useState } from 'react'

import { useTheme } from '@mui/material/styles'
import SignatureSelect from './SignatureSelect.tsx'

interface phraseDialogProps {
  selected: Signature | null
  signatures: Signature[]
  onSave: (id: string | null, phrase: string[], weight: string, banned: boolean) => void
  dark: boolean
  open: boolean
  onClose: () => void
  error: string
  weightedPhrase: WeightedPhrase | null
  status: string
}

const PhraseDialog = ({
  selected,
  signatures,
  onSave = () => {},
  dark,
  open = false,
  onClose = () => {},
  error,
  weightedPhrase,
  status,
}: phraseDialogProps) => {
  const theme = useTheme()
  const [newSignature, setNewSignature] = useState<Signature | null>(null)
  const [phrase, setPhrase] = useState(weightedPhrase?.phrase.join('\n') || '')
  const [phrases, setPhrases] = useState<string[]>(weightedPhrase?.phrase || [])
  const [weight, setWeight] = useState(weightedPhrase?.weight || '')
  const [banned, setBanned] = useState<boolean>(weightedPhrase?.banned ? true : false)

  const handleChangePhrase = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhrase(e.target.value)
    if (e.target.value.length === 0) {
      return setPhrases([])
    }
    setPhrases(
      e.target.value
        .trim()
        .split('\n')
        .map((s) => s.trim())
    )
  }
  const handleChangeWeight = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!e.target.value) return
    setWeight(e.target.value)
  }

  const handleToggleBanned = () => {
    if (banned) {
      setWeight('100')
    }
    setBanned(!banned)
  }

  const handleClickAdd = () => {
    if (!newSignature) {
      alert('Please select a signature')
      return
    }
    onSave(newSignature?.id, phrases, weight.toString(), banned)
  }

  const handleChangeSignature = (e: React.SyntheticEvent, signature: Signature | null) => {
    setNewSignature(signature)
  }

  const handleClose = () => {
    onClose()
  }

  useEffect(() => {
    setPhrase(weightedPhrase?.phrase.join('\n') || '')
    setWeight(weightedPhrase?.weight || '')
    setPhrases(weightedPhrase?.phrase || [])
    setBanned(weightedPhrase?.banned ? true : false)
    setNewSignature(selected)
  }, [selected, weightedPhrase?.banned, weightedPhrase?.phrase, weightedPhrase?.weight])
  return (
    <Dialog open={open} onClose={handleClose}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                {status === 'Edit' && 'Edit the'}
                {status === 'Add' && 'Add a'} Phrase
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          {error && (
            <Grid container justifyContent="center" sx={{ mb: 3 }}>
              <Grid item>
                <Alert severity="error">{error}</Alert>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            direction="column"
            alignItems="stretch"
            justifyContent="center"
            padding={2}
            spacing={3}
            minWidth={400}
          >
            {status === 'Add' && (
              <Grid item>
                <SignatureSelect signatures={signatures} onChange={handleChangeSignature} defaultSignature={selected} />
              </Grid>
            )}

            <Grid item>
              <TextField
                id="Phrase"
                variant="outlined"
                label="Desired Phrase(s) (Press ENTER for multiple)"
                value={phrase}
                fullWidth
                multiline
                minRows={3}
                onChange={handleChangePhrase}
              />
            </Grid>
            <Grid item>
              <TextField
                id="Weighting"
                variant="outlined"
                label="Weighting (Min: -100, Max: 100)"
                type="number"
                fullWidth
                disabled={banned}
                defaultValue="0"
                value={weight}
                onChange={handleChangeWeight}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={<Switch checked={banned} onChange={() => handleToggleBanned()} />}
                label="Banned (sets weighting to 100)"
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                fullWidth
                disabled={!phrase || !weight || !newSignature}
                onClick={handleClickAdd}
              >
                {status} Phrase
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}

export default PhraseDialog
