import {
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import LazyImage from './LazyImage.tsx'
import { useCallback, useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { ImageClassifierCategory, Signature } from '../util/Api.tsx'

interface ImageClassifierTableProps {
  classifierCategories: ImageClassifierCategory[]
  loading?: boolean
  selected?: ImageClassifierCategory | null
  signatures: Signature[]
  dark?: boolean
  onClick?: (classifier: ImageClassifierCategory) => void
  onEdit?: (classifier: ImageClassifierCategory) => void
  onDelete?: (classifier: ImageClassifierCategory) => void
}

const ImageClassifierTable = ({
  classifierCategories,
  loading,
  selected,
  signatures,
  dark = false,
  onClick = () => {},
  onEdit = () => {},
  onDelete = () => {},
}: ImageClassifierTableProps) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [orderBy, setOrderBy] = useState<keyof ImageClassifierCategory>('label')
  const [order, setOrder] = useState<'asc' | 'desc'>('desc')

  const getMappings = useCallback(() => {
    const sorted = classifierCategories.sort((a, b) => {
      if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1
      if (a[orderBy] < b[orderBy]) return order === 'desc' ? 1 : -1
      return 0
    })
    const currentPage: (ImageClassifierCategory | null)[] = sorted.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    )
    const length = currentPage.length
    if (currentPage.length === rowsPerPage) return currentPage
    for (let i = 0; i < rowsPerPage - length; i++) {
      currentPage.push(null)
    }
    return currentPage
  }, [classifierCategories, page, rowsPerPage, order, orderBy])

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const handleClickDelete = async (e: React.MouseEvent, Entry: ImageClassifierCategory) => {
    try {
      e.preventDefault()
      e.stopPropagation()
      onDelete(Entry)
    } catch (err) {
      throw err
    }
  }

  const handleClickEdit = async (e: React.MouseEvent, Entry: ImageClassifierCategory) => {
    onEdit(Entry)
  }

  const createSortHandler = (id: keyof ImageClassifierCategory) => (e: React.MouseEvent) => {
    if (id !== orderBy) {
      setOrder('asc')
      return setOrderBy(id)
    }
    if (order === 'asc') {
      return setOrder('desc')
    }
    if (order === 'desc') {
      return setOrder('asc')
    }
  }

  useEffect(() => {
    setPage(0)
  }, [classifierCategories])

  return (
    <Paper variant="outlined">
      <TableContainer>
        <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
        <Table sx={{ minWidth: 700 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell width="30%">
                <TableSortLabel
                  active={orderBy === 'label'}
                  direction={orderBy === 'label' ? order : 'asc'}
                  onClick={createSortHandler('label')}
                >
                  Label
                </TableSortLabel>
              </TableCell>
              <TableCell width="30%">
                <TableSortLabel
                  active={orderBy === 'signature_id'}
                  direction={orderBy === 'signature_id' ? order : 'asc'}
                  onClick={createSortHandler('signature_id')}
                >
                  Signature
                </TableSortLabel>
              </TableCell>
              <TableCell width="30%">
                <TableSortLabel
                  active={orderBy === 'threshold'}
                  direction={orderBy === 'threshold' ? order : 'asc'}
                  onClick={createSortHandler('threshold')}
                >
                  Threshold
                </TableSortLabel>
              </TableCell>
              <TableCell align="right" width="10%">
                Edit/Delete
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getMappings().map((row, index) => (
              <TableRow
                hover
                key={`keyword-table-item-${index}`}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: 'pointer',
                  height: 47,
                  backgroundColor:
                    row?.signature_id === selected?.signature_id
                      ? dark
                        ? '#4b4b4b'
                        : '#ededed'
                      : row?.id === selected
                      ? dark
                        ? '#4b4b4b'
                        : '#ededed'
                      : null,
                }}
                onClick={row?.id ? () => onClick(row) : () => {}}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="body2" noWrap sx={{ maxWidth: 280 }}>
                    {row?.label || ''}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Grid container>
                    <Grid item pr={1}>
                      {row && (
                        <LazyImage
                          src={signatures.find((sig) => sig.id === row.signature_id)?.faviconUrl || ''}
                          size={24}
                        />
                      )}
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" sx={{ maxWidth: 280 }}>
                        {signatures.find((sig) => sig.id === row?.signature_id)?.name || row?.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid container>
                    <Grid item pr={1} xs={10} pt={0.5}>
                      {row && (
                        <LinearProgress
                          variant="determinate"
                          value={row?.threshold}
                          sx={{ width: '100%', mr: 1, height: '13px' }}
                        />
                      )}
                    </Grid>
                    <Grid item>
                      {row && (
                        <Typography variant="body2" sx={{ maxWidth: 280 }}>
                          {row?.threshold}%
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </TableCell>

                <TableCell align="right">
                  {row && (
                    <IconButton size="small" disabled={loading || !row} onClick={(e) => handleClickEdit(e, row)}>
                      <EditIcon />
                    </IconButton>
                  )}
                  {row && (
                    <IconButton size="small" disabled={loading || !row} onClick={(e) => handleClickDelete(e, row)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={classifierCategories.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default ImageClassifierTable
