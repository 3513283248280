import React, { useState, useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import QuestionIcon from '@mui/icons-material/QuestionAnswer'

import SearchBar from './SearchBar.tsx'
import URLSearchDialog from './URLSearchDialog.tsx'
import AccountButton from './AccountButton.tsx'
import DownvotesDialog from './DownvotesDialog.tsx'
import CreateSuggestionDialog from './CreateSuggestionDialog.tsx'

import useStore from '../util/Store.tsx'
import { countPendingSuggestions } from '../util/Api.tsx'
import useIsMounted from 'react-is-mounted-hook'
import handleError from '../util/Error.tsx'
import { Grid, Menu, MenuItem } from '@mui/material'

const NavAppBar = () => {
  const history = useHistory()
  const location = useLocation()
  const isMounted = useIsMounted()
  const [token] = useStore('token')
  const [user] = useStore('user')

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const [search, setSearch] = useState('')
  const [searchOpen, setSearchOpen] = useState(false)
  const [downvotesOpen, setDownvotesOpen] = useState(false)
  const [suggestionsOpen, setSuggestionsOpen] = useState(false)
  const [pendingSuggestionCount, setPendingSuggestionCount] = useState<null | number>(null)
  const [suggestUrls, setSuggestUrls] = useState<string[]>([])
  const [hovered, setHovered] = useState('')
  const [selected, setSelected] = useState('')

  const changeBackground = async (name: string) => {
    setHovered(name)
  }

  const removeBackground = async () => {
    setHovered('')
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, name: string) => {
    setAnchorEl(event.currentTarget)
    setSelected(name)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setSelected('')
  }

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const handleSearchClose = useCallback(() => {
    setSearchOpen(false)
  }, [])

  const handleClickSearch = useCallback(() => {
    setSearchOpen(true)
  }, [])

  const handleClickDownvotes = useCallback(() => {
    setDownvotesOpen(true)
  }, [])

  const handleNavigateMappings = () => {
    history.push('/mappings')
    handleClose()
  }

  const handleNavigateApplications = () => {
    history.push('/applications')
    handleClose()
  }

  const handleNavigateGenericLists = () => {
    history.push('/generic-lists')
    handleClose()
  }

  const handleNavigateKeywords = () => {
    history.push('/keywords')
    handleClose()
  }

  const handleNavigateDirectives = () => {
    history.push('/content-modifications')
    handleClose()
  }

  const handleNavigateWeightedPhrases = () => {
    history.push('/weighted-phrases')
    handleClose()
  }

  const handleNavigateMonitorModeration = () => {
    history.push('/monitor-moderation')
    handleClose()
  }

  const handleNavigateSignatures = () => {
    history.push('/signatures')
    handleClose()
  }

  const handleNavigateHome = () => {
    history.push('/appindex-manager')
  }

  const handleNavigateSignatureTree = () => {
    history.push('/signature-tree')
    handleClose()
  }

  const handleSubmitSearch = () => {
    setSearchOpen(true)
  }

  const handleClickSignature = (signatureId: string) => {
    history.push(`/signature/${signatureId}`)
  }

  const handleClickClip = () => {
    history.push(`/image-classifier`)
    handleClose()
  }

  const handleReclassify = (signatureId: string) => {
    history.push(`/signature/${signatureId}`)
  }

  const handleDownvotesClose = () => {
    setDownvotesOpen(false)
  }

  const handleClickWebsite = (website: string) => {
    setSearch(website)
    setSearchOpen(true)
  }

  const handleOpenSuggestion = () => {
    setSuggestionsOpen(true)
  }

  const handleCloseSuggestions = () => {
    setSuggestionsOpen(false)
  }

  const handleSuggestUrlRecat = (website: string) => {
    setSuggestUrls([website])
    setSuggestionsOpen(true)
  }

  const handleNavigateBlockman = () => {
    history.push('/blockman')
  }

  const populatePendingSuggestionCount = useCallback(async () => {
    try {
      if (location.pathname === '/suggestions') return
      const data = await countPendingSuggestions()
      if (!isMounted()) return
      setPendingSuggestionCount(data.count)
    } catch (err) {
      handleError(err)
    }
  }, [isMounted, location])

  useEffect(() => {
    if (!user?.appindexAdmin) return
    if (!token) return
    const timeout = setTimeout(() => {
      populatePendingSuggestionCount()
    }, 1000)
    const interval = setInterval(() => {
      populatePendingSuggestionCount()
    }, 60 * 1000)
    return () => {
      clearTimeout(timeout)
      clearInterval(interval)
    }
  }, [token, user, populatePendingSuggestionCount])

  return (
    <Box sx={{ flexGrow: 1 }} className="no-print">
      <AppBar position="static" color="primary">
        <Toolbar color="primary">
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' }, cursor: 'pointer' }}
            onClick={handleNavigateHome}
          >
            Appindex Manager
          </Typography>
          <Box sx={{ mx: 2 }}>
            <SearchBar
              value={search}
              disabled={!token}
              onChange={handleChangeSearch}
              onSubmit={handleSubmitSearch}
              onClickButton={handleClickSearch}
              button
            />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          {token && (
            <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
              <Button
                color="inherit"
                disabled={!token}
                aria-controls={open ? 'Appindex-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(e) => handleClick(e, 'appindex')}
                sx={
                  selected === 'appindex' ? { background: 'lightgrey', ml: 1, fontSize: 18 } : { ml: 1, fontSize: 18 }
                }
              >
                Appindex
              </Button>
              <Menu id="Appindex-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                <Grid container>
                  <Grid
                    item
                    onMouseEnter={() => changeBackground('signature')}
                    onMouseOut={removeBackground}
                    xs={12}
                    sx={hovered === 'signature' ? { background: 'lightgrey' } : {}}
                  >
                    <MenuItem onClick={handleNavigateSignatures}>
                      <Typography
                        sx={{ fontSize: 16 }}
                        onMouseEnter={() => changeBackground('signature')}
                        onMouseOut={removeBackground}
                      >
                        SIGNATURES
                      </Typography>
                    </MenuItem>
                  </Grid>
                </Grid>
                <Grid
                  item
                  onMouseEnter={() => changeBackground('tree')}
                  onMouseOut={removeBackground}
                  xs={12}
                  sx={hovered === 'tree' ? { background: 'lightgrey' } : {}}
                >
                  <MenuItem
                    onClick={handleNavigateSignatureTree}
                    onMouseEnter={() => changeBackground('tree')}
                    onMouseOut={removeBackground}
                  >
                    <Typography sx={{ fontSize: 16 }}>TREE</Typography>
                  </MenuItem>
                </Grid>
                <Grid
                  item
                  onMouseEnter={() => changeBackground('keywords')}
                  onMouseOut={removeBackground}
                  xs={12}
                  sx={hovered === 'keywords' ? { background: 'lightgrey' } : {}}
                >
                  <MenuItem onClick={handleNavigateKeywords}>
                    <Typography
                      sx={{ fontSize: 16 }}
                      onMouseEnter={() => changeBackground('keywords')}
                      onMouseOut={removeBackground}
                    >
                      KEYWORDS
                    </Typography>
                  </MenuItem>
                </Grid>
                <Grid
                  item
                  onMouseEnter={() => changeBackground('mappings')}
                  onMouseOut={removeBackground}
                  xs={12}
                  sx={hovered === 'mappings' ? { background: 'lightgrey' } : {}}
                >
                  <MenuItem onClick={handleNavigateMappings}>
                    <Typography
                      sx={{ fontSize: 16 }}
                      onMouseEnter={() => changeBackground('mappings')}
                      onMouseOut={removeBackground}
                    >
                      MAPPINGS
                    </Typography>
                  </MenuItem>
                </Grid>
                <Grid
                  item
                  onMouseEnter={() => changeBackground('applications')}
                  onMouseOut={removeBackground}
                  xs={12}
                  sx={hovered === 'applications' ? { background: 'lightgrey' } : {}}
                >
                  <MenuItem onClick={handleNavigateApplications}>
                    <Typography
                      sx={{ fontSize: 16 }}
                      onMouseEnter={() => changeBackground('applications')}
                      onMouseOut={removeBackground}
                    >
                      APP ID CONTROLS
                    </Typography>
                  </MenuItem>
                </Grid>
                <Grid
                  item
                  onMouseEnter={() => changeBackground('genericlists')}
                  onMouseOut={removeBackground}
                  xs={12}
                  sx={hovered === 'genericlists' ? { background: 'lightgrey' } : {}}
                >
                  <MenuItem onClick={handleNavigateGenericLists}>
                    <Typography
                      sx={{ fontSize: 16 }}
                      onMouseEnter={() => changeBackground('genericlists')}
                      onMouseOut={removeBackground}
                    >
                      GENERIC LISTS
                    </Typography>
                  </MenuItem>
                </Grid>
                {user.appindexAdmin && (
                  <Grid
                    item
                    onMouseEnter={() => changeBackground('contentmods')}
                    onMouseOut={removeBackground}
                    xs={12}
                    sx={hovered === 'contentmods' ? { background: 'lightgrey' } : {}}
                  >
                    <MenuItem onClick={handleNavigateDirectives}>
                      <Typography
                        sx={{ fontSize: 16 }}
                        onMouseEnter={() => changeBackground('contentmods')}
                        onMouseOut={removeBackground}
                      >
                        CONTENT MODIFICATIONS
                      </Typography>
                    </MenuItem>
                  </Grid>
                )}
                <Grid item onMouseEnter={() => changeBackground('clip')} onMouseOut={removeBackground} xs={12}>
                  <MenuItem
                    onClick={handleClickClip}
                    onMouseEnter={() => changeBackground('clip')}
                    onMouseOut={removeBackground}
                  >
                    <Typography sx={{ fontSize: 16 }}>IMAGE CLASSIFIER</Typography>
                  </MenuItem>
                </Grid>
                {user.appindexAdmin && (
                  <Grid>
                    <MenuItem
                      onClick={handleNavigateWeightedPhrases}
                      onMouseEnter={() => changeBackground('weighted')}
                      onMouseOut={removeBackground}
                    >
                      <Typography sx={{ fontSize: 16 }}>WEIGHTED PHRASES</Typography>
                    </MenuItem>
                  </Grid>
                )}
                <Grid>
                  <MenuItem
                    onClick={handleNavigateMonitorModeration}
                    onMouseEnter={() => changeBackground('moderation')}
                    onMouseOut={removeBackground}
                  >
                    <Typography sx={{ fontSize: 16 }}>MONITOR MODERATION</Typography>
                  </MenuItem>
                </Grid>
              </Menu>

              <Button color="inherit" disabled={!token} onClick={handleNavigateBlockman} sx={{ ml: 1, fontSize: 18 }}>
                Blockman
              </Button>

              <Button
                color="inherit"
                variant="outlined"
                disabled={!token}
                onClick={handleOpenSuggestion}
                sx={{ ml: 1 }}
                startIcon={<QuestionIcon />}
              >
                Request Change
              </Button>
            </Box>
          )}
          <AccountButton suggestionCount={pendingSuggestionCount} onClickDownvotes={handleClickDownvotes} />
        </Toolbar>
      </AppBar>
      <URLSearchDialog
        open={searchOpen}
        onClose={handleSearchClose}
        value={search}
        onChange={handleChangeSearch}
        onClickSignature={handleClickSignature}
        onReclassify={handleReclassify}
        onClickSuggestUrl={handleSuggestUrlRecat}
      />
      <DownvotesDialog open={downvotesOpen} onClose={handleDownvotesClose} onClickWebsite={handleClickWebsite} />
      <CreateSuggestionDialog
        open={suggestionsOpen}
        onClose={handleCloseSuggestions}
        defaultUrls={suggestUrls}
        afterSubmit={handleSearchClose}
      />
    </Box>
  )
}

export default NavAppBar
