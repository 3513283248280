import React, { useState, useEffect, useMemo } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Signature } from '../util/Api.tsx'

interface SignatureSelectionOption {
  label: string
  value: string
  type: number
  favicon: string | null | undefined
}

const getSigOption = (sig: Signature): SignatureSelectionOption => ({
  label: sig?.name,
  value: sig?.id,
  type: sig?.isCategory ? 2 : sig?.isSubCategory ? 1 : 0,
  favicon: sig?.faviconUrl,
})

interface SignatureOptionProps {
  favicon: string
  name: string
  type: number
  [key: string]: any
}

const SignatureOption = ({ favicon = '', name = '', type = 0, ...props }: SignatureOptionProps) => {
  const catType = useMemo(() => {
    return type === 2 ? 'THM' : type === 1 ? 'CAT' : 'SIG'
  }, [type])

  return (
    <Box
      component="li"
      sx={{
        '& > img': { mr: 2, flexShrink: 0 },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      {...props}
    >
      <img src={favicon} srcSet={favicon} alt="" loading="lazy" width="20" />
      <Typography>
        {name} ({catType})
      </Typography>
    </Box>
  )
}

interface SignatureSelectProps {
  id?: string
  signatures: Signature[]
  error?: string
  defaultSignature?: Signature | null
  label?: string
  onChange?: (e: React.SyntheticEvent, signatures: Signature | null) => void
}

const SignatureSelect = ({
  id = '',
  signatures = [],
  error = '',
  defaultSignature,
  label = '',
  onChange = () => {},
}: SignatureSelectProps) => {
  const [selectedSignature, setSelectedSignature] = useState<SignatureSelectionOption | null>(
    defaultSignature ? getSigOption(defaultSignature) : null
  )

  const handleChangeSelected = (e: React.SyntheticEvent, newValue: SignatureSelectionOption | null) => {
    setSelectedSignature(newValue)
    if (!newValue) {
      onChange(e, null)
    } else {
      onChange(e, signatures.find((sig) => sig.id === newValue.value) || null)
    }
  }

  const handleEquals = (option: SignatureSelectionOption, value: SignatureSelectionOption) => {
    return option?.value === value?.value
  }

  useEffect(() => {
    if (!defaultSignature) {
      setSelectedSignature(null)
      return
    }
    setSelectedSignature(getSigOption(defaultSignature))
  }, [defaultSignature])

  const options = useMemo(() => signatures.map(getSigOption), [signatures])

  const inputLabel = label || 'Select a signature...'

  return (
    <Autocomplete
      id={id}
      options={options}
      value={selectedSignature}
      onChange={handleChangeSelected}
      isOptionEqualToValue={handleEquals}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <SignatureOption favicon={option?.favicon || ''} name={option.label} type={option.type} {...props} />
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={inputLabel}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off', // disable autocomplete and autofill
          }}
          error={Boolean(error)}
          helperText={error}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
        />
      )}
    />
  )
}

export default SignatureSelect
