import React, { useState, useEffect, useCallback } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'
import TableSortLabel from '@mui/material/TableSortLabel'
import Fab from '@mui/material/Fab'
import SearchBar from './SearchBar.tsx'
import { Appstore } from '../util/Api.tsx'

interface AppTableProps {
  apps: Appstore[]
  loading?: boolean
  admin?: boolean
  onClick?: (appstore: Appstore) => void
  onDelete?: (id: string, type: string, control: string) => void
  onAdd?: () => void
}

const AppTable = ({
  apps,
  loading = false,
  admin = false,
  onClick = () => {},
  onDelete = () => {},
  onAdd = () => {},
}: AppTableProps) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [orderBy, setOrderBy] = useState<keyof Omit<Appstore, 'modified' | 'controlapp'>>('id')
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')
  const [search, setSearch] = useState('')
  const [shownApps, setShownApps] = useState<Appstore[]>([])

  const getApps = useCallback(() => {
    const sortedApps = shownApps?.sort((a, b) => {
      if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1
      if (a[orderBy] < b[orderBy]) return order === 'desc' ? 1 : -1
      return 0
    })
    const currentApps: any[] = sortedApps.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const length = currentApps.length
    if (currentApps.length === rowsPerPage) return currentApps
    for (let i = 0; i < rowsPerPage - length; i++) {
      currentApps.push('')
    }
    return currentApps
  }, [shownApps, page, rowsPerPage, order, orderBy])

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    setShownApps(apps.filter((appId) => appId.id.includes(e.target.value) || appId.type.includes(e.target.value)))
    setPage(0)
  }

  const handleClickAdd = () => {
    onAdd()
  }

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const handleClickDelete = (e: React.MouseEvent, row: Appstore) => {
    e.preventDefault()
    e.stopPropagation()
    onDelete(row.id, row.type, row?.controlapp || '')
  }

  const createSortHandler = (id: keyof Omit<Appstore, 'modified' | 'controlapp'>) => (e: React.MouseEvent) => {
    if (id !== orderBy) {
      setOrder('asc')
      return setOrderBy(id)
    }
    if (order === 'asc') {
      return setOrder('desc')
    }
    if (order === 'desc') {
      return setOrder('asc')
    }
  }

  useEffect(() => {
    setPage(0)
    setShownApps(apps)
    setSearch('')
  }, [apps])

  return (
    <Paper variant="outlined">
      <Grid container justifyContent="center" alignItems="center" spacing={2} padding={2}>
        {admin && (
          <Grid item>
            <Fab size="small" color="primary" onClick={handleClickAdd}>
              <AddIcon />
            </Fab>
          </Grid>
        )}
        <Grid item>
          <SearchBar dark value={search} onChange={handleChangeSearch} />
        </Grid>
      </Grid>
      <TableContainer>
        <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'id'}
                  direction={orderBy === 'id' ? order : 'asc'}
                  onClick={createSortHandler('id')}
                >
                  App ID
                </TableSortLabel>
              </TableCell>

              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === 'type'}
                  direction={orderBy === 'type' ? order : 'asc'}
                  onClick={createSortHandler('type')}
                >
                  Platform
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">Control ID</TableCell>
              <TableCell align="center">Last Modified</TableCell>
              {admin && <TableCell align="right">Delete</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {getApps().map((row, index) => (
              <TableRow
                hover={Boolean(row)}
                key={`apps-${row || ''}-${index}`}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: !row ? undefined : 'pointer',
                  height: 40,
                  opacity: !row ? 0 : 1,
                }}
                onClick={row ? () => onClick(row) : () => {}}
              >
                <TableCell component="th" scope="row" align="left">
                  <Typography variant="body2" noWrap>
                    {row.id}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body2" noWrap>
                    {row.type}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body2" noWrap>
                    {row?.controlapp}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2" noWrap>
                    {row.modified
                      ? Intl.DateTimeFormat().resolvedOptions().timeZone.substring(0, 7) === 'America'
                        ? Intl.DateTimeFormat('en-US', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                          }).format(new Date(row.modified))
                        : Intl.DateTimeFormat('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                          }).format(new Date(row.modified))
                      : 'No Recorded Date'}
                  </Typography>
                </TableCell>
                {admin && (
                  <TableCell align="right">
                    <IconButton size="small" disabled={loading || !row} onClick={(e) => handleClickDelete(e, row)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={apps.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default AppTable
