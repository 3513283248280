import React, { ReactNode } from 'react'

import { useTheme } from '@mui/material/styles'

import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'

interface ConfirmDialogProps {
  title?: string
  text?: string | Element | JSX.Element
  buttonText?: string
  open?: boolean
  error?: string
  disabled?: boolean
  loading?: boolean
  color?: 'inherit' | 'error' | 'success' | 'info' | 'warning' | 'primary' | 'secondary' | undefined
  onConfirm?: () => void
  onClose?: () => void
  children?: ReactNode
}

const ConfirmDialog = ({
  title = '',
  text = '',
  buttonText = 'Confirm',
  open = false,
  error = '',
  disabled = false,
  loading = false,
  color = 'error',
  onConfirm = () => {},
  onClose = () => {},
  children,
}: ConfirmDialogProps) => {
  const theme = useTheme()

  const handleClose = () => {
    if (loading) return
    onClose()
  }

  const handleConfirm = () => {
    onConfirm()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                {title}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Collapse in={Boolean(error)}>
            <Box>
              <Grid container padding={2} justifyContent="center">
                <Grid item xs={12}>
                  <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
                    {error || ' '}
                  </Alert>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          {children}
          <Grid container padding={2} spacing={2} justifyContent="space-between">
            <Grid item xs={12}>
              <Typography>{text as ReactNode}</Typography>
            </Grid>
            <Grid item>
              <LoadingButton variant="contained" loading={loading} disabled={disabled} onClick={handleConfirm}>
                {buttonText}
              </LoadingButton>
            </Grid>
            <Grid item>
              <Button variant="contained" color={color} disabled={loading} onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}

export default ConfirmDialog
