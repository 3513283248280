import { WeightedPhrase } from '../util/Api.tsx'
import React, { useState, useEffect, useCallback } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import TableSortLabel from '@mui/material/TableSortLabel'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'

interface PhrasesTableProps {
  shownPhrases: WeightedPhrase[]
  loading: boolean
  dark: boolean
  category: string
  onDelete: (id: WeightedPhrase) => void
  onEdit: (old: WeightedPhrase) => void
}

const PhrasesTable = ({
  shownPhrases,
  loading,
  dark,
  category,
  onDelete = () => {},
  onEdit = () => {},
}: PhrasesTableProps) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [orderBy, setOrderBy] = useState<keyof WeightedPhrase>('phrase')
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')

  const getPhrases = useCallback(() => {
    const sorted = shownPhrases.sort((a, b) => {
      if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1
      if (a[orderBy] < b[orderBy]) return order === 'desc' ? 1 : -1
      return 0
    })
    const currentPage: (WeightedPhrase | null)[] = sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const length = currentPage.length
    if (currentPage.length === rowsPerPage) return currentPage
    for (let i = 0; i < rowsPerPage - length; i++) {
      currentPage.push(null)
    }
    return currentPage
  }, [shownPhrases, page, rowsPerPage, orderBy, order])

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const handleClickDelete = async (e: React.MouseEvent<HTMLButtonElement>, entry: WeightedPhrase) => {
    try {
      e.preventDefault()
      e.stopPropagation()
      onDelete(entry)
    } catch (err) {
      throw err
    }
  }

  const handleClickEdit = async (e: React.MouseEvent<HTMLButtonElement>, entry: WeightedPhrase) => {
    try {
      e.preventDefault()
      e.stopPropagation()
      onEdit(entry)
    } catch (err) {
      throw err
    }
  }

  const createSortHandler = (id: keyof WeightedPhrase) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (id !== orderBy) {
      setOrder('asc')
      return setOrderBy(id)
    }
    if (order === 'asc') {
      return setOrder('desc')
    }
    if (order === 'desc') {
      return setOrder('asc')
    }
  }

  useEffect(() => {
    setPage(0)
  }, [shownPhrases])

  return (
    <Paper variant="outlined">
      <TableContainer>
        <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'phrase'}
                  direction={orderBy === 'phrase' ? order : 'asc'}
                  onClick={createSortHandler('phrase')}
                >
                  Phrase
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'modified'}
                  direction={orderBy === 'modified' ? order : 'asc'}
                  onClick={createSortHandler('modified')}
                >
                  Date Modified
                </TableSortLabel>
              </TableCell>
              {category === 'weighted' && (
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'weight'}
                    direction={orderBy === 'weight' ? order : 'asc'}
                    onClick={createSortHandler('weight')}
                  >
                    Weighting
                  </TableSortLabel>
                </TableCell>
              )}
              <TableCell align="right" sx={{ maxWidth: 80 }}>
                Edit/Delete
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getPhrases().map((row, index) => (
              <TableRow
                key={`keyword-entry-table-item-${index}`}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  height: 47,
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="body2" noWrap sx={{ minWidth: 150, maxWidth: 400 }}>
                    {JSON.stringify(row?.phrase) || ''}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant="body2" noWrap>
                    {row?.modified
                      ? Intl.DateTimeFormat().resolvedOptions().timeZone.substring(0, 7) === 'America'
                        ? Intl.DateTimeFormat('en-US', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                          }).format(new Date(row?.modified))
                        : Intl.DateTimeFormat('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                          }).format(new Date(row?.modified))
                      : ''}
                  </Typography>
                </TableCell>
                {category === 'weighted' && <TableCell>{row?.weight}</TableCell>}
                <TableCell align="right" sx={{ maxWidth: 80 }}>
                  {row?.phrase && (
                    <IconButton size="small" disabled={loading || !row} onClick={(e) => handleClickEdit(e, row)}>
                      <EditIcon />
                    </IconButton>
                  )}
                  {row?.phrase && (
                    <IconButton size="small" disabled={loading || !row} onClick={(e) => handleClickDelete(e, row)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
        component="div"
        count={shownPhrases.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default PhrasesTable
