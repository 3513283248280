import { Alert, Button, Collapse, Dialog, Grid, TextField, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import { addDirectives, Directive } from '../util/Api.tsx'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import useIsMounted from 'react-is-mounted-hook'
import handleError from '../util/Error.tsx'
import { Box } from '@mui/system'

interface AddDirectiveDialogProps {
  dark: boolean
  open: boolean
  onClose: () => void
  onAdd: (dir: Directive[], id: string) => void
}

const AddDirectiveDialog = ({
  dark = false,
  open = false,
  onAdd = () => {},
  onClose = () => {},
}: AddDirectiveDialogProps) => {
  const [name, setName] = useState('')
  const isMounted = useIsMounted()
  const [id, setId] = useState('')
  const [description, setDescription] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSave = async () => {
    try {
      setLoading(true)
      const data = await addDirectives(id, name, description)
      if (!isMounted()) return
      onAdd(data.directives, id)
      onClose()
      setError('')
      setDescription('')
      setId('')
      setName('')
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ height: '100%' }}>
        <div style={{ height: 'auto' }}>
          <Grid container padding={2} spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                Create a new Content Modification
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Collapse in={Boolean(error)}>
            <Box>
              <Grid container padding={2} justifyContent="center">
                <Grid item xs={12}>
                  <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
                    {error || ' '}
                  </Alert>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Grid
            container
            padding={2}
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ minWidth: 600 }}
          >
            <Grid item flexGrow={1}>
              <Tooltip title="Must be unique, format is as follows: service this is intended for (full stop), what it is doing (full stop), followed by a name e.g. sphirewall.contentmod.youtube-safe-search">
                <Typography variant="overline">ID</Typography>
              </Tooltip>
              <TextField variant="standard" fullWidth onChange={(e) => setId(e.target.value)} value={id} />
            </Grid>
            <Grid item>
              <Typography variant="overline">Display Name</Typography>
              <TextField variant="standard" fullWidth onChange={(e) => setName(e.target.value)} value={name} />
            </Grid>
          </Grid>
          <Grid container padding={2} spacing={2} alignItems="center">
            <Grid item flexGrow={1}>
              <Tooltip title="What the Content Mod will do or alter">
                <Typography variant="overline">Description</Typography>
              </Tooltip>
              <TextField
                value={description}
                fullWidth
                multiline
                minRows={2}
                onChange={(e) => setDescription(e.target.value)}
                helperText={`${description.length} / 500`}
              />
            </Grid>
          </Grid>
          <Grid container padding={2} spacing={2}>
            <Grid item>
              <Typography variant="body2">
                For Favicons, Conditions and Action please edit through the Content Modifications page after this
                Content Mod has been created
              </Typography>
            </Grid>
          </Grid>
          <Grid container padding={2} spacing={2}>
            <Grid item>
              <Button variant="contained" onClick={handleSave} disabled={loading} color="primary">
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}

export default AddDirectiveDialog
