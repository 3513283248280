import React from 'react'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { useTheme } from '@mui/material/styles'
import { TransitionProps } from '@mui/material/transitions'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface FullScreenDialogProps {
  open?: boolean
  onClose?: () => void
  title?: string
  actions?: React.ReactElement<any>
  children?: React.ReactElement<any>
  [key: string]: any
}

const FullScreenDialog = ({
  open = false,
  onClose = () => {},
  title = '',
  actions,
  children,
  ...props
}: FullScreenDialogProps) => {
  const theme = useTheme()

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition} {...props}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>
          {actions}
        </Toolbar>
      </AppBar>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>{children}</div>
      </div>
    </Dialog>
  )
}

export default FullScreenDialog
