import React, { useState } from 'react'
import useIsMounted from 'react-is-mounted-hook'

import handleError from '../util/Error.tsx'
import { addURLSecondary, reclassifyBulkUrls, SignatureUrlInfo } from '../util/Api.tsx'
import UrlImportDialog from './UrlImportDialog.tsx'

interface RecatDialogProps {
  open?: boolean
  signatureId: string
  onClose?: () => void
  onRefresh?: () => void
  secondaryURLs?: boolean
}

const RecatDialog = ({
  open = false,
  signatureId = '',
  onClose = () => {},
  onRefresh = () => {},
  secondaryURLs = false,
}: RecatDialogProps) => {
  const isMounted = useIsMounted()
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    onClose()
  }

  const handleStartRecat = async (urlsInfo: SignatureUrlInfo[]) => {
    try {
      setLoading(true)
      try {
        if (secondaryURLs) {
          await addURLSecondary(
            urlsInfo.map((url: SignatureUrlInfo) => ({
              url: url.url,
              signatureId: url.signatureId,
              primarySig: url.primarySig,
            })),
            signatureId
          )
        } else {
          await reclassifyBulkUrls(
            urlsInfo.map((url: SignatureUrlInfo) => ({
              url: url.url,
              signatureId: url.signatureId,
              primarySig: url.primarySig,
            })),
            signatureId,
            ''
          )
        }
        alert('URLs have been imported')
      } catch (err) {
        alert(err)
        return null
      }
      onRefresh()
      onClose()
    } catch (err) {
      const { msg } = handleError(err)
      alert(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  return (
    <UrlImportDialog
      erroredUrls={[]}
      open={open}
      signatureId={signatureId}
      loading={loading}
      onClose={handleClose}
      onSubmit={handleStartRecat}
      secondary={secondaryURLs}
    />
  )
}

export default RecatDialog
