import React, { useState } from 'react'
import useIsMounted from 'react-is-mounted-hook'

import { useTheme } from '@mui/material/styles'

import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import LoadingButton from '@mui/lab/LoadingButton'
import handleError from '../util/Error.tsx'
import { publishBoxes } from '../util/Api.tsx'

interface PublishBoxesDialogProps {
  open?: boolean
  onClose?: () => void
}

const PublishBoxesDialog = ({ open = false, onClose = () => {} }: PublishBoxesDialogProps) => {
  const theme = useTheme()
  const isMounted = useIsMounted()

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    if (loading) return
    onClose()
  }

  const handlePublish = async () => {
    try {
      setLoading(true)
      const { msg } = await publishBoxes()
      alert(msg)
      onClose()
    } catch (err) {
      const { msg } = handleError(err)
      setError(msg)
    } finally {
      if (!isMounted()) return
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <div style={{ height: '100%', backgroundColor: theme.palette.background.default }}>
        <div style={{ height: 'auto', backgroundColor: theme.palette.background.default }}>
          <Grid container padding={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" noWrap>
                Update Boxes
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Collapse in={Boolean(error)}>
            <Box>
              <Grid container padding={2} justifyContent="center">
                <Grid item xs={12}>
                  <Alert severity="error" variant="outlined" sx={{ mb: 2 }}>
                    {error || ' '}
                  </Alert>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Grid container padding={2} spacing={2} justifyContent="center">
            <Grid item>
              <Typography>
                Send new Appindex versions as a configuration to boxes. Be careful! This cannot be undone. You have been
                warned.
              </Typography>
            </Grid>
            <Grid item>
              <LoadingButton variant="contained" loading={loading} onClick={handlePublish}>
                Update Boxes Now
              </LoadingButton>
            </Grid>
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}

export default PublishBoxesDialog
